/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import KeitouzuItemTitle from "./KeitouzuItemTitle";
import { Flex, Icon, Text, View } from "@aws-amplify/ui-react";
import KeitouzuItemBadgeLanSwitch1 from "./KeitouzuItemBadgeLanSwitch1";
import KeitouzuItemBadgeInternetConnectionUnit1 from "./KeitouzuItemBadgeInternetConnectionUnit1";
import KeitouzuItemBadgeMeasurementPcMain from "./KeitouzuItemBadgeMeasurementPcMain";
import KeitouzuItemBadgeMonitoringPc from "./KeitouzuItemBadgeMonitoringPc";
import KeitouzuItemBadgeLanSwitch3 from "./KeitouzuItemBadgeLanSwitch3";
import KeitouzuItemBadgeMeasurementPcSub from "./KeitouzuItemBadgeMeasurementPcSub";
import KeitouzuItemBadgeInternetConnectionUnit2 from "./KeitouzuItemBadgeInternetConnectionUnit2";
import KeitouzuItemBadgeLanSwitch2 from "./KeitouzuItemBadgeLanSwitch2";
import KeitouzuItemAntenna from "./KeitouzuItemAntenna";
import KeitouzuItemBadgeLanSwitch4 from "./KeitouzuItemBadgeLanSwitch4";
import KeitouzuItemBadgeDirectCurrentPower1 from "./KeitouzuItemBadgeDirectCurrentPower1";
import KeitouzuItemBadgeNoiseSource from "./KeitouzuItemBadgeNoiseSource";
import KeitouzuItemBadgeGpsSensor1 from "./KeitouzuItemBadgeGpsSensor1";
import KeitouzuItemBadgeDirectCurrentPower2 from "./KeitouzuItemBadgeDirectCurrentPower2";
import KeitouzuItemBadgeLowNoiseAmplifier from "./KeitouzuItemBadgeLowNoiseAmplifier";
import KeitouzuItemBadgeDirectCurrentPower3 from "./KeitouzuItemBadgeDirectCurrentPower3";
import KeitouzuItemBadgeLowNoiseAmplifierSwitch1 from "./KeitouzuItemBadgeLowNoiseAmplifierSwitch1";
import KeitouzuItemBadgeDirectCurrentPower4 from "./KeitouzuItemBadgeDirectCurrentPower4";
import KeitouzuItemBadgeLowNoiseAmplifierSwitch2 from "./KeitouzuItemBadgeLowNoiseAmplifierSwitch2";
import KeitouzuItemBadgeDirectCurrentPower5 from "./KeitouzuItemBadgeDirectCurrentPower5";
import KeitouzuItemBadgeLowNoiseAmplifierSwitch3 from "./KeitouzuItemBadgeLowNoiseAmplifierSwitch3";
import KeitouzuItemBadgeGpsSensor2 from "./KeitouzuItemBadgeGpsSensor2";
import KeitouzuItemBadgeSpectrumAnalyzer2 from "./KeitouzuItemBadgeSpectrumAnalyzer2";
import KeitouzuItemBadgeTemperatureSensor from "./KeitouzuItemBadgeTemperatureSensor";
import KeitouzuItemBadgeEnergyGazer from "./KeitouzuItemBadgeEnergyGazer";
import KeitouzuItemBadgeSpectrumAnalyzer1 from "./KeitouzuItemBadgeSpectrumAnalyzer1";
import KeitouzuItemBadgeOutdoorCabinetTemperature from "./KeitouzuItemBadgeOutdoorCabinetTemperature";
import KeitouzuItemBadgeNoiseSourceTemperature from "./KeitouzuItemBadgeNoiseSourceTemperature";
export default function KeitouzuFrameOther(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      gap="10px"
      direction="column"
      width="unset"
      height="unset"
      justifyContent="flex-start"
      alignItems="center"
      overflow="hidden"
      position="relative"
      padding="0px 40px 16px 40px"
      backgroundColor="rgba(245,245,245,1)"
      {...rest}
      {...getOverrideProps(overrides, "KeitouzuFrameOther")}
    >
      <Flex
        padding="0px 0px 0px 0px"
        width="752px"
        height="638px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        position="relative"
        {...getOverrideProps(overrides, "Keitouzu")}
      >
        <Flex
          gap="0"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          position="absolute"
          top="0px"
          left="448px"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Kyokusya")}
        >
          <KeitouzuItemTitle
            display="flex"
            gap="10px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            position="relative"
            borderRadius="8px 8px 0px 0px"
            padding="12px 16px 0px 16px"
            backgroundColor="rgba(255,255,255,1)"
            {...getOverrideProps(overrides, "Keitouzu/Item/Title571118060")}
          ></KeitouzuItemTitle>
          <View
            width="304px"
            height="612px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            position="relative"
            borderRadius="0px 8px 8px 8px"
            padding="0px 0px 0px 0px"
            backgroundColor="rgba(255,255,255,1)"
            {...getOverrideProps(overrides, "Contents571118061")}
          >
            <View
              padding="0px 0px 0px 0px"
              width="256px"
              height="475px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="48px"
              left="calc(50% - 128px - 0px)"
              {...getOverrideProps(overrides, "Right")}
            >
              <View
                padding="0px 0px 0px 0px"
                width="195px"
                height="435px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="25px"
                left="36px"
                {...getOverrideProps(overrides, "Line571118063")}
              >
                <View
                  width="2px"
                  height="57px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  position="absolute"
                  top="57.7%"
                  bottom="29.2%"
                  left="61.54%"
                  right="37.44%"
                  padding="0px 0px 0px 0px"
                  {...getOverrideProps(
                    overrides,
                    "Keitouzu/Item/Line571118064"
                  )}
                >
                  <Icon
                    width="2px"
                    height="57px"
                    viewBox={{
                      minX: 0,
                      minY: 0,
                      width: 2,
                      height: 56.999996185302734,
                    }}
                    paths={[
                      {
                        d: "M0 2.03571L0 0L2 0L2 2.03571L0 2.03571ZM0 10.1786L0 6.10714L2 6.10714L2 10.1786L0 10.1786ZM0 18.3214L0 14.25L2 14.25L2 18.3214L0 18.3214ZM0 26.4643L0 22.3929L2 22.3929L2 26.4643L0 26.4643ZM0 34.6071L0 30.5357L2 30.5357L2 34.6071L0 34.6071ZM0 42.75L0 38.6786L2 38.6786L2 42.75L0 42.75ZM0 50.8928L0 46.8214L2 46.8214L2 50.8928L0 50.8928ZM0 57L0 54.9643L2 54.9643L2 57L0 57Z",
                        fill: "rgba(100,100,100,1)",
                        fillRule: "evenodd",
                      },
                    ]}
                    display="block"
                    gap="unset"
                    alignItems="unset"
                    justifyContent="unset"
                    position="absolute"
                    top="0%"
                    bottom="0%"
                    left="0%"
                    right="0%"
                    {...getOverrideProps(overrides, "Vector (Stroke)571223503")}
                  ></Icon>
                </View>
                <View
                  width="67px"
                  height="65px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  position="absolute"
                  top="0%"
                  bottom="85.06%"
                  left="5.64%"
                  right="60%"
                  padding="0px 0px 0px 0px"
                  {...getOverrideProps(
                    overrides,
                    "Keitouzu/Item/Line571118066"
                  )}
                >
                  <Icon
                    width="75.36px"
                    height="66px"
                    viewBox={{
                      minX: 0,
                      minY: 0,
                      width: 75.36396026611328,
                      height: 66,
                    }}
                    paths={[
                      {
                        d: "M67.2929 0.292893C67.6834 -0.0976311 68.3166 -0.097631 68.7071 0.292893L75.0711 6.65686C75.4616 7.04738 75.4616 7.68054 75.0711 8.07107C74.6805 8.46159 74.0474 8.46159 73.6569 8.07107L69 3.41421L69 34.5L2 34.5L2 66L0 66L3.03984e-06 32.5L67 32.5L67 3.41421L62.3431 8.07107C61.9526 8.46159 61.3195 8.46159 60.9289 8.07107C60.5384 7.68054 60.5384 7.04738 60.9289 6.65685L67.2929 0.292893Z",
                        fill: "rgba(100,100,100,1)",
                        fillRule: "evenodd",
                      },
                    ]}
                    display="block"
                    gap="unset"
                    alignItems="unset"
                    justifyContent="unset"
                    position="absolute"
                    top="-1.54%"
                    bottom="0%"
                    left="-1.49%"
                    right="-10.99%"
                    {...getOverrideProps(overrides, "Vector (Stroke)571223478")}
                  ></Icon>
                </View>
                <View
                  width="67px"
                  height="65px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  position="absolute"
                  top="0%"
                  bottom="85.06%"
                  left="54.36%"
                  right="11.28%"
                  padding="0px 0px 0px 0px"
                  {...getOverrideProps(
                    overrides,
                    "Keitouzu/Item/Line571118068"
                  )}
                >
                  <Icon
                    width="75.36px"
                    height="66px"
                    viewBox={{
                      minX: 0,
                      minY: 0,
                      width: 75.36396026611328,
                      height: 66,
                    }}
                    paths={[
                      {
                        d: "M6.65685 0.292893C7.04738 -0.097631 7.68054 -0.0976311 8.07107 0.292893L14.435 6.65685C14.8256 7.04738 14.8256 7.68054 14.435 8.07107C14.0445 8.46159 13.4113 8.46159 13.0208 8.07107L8.36396 3.41421L8.36397 32.5L75.364 32.5L75.364 66L73.364 66L73.364 34.5L6.36397 34.5L6.36396 3.41421L1.70711 8.07107C1.31658 8.46159 0.683418 8.46159 0.292893 8.07107C-0.097631 7.68054 -0.097631 7.04738 0.292893 6.65686L6.65685 0.292893Z",
                        fill: "rgba(100,100,100,1)",
                        fillRule: "evenodd",
                      },
                    ]}
                    display="block"
                    gap="unset"
                    alignItems="unset"
                    justifyContent="unset"
                    position="absolute"
                    top="-1.54%"
                    bottom="0%"
                    left="-10.99%"
                    right="-1.49%"
                    {...getOverrideProps(overrides, "Vector (Stroke)571223483")}
                  ></Icon>
                </View>
                <View
                  width="80px"
                  height="2px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  position="absolute"
                  top="198px"
                  left="52px"
                  padding="0px 0px 0px 0px"
                  {...getOverrideProps(
                    overrides,
                    "Keitouzu/Item/Line571118070"
                  )}
                >
                  <Icon
                    width="80px"
                    height="2px"
                    viewBox={{ minX: 0, minY: 0, width: 80, height: 2 }}
                    paths={[
                      {
                        d: "M80 2L0 2L0 0L80 0L80 2Z",
                        fill: "rgba(100,100,100,1)",
                        fillRule: "evenodd",
                      },
                    ]}
                    display="block"
                    gap="unset"
                    alignItems="unset"
                    justifyContent="unset"
                    position="absolute"
                    top="0%"
                    bottom="0%"
                    left="0%"
                    right="0%"
                    {...getOverrideProps(overrides, "Vector (Stroke)571118071")}
                  ></Icon>
                </View>
                <View
                  width="80px"
                  height="2px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  position="absolute"
                  top="78px"
                  left="52px"
                  padding="0px 0px 0px 0px"
                  {...getOverrideProps(
                    overrides,
                    "Keitouzu/Item/Line571118072"
                  )}
                >
                  <Icon
                    width="80px"
                    height="2px"
                    viewBox={{ minX: 0, minY: 0, width: 80, height: 2 }}
                    paths={[
                      {
                        d: "M80 2L0 2L0 0L80 0L80 2Z",
                        fill: "rgba(100,100,100,1)",
                        fillRule: "evenodd",
                      },
                    ]}
                    display="block"
                    gap="unset"
                    alignItems="unset"
                    justifyContent="unset"
                    position="absolute"
                    top="0%"
                    bottom="0%"
                    left="0%"
                    right="0%"
                    {...getOverrideProps(overrides, "Vector (Stroke)571118073")}
                  ></Icon>
                </View>
                <View
                  width="2px"
                  height="40px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  position="absolute"
                  top="268px"
                  left="110px"
                  padding="0px 0px 0px 0px"
                  {...getOverrideProps(
                    overrides,
                    "Keitouzu/Item/Line571118074"
                  )}
                >
                  <Icon
                    width="2px"
                    height="40px"
                    viewBox={{ minX: 0, minY: 0, width: 2, height: 40 }}
                    paths={[
                      {
                        d: "M0 40L0 0L2 0L2 40L0 40Z",
                        fill: "rgba(100,100,100,1)",
                        fillRule: "evenodd",
                      },
                    ]}
                    display="block"
                    gap="unset"
                    alignItems="unset"
                    justifyContent="unset"
                    position="absolute"
                    top="0%"
                    bottom="0%"
                    left="0%"
                    right="0%"
                    {...getOverrideProps(overrides, "Vector (Stroke)571118075")}
                  ></Icon>
                </View>
                <View
                  width="2px"
                  height="352px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  position="absolute"
                  top="83px"
                  left="0px"
                  padding="0px 0px 0px 0px"
                  {...getOverrideProps(
                    overrides,
                    "Keitouzu/Item/Line571118076"
                  )}
                >
                  <Icon
                    width="2px"
                    height="352px"
                    viewBox={{ minX: 0, minY: 0, width: 2, height: 352 }}
                    paths={[
                      {
                        d: "M0 352L0 0L2 0L2 352L0 352Z",
                        fill: "rgba(100,100,100,1)",
                        fillRule: "evenodd",
                      },
                    ]}
                    display="block"
                    gap="unset"
                    alignItems="unset"
                    justifyContent="unset"
                    position="absolute"
                    top="0%"
                    bottom="0%"
                    left="0%"
                    right="0%"
                    {...getOverrideProps(overrides, "Vector (Stroke)571118077")}
                  ></Icon>
                </View>
                <View
                  width="183px"
                  height="40px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  position="absolute"
                  top="61.61%"
                  bottom="29.2%"
                  left="0.51%"
                  right="5.64%"
                  padding="0px 0px 0px 0px"
                  {...getOverrideProps(
                    overrides,
                    "Keitouzu/Item/Line571118078"
                  )}
                >
                  <Icon
                    width="184px"
                    height="41px"
                    viewBox={{
                      minX: 0,
                      minY: 0,
                      width: 183.99998474121094,
                      height: 41,
                    }}
                    paths={[
                      {
                        d: "M182 2L0 2L0 1.72853e-06L184 0L184 41L182 41L182 2Z",
                        fill: "rgba(100,100,100,1)",
                        fillRule: "evenodd",
                      },
                    ]}
                    display="block"
                    gap="unset"
                    alignItems="unset"
                    justifyContent="unset"
                    position="absolute"
                    top="-2.5%"
                    bottom="0%"
                    left="0%"
                    right="-0.55%"
                    {...getOverrideProps(overrides, "Vector (Stroke)571223498")}
                  ></Icon>
                </View>
                <View
                  width="2px"
                  height="216px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  position="absolute"
                  top="21.38%"
                  bottom="28.97%"
                  left="98.97%"
                  right="0%"
                  padding="0px 0px 0px 0px"
                  {...getOverrideProps(
                    overrides,
                    "Keitouzu/Item/Line571118080"
                  )}
                >
                  <Icon
                    width="2px"
                    height="216px"
                    viewBox={{
                      minX: 0,
                      minY: 0,
                      width: 2,
                      height: 216.00001525878906,
                    }}
                    paths={[
                      {
                        d: "M0 2L0 0L2 0L2 2L0 2ZM0 10L0 6L2 6L2 10L0 10ZM0 18L0 14L2 14L2 18L0 18ZM0 26L0 22L2 22L2 26L0 26ZM0 34L0 30L2 30L2 34L0 34ZM0 42L0 38L2 38L2 42L0 42ZM0 50L0 46L2 46L2 50L0 50ZM0 58L0 54L2 54L2 58L0 58ZM0 66L0 62L2 62L2 66L0 66ZM0 74L0 70L2 70L2 74L0 74ZM0 82L0 78L2 78L2 82L0 82ZM0 90L0 86L2 86L2 90L0 90ZM0 98L0 94L2 94L2 98L0 98ZM0 106L0 102L2 102L2 106L0 106ZM0 114L0 110L2 110L2 114L0 114ZM0 122L0 118L2 118L2 122L0 122ZM0 130L0 126L2 126L2 130L0 130ZM0 138L0 134L2 134L2 138L0 138ZM0 146L0 142L2 142L2 146L0 146ZM0 154L0 150L2 150L2 154L0 154ZM0 162L0 158L2 158L2 162L0 162ZM0 170L0 166L2 166L2 170L0 170ZM0 178L0 174L2 174L2 178L0 178ZM0 186L0 182L2 182L2 186L0 186ZM0 194L0 190L2 190L2 194L0 194ZM0 202L0 198L2 198L2 202L0 202ZM0 210L0 206L2 206L2 210L0 210ZM0 216L0 214L2 214L2 216L0 216Z",
                        fill: "rgba(100,100,100,1)",
                        fillRule: "evenodd",
                      },
                    ]}
                    display="block"
                    gap="unset"
                    alignItems="unset"
                    justifyContent="unset"
                    position="absolute"
                    top="0%"
                    bottom="0%"
                    left="0%"
                    right="0%"
                    {...getOverrideProps(overrides, "Vector (Stroke)571223488")}
                  ></Icon>
                </View>
                <View
                  width="183px"
                  height="56px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  position="absolute"
                  top="57.7%"
                  bottom="29.43%"
                  left="5.64%"
                  right="0.51%"
                  padding="0px 0px 0px 0px"
                  {...getOverrideProps(
                    overrides,
                    "Keitouzu/Item/Line571118082"
                  )}
                >
                  <Icon
                    width="184px"
                    height="57px"
                    viewBox={{ minX: 0, minY: 0, width: 184, height: 57 }}
                    paths={[
                      {
                        d: "M0 0L2.98913 0L2.98913 2L2 2L2 3L0 3L0 0ZM10.9457 2L6.96739 2L6.96739 5.96046e-08L10.9457 1.19209e-07L10.9457 2ZM18.9022 2L14.9239 2L14.9239 1.78814e-07L18.9022 2.38419e-07L18.9022 2ZM26.8587 2L22.8804 2L22.8804 2.98023e-07L26.8587 3.57628e-07L26.8587 2ZM34.8152 2L30.837 2L30.837 4.17233e-07L34.8152 4.76837e-07L34.8152 2ZM42.7717 2L38.7935 2L38.7935 4.76837e-07L42.7717 5.36442e-07L42.7717 2ZM50.7283 2L46.75 2L46.75 5.96046e-07L50.7283 6.55651e-07L50.7283 2ZM58.6848 2L54.7065 2L54.7065 7.15256e-07L58.6848 7.7486e-07L58.6848 2ZM66.6413 2L62.663 2L62.663 8.34465e-07L66.6413 8.9407e-07L66.6413 2ZM74.5978 2L70.6196 2L70.6196 9.53674e-07L74.5978 1.01328e-06L74.5978 2ZM82.5543 2L78.5761 2L78.5761 1.01328e-06L82.5543 1.07288e-06L82.5543 2ZM90.5109 2L86.5326 2L86.5326 1.13249e-06L90.5109 1.19209e-06L90.5109 2ZM98.4674 2L94.4891 2L94.4891 1.2517e-06L98.4674 1.3113e-06L98.4674 2ZM106.424 2L102.446 2L102.446 1.37091e-06L106.424 1.43051e-06L106.424 2ZM114.38 2L110.402 2L110.402 1.49012e-06L114.38 1.49012e-06L114.38 2ZM122.337 2L118.359 2L118.359 1.54972e-06L122.337 1.60933e-06L122.337 2ZM130.294 2L126.315 2L126.315 1.66893e-06L130.294 1.72853e-06L130.294 2ZM138.25 2L134.272 2L134.272 1.78814e-06L138.25 1.84774e-06L138.25 2ZM146.207 2L142.228 2L142.228 1.90735e-06L146.207 1.96695e-06L146.207 2ZM154.163 2L150.185 2L150.185 1.96695e-06L154.163 2.02656e-06L154.163 2ZM162.12 2L158.141 2L158.141 2.08616e-06L162.12 2.14577e-06L162.12 2ZM170.076 2L166.098 2L166.098 2.20537e-06L170.076 2.26498e-06L170.076 2ZM178.033 2L174.054 2L174.054 2.32458e-06L178.033 2.38419e-06L178.033 2ZM184 2L182.011 2L182.011 2.44379e-06L184 2.44379e-06L184 2ZM2 7L2 11L0 11L0 7L2 7ZM2 15L2 19L0 19L0 15L2 15ZM2 23L2 27L0 27L0 23L2 23ZM2 31L2 35L0 35L0 31L2 31ZM2 39L2 43L0 43L0 39L2 39ZM2 47L2 51L0 51L0 47L2 47ZM2 55L2 57L0 57L0 55L2 55Z",
                        fill: "rgba(100,100,100,1)",
                        fillRule: "evenodd",
                      },
                    ]}
                    display="block"
                    gap="unset"
                    alignItems="unset"
                    justifyContent="unset"
                    position="absolute"
                    top="-1.79%"
                    bottom="0%"
                    left="-0.55%"
                    right="0%"
                    {...getOverrideProps(overrides, "Vector (Stroke)571223493")}
                  ></Icon>
                </View>
              </View>
              <View
                padding="0px 0px 0px 0px"
                width="256px"
                height="475px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="0px"
                left="0px"
                {...getOverrideProps(overrides, "Badge571118084")}
              >
                <KeitouzuItemBadgeLanSwitch1
                  display="flex"
                  gap="10px"
                  direction="column"
                  width="unset"
                  height="unset"
                  justifyContent="center"
                  alignItems="center"
                  position="absolute"
                  top="202px"
                  left="0px"
                  borderRadius="4px"
                  padding="8px 12px 8px 12px"
                  backgroundColor="rgba(38,193,147,1)"
                  type="Default"
                  {...getOverrideProps(
                    overrides,
                    "Keitouzu/Item/Badge/LanSwitch1"
                  )}
                ></KeitouzuItemBadgeLanSwitch1>
                <KeitouzuItemBadgeInternetConnectionUnit1
                  display="flex"
                  gap="10px"
                  direction="column"
                  width="unset"
                  height="unset"
                  justifyContent="center"
                  alignItems="center"
                  position="absolute"
                  top="89px"
                  left="0px"
                  borderRadius="4px"
                  type="White"
                  {...getOverrideProps(
                    overrides,
                    "Keitouzu/Item/Badge/InternetConnectionUnit1"
                  )}
                ></KeitouzuItemBadgeInternetConnectionUnit1>
                <KeitouzuItemBadgeMeasurementPcMain
                  display="flex"
                  gap="10px"
                  direction="column"
                  width="unset"
                  height="unset"
                  justifyContent="center"
                  alignItems="center"
                  position="absolute"
                  top="332px"
                  left="118px"
                  borderRadius="4px"
                  padding="8px 12px 8px 12px"
                  backgroundColor="rgba(38,193,147,1)"
                  type="Keitouzu"
                  {...getOverrideProps(
                    overrides,
                    "Keitouzu/Item/Badge/MeasurementPcMain"
                  )}
                ></KeitouzuItemBadgeMeasurementPcMain>
                <KeitouzuItemBadgeMonitoringPc
                  display="flex"
                  gap="10px"
                  direction="column"
                  width="unset"
                  height="unset"
                  justifyContent="center"
                  alignItems="center"
                  position="absolute"
                  top="445px"
                  left="0px"
                  borderRadius="4px"
                  type="White"
                  {...getOverrideProps(
                    overrides,
                    "Keitouzu/Item/Badge/MonitoringPc"
                  )}
                ></KeitouzuItemBadgeMonitoringPc>
                <KeitouzuItemBadgeLanSwitch3
                  display="flex"
                  gap="10px"
                  direction="column"
                  width="unset"
                  height="unset"
                  justifyContent="center"
                  alignItems="center"
                  position="absolute"
                  top="332px"
                  left="0px"
                  borderRadius="4px"
                  padding="8px 12px 8px 12px"
                  backgroundColor="rgba(38,193,147,1)"
                  type="Default"
                  {...getOverrideProps(
                    overrides,
                    "Keitouzu/Item/Badge/LanSwitch3"
                  )}
                ></KeitouzuItemBadgeLanSwitch3>
                <Text
                  fontFamily="Noto Sans"
                  fontSize="14px"
                  fontWeight="700"
                  color="rgba(53,56,59,1)"
                  lineHeight="16.80000114440918px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  letterSpacing="0.55px"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  position="absolute"
                  top="0px"
                  left="calc(50% - 58.5px - -0.5px)"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="インターネットへ"
                  {...getOverrideProps(overrides, "text")}
                ></Text>
                <KeitouzuItemBadgeMeasurementPcSub
                  display="flex"
                  gap="10px"
                  direction="column"
                  width="unset"
                  height="unset"
                  justifyContent="center"
                  alignItems="center"
                  position="absolute"
                  top="332px"
                  left="191px"
                  borderRadius="4px"
                  padding="8px 12px 8px 12px"
                  backgroundColor="rgba(38,193,147,1)"
                  type="Default"
                  {...getOverrideProps(
                    overrides,
                    "Keitouzu/Item/Badge/MeasurementPcSub"
                  )}
                ></KeitouzuItemBadgeMeasurementPcSub>
                <KeitouzuItemBadgeInternetConnectionUnit2
                  display="flex"
                  gap="10px"
                  direction="column"
                  width="unset"
                  height="unset"
                  justifyContent="center"
                  alignItems="center"
                  position="absolute"
                  top="89px"
                  left="162px"
                  borderRadius="4px"
                  type="White"
                  {...getOverrideProps(
                    overrides,
                    "Keitouzu/Item/Badge/InternetConnectionUnit2"
                  )}
                ></KeitouzuItemBadgeInternetConnectionUnit2>
                <KeitouzuItemBadgeLanSwitch2
                  display="flex"
                  gap="10px"
                  direction="column"
                  width="unset"
                  height="unset"
                  justifyContent="center"
                  alignItems="center"
                  position="absolute"
                  top="202px"
                  left="157px"
                  borderRadius="4px"
                  padding="8px 12px 8px 12px"
                  backgroundColor="rgba(38,193,147,1)"
                  type="Default"
                  {...getOverrideProps(
                    overrides,
                    "Keitouzu/Item/Badge/LanSwitch2"
                  )}
                ></KeitouzuItemBadgeLanSwitch2>
              </View>
            </View>
          </View>
        </Flex>
        <Flex
          gap="0"
          direction="column"
          width="408px"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          position="absolute"
          top="0px"
          left="0px"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Cabinet")}
        >
          <KeitouzuItemTitle
            display="flex"
            gap="10px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            position="relative"
            borderRadius="8px 8px 0px 0px"
            padding="12px 16px 0px 16px"
            backgroundColor="rgba(255,255,255,1)"
            {...getOverrideProps(overrides, "Keitouzu/Item/Title571118095")}
          ></KeitouzuItemTitle>
          <View
            width="unset"
            height="612px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            borderRadius="0px 8px 8px 8px"
            padding="0px 0px 0px 0px"
            backgroundColor="rgba(255,255,255,1)"
            {...getOverrideProps(overrides, "Contents571118096")}
          >
            <View
              padding="0px 0px 0px 0px"
              width="360px"
              height="548px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="calc(50% - 274px - -16px)"
              left="calc(50% - 180px - 0px)"
              {...getOverrideProps(overrides, "Left")}
            >
              <View
                padding="0px 0px 0px 0px"
                width="295px"
                height="489px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="44px"
                left="16px"
                {...getOverrideProps(overrides, "Line571118098")}
              >
                <View
                  width="56px"
                  height="489px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  position="absolute"
                  top="0%"
                  bottom="0%"
                  left="81.02%"
                  right="0%"
                  padding="0px 0px 0px 0px"
                  {...getOverrideProps(
                    overrides,
                    "Keitouzu/Item/Line571118099"
                  )}
                >
                  <Icon
                    width="57px"
                    height="490px"
                    viewBox={{ minX: 0, minY: 0, width: 57, height: 490 }}
                    paths={[
                      {
                        d: "M55 488L55 0L57 0L57 490L0 490L0 488L55 488Z",
                        fill: "rgba(100,100,100,1)",
                        fillRule: "evenodd",
                      },
                    ]}
                    display="block"
                    gap="unset"
                    alignItems="unset"
                    justifyContent="unset"
                    position="absolute"
                    top="0%"
                    bottom="-0.2%"
                    left="0%"
                    right="-1.79%"
                    {...getOverrideProps(overrides, "Vector (Stroke)571223518")}
                  ></Icon>
                </View>
                <View
                  width="16px"
                  height="46px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  position="absolute"
                  top="90.59%"
                  bottom="0%"
                  left="36.61%"
                  right="57.97%"
                  padding="0px 0px 0px 0px"
                  {...getOverrideProps(
                    overrides,
                    "Keitouzu/Item/Line571118101"
                  )}
                >
                  <Icon
                    width="17px"
                    height="47px"
                    viewBox={{ minX: 0, minY: 0, width: 17, height: 47 }}
                    paths={[
                      {
                        d: "M15 45L15 0L17 0L17 47L0 47L0 45L15 45Z",
                        fill: "rgba(100,100,100,1)",
                        fillRule: "evenodd",
                      },
                    ]}
                    display="block"
                    gap="unset"
                    alignItems="unset"
                    justifyContent="unset"
                    position="absolute"
                    top="0%"
                    bottom="-2.17%"
                    left="0%"
                    right="-6.25%"
                    {...getOverrideProps(overrides, "Vector (Stroke)571223513")}
                  ></Icon>
                </View>
                <View
                  width="295px"
                  height="112px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  position="absolute"
                  top="9.82%"
                  bottom="67.28%"
                  left="0%"
                  right="0%"
                  padding="0px 0px 0px 0px"
                  {...getOverrideProps(
                    overrides,
                    "Keitouzu/Item/Line571118103"
                  )}
                >
                  <Icon
                    width="296px"
                    height="113px"
                    viewBox={{ minX: 0, minY: 0, width: 296, height: 113 }}
                    paths={[
                      {
                        d: "M0 0L2 0L2 111L296 111L296 113L0 113L0 0Z",
                        fill: "rgba(100,100,100,1)",
                        fillRule: "evenodd",
                      },
                    ]}
                    display="block"
                    gap="unset"
                    alignItems="unset"
                    justifyContent="unset"
                    position="absolute"
                    top="0%"
                    bottom="-0.89%"
                    left="-0.34%"
                    right="0%"
                    {...getOverrideProps(overrides, "Vector (Stroke)571223523")}
                  ></Icon>
                </View>
                <View
                  width="295px"
                  height="2px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  position="absolute"
                  top="58px"
                  left="0px"
                  padding="0px 0px 0px 0px"
                  {...getOverrideProps(
                    overrides,
                    "Keitouzu/Item/Line571118105"
                  )}
                >
                  <Icon
                    width="295px"
                    height="2px"
                    viewBox={{ minX: 0, minY: 0, width: 295, height: 2 }}
                    paths={[
                      {
                        d: "M295 2L0 2L0 0L295 0L295 2Z",
                        fill: "rgba(100,100,100,1)",
                        fillRule: "evenodd",
                      },
                    ]}
                    display="block"
                    gap="unset"
                    alignItems="unset"
                    justifyContent="unset"
                    position="absolute"
                    top="0%"
                    bottom="0%"
                    left="0%"
                    right="0%"
                    {...getOverrideProps(overrides, "Vector (Stroke)571118106")}
                  ></Icon>
                </View>
                <View
                  width="2px"
                  height="24px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  position="absolute"
                  top="124px"
                  left="173px"
                  padding="0px 0px 0px 0px"
                  {...getOverrideProps(
                    overrides,
                    "Keitouzu/Item/Line571118107"
                  )}
                >
                  <Icon
                    width="2px"
                    height="24px"
                    viewBox={{ minX: 0, minY: 0, width: 2, height: 24 }}
                    paths={[
                      {
                        d: "M0 24L0 0L2 0L2 24L0 24Z",
                        fill: "rgba(100,100,100,1)",
                        fillRule: "evenodd",
                      },
                    ]}
                    display="block"
                    gap="unset"
                    alignItems="unset"
                    justifyContent="unset"
                    position="absolute"
                    top="0%"
                    bottom="0%"
                    left="0%"
                    right="0%"
                    {...getOverrideProps(overrides, "Vector (Stroke)571118108")}
                  ></Icon>
                </View>
                <View
                  width="2px"
                  height="24px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  position="absolute"
                  top="24px"
                  left="173px"
                  padding="0px 0px 0px 0px"
                  {...getOverrideProps(
                    overrides,
                    "Keitouzu/Item/Line571118109"
                  )}
                >
                  <Icon
                    width="2px"
                    height="24px"
                    viewBox={{ minX: 0, minY: 0, width: 2, height: 24 }}
                    paths={[
                      {
                        d: "M0 24L0 0L2 0L2 24L0 24Z",
                        fill: "rgba(100,100,100,1)",
                        fillRule: "evenodd",
                      },
                    ]}
                    display="block"
                    gap="unset"
                    alignItems="unset"
                    justifyContent="unset"
                    position="absolute"
                    top="0%"
                    bottom="0%"
                    left="0%"
                    right="0%"
                    {...getOverrideProps(overrides, "Vector (Stroke)571118110")}
                  ></Icon>
                </View>
                <View
                  width="295px"
                  height="2px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  position="absolute"
                  top="212px"
                  left="0px"
                  padding="0px 0px 0px 0px"
                  {...getOverrideProps(
                    overrides,
                    "Keitouzu/Item/Line571118111"
                  )}
                >
                  <Icon
                    width="295px"
                    height="2px"
                    viewBox={{ minX: 0, minY: 0, width: 295, height: 2 }}
                    paths={[
                      {
                        d: "M295 2L0 2L0 0L295 0L295 2Z",
                        fill: "rgba(100,100,100,1)",
                        fillRule: "evenodd",
                      },
                    ]}
                    display="block"
                    gap="unset"
                    alignItems="unset"
                    justifyContent="unset"
                    position="absolute"
                    top="0px"
                    left="calc(50% - 147.5px - 0px)"
                    {...getOverrideProps(overrides, "Vector (Stroke)571118112")}
                  ></Icon>
                </View>
                <View
                  width="295px"
                  height="2px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  position="absolute"
                  top="258px"
                  left="0px"
                  padding="0px 0px 0px 0px"
                  {...getOverrideProps(
                    overrides,
                    "Keitouzu/Item/Line571118113"
                  )}
                >
                  <Icon
                    width="295px"
                    height="2px"
                    viewBox={{ minX: 0, minY: 0, width: 295, height: 2 }}
                    paths={[
                      {
                        d: "M295 2L0 2L0 0L295 0L295 2Z",
                        fill: "rgba(100,100,100,1)",
                        fillRule: "evenodd",
                      },
                    ]}
                    display="block"
                    gap="unset"
                    alignItems="unset"
                    justifyContent="unset"
                    position="absolute"
                    top="0%"
                    bottom="0%"
                    left="0%"
                    right="0%"
                    {...getOverrideProps(overrides, "Vector (Stroke)571118114")}
                  ></Icon>
                </View>
                <View
                  width="295px"
                  height="2px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  position="absolute"
                  top="304px"
                  left="0px"
                  padding="0px 0px 0px 0px"
                  {...getOverrideProps(
                    overrides,
                    "Keitouzu/Item/Line571118115"
                  )}
                >
                  <Icon
                    width="295px"
                    height="2px"
                    viewBox={{ minX: 0, minY: 0, width: 295, height: 2 }}
                    paths={[
                      {
                        d: "M295 2L0 2L0 0L295 0L295 2Z",
                        fill: "rgba(100,100,100,1)",
                        fillRule: "evenodd",
                      },
                    ]}
                    display="block"
                    gap="unset"
                    alignItems="unset"
                    justifyContent="unset"
                    position="absolute"
                    top="0%"
                    bottom="0%"
                    left="0%"
                    right="0%"
                    {...getOverrideProps(overrides, "Vector (Stroke)571118116")}
                  ></Icon>
                </View>
                <View
                  width="295px"
                  height="2px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  position="absolute"
                  top="350px"
                  left="0px"
                  padding="0px 0px 0px 0px"
                  {...getOverrideProps(
                    overrides,
                    "Keitouzu/Item/Line571118117"
                  )}
                >
                  <Icon
                    width="295px"
                    height="2px"
                    viewBox={{ minX: 0, minY: 0, width: 295, height: 2 }}
                    paths={[
                      {
                        d: "M295 2L0 2L0 0L295 0L295 2Z",
                        fill: "rgba(100,100,100,1)",
                        fillRule: "evenodd",
                      },
                    ]}
                    display="block"
                    gap="unset"
                    alignItems="unset"
                    justifyContent="unset"
                    position="absolute"
                    top="0%"
                    bottom="0%"
                    left="0%"
                    right="0%"
                    {...getOverrideProps(overrides, "Vector (Stroke)571118118")}
                  ></Icon>
                </View>
                <View
                  width="295px"
                  height="2px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  position="absolute"
                  top="396px"
                  left="0px"
                  padding="0px 0px 0px 0px"
                  {...getOverrideProps(
                    overrides,
                    "Keitouzu/Item/Line571118119"
                  )}
                >
                  <Icon
                    width="295px"
                    height="2px"
                    viewBox={{ minX: 0, minY: 0, width: 295, height: 2 }}
                    paths={[
                      {
                        d: "M295 2L0 2L0 0L295 0L295 2Z",
                        fill: "rgba(100,100,100,1)",
                        fillRule: "evenodd",
                      },
                    ]}
                    display="block"
                    gap="unset"
                    alignItems="unset"
                    justifyContent="unset"
                    position="absolute"
                    top="0%"
                    bottom="0%"
                    left="0%"
                    right="0%"
                    {...getOverrideProps(overrides, "Vector (Stroke)571118120")}
                  ></Icon>
                </View>
                <View
                  width="295px"
                  height="2px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  position="absolute"
                  top="442px"
                  left="0px"
                  padding="0px 0px 0px 0px"
                  {...getOverrideProps(
                    overrides,
                    "Keitouzu/Item/Line571118121"
                  )}
                >
                  <Icon
                    width="295px"
                    height="2px"
                    viewBox={{ minX: 0, minY: 0, width: 295, height: 2 }}
                    paths={[
                      {
                        d: "M295 2L0 2L0 0L295 0L295 2Z",
                        fill: "rgba(100,100,100,1)",
                        fillRule: "evenodd",
                      },
                    ]}
                    display="block"
                    gap="unset"
                    alignItems="unset"
                    justifyContent="unset"
                    position="absolute"
                    top="0%"
                    bottom="0%"
                    left="0px"
                    {...getOverrideProps(overrides, "Vector (Stroke)571118122")}
                  ></Icon>
                </View>
              </View>
              <View
                padding="0px 0px 0px 0px"
                width="360px"
                height="548px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="0px"
                left="0px"
                {...getOverrideProps(overrides, "Badge571118123")}
              >
                <KeitouzuItemAntenna
                  display="flex"
                  gap="10px"
                  direction="column"
                  width="unset"
                  height="unset"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  position="absolute"
                  top="0px"
                  left="0px"
                  border="2px SOLID rgba(100,100,100,1)"
                  borderRadius="4px"
                  padding="14px 6px 14px 6px"
                  backgroundColor="rgba(255,255,255,1)"
                  {...getOverrideProps(overrides, "Keitouzu/Item/Antenna")}
                ></KeitouzuItemAntenna>
                <KeitouzuItemBadgeLanSwitch4
                  display="flex"
                  gap="10px"
                  direction="column"
                  width="unset"
                  height="unset"
                  justifyContent="center"
                  alignItems="center"
                  position="absolute"
                  top="0px"
                  left="261px"
                  borderRadius="4px"
                  padding="8px 12px 8px 12px"
                  backgroundColor="rgba(38,193,147,1)"
                  type="Default"
                  {...getOverrideProps(
                    overrides,
                    "Keitouzu/Item/Badge/LanSwitch4"
                  )}
                ></KeitouzuItemBadgeLanSwitch4>
                <KeitouzuItemBadgeDirectCurrentPower1
                  display="flex"
                  gap="10px"
                  direction="column"
                  width="unset"
                  height="unset"
                  justifyContent="center"
                  alignItems="center"
                  position="absolute"
                  top="242px"
                  left="156px"
                  borderRadius="4px"
                  padding="8px 12px 8px 12px"
                  backgroundColor="rgba(38,193,147,1)"
                  type="Default"
                  {...getOverrideProps(
                    overrides,
                    "Keitouzu/Item/Badge/DirectCurrentPower1"
                  )}
                ></KeitouzuItemBadgeDirectCurrentPower1>
                <KeitouzuItemBadgeNoiseSource
                  display="flex"
                  gap="10px"
                  direction="column"
                  width="unset"
                  height="unset"
                  justifyContent="center"
                  alignItems="center"
                  position="absolute"
                  top="242px"
                  left="0px"
                  borderRadius="4px"
                  type="White"
                  {...getOverrideProps(
                    overrides,
                    "Keitouzu/Item/Badge/NoiseSource"
                  )}
                ></KeitouzuItemBadgeNoiseSource>
                <KeitouzuItemBadgeGpsSensor1
                  display="flex"
                  gap="10px"
                  direction="column"
                  width="unset"
                  height="unset"
                  justifyContent="center"
                  alignItems="center"
                  position="absolute"
                  top="42px"
                  left="156px"
                  borderRadius="4px"
                  padding="8px 12px 8px 12px"
                  backgroundColor="rgba(38,193,147,1)"
                  type="Default"
                  {...getOverrideProps(
                    overrides,
                    "Keitouzu/Item/Badge/GpsSensor1"
                  )}
                ></KeitouzuItemBadgeGpsSensor1>
                <KeitouzuItemBadgeDirectCurrentPower2
                  display="flex"
                  gap="10px"
                  direction="column"
                  width="unset"
                  height="unset"
                  justifyContent="center"
                  alignItems="center"
                  position="absolute"
                  top="288px"
                  left="156px"
                  borderRadius="4px"
                  padding="8px 12px 8px 12px"
                  backgroundColor="rgba(38,193,147,1)"
                  type="Default"
                  {...getOverrideProps(
                    overrides,
                    "Keitouzu/Item/Badge/DirectCurrentPower2"
                  )}
                ></KeitouzuItemBadgeDirectCurrentPower2>
                <KeitouzuItemBadgeLowNoiseAmplifier
                  display="flex"
                  gap="10px"
                  direction="column"
                  width="unset"
                  height="unset"
                  justifyContent="center"
                  alignItems="center"
                  position="absolute"
                  top="288px"
                  left="0px"
                  borderRadius="4px"
                  type="White"
                  {...getOverrideProps(
                    overrides,
                    "Keitouzu/Item/Badge/LowNoiseAmplifier"
                  )}
                ></KeitouzuItemBadgeLowNoiseAmplifier>
                <KeitouzuItemBadgeDirectCurrentPower3
                  display="flex"
                  gap="10px"
                  direction="column"
                  width="unset"
                  height="unset"
                  justifyContent="center"
                  alignItems="center"
                  position="absolute"
                  top="334px"
                  left="156px"
                  borderRadius="4px"
                  padding="8px 12px 8px 12px"
                  backgroundColor="rgba(38,193,147,1)"
                  type="Default"
                  {...getOverrideProps(
                    overrides,
                    "Keitouzu/Item/Badge/DirectCurrentPower3"
                  )}
                ></KeitouzuItemBadgeDirectCurrentPower3>
                <KeitouzuItemBadgeLowNoiseAmplifierSwitch1
                  display="flex"
                  gap="10px"
                  direction="column"
                  width="unset"
                  height="unset"
                  justifyContent="center"
                  alignItems="center"
                  position="absolute"
                  top="334px"
                  left="0px"
                  borderRadius="4px"
                  type="White"
                  {...getOverrideProps(
                    overrides,
                    "Keitouzu/Item/Badge/LowNoiseAmplifierSwitch1"
                  )}
                ></KeitouzuItemBadgeLowNoiseAmplifierSwitch1>
                <KeitouzuItemBadgeDirectCurrentPower4
                  display="flex"
                  gap="10px"
                  direction="column"
                  width="unset"
                  height="unset"
                  justifyContent="center"
                  alignItems="center"
                  position="absolute"
                  top="380px"
                  left="156px"
                  borderRadius="4px"
                  padding="8px 12px 8px 12px"
                  backgroundColor="rgba(38,193,147,1)"
                  type="Default"
                  {...getOverrideProps(
                    overrides,
                    "Keitouzu/Item/Badge/DirectCurrentPower4"
                  )}
                ></KeitouzuItemBadgeDirectCurrentPower4>
                <KeitouzuItemBadgeLowNoiseAmplifierSwitch2
                  display="flex"
                  gap="10px"
                  direction="column"
                  width="unset"
                  height="unset"
                  justifyContent="center"
                  alignItems="center"
                  position="absolute"
                  top="380px"
                  left="0px"
                  borderRadius="4px"
                  type="White"
                  {...getOverrideProps(
                    overrides,
                    "Keitouzu/Item/Badge/LowNoiseAmplifierSwitch2"
                  )}
                ></KeitouzuItemBadgeLowNoiseAmplifierSwitch2>
                <KeitouzuItemBadgeDirectCurrentPower5
                  display="flex"
                  gap="10px"
                  direction="column"
                  width="unset"
                  height="unset"
                  justifyContent="center"
                  alignItems="center"
                  position="absolute"
                  top="426px"
                  left="156px"
                  borderRadius="4px"
                  padding="8px 12px 8px 12px"
                  backgroundColor="rgba(38,193,147,1)"
                  type="Default"
                  {...getOverrideProps(
                    overrides,
                    "Keitouzu/Item/Badge/DirectCurrentPower5"
                  )}
                ></KeitouzuItemBadgeDirectCurrentPower5>
                <KeitouzuItemBadgeLowNoiseAmplifierSwitch3
                  display="flex"
                  gap="10px"
                  direction="column"
                  width="unset"
                  height="unset"
                  justifyContent="center"
                  alignItems="center"
                  position="absolute"
                  top="426px"
                  left="0px"
                  borderRadius="4px"
                  type="White"
                  {...getOverrideProps(
                    overrides,
                    "Keitouzu/Item/Badge/LowNoiseAmplifierSwitch3"
                  )}
                ></KeitouzuItemBadgeLowNoiseAmplifierSwitch3>
                <KeitouzuItemBadgeGpsSensor2
                  display="flex"
                  gap="10px"
                  direction="column"
                  width="unset"
                  height="unset"
                  justifyContent="center"
                  alignItems="center"
                  position="absolute"
                  top="142px"
                  left="156px"
                  borderRadius="4px"
                  padding="8px 12px 8px 12px"
                  backgroundColor="rgba(38,193,147,1)"
                  type="Default"
                  {...getOverrideProps(
                    overrides,
                    "Keitouzu/Item/Badge/GpsSensor2"
                  )}
                ></KeitouzuItemBadgeGpsSensor2>
                <KeitouzuItemBadgeSpectrumAnalyzer2
                  display="flex"
                  gap="10px"
                  direction="column"
                  width="unset"
                  height="unset"
                  justifyContent="center"
                  alignItems="center"
                  position="absolute"
                  top="188px"
                  left="156px"
                  borderRadius="4px"
                  padding="8px 12px 8px 12px"
                  backgroundColor="rgba(38,193,147,1)"
                  type="Default"
                  {...getOverrideProps(
                    overrides,
                    "Keitouzu/Item/Badge/SpectrumAnalyzer2"
                  )}
                ></KeitouzuItemBadgeSpectrumAnalyzer2>
                <KeitouzuItemBadgeTemperatureSensor
                  display="flex"
                  gap="10px"
                  direction="column"
                  width="unset"
                  height="unset"
                  justifyContent="center"
                  alignItems="center"
                  position="absolute"
                  top="472px"
                  left="156px"
                  borderRadius="4px"
                  padding="8px 12px 8px 12px"
                  backgroundColor="rgba(38,193,147,1)"
                  type="Default"
                  {...getOverrideProps(
                    overrides,
                    "Keitouzu/Item/Badge/TemperatureSensor"
                  )}
                ></KeitouzuItemBadgeTemperatureSensor>
                <KeitouzuItemBadgeEnergyGazer
                  display="flex"
                  gap="10px"
                  direction="column"
                  width="unset"
                  height="unset"
                  justifyContent="center"
                  alignItems="center"
                  position="absolute"
                  top="518px"
                  left="156px"
                  borderRadius="4px"
                  padding="8px 12px 8px 12px"
                  backgroundColor="rgba(38,193,147,1)"
                  type="Default"
                  {...getOverrideProps(
                    overrides,
                    "Keitouzu/Item/Badge/EnergyGazer"
                  )}
                ></KeitouzuItemBadgeEnergyGazer>
                <KeitouzuItemBadgeSpectrumAnalyzer1
                  display="flex"
                  gap="10px"
                  direction="column"
                  width="unset"
                  height="unset"
                  justifyContent="center"
                  alignItems="center"
                  position="absolute"
                  top="88px"
                  left="156px"
                  borderRadius="4px"
                  padding="8px 12px 8px 12px"
                  backgroundColor="rgba(38,193,147,1)"
                  type="Default"
                  {...getOverrideProps(
                    overrides,
                    "Keitouzu/Item/Badge/SpectrumAnalyzer1"
                  )}
                ></KeitouzuItemBadgeSpectrumAnalyzer1>
                <KeitouzuItemBadgeOutdoorCabinetTemperature
                  display="flex"
                  gap="10px"
                  direction="column"
                  width="unset"
                  height="unset"
                  justifyContent="center"
                  alignItems="center"
                  position="absolute"
                  top="472px"
                  left="0px"
                  borderRadius="4px"
                  padding="8px 12px 8px 12px"
                  backgroundColor="rgba(38,193,147,1)"
                  type="Default"
                  {...getOverrideProps(
                    overrides,
                    "Keitouzu/Item/Badge/OutdoorCabinetTemperature"
                  )}
                ></KeitouzuItemBadgeOutdoorCabinetTemperature>
                <KeitouzuItemBadgeNoiseSourceTemperature
                  display="flex"
                  gap="10px"
                  direction="column"
                  width="unset"
                  height="unset"
                  justifyContent="center"
                  alignItems="center"
                  position="absolute"
                  top="518px"
                  left="0px"
                  borderRadius="4px"
                  padding="8px 12px 8px 12px"
                  backgroundColor="rgba(38,193,147,1)"
                  type="Default"
                  {...getOverrideProps(
                    overrides,
                    "Keitouzu/Item/Badge/NoiseSourceTemperature"
                  )}
                ></KeitouzuItemBadgeNoiseSourceTemperature>
              </View>
            </View>
            <View
              width="88px"
              height="331px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="71px"
              left="384px"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Keitouzu/Item/Line571118144")}
            >
              <Icon
                width="88px"
                height="333px"
                viewBox={{ minX: 0, minY: 0, width: 88, height: 333 }}
                paths={[
                  {
                    d: "M0 0L45 0L45 331L88 331L88 333L43 333L43 2L0 2L0 0Z",
                    fill: "rgba(100,100,100,1)",
                    fillRule: "evenodd",
                  },
                ]}
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="-0.3%"
                bottom="-0.3%"
                left="0%"
                right="0%"
                {...getOverrideProps(overrides, "Vector (Stroke)571223508")}
              ></Icon>
            </View>
          </View>
        </Flex>
      </Flex>
    </Flex>
  );
}
