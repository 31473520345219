/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Flex, Text } from "@aws-amplify/ui-react";
import KanshiAlert from "./KanshiAlert";
export default function KanshiAlertCard(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      gap="40px"
      direction="column"
      width="364px"
      height="unset"
      justifyContent="center"
      alignItems="center"
      position="relative"
      borderRadius="8px"
      padding="32px 24px 32px 24px"
      backgroundColor="rgba(255,255,255,1)"
      {...rest}
      {...getOverrideProps(overrides, "KanshiAlertCard")}
    >
      <Text
        fontFamily="Noto Sans"
        fontSize="20px"
        fontWeight="500"
        color="rgba(53,56,59,1)"
        lineHeight="20px"
        textAlign="left"
        display="block"
        direction="column"
        justifyContent="unset"
        letterSpacing="0.85px"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="YSCC"
        {...getOverrideProps(overrides, "text")}
      ></Text>
      <Flex
        gap="16px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Alert")}
      >
        <KanshiAlert
          display="flex"
          gap="10px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          grow="1"
          shrink="1"
          basis="0"
          position="relative"
          borderRadius="4px"
          padding="16px 24px 16px 24px"
          backgroundColor="rgba(38,193,147,1)"
          type="default"
          situation="Kansyoha"
          {...getOverrideProps(overrides, "Kanshi/Alert35293049")}
        ></KanshiAlert>
        <KanshiAlert
          display="flex"
          gap="10px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          grow="1"
          shrink="1"
          basis="0"
          position="relative"
          borderRadius="4px"
          padding="16px 24px 16px 24px"
          backgroundColor="rgba(38,193,147,1)"
          type="default"
          situation="Kiki"
          {...getOverrideProps(overrides, "Kanshi/Alert35293050")}
        ></KanshiAlert>
      </Flex>
    </Flex>
  );
}
