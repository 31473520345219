// // // // // // // // // // // 
// アラーム音を制御するコンテキスト
// // // // // // // // // // // 

// react
import {createContext, useContext} from 'react'

// envUtil
import { isLocalMode } from "../lib/envUtil";

// アラーム音を制御するカスタムフック
import { useControlAlarm } from "../hooks/useControlAlarm";

// ユーザーコンテキスト
import { UserContext } from '../UserContext';

// コンテキストの作成
const ControlAlarmContext = createContext();

// アラーム音を制御するコンテキスト
const ControlAlarmProvider = ({ children }) => {
    // ユーザーコンテキストから取得したユーザー情報
    const { userInfo } = useContext(UserContext);

    // アラーム音を制御するカスタムフック
    const { alarmState, alarmPullDownState, onClickAlarm, onClickPullDown, setAlarmPullDownState } = useControlAlarm(!isLocalMode && userInfo.isAdminOrg)

    return (
        <ControlAlarmContext.Provider value={{alarmState, alarmPullDownState, onClickAlarm, onClickPullDown, setAlarmPullDownState}}>
            {children}
        </ControlAlarmContext.Provider>
    )
}

export default ControlAlarmProvider

// 作成したContextを外部から使いやすいようにする
export const useControlAlarmContext = () => useContext(ControlAlarmContext);