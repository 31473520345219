import { useContext, useState } from "react";
import { UserContext } from '../UserContext';

import {
    DatakanriListFile,
    DatakanriFileDetails,
} from '../ui-components'

import {
    CustomModal
} from '../customComponents/CustomModal'

import {
    getPreviewUrl,
} from '../lib/StorageUtil'

import CheckWrapper from './CheckWrapper'

export default function DatakanriListFileWrapper(props) {
    const { userInfo } = useContext(UserContext);

    // チェックボックスの状態
    const [check, setCheck] = useState("File");
    function onMouseEventEnter() {
        if (!props.item.check) {
            setCheck("FileHover")
        }
    }
    function onMouseEventLeave() {
        setCheck("File")
    }

    // 画像イメージsrc
    const [imageSrc, setImageSrc] = useState(null);

    // 画像表示モーダルの表示フラグ
    const [openImageModal, setOpenImageModal] = useState(false);

    // クリックイベント
    const onClick = () => {
        // 画像の場合 
        if (props.item.fileType === "png") {
            // プレビューurlを取得する関数
            getPreviewUrl(props.item.key, props.item.name, props.item.fileType)
                .then((url) => {
                    // プレビューurlを取得
                    setImageSrc(url)
                })

            // モーダルを表示
            setOpenImageModal(true);
        }
    }

    // 削除確認モーダルの表示フラグ
    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    // 削除ボタンクリックイベント
    const onDeleteClick = (e) => {
        // モーダルを閉じる
        setOpenDeleteModal(false);

        // 削除ボタンクリックイベントを実行
        props.onDeleteClick();
    }
    return (
        <>
            <DatakanriListFile
                width={"100%"}
                minWidth={"1355px"}
                onClick={onClick}
                overrides={{
                    DatakanriListFile: {
                        style: {
                            ...props.item.fileType === "png" && {
                                cursor: "pointer",
                            }
                        }
                    },
                    Checkbox: {
                        onClick: (e) => {
                            e.stopPropagation();
                        },
                    },
                    CheckboxField: {
                        checked: props.item.check,
                        onClick: (e) => {
                            e.stopPropagation();
                        },
                        onChange: (e) => {
                            let check = e.target.checked;

                            props.onCheckClick(check);

                            if (check) {
                                onMouseEventLeave()
                            }
                            else {
                                onMouseEventEnter()
                            }
                        }
                    },
                    text480814280: {
                        children: props.item.name,
                    },
                    text480814283: {
                        children: props.item.lastModified,
                    },
                    text480814286: {
                        children: props.item.fileType,
                    },
                    text480814289: {
                        children: props.item.filesize,
                    },
                    "Common/Badge/Icon480814293": {
                        style: {
                            visibility: userInfo.isAdminOrg && userInfo.isAdminUser ? "visible" : "hidden",
                        },
                        onClick: (e) => {
                            e.stopPropagation();
                            setOpenDeleteModal(true);
                        },
                        overrides: {
                            Myicons: {
                                type: "trash"
                            }
                        }
                    },
                    "Rectangle 25": {
                        style: {
                            visibility: userInfo.isAdminOrg && userInfo.isAdminUser ? "visible" : "hidden",
                        },
                    },
                    "Common/Badge/Icon480814295": {
                        onClick: (e) => {
                            e.stopPropagation();
                            props.onDownloadClick();
                        },
                        overrides: {
                            Myicons: { type: "download" }
                        }
                    },
                }}
                type={check}
                onMouseEnter={onMouseEventEnter}
                onMouseLeave={onMouseEventLeave}
                check={props.item.check ? "on" : "off"}
            ></DatakanriListFile>

            {/* 画像用時モーダル */}
            <CustomModal
                open={openImageModal}
                onClose={() => setOpenImageModal(false)}
            >
                <DatakanriFileDetails
                    overrides={{
                        "Datakanri/File/FileName": {
                            overrides: {
                                text: {
                                    children: props.item.name,
                                },
                                "Common/Badge/Icon": {
                                    onClick: (e) => {
                                        e.stopPropagation();
                                        props.onDownloadClick();
                                    },
                                    overrides: {
                                        Myicons: {
                                            type: "download",
                                            style: {
                                                cursor: "pointer",
                                            }
                                        }
                                    }
                                }
                            }
                        },
                        "Mask group": {
                            width: "825px",
                        },
                        "Rectangle 89": {
                            width: "825px",
                            style: {
                                visibility: userInfo.isAdminOrg && userInfo.isAdminUser ? "visible" : "hidden",
                            },
                        },
                        "image 1": {
                            width: "825px",
                            left: "0px",
                            src: imageSrc,
                        },
                    }}
                />
            </CustomModal>

            {/* 削除確認モーダル */}
            <CustomModal
                open={openDeleteModal}
                onClose={() => setOpenDeleteModal(false)}
            >
                <CheckWrapper
                    msg={"本当に削除しますか？"}
                    okBtnMsg={"OK"}
                    cancelBtnMsg={"キャンセル"}
                    onClickOk={onDeleteClick}
                    onClickCancel={() => setOpenDeleteModal(false)}
                />   
            </CustomModal>
        </>
    );
}