/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "@aws-amplify/ui-react/internal";
import { Flex, Text, View } from "@aws-amplify/ui-react";
export default function DatakanriBreadcrumbs(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: { text: {}, "Rectangle 16": {}, DatakanriBreadcrumbs: {} },
      variantValues: { situation: "default" },
    },
    {
      overrides: {
        text: { color: "rgba(170,170,170,1)" },
        "Rectangle 16": { backgroundColor: "rgba(170,170,170,1)" },
        DatakanriBreadcrumbs: {},
      },
      variantValues: { situation: "hover" },
    },
    {
      overrides: {
        text: { color: "rgba(0,153,255,1)" },
        "Rectangle 16": {
          display: "none",
          backgroundColor: "rgba(102,102,102,1)",
        },
        DatakanriBreadcrumbs: { padding: "7px 0px 7px 0px" },
      },
      variantValues: { situation: "select" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="0"
      direction="column"
      width="unset"
      height="unset"
      justifyContent="flex-start"
      alignItems="flex-start"
      position="relative"
      padding="7px 0px 6px 0px"
      backgroundColor="rgba(255,255,255,1)"
      display="flex"
      {...rest}
      {...getOverrideProps(overrides, "DatakanriBreadcrumbs")}
    >
      <Text
        fontFamily="Noto Sans"
        fontSize="18px"
        fontWeight="500"
        color="rgba(100,100,100,1)"
        lineHeight="26px"
        textAlign="left"
        display="block"
        direction="column"
        justifyContent="unset"
        letterSpacing="0.75px"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="YSCC"
        {...getOverrideProps(overrides, "text")}
      ></Text>
      <View
        width="unset"
        height="1px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        backgroundColor="rgba(100,100,100,1)"
        {...getOverrideProps(overrides, "Rectangle 16")}
      ></View>
    </Flex>
  );
}
