import {
    DatakanriSearch
} from '../ui-components'

export default function DatakanriSearchWrapper(props) {
    return (
        <DatakanriSearch width={"40%"} children={""}
            overrides={{
                SearchField: {
                    hasSearchButton: false,
                    hasSearchIcon: true,
                    onChange: props.onChange,
                    onClear: props.onClear,
                    onSubmit: props.onSubmit,
                    value: props.value,
                }
            }}
        ></DatakanriSearch>
    );
}