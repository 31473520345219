import { useEffect, useState } from "react";
import { Flex, View, Text } from "@aws-amplify/ui-react";


import {
    HeaderWrapper,
    KanshiSetteiLabelWrapper,
    KanshiSetteiKyotenWrapper
} from './componentsWrapper'

import {
    getDisplaySettingJson
} from './lib/SettingUtil'


const KanshiSettings = () => {
    const [displayModeKanshiSetting, setDisplayModeKanshiSetting] = useState(true);
    const [displaySettingJson, setDisplaySettingJson] = useState({
        "location_name": {
            "YSCC": "YSCC",
            "SPE": "SPE",
            "SPW": "SPW",
            "SAYADO": "小夜戸"
        },
        "band_label": {
            "3400-3440": {
                "name": "S-1",
                "color": "#bbbcbe"
            },
            "3440-3480": {
                "name": "D-1",
                "color": "#cc0033"
            },
            "3480-3520": {
                "name": "D-2",
                "color": "#cc0033"
            },
            "3520-3560": {
                "name": "K-1",
                "color": "#eb5505"
            },
            "3560-3600": {
                "name": "S-2",
                "color": "#bbbcbe"
            },
            "3600-3700": {
                "name": "D-3",
                "color": "#cc0033"
            },
            "3700-3800": {
                "name": "K-2",
                "color": "#eb5505"
            },
            "3800-3900": {
                "name": "R-1",
                "color": "#ff00ff"
            },
            "3900-4000": {
                "name": "S-3",
                "color": "#bbbcbe"
            },
            "4000-4100": {
                "name": "K-3",
                "color": "#eb5505"
            }
        }
    });

    const initDisplaySettingJson = async () => {
        const displaySettingJson = await getDisplaySettingJson();
        if (displaySettingJson !== null) {
            setDisplaySettingJson(displaySettingJson);
        } else {
            console.log("displaySettingJson is null");
        }
    }

    const onClickBtnDisplayModeKanshiSettingChange = (bool) => {
        setDisplayModeKanshiSetting(bool);
    }

    useEffect(() => {
        // 表示設定取得
        initDisplaySettingJson();
    }, []);

    return (
        <div style={{ height: '100vh', backgroundColor: 'rgba(245,245,245,1)' }}>
            <HeaderWrapper
            />
            {!displayModeKanshiSetting ?
                <KanshiSetteiLabelWrapper
                    onClickBackBtn={() => onClickBtnDisplayModeKanshiSettingChange(true)}
                    displaySettingJson={displaySettingJson}
                    setDisplaySettingJson={setDisplaySettingJson}
                    getDisplaySettingJson={initDisplaySettingJson}
                >
                </KanshiSetteiLabelWrapper>
                :
                <KanshiSetteiKyotenWrapper
                    onClickLabelBtn={() => onClickBtnDisplayModeKanshiSettingChange(false)}
                    displaySettingJson={displaySettingJson}
                >
                </KanshiSetteiKyotenWrapper>
            }
        </div>
    );

}

export default KanshiSettings;