/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateS3Objects = /* GraphQL */ `
  subscription OnCreateS3Objects(
    $filter: ModelSubscriptionS3ObjectsFilterInput
  ) {
    onCreateS3Objects(filter: $filter) {
      type
      key
      fileName
      size
      lastModified
      id
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateS3Objects = /* GraphQL */ `
  subscription OnUpdateS3Objects(
    $filter: ModelSubscriptionS3ObjectsFilterInput
  ) {
    onUpdateS3Objects(filter: $filter) {
      type
      key
      fileName
      size
      lastModified
      id
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteS3Objects = /* GraphQL */ `
  subscription OnDeleteS3Objects(
    $filter: ModelSubscriptionS3ObjectsFilterInput
  ) {
    onDeleteS3Objects(filter: $filter) {
      type
      key
      fileName
      size
      lastModified
      id
      createdAt
      updatedAt
    }
  }
`;
export const onCreatePatliteStatus = /* GraphQL */ `
  subscription OnCreatePatliteStatus(
    $filter: ModelSubscriptionPatliteStatusFilterInput
  ) {
    onCreatePatliteStatus(filter: $filter) {
      id
      isMute
      alarmLogs
      createdAt
      updatedAt
    }
  }
`;
export const onUpdatePatliteStatus = /* GraphQL */ `
  subscription OnUpdatePatliteStatus(
    $filter: ModelSubscriptionPatliteStatusFilterInput
  ) {
    onUpdatePatliteStatus(filter: $filter) {
      id
      isMute
      alarmLogs
      createdAt
      updatedAt
    }
  }
`;
export const onDeletePatliteStatus = /* GraphQL */ `
  subscription OnDeletePatliteStatus(
    $filter: ModelSubscriptionPatliteStatusFilterInput
  ) {
    onDeletePatliteStatus(filter: $filter) {
      id
      isMute
      alarmLogs
      createdAt
      updatedAt
    }
  }
`;
export const onCreateMoniteringLog = /* GraphQL */ `
  subscription OnCreateMoniteringLog(
    $filter: ModelSubscriptionMoniteringLogFilterInput
  ) {
    onCreateMoniteringLog(filter: $filter) {
      id
      type
      location_name
      occurrence_at
      recover_state
      state_type
      alarm_type
      occurrence_source
      log_type
      log_detail
      recover_flg
      recover_at
      confirmed_flg
      confirmation_at
      confirmation_note
      all_status
      sequence_number
      log_id
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateMoniteringLog = /* GraphQL */ `
  subscription OnUpdateMoniteringLog(
    $filter: ModelSubscriptionMoniteringLogFilterInput
  ) {
    onUpdateMoniteringLog(filter: $filter) {
      id
      type
      location_name
      occurrence_at
      recover_state
      state_type
      alarm_type
      occurrence_source
      log_type
      log_detail
      recover_flg
      recover_at
      confirmed_flg
      confirmation_at
      confirmation_note
      all_status
      sequence_number
      log_id
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteMoniteringLog = /* GraphQL */ `
  subscription OnDeleteMoniteringLog(
    $filter: ModelSubscriptionMoniteringLogFilterInput
  ) {
    onDeleteMoniteringLog(filter: $filter) {
      id
      type
      location_name
      occurrence_at
      recover_state
      state_type
      alarm_type
      occurrence_source
      log_type
      log_detail
      recover_flg
      recover_at
      confirmed_flg
      confirmation_at
      confirmation_note
      all_status
      sequence_number
      log_id
      createdAt
      updatedAt
    }
  }
`;
export const onCreateWaveMoniteringStatus = /* GraphQL */ `
  subscription OnCreateWaveMoniteringStatus(
    $filter: ModelSubscriptionWaveMoniteringStatusFilterInput
  ) {
    onCreateWaveMoniteringStatus(filter: $filter) {
      id
      location_name
      band
      threshold_type
      state
      occurrence_detail
      sequence_number
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateWaveMoniteringStatus = /* GraphQL */ `
  subscription OnUpdateWaveMoniteringStatus(
    $filter: ModelSubscriptionWaveMoniteringStatusFilterInput
  ) {
    onUpdateWaveMoniteringStatus(filter: $filter) {
      id
      location_name
      band
      threshold_type
      state
      occurrence_detail
      sequence_number
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteWaveMoniteringStatus = /* GraphQL */ `
  subscription OnDeleteWaveMoniteringStatus(
    $filter: ModelSubscriptionWaveMoniteringStatusFilterInput
  ) {
    onDeleteWaveMoniteringStatus(filter: $filter) {
      id
      location_name
      band
      threshold_type
      state
      occurrence_detail
      sequence_number
      createdAt
      updatedAt
    }
  }
`;
export const onCreateDeviceMoniteringStatus = /* GraphQL */ `
  subscription OnCreateDeviceMoniteringStatus(
    $filter: ModelSubscriptionDeviceMoniteringStatusFilterInput
  ) {
    onCreateDeviceMoniteringStatus(filter: $filter) {
      id
      location_name
      device
      state
      occurrence_detail
      sequence_number
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateDeviceMoniteringStatus = /* GraphQL */ `
  subscription OnUpdateDeviceMoniteringStatus(
    $filter: ModelSubscriptionDeviceMoniteringStatusFilterInput
  ) {
    onUpdateDeviceMoniteringStatus(filter: $filter) {
      id
      location_name
      device
      state
      occurrence_detail
      sequence_number
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteDeviceMoniteringStatus = /* GraphQL */ `
  subscription OnDeleteDeviceMoniteringStatus(
    $filter: ModelSubscriptionDeviceMoniteringStatusFilterInput
  ) {
    onDeleteDeviceMoniteringStatus(filter: $filter) {
      id
      location_name
      device
      state
      occurrence_detail
      sequence_number
      createdAt
      updatedAt
    }
  }
`;
export const onCreateWaveMoniteringSetting = /* GraphQL */ `
  subscription OnCreateWaveMoniteringSetting(
    $filter: ModelSubscriptionWaveMoniteringSettingFilterInput
  ) {
    onCreateWaveMoniteringSetting(filter: $filter) {
      id
      location_name
      setting_json
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateWaveMoniteringSetting = /* GraphQL */ `
  subscription OnUpdateWaveMoniteringSetting(
    $filter: ModelSubscriptionWaveMoniteringSettingFilterInput
  ) {
    onUpdateWaveMoniteringSetting(filter: $filter) {
      id
      location_name
      setting_json
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteWaveMoniteringSetting = /* GraphQL */ `
  subscription OnDeleteWaveMoniteringSetting(
    $filter: ModelSubscriptionWaveMoniteringSettingFilterInput
  ) {
    onDeleteWaveMoniteringSetting(filter: $filter) {
      id
      location_name
      setting_json
      createdAt
      updatedAt
    }
  }
`;
export const onCreateDeviceMoniteringSetting = /* GraphQL */ `
  subscription OnCreateDeviceMoniteringSetting(
    $filter: ModelSubscriptionDeviceMoniteringSettingFilterInput
  ) {
    onCreateDeviceMoniteringSetting(filter: $filter) {
      id
      location_name
      setting_json
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateDeviceMoniteringSetting = /* GraphQL */ `
  subscription OnUpdateDeviceMoniteringSetting(
    $filter: ModelSubscriptionDeviceMoniteringSettingFilterInput
  ) {
    onUpdateDeviceMoniteringSetting(filter: $filter) {
      id
      location_name
      setting_json
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteDeviceMoniteringSetting = /* GraphQL */ `
  subscription OnDeleteDeviceMoniteringSetting(
    $filter: ModelSubscriptionDeviceMoniteringSettingFilterInput
  ) {
    onDeleteDeviceMoniteringSetting(filter: $filter) {
      id
      location_name
      setting_json
      createdAt
      updatedAt
    }
  }
`;
export const onCreateDisplaySetting = /* GraphQL */ `
  subscription OnCreateDisplaySetting(
    $filter: ModelSubscriptionDisplaySettingFilterInput
  ) {
    onCreateDisplaySetting(filter: $filter) {
      id
      setting_json
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateDisplaySetting = /* GraphQL */ `
  subscription OnUpdateDisplaySetting(
    $filter: ModelSubscriptionDisplaySettingFilterInput
  ) {
    onUpdateDisplaySetting(filter: $filter) {
      id
      setting_json
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteDisplaySetting = /* GraphQL */ `
  subscription OnDeleteDisplaySetting(
    $filter: ModelSubscriptionDisplaySettingFilterInput
  ) {
    onDeleteDisplaySetting(filter: $filter) {
      id
      setting_json
      createdAt
      updatedAt
    }
  }
`;
export const onCreateMeasurementPcStatus = /* GraphQL */ `
  subscription OnCreateMeasurementPcStatus(
    $filter: ModelSubscriptionMeasurementPcStatusFilterInput
  ) {
    onCreateMeasurementPcStatus(filter: $filter) {
      id
      pc_id
      location_name
      isMain
      status
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateMeasurementPcStatus = /* GraphQL */ `
  subscription OnUpdateMeasurementPcStatus(
    $filter: ModelSubscriptionMeasurementPcStatusFilterInput
  ) {
    onUpdateMeasurementPcStatus(filter: $filter) {
      id
      pc_id
      location_name
      isMain
      status
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteMeasurementPcStatus = /* GraphQL */ `
  subscription OnDeleteMeasurementPcStatus(
    $filter: ModelSubscriptionMeasurementPcStatusFilterInput
  ) {
    onDeleteMeasurementPcStatus(filter: $filter) {
      id
      pc_id
      location_name
      isMain
      status
      createdAt
      updatedAt
    }
  }
`;
export const onCreateUsers = /* GraphQL */ `
  subscription OnCreateUsers($filter: ModelSubscriptionUsersFilterInput) {
    onCreateUsers(filter: $filter) {
      casthive_id
      last_login_at
      id
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateUsers = /* GraphQL */ `
  subscription OnUpdateUsers($filter: ModelSubscriptionUsersFilterInput) {
    onUpdateUsers(filter: $filter) {
      casthive_id
      last_login_at
      id
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteUsers = /* GraphQL */ `
  subscription OnDeleteUsers($filter: ModelSubscriptionUsersFilterInput) {
    onDeleteUsers(filter: $filter) {
      casthive_id
      last_login_at
      id
      createdAt
      updatedAt
    }
  }
`;
