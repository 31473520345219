// // // // // // // // // // // //
// カスタムモーダルコンポーネント
// // // // // // // // // // // //

import { View } from '@aws-amplify/ui-react';
import React from 'react';
import ReactDOM from 'react-dom';

export const CustomModal = ({ open, onClose, children }) => {
    // モーダルを閉じる
    const closeModal = () => {
        onClose();
    }

    const modalRoot = document.getElementById('root'); // ポータル用のルート要素を指定

    React.useEffect(() => {
        if (open) {
            // モーダルが表示されるときにポータルを作成して追加
            const modalElement = document.createElement('div');
            modalRoot.appendChild(modalElement);

            return () => {
                // モーダルが非表示になるときにポータルを削除
                modalRoot.removeChild(modalElement);
            };
        }
    }, [open, modalRoot]);

    return open && ReactDOM.createPortal(
        <View
            position="fixed"
            top="0"
            left="0"
            width="100%"
            height="100%"
            backgroundColor="rgba(0,0,0,0.5)"
            display="flex"
            alignItems="center"
            justifyContent="center"
            style={{ zIndex: 1000 }}
            onClick={closeModal}
        >
            <View
                position="absolute"
                top="50%"
                left="50%"
                transform="translate(-50%, -50%)"
                backgroundColor="white"
                borderRadius="14px"
                onClick={(e) => { e.stopPropagation() }}
            >
                {children}
            </View>
        </View>
        ,
        modalRoot
    )
}