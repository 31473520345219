import { API, graphqlOperation } from 'aws-amplify';
import { moniteringLogsByTypeAndOccurrence_at } from '../graphql/queries';
import { updateMoniteringLog } from '../graphql/mutations';
import { onCreateMoniteringLog, onUpdateMoniteringLog } from '../graphql/subscriptions';

// test
import { 
    moniteringLogsByConfirmed_flgAndOccurrence_at,
    moniteringLogsByRecover_flgAndOccurrence_at
} from '../graphql/queries';

const CONFIRMED_FLG_IS_CONFIRMED = 1;
const CONFIRMED_FLG_IS_UNCONFIRMED = 0;
const RECOVER_FLG_IS_RECOVERED = 1;
const RECOVER_FLG_IS_UNRECOVERED = 0;

const MONITERING_LOG_MAX_COUNT = 100;

export const getMoniteringLog = async (filter = {}, nextToken = null, allLogs = []) => {
    // // console.log("getMoniteringLog")

    let resultNextToken = null;
    let moniteringLogList = [];
    try {
        const variables = {
            type: "MoniteringLog",
            sortDirection: "DESC",
            filter: filter,
            nextToken: nextToken,
        };

        // console.log("LogUtil getMoniteringLog", variables);
        const result = await API.graphql(graphqlOperation(moniteringLogsByTypeAndOccurrence_at, variables));

        moniteringLogList = [...allLogs, ...result.data.moniteringLogsByTypeAndOccurrence_at.items];
        resultNextToken = result.data.moniteringLogsByTypeAndOccurrence_at.nextToken;

        if (resultNextToken && moniteringLogList.length < MONITERING_LOG_MAX_COUNT) {
            return getMoniteringLog(filter, resultNextToken, moniteringLogList);
        } 
        // console.log("LogUtil getMoniteringLog", result);
        // moniteringLogList = result.data.moniteringLogsByTypeAndOccurrence_at.items;
        // console.log("LogUtil moniteringLog", moniteringLogList);
    } catch (error) {
        console.log("LogUtil getMoniteringLog", error);
    }

    return { moniteringLogList, nextToken: resultNextToken };
}

export const getMoniteringLogSearch = async (filter = {}, nextToken = null, occurrenceAtCondition, allLogs = []) => {
    // console.log("getMoniteringLogSearch");

    let resultNextToken = null;
    let moniteringLogList = [];
    try {
        const variables = {
            type: "MoniteringLog",
            occurrence_at: occurrenceAtCondition,
            sortDirection: "DESC",
            filter: filter,
            nextToken: nextToken,
        };

        const result = await API.graphql(graphqlOperation(moniteringLogsByTypeAndOccurrence_at, variables));

        moniteringLogList = [...allLogs, ...result.data.moniteringLogsByTypeAndOccurrence_at.items];
        resultNextToken = result.data.moniteringLogsByTypeAndOccurrence_at.nextToken;

        if (resultNextToken) {
            return getMoniteringLogSearch(filter, resultNextToken, occurrenceAtCondition, moniteringLogList);
        }
        // console.log("LogUtil getMoniteringLog", result);
        // moniteringLogList = result.data.moniteringLogsByTypeAndOccurrence_at.items;
        // console.log("LogUtil moniteringLog", moniteringLogList);
    } catch (error) {
        console.log("LogUtil getMoniteringLog", error);
    }

    return { moniteringLogList, nextToken: resultNextToken };
}

export const getUnconfirmedMoniteringLog = async (filter = {}, nextToken = null, occurrenceAtCondition = null, allLogs = []) => {
    // console.log("getUnconfirmedMoniteringLog");

    let resultNextToken = null;
    let moniteringLogList = [];
    try {
        const variables = {
            confirmed_flg: CONFIRMED_FLG_IS_UNCONFIRMED,
            sortDirection: "DESC",
            filter: filter,
            nextToken: nextToken,
        };

        if (occurrenceAtCondition !== null) {
            variables.occurrence_at = occurrenceAtCondition;
        }

        const result = await API.graphql(graphqlOperation(moniteringLogsByConfirmed_flgAndOccurrence_at, variables));

        moniteringLogList = [...allLogs, ...result.data.moniteringLogsByConfirmed_flgAndOccurrence_at.items];
        resultNextToken = result.data.moniteringLogsByConfirmed_flgAndOccurrence_at.nextToken;

        if (resultNextToken && moniteringLogList.length < MONITERING_LOG_MAX_COUNT) {
            return getUnconfirmedMoniteringLog(filter, resultNextToken, occurrenceAtCondition, moniteringLogList);
        } 
        // console.log("LogUtil getUnconfirmedMoniteringLog", result);
        // moniteringLogList = result.data.moniteringLogsByConfirmed_flgAndOccurrence_at.items;
        // console.log("LogUtil unconfirmed moniteringLog", moniteringLogList);
    } catch (error) {
        console.log("LogUtil getUnconfirmedMoniteringLog", error);
    }

    return { moniteringLogList, nextToken: resultNextToken };
}

export const getUnrecoveredMoniteringLog = async (filter = {}, nextToken = null, occurrenceAtCondition = null, allLogs = []) => {
    // console.log("getUnrecoveredMoniteringLog");

    let resultNextToken = null;
    let moniteringLogList = [];
    try {
        const variables = {
            recover_flg: RECOVER_FLG_IS_UNRECOVERED,
            sortDirection: "DESC",
            filter: filter,
            nextToken: nextToken,
        };

        if (occurrenceAtCondition !== null) {
            variables.occurrence_at = occurrenceAtCondition;
        }

        const result = await API.graphql(graphqlOperation(moniteringLogsByRecover_flgAndOccurrence_at, variables));

        moniteringLogList = [...allLogs, ...result.data.moniteringLogsByRecover_flgAndOccurrence_at.items];
        resultNextToken = result.data.moniteringLogsByRecover_flgAndOccurrence_at.nextToken;

        if (resultNextToken && moniteringLogList.length < MONITERING_LOG_MAX_COUNT) {
            return getUnrecoveredMoniteringLog(filter, resultNextToken, occurrenceAtCondition, moniteringLogList);
        }
        // console.log("LogUtil getUnrecoveredMoniteringLog", result);
        // moniteringLogList = result.data.moniteringLogsByRecover_flgAndOccurrence_at.items;
        // console.log("LogUtil unrecovered moniteringLog", moniteringLogList);
    } catch (error) {
        console.log("LogUtil getUnrecoveredMoniteringLog", error);
    }

    return { moniteringLogList, nextToken: resultNextToken };
}

export const subscribeToCreateMoniteringLog = async () => {
    let observable;
    try {
        observable = API.graphql(graphqlOperation(onCreateMoniteringLog))
    } catch (error) {
        console.log("LogUtil subscribeToCreateMoniteringLog", error);
    }

    return observable;
}

export const subscribeToUpdateMoniteringLog = async () => {
    let observable;
    try {
        // console.log("LogUtil subscribeToUpdateMoniteringLog")
        observable = API.graphql(graphqlOperation(onUpdateMoniteringLog))
    } catch (error) {
        console.log("LogUtil subscribeToUpdateMoniteringLog", error);
    }

    return observable;
}

export const updateMoniteringLogKakuninStatus = async (
    id,
    confirmed_flg=CONFIRMED_FLG_IS_CONFIRMED,
    confirmation_at=new Date().toISOString(),
    confirmation_note=""
) => {
    // console.log("updateMoniteringLogKakuninStatus", id);

    try {
        const variables = {
            input: {
                id: id,
                confirmed_flg: confirmed_flg,
                confirmation_at: confirmation_at,
                confirmation_note: confirmation_note
            }
        };

        const result = await API.graphql(graphqlOperation(updateMoniteringLog, variables));
        // console.log("LogUtil updateMoniteringLogKakuninStatus", result);
    } catch (error) {
        console.log("LogUtil updateMoniteringLogKakuninStatus", error);
    }
}

export const resetMoniteringLogKakuninStatus = async (id) => {
    // console.log("resetMoniteringLogKakuninStatus");

    try {
        const variables = {
            input: {
                id: id,
                confirmed_flg: CONFIRMED_FLG_IS_UNCONFIRMED,
                confirmation_note: ""
            }
        };

        const result = await API.graphql(graphqlOperation(updateMoniteringLog, variables));
        // console.log("LogUtil resetMoniteringLogKakuninStatus", result);
    } catch (error) {
        console.log("LogUtil resetMoniteringLogKakuninStatus", error);
    }
}

// ログ集計関数
export const getMoniteringLogSummary = async (filter = {}, nextToken = null, occurrenceAtCondition, allLogs = []) => {

    let resultNextToken = null;
    let moniteringLogList = [];
    try {
        const variables = {
            type: "MoniteringLog",
            occurrence_at: occurrenceAtCondition,
            sortDirection: "DESC",
            filter: filter,
            nextToken: nextToken,
        };

        console.log("LogUtil getMoniteringLogSummary", variables);

        const result = await API.graphql(graphqlOperation(moniteringLogsByTypeAndOccurrence_at, variables));

        moniteringLogList = [...allLogs, ...result.data.moniteringLogsByTypeAndOccurrence_at.items];
        resultNextToken = result.data.moniteringLogsByTypeAndOccurrence_at.nextToken;

        if (resultNextToken) {
            return getMoniteringLogSearch(filter, resultNextToken, occurrenceAtCondition, moniteringLogList);
        }
        // console.log("LogUtil getMoniteringLog", result);
        // moniteringLogList = result.data.moniteringLogsByTypeAndOccurrence_at.items;
        // console.log("LogUtil moniteringLog", moniteringLogList);
    } catch (error) {
        console.log("LogUtil getMoniteringLog", error);
        return { moniteringLogList, nextToken: resultNextToken, error: error };
    }

    return { moniteringLogList, nextToken: resultNextToken, error: null };
}