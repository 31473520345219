// // // // // // // // // // // 
// PDF
// // // // // // // // // // // 

// pdfmakeのフォント設定
import pdfMake from 'pdfmake/build/pdfmake';

// htmlToPdfmake
import htmlToPdfmake from 'html-to-pdfmake';

// axios
import axiosBase from 'axios';

import {
    formatOccurrenceSource,
    formatStateType,
    formatAlarmType,
} from "./../customComponents/LogSummaryTable"

import { formatDatetime } from '../componentsWrapper/KanshiFrameLogDetailsWrapper'

// axios設定
const blobAxios = axiosBase.create({
    headers: {
        'Content-Type': 'application/x-font-ttf',
    },
    responseType: 'blob',
})

// BOLBをBase64に変換
async function blobToBase64(blob) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = function () {
            if (typeof reader.result === "string") {
                const result = reader.result.split("base64,")[1];
                resolve(result);
            } else {
                reject(new Error("FileReader result is not a string"));
            }
        };

        reader.onerror = function () {
            reject(reader.error);
        };

        reader.readAsDataURL(blob);
    });
}


// PDF作成クラス
export default class PDF {
    // PDFダウンロード
    download = async (data, fileName) => {
        // ipaフォントを設定
        if (pdfMake.vfs == null) {
            const response = await blobAxios.get('/ipa/ipagp.ttf')
            if (response.status !== 200) {
                return
            }
            const font = await blobToBase64(response.data)
            pdfMake.vfs = { "ipagp.ttf": font };
            pdfMake.fonts = {
                ipa: {
                    normal: 'ipagp.ttf',
                    bold: 'ipagp.ttf',
                },
            };
        }

        // html文字列作成
        const html = this.createHTML(data);

        // pdfmakeの設定
        const docDefinition = {
            content: [
                htmlToPdfmake(
                    html,
                    {
                        "tableAutoSize": true
                    }
                ),
            ],
            defaultStyle: {
                font: 'ipa',
            },
        };

        // pdfの名前設定
        const pdf_name = fileName + '.pdf';

        // pdfダウンロード
        pdfMake.createPdf(docDefinition).download(pdf_name);
    }
    
    // HTML作成
    createHTML(params) {
        // データ取得
        const { displaySettingJson, period } = params;

        const data = JSON.parse(JSON.stringify(params.data));

        // 出力日
        const outputDate = new Date();

        let html = `
            <div>
                <h3>干渉モニターシステム　定期レポート出力</h3>

                <div>
                <h5>出力日</h5>
                <p>${formatDatetime(outputDate, "YYYY年MM月DD日 hh:mm")}</p>
                </div>

                <div>
                <h5>対象期間</h5>
                <p>${period.start} 〜 ${period.end}</p>
                </div>

                <h5>出力結果</h5>
                <table style="width: 100%; font-size: 10px" border="1">
                    <tr>
                        <th>対象拠点</th>
                        <th>拠点毎件数</th>
                        <th>監視種別</th>
                        <th>状態</th>
                        <th>状態毎件数</th>
                        <th>発生元</th>
                        <th>発生件数</th>
                    </tr>
        `;

        for (const [location_name, locationData] of Object.entries(data)) {
            for (const [alarm_type, alarmTypeData] of Object.entries(locationData.alarm_type)) {
                for (const [state_type, stateTypeData] of Object.entries(alarmTypeData)) {
                    const rowspan = Object.entries(stateTypeData.occurrence_sources).length;

                    if (!("rowspan" in data[location_name].alarm_type[alarm_type][state_type])) {
                        data[location_name].alarm_type[alarm_type][state_type].rowspan = 0;
                    }
                    
                    data[location_name].alarm_type[alarm_type][state_type].rowspan += rowspan;

                    if (!("rowspan" in data[location_name].alarm_type[alarm_type])) {
                        data[location_name].alarm_type[alarm_type].rowspan = 0;
                    }

                    data[location_name].alarm_type[alarm_type].rowspan += rowspan;

                    if (!("rowspan" in data[location_name].alarm_type)) {
                        data[location_name].alarm_type.rowspan = 0;
                    }

                    data[location_name].alarm_type.rowspan += rowspan;
                }
            }
        }

        // console.log(data);

        let i = 0;
        for (const [location_name, locationData] of Object.entries(data)) {
            let j = 0;
            for (const [alarm_type, alarmTypeData] of Object.entries(locationData.alarm_type)) {
                let k = 0;

                if (alarm_type == "rowspan") {
                    continue;
                }

                for (const [state_type, stateTypeData] of Object.entries(alarmTypeData)) {
                    let l = 0;
                    if (state_type == "rowspan") {
                        continue;
                    }

                    for (const [occurrence_source, occurrenceSourceData] of Object.entries(stateTypeData.occurrence_sources)) {
                        if (j == 0 && k == 0 && l == 0) {
                            html += `
                                <td rowspan="${data[location_name].alarm_type.rowspan}">${displaySettingJson.location_name[location_name]}</td>
                                <td rowspan="${data[location_name].alarm_type.rowspan}" style="text-align:right">${locationData.count}件</td>
                            `;
                        }

                        if (k == 0 && l == 0) {
                            html += `    
                                <td rowspan="${data[location_name].alarm_type[alarm_type].rowspan}">${formatAlarmType(alarm_type)}</td>
                            `;
                        }

                        if (l == 0) {
                            html += `
                                <td rowspan="${data[location_name].alarm_type[alarm_type][state_type].rowspan}">${formatStateType(state_type)}</td>
                                <td rowspan="${data[location_name].alarm_type[alarm_type][state_type].rowspan}" style="text-align:right">${stateTypeData.count}件</td>
                            `;                            
                        }

                        html += `
                            <td rowspan="1">${formatOccurrenceSource(alarm_type, occurrence_source, displaySettingJson)}</td>
                            <td rowspan="1" style="text-align:right">${occurrenceSourceData}件</td>
                            </tr>
                        `;

                        l++;
                    }

                    k++;
                }

                j++;
            }

            i++;
        }

        // 末尾
        html += `
                </table>

                <div>
                    <p style="text-align: right">以上</p>
                </div>
            </div>
        `

        return html;
    }
}

export const pdf = new PDF();