/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Flex, Text, View } from "@aws-amplify/ui-react";
import CommonDivider from "./CommonDivider";
import KanshiAntennaindicator from "./KanshiAntennaindicator";
export default function KanshiAntennaFrame(props) {
  const { overrides, ...rest } = props;
  return (
    <View
      width="752px"
      height="255px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      position="relative"
      padding="0px 0px 0px 0px"
      {...rest}
      {...getOverrideProps(overrides, "KanshiAntennaFrame")}
    >
      <Flex
        gap="4px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        position="absolute"
        top="243px"
        left="140px"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 722")}
      >
        <Text
          fontFamily="Noto Sans"
          fontSize="10px"
          fontWeight="400"
          color="rgba(100,100,100,1)"
          lineHeight="10px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          letterSpacing="0.35px"
          width="56px"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="3400"
          {...getOverrideProps(overrides, "text505131302")}
        ></Text>
        <Text
          fontFamily="Noto Sans"
          fontSize="10px"
          fontWeight="400"
          color="rgba(100,100,100,1)"
          lineHeight="10px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          letterSpacing="0.35px"
          width="56px"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="3440"
          {...getOverrideProps(overrides, "text505131303")}
        ></Text>
        <Text
          fontFamily="Noto Sans"
          fontSize="10px"
          fontWeight="400"
          color="rgba(100,100,100,1)"
          lineHeight="10px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          letterSpacing="0.35px"
          width="56px"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="3480"
          {...getOverrideProps(overrides, "text505131304")}
        ></Text>
        <Text
          fontFamily="Noto Sans"
          fontSize="10px"
          fontWeight="400"
          color="rgba(100,100,100,1)"
          lineHeight="10px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          letterSpacing="0.35px"
          width="56px"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="3520"
          {...getOverrideProps(overrides, "text505131305")}
        ></Text>
        <Text
          fontFamily="Noto Sans"
          fontSize="10px"
          fontWeight="400"
          color="rgba(100,100,100,1)"
          lineHeight="10px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          letterSpacing="0.35px"
          width="56px"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="3560"
          {...getOverrideProps(overrides, "text505131306")}
        ></Text>
        <Text
          fontFamily="Noto Sans"
          fontSize="10px"
          fontWeight="400"
          color="rgba(100,100,100,1)"
          lineHeight="10px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          letterSpacing="0.35px"
          width="56px"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="3600"
          {...getOverrideProps(overrides, "text505131307")}
        ></Text>
        <Text
          fontFamily="Noto Sans"
          fontSize="10px"
          fontWeight="400"
          color="rgba(100,100,100,1)"
          lineHeight="10px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          letterSpacing="0.35px"
          width="56px"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="3700"
          {...getOverrideProps(overrides, "text505131308")}
        ></Text>
        <Text
          fontFamily="Noto Sans"
          fontSize="10px"
          fontWeight="400"
          color="rgba(100,100,100,1)"
          lineHeight="10px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          letterSpacing="0.35px"
          width="56px"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="3800"
          {...getOverrideProps(overrides, "text505131309")}
        ></Text>
        <Text
          fontFamily="Noto Sans"
          fontSize="10px"
          fontWeight="400"
          color="rgba(100,100,100,1)"
          lineHeight="10px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          letterSpacing="0.35px"
          width="56px"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="3900"
          {...getOverrideProps(overrides, "text505131310")}
        ></Text>
        <Text
          fontFamily="Noto Sans"
          fontSize="10px"
          fontWeight="400"
          color="rgba(100,100,100,1)"
          lineHeight="10px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          letterSpacing="0.35px"
          width="56px"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="4000"
          {...getOverrideProps(overrides, "text505131311")}
        ></Text>
        <Text
          fontFamily="Noto Sans"
          fontSize="10px"
          fontWeight="400"
          color="rgba(100,100,100,1)"
          lineHeight="10px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          letterSpacing="0.35px"
          width="49px"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="4100"
          {...getOverrideProps(overrides, "text505131312")}
        ></Text>
      </Flex>
      <Flex
        gap="0"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        position="absolute"
        top="0px"
        left="0px"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 773")}
      >
        <Flex
          gap="16px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="16px 16px 16px 16px"
          backgroundColor="rgba(245,245,245,1)"
          {...getOverrideProps(overrides, "Frame 735")}
        >
          <View
            width="108px"
            height="14px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 771")}
          >
            <Text
              fontFamily="Noto Sans"
              fontSize="14px"
              fontWeight="500"
              color="rgba(100,100,100,1)"
              lineHeight="14px"
              textAlign="right"
              display="block"
              direction="column"
              justifyContent="unset"
              letterSpacing="0.55px"
              width="108px"
              height="unset"
              gap="unset"
              alignItems="unset"
              position="absolute"
              top="0px"
              left="0px"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children=" "
              {...getOverrideProps(overrides, "text505131247")}
            ></Text>
          </View>
          <Flex
            gap="4px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 711")}
          >
            <Text
              fontFamily="Noto Sans"
              fontSize="14px"
              fontWeight="400"
              color="rgba(100,100,100,1)"
              lineHeight="14px"
              textAlign="center"
              display="block"
              direction="column"
              justifyContent="unset"
              letterSpacing="0.55px"
              width="56px"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="S-1"
              {...getOverrideProps(overrides, "text505131249")}
            ></Text>
            <Text
              fontFamily="Noto Sans"
              fontSize="14px"
              fontWeight="400"
              color="rgba(100,100,100,1)"
              lineHeight="14px"
              textAlign="center"
              display="block"
              direction="column"
              justifyContent="unset"
              letterSpacing="0.55px"
              width="56px"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="d-1"
              {...getOverrideProps(overrides, "text505131250")}
            ></Text>
            <Text
              fontFamily="Noto Sans"
              fontSize="14px"
              fontWeight="400"
              color="rgba(100,100,100,1)"
              lineHeight="14px"
              textAlign="center"
              display="block"
              direction="column"
              justifyContent="unset"
              letterSpacing="0.55px"
              width="56px"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="d-2"
              {...getOverrideProps(overrides, "text505131251")}
            ></Text>
            <Text
              fontFamily="Noto Sans"
              fontSize="14px"
              fontWeight="400"
              color="rgba(100,100,100,1)"
              lineHeight="14px"
              textAlign="center"
              display="block"
              direction="column"
              justifyContent="unset"
              letterSpacing="0.55px"
              width="56px"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="K-1"
              {...getOverrideProps(overrides, "text505131252")}
            ></Text>
            <Text
              fontFamily="Noto Sans"
              fontSize="14px"
              fontWeight="400"
              color="rgba(100,100,100,1)"
              lineHeight="14px"
              textAlign="center"
              display="block"
              direction="column"
              justifyContent="unset"
              letterSpacing="0.55px"
              width="56px"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="S-2"
              {...getOverrideProps(overrides, "text505131253")}
            ></Text>
            <Text
              fontFamily="Noto Sans"
              fontSize="14px"
              fontWeight="400"
              color="rgba(100,100,100,1)"
              lineHeight="14px"
              textAlign="center"
              display="block"
              direction="column"
              justifyContent="unset"
              letterSpacing="0.55px"
              width="56px"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="d-3"
              {...getOverrideProps(overrides, "text505131254")}
            ></Text>
            <Text
              fontFamily="Noto Sans"
              fontSize="14px"
              fontWeight="400"
              color="rgba(100,100,100,1)"
              lineHeight="14px"
              textAlign="center"
              display="block"
              direction="column"
              justifyContent="unset"
              letterSpacing="0.55px"
              width="56px"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="K-2"
              {...getOverrideProps(overrides, "text505131255")}
            ></Text>
            <Text
              fontFamily="Noto Sans"
              fontSize="14px"
              fontWeight="400"
              color="rgba(100,100,100,1)"
              lineHeight="14px"
              textAlign="center"
              display="block"
              direction="column"
              justifyContent="unset"
              letterSpacing="0.55px"
              width="56px"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="R-1"
              {...getOverrideProps(overrides, "text505131256")}
            ></Text>
            <Text
              fontFamily="Noto Sans"
              fontSize="14px"
              fontWeight="400"
              color="rgba(100,100,100,1)"
              lineHeight="14px"
              textAlign="center"
              display="block"
              direction="column"
              justifyContent="unset"
              letterSpacing="0.55px"
              width="56px"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="S-3"
              {...getOverrideProps(overrides, "text505131257")}
            ></Text>
            <Text
              fontFamily="Noto Sans"
              fontSize="14px"
              fontWeight="400"
              color="rgba(100,100,100,1)"
              lineHeight="14px"
              textAlign="center"
              display="block"
              direction="column"
              justifyContent="unset"
              letterSpacing="0.55px"
              width="56px"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="K-3"
              {...getOverrideProps(overrides, "text505131258")}
            ></Text>
          </Flex>
        </Flex>
        <CommonDivider
          display="flex"
          gap="0"
          direction="row"
          width="unset"
          height="1px"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Common/Divider505131259")}
        ></CommonDivider>
        <Flex
          gap="16px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="20px 16px 20px 16px"
          backgroundColor="rgba(255,255,255,1)"
          {...getOverrideProps(overrides, "ListAntenna505131260")}
        >
          <Text
            fontFamily="Noto Sans"
            fontSize="14px"
            fontWeight="400"
            color="rgba(100,100,100,1)"
            lineHeight="22.399999618530273px"
            textAlign="center"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0.55px"
            width="108px"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="A：時間率判定"
            {...getOverrideProps(overrides, "text505131261")}
          ></Text>
          <Flex
            gap="4px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 742505131262")}
          >
            <KanshiAntennaindicator
              width="56px"
              height="16px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              borderRadius="2px"
              padding="0px 0px 0px 0px"
              type="None"
              {...getOverrideProps(
                overrides,
                "Kanshi/Antenna/indicator505131263"
              )}
            ></KanshiAntennaindicator>
            <KanshiAntennaindicator
              width="56px"
              height="16px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              borderRadius="2px"
              padding="0px 0px 0px 0px"
              type="None"
              {...getOverrideProps(
                overrides,
                "Kanshi/Antenna/indicator505131264"
              )}
            ></KanshiAntennaindicator>
            <KanshiAntennaindicator
              width="56px"
              height="16px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              borderRadius="2px"
              padding="0px 0px 0px 0px"
              type="None"
              {...getOverrideProps(
                overrides,
                "Kanshi/Antenna/indicator505131265"
              )}
            ></KanshiAntennaindicator>
            <KanshiAntennaindicator
              width="56px"
              height="16px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              borderRadius="2px"
              padding="0px 0px 0px 0px"
              type="None"
              {...getOverrideProps(
                overrides,
                "Kanshi/Antenna/indicator505131266"
              )}
            ></KanshiAntennaindicator>
            <KanshiAntennaindicator
              width="56px"
              height="16px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              borderRadius="2px"
              padding="0px 0px 0px 0px"
              type="None"
              {...getOverrideProps(
                overrides,
                "Kanshi/Antenna/indicator505131267"
              )}
            ></KanshiAntennaindicator>
            <KanshiAntennaindicator
              width="56px"
              height="16px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              borderRadius="2px"
              padding="0px 0px 0px 0px"
              type="Error"
              {...getOverrideProps(
                overrides,
                "Kanshi/Antenna/indicator505131268"
              )}
            ></KanshiAntennaindicator>
            <KanshiAntennaindicator
              width="56px"
              height="16px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              borderRadius="2px"
              padding="0px 0px 0px 0px"
              backgroundColor="rgba(38,193,147,1)"
              type="Default"
              {...getOverrideProps(
                overrides,
                "Kanshi/Antenna/indicator505131269"
              )}
            ></KanshiAntennaindicator>
            <KanshiAntennaindicator
              width="56px"
              height="16px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              borderRadius="2px"
              padding="0px 0px 0px 0px"
              backgroundColor="rgba(38,193,147,1)"
              type="Default"
              {...getOverrideProps(
                overrides,
                "Kanshi/Antenna/indicator505131270"
              )}
            ></KanshiAntennaindicator>
            <KanshiAntennaindicator
              width="56px"
              height="16px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              borderRadius="2px"
              padding="0px 0px 0px 0px"
              backgroundColor="rgba(38,193,147,1)"
              type="Default"
              {...getOverrideProps(
                overrides,
                "Kanshi/Antenna/indicator505131271"
              )}
            ></KanshiAntennaindicator>
            <KanshiAntennaindicator
              width="56px"
              height="16px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              borderRadius="2px"
              padding="0px 0px 0px 0px"
              backgroundColor="rgba(38,193,147,1)"
              type="Default"
              {...getOverrideProps(
                overrides,
                "Kanshi/Antenna/indicator505131272"
              )}
            ></KanshiAntennaindicator>
          </Flex>
        </Flex>
        <CommonDivider
          display="flex"
          gap="0"
          direction="row"
          width="unset"
          height="1px"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Common/Divider505131273")}
        ></CommonDivider>
        <Flex
          gap="16px"
          direction="row"
          width="752px"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="20px 16px 20px 16px"
          backgroundColor="rgba(255,255,255,1)"
          {...getOverrideProps(overrides, "ListAntenna505131274")}
        >
          <Text
            fontFamily="Noto Sans"
            fontSize="14px"
            fontWeight="400"
            color="rgba(100,100,100,1)"
            lineHeight="22.399999618530273px"
            textAlign="center"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0.55px"
            width="108px"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="B：最大値判定"
            {...getOverrideProps(overrides, "text505131275")}
          ></Text>
          <Flex
            gap="4px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 742505131276")}
          >
            <KanshiAntennaindicator
              width="56px"
              height="16px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              borderRadius="2px"
              padding="0px 0px 0px 0px"
              type="None"
              {...getOverrideProps(
                overrides,
                "Kanshi/Antenna/indicator505131277"
              )}
            ></KanshiAntennaindicator>
            <KanshiAntennaindicator
              width="56px"
              height="16px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              borderRadius="2px"
              padding="0px 0px 0px 0px"
              type="None"
              {...getOverrideProps(
                overrides,
                "Kanshi/Antenna/indicator505131278"
              )}
            ></KanshiAntennaindicator>
            <KanshiAntennaindicator
              width="56px"
              height="16px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              borderRadius="2px"
              padding="0px 0px 0px 0px"
              type="None"
              {...getOverrideProps(
                overrides,
                "Kanshi/Antenna/indicator505131279"
              )}
            ></KanshiAntennaindicator>
            <KanshiAntennaindicator
              width="56px"
              height="16px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              borderRadius="2px"
              padding="0px 0px 0px 0px"
              type="None"
              {...getOverrideProps(
                overrides,
                "Kanshi/Antenna/indicator505131280"
              )}
            ></KanshiAntennaindicator>
            <KanshiAntennaindicator
              width="56px"
              height="16px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              borderRadius="2px"
              padding="0px 0px 0px 0px"
              type="None"
              {...getOverrideProps(
                overrides,
                "Kanshi/Antenna/indicator505131281"
              )}
            ></KanshiAntennaindicator>
            <KanshiAntennaindicator
              width="56px"
              height="16px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              borderRadius="2px"
              padding="0px 0px 0px 0px"
              backgroundColor="rgba(38,193,147,1)"
              type="Default"
              {...getOverrideProps(
                overrides,
                "Kanshi/Antenna/indicator505131282"
              )}
            ></KanshiAntennaindicator>
            <KanshiAntennaindicator
              width="56px"
              height="16px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              borderRadius="2px"
              padding="0px 0px 0px 0px"
              backgroundColor="rgba(38,193,147,1)"
              type="Default"
              {...getOverrideProps(
                overrides,
                "Kanshi/Antenna/indicator505131283"
              )}
            ></KanshiAntennaindicator>
            <KanshiAntennaindicator
              width="56px"
              height="16px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              borderRadius="2px"
              padding="0px 0px 0px 0px"
              backgroundColor="rgba(38,193,147,1)"
              type="Default"
              {...getOverrideProps(
                overrides,
                "Kanshi/Antenna/indicator505131284"
              )}
            ></KanshiAntennaindicator>
            <KanshiAntennaindicator
              width="56px"
              height="16px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              borderRadius="2px"
              padding="0px 0px 0px 0px"
              backgroundColor="rgba(38,193,147,1)"
              type="Default"
              {...getOverrideProps(
                overrides,
                "Kanshi/Antenna/indicator505131285"
              )}
            ></KanshiAntennaindicator>
            <KanshiAntennaindicator
              width="56px"
              height="16px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              borderRadius="2px"
              padding="0px 0px 0px 0px"
              backgroundColor="rgba(38,193,147,1)"
              type="Default"
              {...getOverrideProps(
                overrides,
                "Kanshi/Antenna/indicator505131286"
              )}
            ></KanshiAntennaindicator>
          </Flex>
        </Flex>
        <CommonDivider
          display="flex"
          gap="0"
          direction="row"
          width="unset"
          height="1px"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Common/Divider505131287")}
        ></CommonDivider>
        <Flex
          gap="16px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="20px 16px 20px 16px"
          backgroundColor="rgba(255,255,255,1)"
          {...getOverrideProps(overrides, "ListAntenna505131288")}
        >
          <Text
            fontFamily="Noto Sans"
            fontSize="14px"
            fontWeight="400"
            color="rgba(100,100,100,1)"
            lineHeight="22.399999618530273px"
            textAlign="center"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0.55px"
            width="108px"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="C：合計値判定"
            {...getOverrideProps(overrides, "text505131289")}
          ></Text>
          <Flex
            gap="4px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 742505131290")}
          >
            <KanshiAntennaindicator
              width="56px"
              height="16px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              borderRadius="2px"
              padding="0px 0px 0px 0px"
              backgroundColor="rgba(38,193,147,1)"
              type="Default"
              {...getOverrideProps(
                overrides,
                "Kanshi/Antenna/indicator505131291"
              )}
            ></KanshiAntennaindicator>
            <KanshiAntennaindicator
              width="56px"
              height="16px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              borderRadius="2px"
              padding="0px 0px 0px 0px"
              backgroundColor="rgba(38,193,147,1)"
              type="Default"
              {...getOverrideProps(
                overrides,
                "Kanshi/Antenna/indicator505131292"
              )}
            ></KanshiAntennaindicator>
            <KanshiAntennaindicator
              width="56px"
              height="16px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              borderRadius="2px"
              padding="0px 0px 0px 0px"
              backgroundColor="rgba(38,193,147,1)"
              type="Default"
              {...getOverrideProps(
                overrides,
                "Kanshi/Antenna/indicator505131293"
              )}
            ></KanshiAntennaindicator>
            <KanshiAntennaindicator
              width="56px"
              height="16px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              borderRadius="2px"
              padding="0px 0px 0px 0px"
              backgroundColor="rgba(38,193,147,1)"
              type="Default"
              {...getOverrideProps(
                overrides,
                "Kanshi/Antenna/indicator505131294"
              )}
            ></KanshiAntennaindicator>
            <KanshiAntennaindicator
              width="56px"
              height="16px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              borderRadius="2px"
              padding="0px 0px 0px 0px"
              backgroundColor="rgba(38,193,147,1)"
              type="Default"
              {...getOverrideProps(
                overrides,
                "Kanshi/Antenna/indicator505131295"
              )}
            ></KanshiAntennaindicator>
            <KanshiAntennaindicator
              width="56px"
              height="16px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              borderRadius="2px"
              padding="0px 0px 0px 0px"
              type="None"
              {...getOverrideProps(
                overrides,
                "Kanshi/Antenna/indicator505131296"
              )}
            ></KanshiAntennaindicator>
            <KanshiAntennaindicator
              width="56px"
              height="16px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              borderRadius="2px"
              padding="0px 0px 0px 0px"
              type="None"
              {...getOverrideProps(
                overrides,
                "Kanshi/Antenna/indicator505131297"
              )}
            ></KanshiAntennaindicator>
            <KanshiAntennaindicator
              width="56px"
              height="16px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              borderRadius="2px"
              padding="0px 0px 0px 0px"
              type="None"
              {...getOverrideProps(
                overrides,
                "Kanshi/Antenna/indicator505131298"
              )}
            ></KanshiAntennaindicator>
            <KanshiAntennaindicator
              width="56px"
              height="16px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              borderRadius="2px"
              padding="0px 0px 0px 0px"
              type="None"
              {...getOverrideProps(
                overrides,
                "Kanshi/Antenna/indicator505131299"
              )}
            ></KanshiAntennaindicator>
            <KanshiAntennaindicator
              width="56px"
              height="16px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              borderRadius="2px"
              padding="0px 0px 0px 0px"
              type="None"
              {...getOverrideProps(
                overrides,
                "Kanshi/Antenna/indicator505131300"
              )}
            ></KanshiAntennaindicator>
          </Flex>
        </Flex>
      </Flex>
    </View>
  );
}
