// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { MoniteringLog, WaveMoniteringStatus, DeviceMoniteringStatus, WaveMoniteringSetting, DeviceMoniteringSetting, DisplaySetting } = initSchema(schema);

export {
  MoniteringLog,
  WaveMoniteringStatus,
  DeviceMoniteringStatus,
  WaveMoniteringSetting,
  DeviceMoniteringSetting,
  DisplaySetting
};