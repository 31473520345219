/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Flex, Text } from "@aws-amplify/ui-react";
export default function KeitouzuItemAntenna(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      gap="10px"
      direction="column"
      width="unset"
      height="unset"
      justifyContent="flex-start"
      alignItems="flex-start"
      position="relative"
      border="2px SOLID rgba(100,100,100,1)"
      borderRadius="4px"
      padding="14px 6px 14px 6px"
      backgroundColor="rgba(255,255,255,1)"
      {...rest}
      {...getOverrideProps(overrides, "KeitouzuItemAntenna")}
    >
      <Text
        fontFamily="Noto Sans"
        fontSize="14px"
        fontWeight="700"
        color="rgba(100,100,100,1)"
        lineHeight="15.40000057220459px"
        textAlign="center"
        display="block"
        direction="column"
        justifyContent="unset"
        letterSpacing="0.55px"
        width="14px"
        height="unset"
        gap="unset"
        alignItems="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="アンテナ"
        {...getOverrideProps(overrides, "text")}
      ></Text>
    </Flex>
  );
}
