const hostname = window.location.hostname;

export const getLocalHealthStatus = async () => {
    try {
        const response = await fetch(`http://${hostname}:3001/api/listHealthStatus`);
        const data = await response.json();
        // console.log("fetch result", data);
        return data;
    } catch (error) {
        console.log("fetch error",error);
        return [];
    }
}

export const getLocalRadioStatus = async () => {
    try {
        const response = await fetch(`http://${hostname}:3001/api/listRadioStatus`);
        const data = await response.json();
        // console.log("fetch result", data);
        return data;
    } catch (error) {
        console.log("fetch error",error);
        return [];
    }
}