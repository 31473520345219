/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const deleteMoniteringLog = /* GraphQL */ `
  mutation DeleteMoniteringLog(
    $input: DeleteMoniteringLogInput!
    $condition: ModelMoniteringLogConditionInput
  ) {
    deleteMoniteringLog(input: $input, condition: $condition) {
      id
      type
      location_name
      occurrence_at
      recover_state
      state_type
      alarm_type
      occurrence_source
      log_type
      log_detail
      recover_flg
      recover_at
      confirmed_flg
      confirmation_at
      confirmation_note
      all_status
      sequence_number
      log_id
      createdAt
      updatedAt
    }
  }
`;
export const createWaveMoniteringStatus = /* GraphQL */ `
  mutation CreateWaveMoniteringStatus(
    $input: CreateWaveMoniteringStatusInput!
    $condition: ModelWaveMoniteringStatusConditionInput
  ) {
    createWaveMoniteringStatus(input: $input, condition: $condition) {
      id
      location_name
      band
      threshold_type
      state
      occurrence_detail
      sequence_number
      createdAt
      updatedAt
    }
  }
`;
export const deleteWaveMoniteringStatus = /* GraphQL */ `
  mutation DeleteWaveMoniteringStatus(
    $input: DeleteWaveMoniteringStatusInput!
    $condition: ModelWaveMoniteringStatusConditionInput
  ) {
    deleteWaveMoniteringStatus(input: $input, condition: $condition) {
      id
      location_name
      band
      threshold_type
      state
      occurrence_detail
      sequence_number
      createdAt
      updatedAt
    }
  }
`;
export const createDeviceMoniteringStatus = /* GraphQL */ `
  mutation CreateDeviceMoniteringStatus(
    $input: CreateDeviceMoniteringStatusInput!
    $condition: ModelDeviceMoniteringStatusConditionInput
  ) {
    createDeviceMoniteringStatus(input: $input, condition: $condition) {
      id
      location_name
      device
      state
      occurrence_detail
      sequence_number
      createdAt
      updatedAt
    }
  }
`;
export const deleteDeviceMoniteringStatus = /* GraphQL */ `
  mutation DeleteDeviceMoniteringStatus(
    $input: DeleteDeviceMoniteringStatusInput!
    $condition: ModelDeviceMoniteringStatusConditionInput
  ) {
    deleteDeviceMoniteringStatus(input: $input, condition: $condition) {
      id
      location_name
      device
      state
      occurrence_detail
      sequence_number
      createdAt
      updatedAt
    }
  }
`;
export const createWaveMoniteringSetting = /* GraphQL */ `
  mutation CreateWaveMoniteringSetting(
    $input: CreateWaveMoniteringSettingInput!
    $condition: ModelWaveMoniteringSettingConditionInput
  ) {
    createWaveMoniteringSetting(input: $input, condition: $condition) {
      id
      location_name
      setting_json
      createdAt
      updatedAt
    }
  }
`;
export const updateWaveMoniteringSetting = /* GraphQL */ `
  mutation UpdateWaveMoniteringSetting(
    $input: UpdateWaveMoniteringSettingInput!
    $condition: ModelWaveMoniteringSettingConditionInput
  ) {
    updateWaveMoniteringSetting(input: $input, condition: $condition) {
      id
      location_name
      setting_json
      createdAt
      updatedAt
    }
  }
`;
export const deleteWaveMoniteringSetting = /* GraphQL */ `
  mutation DeleteWaveMoniteringSetting(
    $input: DeleteWaveMoniteringSettingInput!
    $condition: ModelWaveMoniteringSettingConditionInput
  ) {
    deleteWaveMoniteringSetting(input: $input, condition: $condition) {
      id
      location_name
      setting_json
      createdAt
      updatedAt
    }
  }
`;
export const createDeviceMoniteringSetting = /* GraphQL */ `
  mutation CreateDeviceMoniteringSetting(
    $input: CreateDeviceMoniteringSettingInput!
    $condition: ModelDeviceMoniteringSettingConditionInput
  ) {
    createDeviceMoniteringSetting(input: $input, condition: $condition) {
      id
      location_name
      setting_json
      createdAt
      updatedAt
    }
  }
`;
export const updateDeviceMoniteringSetting = /* GraphQL */ `
  mutation UpdateDeviceMoniteringSetting(
    $input: UpdateDeviceMoniteringSettingInput!
    $condition: ModelDeviceMoniteringSettingConditionInput
  ) {
    updateDeviceMoniteringSetting(input: $input, condition: $condition) {
      id
      location_name
      setting_json
      createdAt
      updatedAt
    }
  }
`;
export const deleteDeviceMoniteringSetting = /* GraphQL */ `
  mutation DeleteDeviceMoniteringSetting(
    $input: DeleteDeviceMoniteringSettingInput!
    $condition: ModelDeviceMoniteringSettingConditionInput
  ) {
    deleteDeviceMoniteringSetting(input: $input, condition: $condition) {
      id
      location_name
      setting_json
      createdAt
      updatedAt
    }
  }
`;
export const createDisplaySetting = /* GraphQL */ `
  mutation CreateDisplaySetting(
    $input: CreateDisplaySettingInput!
    $condition: ModelDisplaySettingConditionInput
  ) {
    createDisplaySetting(input: $input, condition: $condition) {
      id
      setting_json
      createdAt
      updatedAt
    }
  }
`;
export const updateDisplaySetting = /* GraphQL */ `
  mutation UpdateDisplaySetting(
    $input: UpdateDisplaySettingInput!
    $condition: ModelDisplaySettingConditionInput
  ) {
    updateDisplaySetting(input: $input, condition: $condition) {
      id
      setting_json
      createdAt
      updatedAt
    }
  }
`;
export const deleteDisplaySetting = /* GraphQL */ `
  mutation DeleteDisplaySetting(
    $input: DeleteDisplaySettingInput!
    $condition: ModelDisplaySettingConditionInput
  ) {
    deleteDisplaySetting(input: $input, condition: $condition) {
      id
      setting_json
      createdAt
      updatedAt
    }
  }
`;
export const createS3Objects = /* GraphQL */ `
  mutation CreateS3Objects(
    $input: CreateS3ObjectsInput!
    $condition: ModelS3ObjectsConditionInput
  ) {
    createS3Objects(input: $input, condition: $condition) {
      type
      key
      fileName
      size
      lastModified
      id
      createdAt
      updatedAt
    }
  }
`;
export const updateS3Objects = /* GraphQL */ `
  mutation UpdateS3Objects(
    $input: UpdateS3ObjectsInput!
    $condition: ModelS3ObjectsConditionInput
  ) {
    updateS3Objects(input: $input, condition: $condition) {
      type
      key
      fileName
      size
      lastModified
      id
      createdAt
      updatedAt
    }
  }
`;
export const deleteS3Objects = /* GraphQL */ `
  mutation DeleteS3Objects(
    $input: DeleteS3ObjectsInput!
    $condition: ModelS3ObjectsConditionInput
  ) {
    deleteS3Objects(input: $input, condition: $condition) {
      type
      key
      fileName
      size
      lastModified
      id
      createdAt
      updatedAt
    }
  }
`;
export const createPatliteStatus = /* GraphQL */ `
  mutation CreatePatliteStatus(
    $input: CreatePatliteStatusInput!
    $condition: ModelPatliteStatusConditionInput
  ) {
    createPatliteStatus(input: $input, condition: $condition) {
      id
      isMute
      alarmLogs
      createdAt
      updatedAt
    }
  }
`;
export const updatePatliteStatus = /* GraphQL */ `
  mutation UpdatePatliteStatus(
    $input: UpdatePatliteStatusInput!
    $condition: ModelPatliteStatusConditionInput
  ) {
    updatePatliteStatus(input: $input, condition: $condition) {
      id
      isMute
      alarmLogs
      createdAt
      updatedAt
    }
  }
`;
export const deletePatliteStatus = /* GraphQL */ `
  mutation DeletePatliteStatus(
    $input: DeletePatliteStatusInput!
    $condition: ModelPatliteStatusConditionInput
  ) {
    deletePatliteStatus(input: $input, condition: $condition) {
      id
      isMute
      alarmLogs
      createdAt
      updatedAt
    }
  }
`;
export const createMeasurementPcStatus = /* GraphQL */ `
  mutation CreateMeasurementPcStatus(
    $input: CreateMeasurementPcStatusInput!
    $condition: ModelMeasurementPcStatusConditionInput
  ) {
    createMeasurementPcStatus(input: $input, condition: $condition) {
      id
      pc_id
      location_name
      isMain
      status
      createdAt
      updatedAt
    }
  }
`;
export const deleteMeasurementPcStatus = /* GraphQL */ `
  mutation DeleteMeasurementPcStatus(
    $input: DeleteMeasurementPcStatusInput!
    $condition: ModelMeasurementPcStatusConditionInput
  ) {
    deleteMeasurementPcStatus(input: $input, condition: $condition) {
      id
      pc_id
      location_name
      isMain
      status
      createdAt
      updatedAt
    }
  }
`;
export const createUsers = /* GraphQL */ `
  mutation CreateUsers(
    $input: CreateUsersInput!
    $condition: ModelUsersConditionInput
  ) {
    createUsers(input: $input, condition: $condition) {
      casthive_id
      last_login_at
      id
      createdAt
      updatedAt
    }
  }
`;
export const deleteUsers = /* GraphQL */ `
  mutation DeleteUsers(
    $input: DeleteUsersInput!
    $condition: ModelUsersConditionInput
  ) {
    deleteUsers(input: $input, condition: $condition) {
      casthive_id
      last_login_at
      id
      createdAt
      updatedAt
    }
  }
`;
export const createMoniteringLog = /* GraphQL */ `
  mutation CreateMoniteringLog(
    $input: CreateMoniteringLogInput!
    $condition: ModelMoniteringLogConditionInput
  ) {
    createMoniteringLog(input: $input, condition: $condition) {
      id
      type
      location_name
      occurrence_at
      recover_state
      state_type
      alarm_type
      occurrence_source
      log_type
      log_detail
      recover_flg
      recover_at
      confirmed_flg
      confirmation_at
      confirmation_note
      all_status
      sequence_number
      log_id
      createdAt
      updatedAt
    }
  }
`;
export const updateMoniteringLog = /* GraphQL */ `
  mutation UpdateMoniteringLog(
    $input: UpdateMoniteringLogInput!
    $condition: ModelMoniteringLogConditionInput
  ) {
    updateMoniteringLog(input: $input, condition: $condition) {
      id
      type
      location_name
      occurrence_at
      recover_state
      state_type
      alarm_type
      occurrence_source
      log_type
      log_detail
      recover_flg
      recover_at
      confirmed_flg
      confirmation_at
      confirmation_note
      all_status
      sequence_number
      log_id
      createdAt
      updatedAt
    }
  }
`;
export const updateWaveMoniteringStatus = /* GraphQL */ `
  mutation UpdateWaveMoniteringStatus(
    $input: UpdateWaveMoniteringStatusInput!
    $condition: ModelWaveMoniteringStatusConditionInput
  ) {
    updateWaveMoniteringStatus(input: $input, condition: $condition) {
      id
      location_name
      band
      threshold_type
      state
      occurrence_detail
      sequence_number
      createdAt
      updatedAt
    }
  }
`;
export const updateDeviceMoniteringStatus = /* GraphQL */ `
  mutation UpdateDeviceMoniteringStatus(
    $input: UpdateDeviceMoniteringStatusInput!
    $condition: ModelDeviceMoniteringStatusConditionInput
  ) {
    updateDeviceMoniteringStatus(input: $input, condition: $condition) {
      id
      location_name
      device
      state
      occurrence_detail
      sequence_number
      createdAt
      updatedAt
    }
  }
`;
export const updateMeasurementPcStatus = /* GraphQL */ `
  mutation UpdateMeasurementPcStatus(
    $input: UpdateMeasurementPcStatusInput!
    $condition: ModelMeasurementPcStatusConditionInput
  ) {
    updateMeasurementPcStatus(input: $input, condition: $condition) {
      id
      pc_id
      location_name
      isMain
      status
      createdAt
      updatedAt
    }
  }
`;
export const updateUsers = /* GraphQL */ `
  mutation UpdateUsers(
    $input: UpdateUsersInput!
    $condition: ModelUsersConditionInput
  ) {
    updateUsers(input: $input, condition: $condition) {
      casthive_id
      last_login_at
      id
      createdAt
      updatedAt
    }
  }
`;
