import React, { useEffect, useState, useRef } from "react";
import { View, Flex, Text } from "@aws-amplify/ui-react";
import { CustomModal } from "../customComponents/CustomModal";
import { getFormatNowDate } from '../lib/StorageUtil';
import {
    CommonBadgeHeader,
    CommonTitle,
    CommonDivider,
    Spacer,
} from "../ui-components";

import {
    CommonBtnBlueWrapper,
    SetteiInputBoxWrapper,
    CheckWrapper,
    CommonBtnRoundWrapper,
} from '../componentsWrapper'

import { updateDisplaySettingJson, mergeObjects } from "../lib/SettingUtil";

export default function KanshiSetteiLabelWrapper(props) {
    const [situation, setSituation] = useState("default");
    function onMouseEventEnter() {
        setSituation("hover")
    }

    function onMouseEventLeave() {
        setSituation("default")
    }

    // 変更後の設定値
    const [changeSettingValueJson, setChangeSettingValueJson] = useState(props.displaySettingJson);

    // 変更確認モーダル
    const [openChangeModal, setOpenChangeModal] = useState(false);

    // 設定ファイル読み込み用inputタグref
    const inputSettingJosnRef = useRef(null);

    //  設定ファイルダウンロード
    const onClickBtnSettingJsonDownload = () => {
        console.log("onClickBtnSettingJsonDownload")

        // jsonファイルをダウンロードする
        const json = JSON.stringify(changeSettingValueJson, null, '  ');

        const blob = new Blob([json], { type: "application/json" });

        const a = document.createElement("a");

        a.href = URL.createObjectURL(blob);

        a.download = "interference-monitoring-表示ラベル設定-" + getFormatNowDate() + ".json";

        a.click();
    }

    // 設定変更ボタンクリック
    const onClickBtnSettingSave = async () => {
        console.log("onClickBtnBlueWrapper")

        // モーダルを閉じる
        setOpenChangeModal(false);

        // 設定値を保存する
        await updateDisplaySettingJson(changeSettingValueJson);

        // 設定値を再取得
        await props.getDisplaySettingJson();
    }

    // 設定ファイル読み込み用関数
    const readJsonFile = (file) =>
        new Promise((resolve, reject) => {
            const fileReader = new FileReader()

            fileReader.onload = event => {
                if (event.target) {
                    resolve(JSON.parse(event.target.result))
                }
            }

            fileReader.onerror = error => reject(error)
            fileReader.readAsText(file)
        })

    // 設定ファイル反映ボタンクリック
    const onClickBtnSettingJsonUpload = async (event) => {
        console.log("onClickBtnSettingJsonUpload")

        if (event.target.files) {
            const parsedData = await readJsonFile(event.target.files[0])

            // 👇️ reset file input
            event.target.value = null;

            if (parsedData != null && changeSettingValueJson != null) {
                // マージする
                let mergeSettingJson = mergeObjects(changeSettingValueJson, parsedData);

                // バリデーションチェック
                Object.entries(mergeSettingJson.location_name).map((item, index) => {
                    let locationName = item[1];

                    // 最大(全角8/半角16)に切り詰める
                    let truncated = "";
                    let currentBytes = 0;
                    for (let i = 0; i < locationName.length; i++) {
                        const char = locationName.charCodeAt(i);
                        const charBytes = char <= 0x7F ? 1 : 2;
                        if (currentBytes + charBytes <= 16) {
                            currentBytes += charBytes;
                            truncated += locationName[i];
                        } else {
                            break;
                        }
                    }

                    mergeSettingJson.location_name[item[0]] = truncated;
                })

                // バリデーションチェック
                Object.entries(mergeSettingJson.band_label).map((item, index) => {
                    let name = item[1].name;
                    // 最大(全角5/半角10)に切り詰める
                    let truncated = "";
                    let currentBytes = 0;
                    for (let i = 0; i < name.length; i++) {
                        const char = name.charCodeAt(i);
                        const charBytes = char <= 0x7F ? 1 : 2;
                        if (currentBytes + charBytes <= 10) {
                            currentBytes += charBytes;
                            truncated += name[i];
                        } else {
                            break;
                        }
                    }

                    mergeSettingJson.band_label[item[0]].name = truncated;

                    let colorCode = item[1].color;

                    if (colorCode.length > 7) {
                        colorCode = colorCode.slice(0, 7);
                    }
                    else if (colorCode.length === 0) {
                        colorCode = "#";
                    }

                    // 小文字に変換
                    colorCode = colorCode.toLowerCase();

                    // 先頭と16新数部分に分割
                    const head = colorCode.slice(0, 1);
                    let body = colorCode.slice(1);

                    // 先頭が#、カラーコードに利用できる文字以外は削除
                    body = body.replace(/[^a-f0-9]/g, "");

                    // カラーコードを作成
                    colorCode = head + body;

                    // カラーコードが#のみの場合
                    if (colorCode === "#") {
                        colorCode = "#000000";
                    }

                    // 三桁カラーコードになっている場合、6桁に変換
                    if (colorCode.length === 4) {
                        colorCode = "#" + colorCode[1] + colorCode[1] + colorCode[2] + colorCode[2] + colorCode[3] + colorCode[3];
                    }
                    // 6桁カラーコードになっていない場合、0で埋める
                    if (colorCode.length < 7) {
                        colorCode = colorCode.padEnd(7, "0");
                    }

                    // カラーコードを設定
                    mergeSettingJson.band_label[item[0]].color = colorCode;
                });

                // 設定値を保存する
                setChangeSettingValueJson(value => {
                    return mergeSettingJson;
                })
            }
        }
    }

    useEffect(() => {
        console.log("KanshiSetteiLabelWrapper useEffect ", props.displaySettingJson);
        setChangeSettingValueJson(value => {
            return props.displaySettingJson;
        })
    }, [props.displaySettingJson]);

    return (
        <Flex
            gap="48px"
            direction="column"
            marginLeft={"24px"}
            marginRight={"24px"}
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            position="relative"
            padding="0px 0px 0px 0px"
            backgroundColor="rgba(245,245,245,1)"
        // {...getOverrideProps(overrides, "LayoutSetteiHyojiLabel")}
        >
            <Flex
                direction="row"
                width="100%"
                height="unset"
                justifyContent="flex-start"
                alignItems="center"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
            // {...getOverrideProps(overrides, "Frame 903")}
            >
                <Flex
                    gap="16px"
                    direction="row"
                    height="unset"
                    justifyContent="flex-start"
                    alignItems="center"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    style={{
                        flexGrow: 1,
                    }}
                // {...getOverrideProps(overrides, "Frame 897")}
                >
                    <CommonBadgeHeader
                        onClick={() => props.onClickBackBtn()}
                        width="40px"
                        height="40px"
                        display="block"
                        gap="unset"
                        alignItems="unset"
                        justifyContent="unset"
                        shrink="0"
                        position="relative"
                        borderRadius="20px"
                        padding="0px 0px 0px 0px"
                        type="Back"
                        style={{ cursor: "pointer" }}
                        situation={situation}
                        onMouseEnter={onMouseEventEnter}
                        onMouseLeave={onMouseEventLeave}
                    // {...getOverrideProps(overrides, "Common/Badge/Header")}
                    ></CommonBadgeHeader>
                    <CommonTitle
                        width="240px"
                        height="18px"
                        display="block"
                        gap="unset"
                        alignItems="unset"
                        justifyContent="unset"
                        shrink="0"
                        position="relative"
                        padding="0px 0px 0px 0px"
                        size="large"
                        overrides={{
                            text: {
                                children: "表示ラベル設定",
                            }
                        }}
                    // {...getOverrideProps(overrides, "Common/Title")}
                    ></CommonTitle>
                </Flex>

                <Flex
                    gap="16px"
                    direction="row"
                    width="unset"
                    height="unset"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                // {...getOverrideProps(overrides, "Frame 884")}
                >
                    <CommonBtnRoundWrapper
                        onClick={onClickBtnSettingJsonDownload}
                        color="White"
                        children="設定ファイルDL"
                    ></CommonBtnRoundWrapper>
                    <CommonBtnRoundWrapper
                        color="White"
                        children="設定ファイル読込"
                        onClick={() => inputSettingJosnRef.current.click()}
                    // {...getOverrideProps(overrides, "Common/Btn/Round505155615")}
                    ></CommonBtnRoundWrapper>
                </Flex>


                <CommonBtnBlueWrapper
                    onClick={() => setOpenChangeModal(true)}
                    children="設定を変更"
                // {...getOverrideProps(overrides, "Common/Btn/Blue")}
                ></CommonBtnBlueWrapper>
            </Flex>
            <Flex
                gap="40px"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
            // {...getOverrideProps(overrides, "Frame 902")}
            >
                <Flex
                    gap="16px"
                    direction="column"
                    width="unset"
                    height="unset"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    shrink="0"
                    alignSelf="stretch"
                    position="relative"
                    padding="0px 0px 0px 0px"
                // {...getOverrideProps(overrides, "Frame 898")}
                >
                    <Text
                        fontFamily="Noto Sans"
                        fontSize="16px"
                        fontWeight="400"
                        color="rgba(53,56,59,1)"
                        lineHeight="16px"
                        textAlign="left"
                        display="block"
                        direction="column"
                        justifyContent="unset"
                        letterSpacing="0.65px"
                        width="unset"
                        height="unset"
                        gap="unset"
                        alignItems="unset"
                        shrink="0"
                        position="relative"
                        padding="0px 0px 0px 0px"
                        whiteSpace="pre-wrap"
                        children="拠点ラベル表示名"
                    // {...getOverrideProps(overrides, "text485918374")}
                    ></Text>
                    <CommonDivider
                        display="flex"
                        gap="0"
                        direction="row"
                        width="unset"
                        height="1px"
                        justifyContent="center"
                        alignItems="center"
                        overflow="hidden"
                        shrink="0"
                        alignSelf="stretch"
                        position="relative"
                        padding="0px 0px 0px 0px"
                    // {...getOverrideProps(overrides, "Common/Divider485918375")}
                    ></CommonDivider>
                    <Flex
                        gap="0"
                        direction="row"
                        width="unset"
                        height="unset"
                        justifyContent="flex-start"
                        alignItems="center"
                        shrink="0"
                        position="relative"
                        padding="0px 0px 0px 0px"
                    // {...getOverrideProps(overrides, "Frame 800485918376")}
                    >
                        {
                            Object.entries(changeSettingValueJson.location_name).map(([inputLabel, inputValue], index) => (
                                <>
                                    <SetteiInputBoxWrapper
                                        key={"kyotenInput" + index}
                                        height="90px"
                                        width="120px"
                                        attention="true"
                                        attentiontext="最大(全角8/半角16)"
                                        label="true"
                                        labelValue={inputLabel}
                                        unit="Off"
                                        inputValue={inputValue}
                                        onChangeHandler={(e) => {
                                            let value = e.target.value;

                                            // 最大(全角8/半角16)に切り詰める
                                            let truncated = "";
                                            let currentBytes = 0;
                                            for (let i = 0; i < value.length; i++) {
                                                const char = value.charCodeAt(i);
                                                const charBytes = char <= 0x7F ? 1 : 2;
                                                if (currentBytes + charBytes <= 16) {
                                                    currentBytes += charBytes;
                                                    truncated += value[i];
                                                } else {
                                                    break;
                                                }
                                            }

                                            setChangeSettingValueJson(value => {
                                                return {
                                                    ...value,
                                                    location_name: {
                                                        ...value.location_name,
                                                        [inputLabel]: truncated
                                                    }
                                                }
                                            })
                                        }}
                                    // onBlurHandler={handleBlur}
                                    />
                                    {Object.entries(changeSettingValueJson.location_name).length - 1 !== index ?
                                        <Spacer
                                            key={"kyotenSpacer" + index}
                                            display="flex"
                                            gap="10px"
                                            direction="column"
                                            width="64px"
                                            height="unset"
                                            justifyContent="center"
                                            alignItems="center"
                                            overflow="hidden"
                                            shrink="0"
                                            alignSelf="stretch"
                                            position="relative"
                                            padding="10px 10px 10px 10px"
                                        ></Spacer>
                                        : null
                                    }
                                </>
                            ))
                        }
                    </Flex>
                </Flex>
                <Flex
                    gap="16px"
                    direction="column"
                    width="unset"
                    height="unset"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    shrink="0"
                    alignSelf="stretch"
                    position="relative"
                    padding="0px 0px 0px 0px"
                // {...getOverrideProps(overrides, "Frame 900")}
                >
                    <Text
                        fontFamily="Noto Sans"
                        fontSize="16px"
                        fontWeight="400"
                        color="rgba(53,56,59,1)"
                        lineHeight="16px"
                        textAlign="left"
                        display="block"
                        direction="column"
                        justifyContent="unset"
                        letterSpacing="0.65px"
                        width="unset"
                        height="unset"
                        gap="unset"
                        alignItems="unset"
                        shrink="0"
                        position="relative"
                        padding="0px 0px 0px 0px"
                        whiteSpace="pre-wrap"
                        children="周波数帯別ラベル表示名設定"
                    // {...getOverrideProps(overrides, "text506027348")}
                    ></Text>
                    <CommonDivider
                        display="flex"
                        gap="0"
                        direction="row"
                        width="unset"
                        height="1px"
                        justifyContent="center"
                        alignItems="center"
                        overflow="hidden"
                        shrink="0"
                        alignSelf="stretch"
                        position="relative"
                        padding="0px 0px 0px 0px"
                    // {...getOverrideProps(overrides, "Common/Divider506027349")}
                    ></CommonDivider>
                    <Flex
                        gap="0"
                        direction="row"
                        width="unset"
                        height="unset"
                        justifyContent="flex-start"
                        alignItems="center"
                        shrink="0"
                        alignSelf="stretch"
                        position="relative"
                        padding="0px 0px 0px 0px"
                    // {...getOverrideProps(overrides, "Frame 800507330106")}
                    >
                        {
                            Object.entries(changeSettingValueJson.band_label).map(([label, valueJson], index) => (
                                <>
                                    <SetteiInputBoxWrapper
                                        key={"bandInput" + index}
                                        height="88px"
                                        width="120px"
                                        attention="true"
                                        attentiontext="最大(全角5/半角10)"
                                        label="true"
                                        labelValue={label}
                                        unit="Off"
                                        inputValue={valueJson.name}
                                        onChangeHandler={(e) => {
                                            let value = e.target.value;

                                            // 最大(全角5/半角10)に切り詰める
                                            let truncated = "";
                                            let currentBytes = 0;
                                            for (let i = 0; i < value.length; i++) {
                                                const char = value.charCodeAt(i);
                                                const charBytes = char <= 0x7F ? 1 : 2;
                                                if (currentBytes + charBytes <= 10) {
                                                    currentBytes += charBytes;
                                                    truncated += value[i];
                                                } else {
                                                    break;
                                                }
                                            }

                                            setChangeSettingValueJson(value => {
                                                return {
                                                    ...value,
                                                    band_label: {
                                                        ...value.band_label,
                                                        [label]: {
                                                            ...value.band_label[label],
                                                            name: truncated
                                                        }
                                                    }
                                                }
                                            })
                                        }}
                                        onBlurHandler={(e) => { }}
                                    />
                                    {Object.entries(changeSettingValueJson.band_label).length - 1 !== index ?
                                        <Spacer
                                            key={"bandSpacer" + index}
                                            display="flex"
                                            gap="10px"
                                            direction="column"
                                            width="64px"
                                            height="unset"
                                            justifyContent="center"
                                            alignItems="center"
                                            overflow="hidden"
                                            shrink="0"
                                            alignSelf="stretch"
                                            position="relative"
                                            padding="10px 10px 10px 10px"
                                        ></Spacer>
                                        : null
                                    }
                                </>
                            ))
                        }
                    </Flex>
                </Flex>
                <Flex
                    gap="16px"
                    direction="column"
                    width="unset"
                    height="unset"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    shrink="0"
                    alignSelf="stretch"
                    position="relative"
                    padding="0px 0px 0px 0px"
                // {...getOverrideProps(overrides, "Frame 901")}
                >
                    <Text
                        fontFamily="Noto Sans"
                        fontSize="16px"
                        fontWeight="400"
                        color="rgba(53,56,59,1)"
                        lineHeight="16px"
                        textAlign="left"
                        display="block"
                        direction="column"
                        justifyContent="unset"
                        letterSpacing="0.65px"
                        width="unset"
                        height="unset"
                        gap="unset"
                        alignItems="unset"
                        shrink="0"
                        position="relative"
                        padding="0px 0px 0px 0px"
                        whiteSpace="pre-wrap"
                        children="plotファイルの背景変更設定"
                    // {...getOverrideProps(overrides, "text549713741")}
                    ></Text>
                    <CommonDivider
                        display="flex"
                        gap="0"
                        direction="row"
                        width="unset"
                        height="1px"
                        justifyContent="center"
                        alignItems="center"
                        overflow="hidden"
                        shrink="0"
                        alignSelf="stretch"
                        position="relative"
                        padding="0px 0px 0px 0px"
                    // {...getOverrideProps(overrides, "Common/Divider549713742")}
                    ></CommonDivider>
                    <Flex
                        gap="0"
                        direction="row"
                        width="unset"
                        height="unset"
                        justifyContent="flex-start"
                        alignItems="center"
                        shrink="0"
                        alignSelf="stretch"
                        position="relative"
                        padding="0px 0px 0px 0px"
                    // {...getOverrideProps(overrides, "Frame 800549713743")}
                    >
                        {
                            Object.entries(changeSettingValueJson.band_label).map(([label, valueJson], index) => (
                                <>
                                    <Flex
                                        gap="8px"
                                        direction="row"
                                        width="unset"
                                        height="unset"
                                        justifyContent="flex-start"
                                        alignItems="center"
                                        shrink="0"
                                        position="relative"
                                        padding="0px 0px 0px 0px"
                                    // {...getOverrideProps(overrides, "Input3400-3440")}
                                    >
                                        <SetteiInputBoxWrapper
                                            key={"bandColorInput" + index}
                                            height="87px"
                                            width="120px"
                                            attention="false"
                                            label="true"
                                            unit="Off"
                                            labelValue={label}
                                            inputValue={valueJson.color}
                                            onChangeHandler={(e) => {
                                                let colorCode = e.target.value;

                                                if (colorCode.length > 7) {
                                                    colorCode = colorCode.slice(0, 7);
                                                }
                                                else if (colorCode.length === 0) {
                                                    colorCode = "#";
                                                }

                                                // 小文字に変換
                                                colorCode = colorCode.toLowerCase();

                                                // 先頭と16新数部分に分割
                                                const head = colorCode.slice(0, 1);
                                                let body = colorCode.slice(1);

                                                // 先頭が#、カラーコードに利用できる文字以外は削除
                                                body = body.replace(/[^a-f0-9]/g, "");

                                                // カラーコードを作成
                                                colorCode = head + body;

                                                setChangeSettingValueJson(value => {
                                                    return {
                                                        ...value,
                                                        band_label: {
                                                            ...value.band_label,
                                                            [label]: {
                                                                ...value.band_label[label],
                                                                color: colorCode
                                                            }
                                                        }
                                                    }
                                                })
                                            }}
                                            onBlurHandler={(e) => {
                                                let colorCode = e.target.value;

                                                // カラーコードが#のみの場合
                                                if (colorCode === "#") {
                                                    colorCode = "#000000";
                                                }

                                                // 三桁カラーコードになっている場合、6桁に変換
                                                if (colorCode.length === 4) {
                                                    colorCode = "#" + colorCode[1] + colorCode[1] + colorCode[2] + colorCode[2] + colorCode[3] + colorCode[3];
                                                }
                                                // 6桁カラーコードになっていない場合、0で埋める
                                                if (colorCode.length < 7) {
                                                    colorCode = colorCode.padEnd(7, "0");
                                                }

                                                setChangeSettingValueJson(value => {
                                                    return {
                                                        ...value,
                                                        band_label: {
                                                            ...value.band_label,
                                                            [label]: {
                                                                ...value.band_label[label],
                                                                color: colorCode
                                                            }
                                                        }
                                                    }
                                                })
                                            }}
                                        />
                                        <View
                                            width="16px"
                                            height="16px"
                                            display="block"
                                            gap="unset"
                                            alignItems="unset"
                                            justifyContent="unset"
                                            overflow="hidden"
                                            shrink="0"
                                            position="relative"
                                            padding="0px 0px 0px 0px"
                                            backgroundColor={valueJson.color}
                                        // {...getOverrideProps(overrides, "ColorFrame549814611")}
                                        ></View>
                                    </Flex>
                                    {Object.entries(changeSettingValueJson.band_label).length - 1 !== index ?
                                        <Spacer
                                            key={"bandColorSpacer" + index}
                                            display="flex"
                                            gap="10px"
                                            direction="column"
                                            width="40px"
                                            height="unset"
                                            justifyContent="center"
                                            alignItems="center"
                                            overflow="hidden"
                                            shrink="0"
                                            alignSelf="stretch"
                                            position="relative"
                                            padding="10px 10px 10px 10px"
                                        ></Spacer>
                                        : null
                                    }
                                </>
                            ))
                        }
                    </Flex>
                </Flex>
            </Flex>

            {/* 設定変更確認モーダル */}
            <CustomModal
                open={openChangeModal}
                onClose={() => setOpenChangeModal(false)}
            >
                <CheckWrapper
                    msg={"表示ラベル設定を変更します。よろしいですか？"}
                    onClickOk={onClickBtnSettingSave}
                    onClickCancel={() => setOpenChangeModal(false)}
                />
            </CustomModal>

            {/* 設定ファイル読み込み用inputタグ */}
            <input
                style={{ display: 'none' }}
                ref={inputSettingJosnRef}
                type="file"
                accept='.json,application/json'
                onChange={onClickBtnSettingJsonUpload}
            />
        </Flex>
    );
}