import { useState, useEffect } from "react";
import { Flex } from "@aws-amplify/ui-react";
import {
    KanshiBtnMenu,
} from '../ui-components'
import {
    KanshiKyotenAntennaStateWrapper,
    KanshiKyotenMachineStateWrapper,
} from '../componentsWrapper'

export default function KanshiKyotenStateWrapper(props) {

    const convertStateType = (state) => {
        switch (state) {
            case "error":
                return "Error";
            case "warning":
                return "Warning";
            case "default":
                return "None";
        }
    }

    const [waveMenuSituation, setAntennaMenuSituation] = useState("Select");
    const [deviceMenuSituation, setMachineMenuSituation] = useState("Default");

    useEffect(() => {
        console.log(props.location);
    }, [props.location]);

    useEffect(() => {
        onClickBtnMenu(props.menuState)
    }, [props.menuState]);

    function onClickBtnMenu(selectType) {
        // console.log(selectType);
        if (selectType === "antenna") {
            setAntennaMenuSituation("Select");
            setMachineMenuSituation("Default");
        } else {
            setAntennaMenuSituation("Default");
            setMachineMenuSituation("Select");
        }
    }

    return (
        <>
            <Flex
                gap="24px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="32px 40px 32px 40px"
                backgroundColor="rgba(245,245,245,1)"
            // {...getOverrideProps(overrides, "Menu")}
            >
                <KanshiBtnMenu
                    display="flex"
                    gap="0"
                    direction="column"
                    width="unset"
                    height="unset"
                    justifyContent="flex-start"
                    alignItems="center"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    situation={waveMenuSituation}
                    type={convertStateType(props.waveState)}
                    overrides={{
                        text: { children: "干渉波監視" },
                    }}
                    onClick={() => onClickBtnMenu("antenna")}
                    style={{ cursor: "pointer" }}
                // {...getOverrideProps(overrides, "Kanshi/Btn/Menu505137042")}
                ></KanshiBtnMenu>
                <KanshiBtnMenu
                    display="flex"
                    gap="0"
                    direction="column"
                    width="unset"
                    height="unset"
                    justifyContent="flex-start"
                    alignItems="center"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    situation={deviceMenuSituation}
                    type={convertStateType(props.deviceState)}
                    overrides={{
                        text: { children: "機器監視" },
                    }}
                    onClick={() => onClickBtnMenu("machine")}
                    style={{ cursor: "pointer" }}
                // {...getOverrideProps(overrides, "Kanshi/Btn/Menu505137043")}
                ></KanshiBtnMenu>
            </Flex>
            {waveMenuSituation === "Select" ?
                <KanshiKyotenAntennaStateWrapper
                    displaySettingJson={props.displaySettingJson}
                    waveMoniteringStatus={props.waveMoniteringStatus}
                >

                </KanshiKyotenAntennaStateWrapper>
                :
                <KanshiKyotenMachineStateWrapper
                    location={props.location}
                    deviceMoniteringStatus={props.deviceMoniteringStatus}
                    displaySettingJson={props.displaySettingJson}
                >

                </KanshiKyotenMachineStateWrapper>
            }
        </>
    )
}