import { useEffect, useState } from "react";
import {
    CommonBtnRound,
    KanshiFrameSummaryResult,
} from "../ui-components";

import KanshiFrameSummary from "../ui-components/KanshiFrameSummary";

import {
    getMoniteringLogSummary,
} from "../lib/LogUtil";

import { isLocalMode } from "../lib/envUtil";

import { formatDatetime } from './KanshiFrameLogDetailsWrapper'

import { CustomModal } from './../customComponents/CustomModal'
import { LogSummaryTable } from './../customComponents/LogSummaryTable'

import {
    formatOccurrenceSource,
    formatStateType,
    formatAlarmType,
} from "./../customComponents/LogSummaryTable"

import { pdf } from "./../lib/PdfUtil";

export default function KanshiFrameSummaryWrapper({
    displaySettingJson,
}) {
    // ボタンホバー時の色
    const [reportBtnColor, setReportBtnColor] = useState("Gray");
    function onMouseEventBtnRoundEnter() {
        setReportBtnColor("Hover");
    }
    function onMouseEventBtnRoundLeave() {
        setReportBtnColor("Gray");
    }

    // モーダル表示フラグ
    const [modalVisible, setModalVisible] = useState(false);

    // ログ集計期間
    const [period, setPeriod] = useState({
        start: new Date(),
        end: new Date(),
    });

    // 対象拠点チェックボックス
    const [locationCheck, setLocationCheck] = useState({
        YSCC: true,
        SPE: true,
        SPW: true,
        SAYADO: true,
    });

    // 集計前フラグ
    const [beforeSummary, setBeforeSummary] = useState(true);

    // ローディングフラグ
    const [loading, setLoading] = useState(false);

    // エラーフラグ
    const [error, setError] = useState(false);

    // 拠点チェックボックス変更時
    const onChangeLocationCheck = (e, location_name) => {
        setLocationCheck(prev => {
            return {
                ...prev,
                [location_name]: e.target.checked,
            }
        });
    }

    // 対象監視種別
    const [alarmTypeCheck, setAlarmTypeCheck] = useState({
        radio: true,
        health: true,
    });

    // バリデーション
    const validation = () => {
        // 期間がnullの場合、エラー
        if (period.start === null || period.end === null) {
            return "正しい期間を入力してください。";
        }

        // 期間が逆の場合、エラー
        if (period.start > period.end) {
            return "開始終了期間が逆転しています。";
        }

        return null;
    }

    // 集計ボタンのホバー
    const [summaryBtnColor, setSummaryBtnColor] = useState("default");

    // 集計データ
    const [summaryDataList, setSummaryDataList] = useState({});
    // 集計時の条件
    const [summaryCondition, setSummaryCondition] = useState({});

    // 集計処理
    const onSummaryLog = async () => {
        // 集計中の場合
        if (loading) {
            return;
        }

        // 集計処理
        console.log("集計処理");

        // バリデーション
        if (validation() !== null) {
            return;
        }

        // 集計前フラグを立てる
        if (beforeSummary) {
            setBeforeSummary(false);
        }

        // エラーフラグを下ろす
        setError(false);

        // ローディングフラグを立てる
        setLoading(true);

        // 集計情報を初期化
        setSummaryDataList({});
        setSummaryCondition({});

        // searchConditionからoccrennce_atの検索条件を作成
        const startDate = period.start;
        // toISOStringを使うとUTC時間表記になるので、9時間足しておく
        startDate.setHours(startDate.getHours() + 9);
        // ミリ秒とZを削除して、+09:00を付与
        const startDateStr = startDate.toISOString().slice(0, -13) + "00:00:00.000+09:00";

        const endDate = period.end
        // toISOStringを使うとUTC時間表記になるので、9時間足しておく
        endDate.setHours(endDate.getHours() + 9);
        // ミリ秒とZを削除して、+09:00を付与
        const endDateStr = endDate.toISOString().slice(0, -13) + "23:59:59.999+09:00";

        const occurenceAtCondition = {
            between: [startDateStr, endDateStr]
        };
        console.log(occurenceAtCondition);

        const locationList = [];

        for (const [key, value] of Object.entries(locationCheck)) {
            if (value) {
                locationList.push({
                    location_name: {
                        eq: key,
                    },
                },);
            }
        }
        console.log(locationList);

        const alarmTypeList = [];

        for (const [key, value] of Object.entries(alarmTypeCheck)) {
            if (value) {
                alarmTypeList.push({
                    alarm_type: {
                        eq: key,
                    },
                },);
            }
        }

        const filter = {
            and: [
                {
                    or: locationList,
                },
                {
                    or: alarmTypeList,
                },
            ]
        }
        console.log(filter);

        const { moniteringLogList: logList, error } = await getMoniteringLogSummary(filter, null, occurenceAtCondition)

        // エラーの場合
        if (error != null) {
            // エラーフラグを立てる
            setError(true);

            // ローディングフラグを下ろす
            setLoading(false);

            return;
        }

        console.log(logList);

        // 集計
        const summaryData = {};

        for (const i in logList) {
            // ログを一つ取得
            const log = logList[i];

            // 拠点名がsummaryDataに存在しない場合、初期化
            if (!(log.location_name in summaryData)) {
                summaryData[log.location_name] = { count: 0, alarm_type: {} };
            }

            // カウント
            summaryData[log.location_name].count += 1;

            if (!(log.alarm_type in summaryData[log.location_name].alarm_type)) {
                summaryData[log.location_name].alarm_type[log.alarm_type] = {}
            }

            

            // 状態
            let state_type = log.state_type;

            //  復帰ログの場合
            if (log.recover_state) {
                state_type = "Recover";
            }

            if (!(state_type in summaryData[log.location_name].alarm_type[log.alarm_type])) {
                summaryData[log.location_name].alarm_type[log.alarm_type][state_type] = { count: 0, occurrence_sources: {} }
            }

            // カウント
            summaryData[log.location_name].alarm_type[log.alarm_type][state_type].count += 1;

            if (!(log.occurrence_source in summaryData[log.location_name].alarm_type[log.alarm_type][state_type].occurrence_sources)) {
                summaryData[log.location_name].alarm_type[log.alarm_type][state_type].occurrence_sources[log.occurrence_source] = 0;
            }

            // カウント
            summaryData[log.location_name].alarm_type[log.alarm_type][state_type].occurrence_sources[log.occurrence_source] += 1;
        }

        console.log(summaryData);

        // データをセット
        setSummaryDataList(summaryData);
        setSummaryCondition({
            period: period,
        });

        // ローディングフラグを下ろす
        setLoading(false);
    }

    // CSV出力
    const onCsvDownload = async () => {
        // データがない場合
        if (Object.keys(summaryDataList).length === 0) {
            return;
        }

        // CSV出力
        console.log("CSV出力");

        console.log(summaryDataList);

        // csv出力処理
        // CSV格納用 ※ヘッダーをあらかじめ設定しておく
        const header = "拠点名,件数,監視対象,ステータス,件数,発生元,件数\n";

        // データ格納用
        let data = header;

        // オブジェクトの中身を取り出してカンマ区切りにする
        for (const [location_name, locationData] of Object.entries(summaryDataList)) {
            for (const [alarm_type, alarmTypeData] of Object.entries(locationData.alarm_type)) {
                for (const [state_type, stateTypeData] of Object.entries(alarmTypeData)) {
                    for (const [occurrence_source, occurrenceSourceData] of Object.entries(stateTypeData.occurrence_sources)) {
                        data += `${displaySettingJson.location_name[location_name]},`;
                        data += `${locationData.count},`;
                        data += `${formatAlarmType(alarm_type)},`;
                        data += `${formatStateType(state_type)},`;
                        data += `${stateTypeData.count},`;
                        data += `${formatOccurrenceSource(alarm_type, occurrence_source, displaySettingJson)},`;
                        data += `${occurrenceSourceData}\n`;
                    }
                }
            }
        }

        ////////////////////CSV形式へ変換////////////////////
        // BOMを付与（Excelで開いた際のの文字化け対策）
        const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
        // CSV用バイナリデータを作成
        const blob = new Blob([bom, data], { type: "text/csv" });
        // blobからオブジェクトURLを作成
        const objectUrl = URL.createObjectURL(blob);

        ////////////////////ダウンロードリンクの作成とクリック////////////////////
        // ダウンロードリンクを作成 ※HTMLのaタグを作成
        const downloadLink = document.createElement("a");
        // ファイル名の設定
        const fileName = `interference_monitoring_定期レポート_${formatDatetime(period.start, "YYYYMMDD")}_${formatDatetime(period.end, "YYYYMMDD")}.csv`;
        downloadLink.download = fileName;

        // ダウンロードファイルを設定 ※aタグのhref属性
        downloadLink.href = objectUrl;

        // ダウンロードリンクを擬似的にクリック
        downloadLink.click();

        // ダウンロードリンクを削除
        downloadLink.remove();
    }

    // PDF出力
    const onPdfDownload = async () => {
        // データがない場合
        if (Object.keys(summaryDataList).length === 0) {
            return;
        }

        // PDF出力
        console.log("PDF出力");

        // pdf出力処理
        pdf.download({
            data: summaryDataList,
            displaySettingJson: displaySettingJson,
            period: {
                start: formatDatetime(summaryCondition.period.start, "YYYY年MM月DD日"),
                end: formatDatetime(summaryCondition.period.end, "YYYY年MM月DD日"),
            },
        }, `interference_monitoring_定期レポート_${formatDatetime(summaryCondition.period.start, "YYYYMMDD")}_${formatDatetime(summaryCondition.period.end, "YYYYMMDD")}`);
    }

    // type切り替え
    const [resultType, setResultType] = useState("Init")

    useEffect(() => {
        if (beforeSummary) {
            setResultType("Before")
        }
        else if (loading) {
            setResultType("Loading")
        }
        else if (error) {
            setResultType("Error")
        }
        else if (Object.keys(summaryDataList).length === 0) {
            setResultType("Empty")
        }
        else {
            setResultType("Success")
        }

    }, [beforeSummary, loading, error, summaryDataList])

    useEffect(() => {
        console.log(summaryDataList);
    }, [summaryDataList]);

    useEffect(() => {
        console.log(resultType);
    }, [resultType]);

    return (
        <>
            {/* 定期レポートボタン */}
            <CommonBtnRound
                display={!isLocalMode ? "flex" : "none"}
                gap="10px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                shrink="0"
                position="relative"
                borderRadius="15px"
                padding="8px 16px 8px 16px"
                backgroundColor="rgba(245,245,245,1)"
                color={reportBtnColor}
                onMouseEnter={onMouseEventBtnRoundEnter}
                onMouseLeave={onMouseEventBtnRoundLeave}
                overrides={{
                    text: {
                        children: "定期レポート出力",
                    }
                }}
                style={{
                    cursor: "pointer",
                }}
                onClick={() => {
                    setModalVisible(true);
                }}
            // {...getOverrideProps(overrides, "Common/Btn/Round")}
            ></CommonBtnRound>

            {/* 定期レポート出力画面 */}
            <CustomModal
                open={modalVisible}
                onClose={() => {
                    setModalVisible(false);
                }}
            >
                <KanshiFrameSummary
                    overrides={{
                        // タイトル
                        "Common/Title": {
                            overrides: {
                                text: {
                                    children: "定期レポート出力",
                                }
                            },
                        },
                        // PDF出力ボタン
                        "Common/Btn/Blue504225344": {
                            overrides: {
                                text: {
                                    children: "PDF出力",
                                }
                            },
                            style: {
                                cursor: "pointer",
                            },
                            onClick: onPdfDownload,
                        },
                        // CSV出力ボタン
                        "Common/Btn/Blue504225346": {
                            overrides: {
                                text: {
                                    children: "CSV出力",
                                }
                            },
                            style: {
                                cursor: "pointer",
                            },
                            onClick: onCsvDownload,
                        },
                        // 対象期間
                        "Datakanri/DataMaking/Date": {
                            example: "false",
                            error: validation() == null ? "false" : "true",
                            overrides: {
                                // 開始日時
                                TextField465312790: {
                                    label: "対象期間",
                                    type: "date",
                                    isRequired: true,
                                    defaultValue: period.start != null ? formatDatetime(period.start, "YYYY-MM-DD") : "",
                                    onBlur: (e) => {
                                        console.log(e.target.value);

                                        if (e.target.value === "") {
                                            // 空の場合、nullを設定
                                            setPeriod({ start: null, end: period.end });
                                            return;
                                        }

                                        // 確認日時を更新
                                        setPeriod({ start: new Date(e.target.value + "T00:00:00.000+09:00"), end: period.end });
                                    }
                                },
                                // 終了日時
                                TextField465312803: {
                                    type: "date",
                                    isRequired: true,
                                    defaultValue: period.end != null ? formatDatetime(period.end, "YYYY-MM-DD") : "",
                                    onBlur: (e) => {
                                        console.log(e.target.value);

                                        if (e.target.value === "") {
                                            // 空の場合、nullを設定
                                            setPeriod({ start: period.start, end: null });
                                            return;
                                        }

                                        // 確認日時を更新
                                        setPeriod({ start: period.start, end: new Date(e.target.value + "T23:59:59.999+09:00") });
                                    }
                                },
                                // エラーメッセージ
                                text461812744: {
                                    children: validation(),
                                }
                            }
                        },
                        // 対象拠点
                        // YSCC
                        CheckboxField515221828: {
                            label: displaySettingJson.location_name.YSCC,
                            checked: locationCheck.YSCC,
                            onChange: (e) => onChangeLocationCheck(e, "YSCC")
                        },
                        // SPE
                        CheckboxField515221879: {
                            label: displaySettingJson.location_name.SPE,
                            checked: locationCheck.SPE,
                            onChange: (e) => onChangeLocationCheck(e, "SPE")
                        },
                        // SPW
                        CheckboxField515221906: {
                            label: displaySettingJson.location_name.SPW,
                            checked: locationCheck.SPW,
                            onChange: (e) => onChangeLocationCheck(e, "SPW")
                        },
                        // SAYADO
                        CheckboxField515221913: {
                            label: displaySettingJson.location_name.SAYADO,
                            checked: locationCheck.SAYADO,
                            onChange: (e) => onChangeLocationCheck(e, "SAYADO")
                        },
                        // 対象監視種別
                        // 干渉波監視
                        CheckboxField515221922: {
                            checked: alarmTypeCheck.radio,
                            onChange: (e) => {
                                setAlarmTypeCheck(prev => {
                                    return {
                                        ...prev,
                                        radio: e.target.checked,
                                    }
                                });
                            }
                        },
                        // 機器監視
                        CheckboxField515221923: {
                            checked: alarmTypeCheck.health,
                            onChange: (e) => {
                                setAlarmTypeCheck(prev => {
                                    return {
                                        ...prev,
                                        health: e.target.checked,
                                    }
                                });
                            }
                        },
                        // 集計ボタン
                        "Common/Btn/White": {
                            overrides: {
                                text: {
                                    children: "集計",
                                }
                            },
                            style: {
                                cursor: "pointer",
                            },
                            onClick: onSummaryLog,
                            situation: summaryBtnColor,
                            onMouseEnter: () => { setSummaryBtnColor("hover") },
                            onMouseLeave: () => { setSummaryBtnColor("default") },
                        },
                        // 集計結果
                        "Kanshi/Frame/Summary/Result": {
                            width: "100%",
                            // ログ集計結果テーブル
                            children: (
                                <LogSummaryTable
                                    resultType={resultType}
                                    summaryDataList={summaryDataList}
                                    displaySettingJson={displaySettingJson}
                                />
                            ),
                        },
                    }}
                />
            </CustomModal>
        </>
    )
}