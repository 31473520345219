/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_user_files_s3_bucket": "data-670634105186110322-staging",
    "aws_user_files_s3_bucket_region": "ap-northeast-1",
    "aws_content_delivery_bucket": "5gcloudweb-20230330144133-hostingbucket-staging",
    "aws_content_delivery_bucket_region": "ap-northeast-1",
    "aws_content_delivery_url": "https://d44c5pn91o4bd.cloudfront.net",
    "aws_appsync_graphqlEndpoint": "https://qfpgzv7je5cplnk7shkf73bway.appsync-api.ap-northeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-1",
    "aws_appsync_authenticationType": "AWS_IAM",
    "aws_appsync_apiKey": "da2-6vpwrvlwujgqbc4bxhevioou3a",
    "aws_cloud_logic_custom": [
        {
            "name": "CreateTimeSeriesApi",
            "endpoint": "https://oc5p7rdik6.execute-api.ap-northeast-1.amazonaws.com/staging",
            "region": "ap-northeast-1"
        }
    ]
};


export default awsmobile;
