import { useRef, useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import * as graphqlQuery from '../graphql/queries';
import { onCreateMoniteringLog, onUpdateMoniteringLog } from '../graphql/subscriptions';

// 共通リクエストID
let currentRequestId = 0;

// ネクストトークン
let currentNextToken = null;

// チェックフラグを追加する関数
const addCheckFlag = (logList) => {
    // チェック済みフラグを更新
    logList.forEach(log => {
        log.check = false;
    });

    return logList;
}

// 監視ログ一覧を取得するカスタムフック
const useMoniteringLogList = () => {
    // 監視ログ
    const [moniteringLogList, setMoniteringLogList] = useState([]);

    // ローディング状態
    const [isLoading, setIsLoading] = useState(true);

    // エラー情報
    const [error, setError] = useState(null);

    // 次のページがあるか
    const [hasNextPage, setHasNextPage] = useState(false);

    // サブスクリプション
    const createSubscription = useRef(null);
    const updateSubscription = useRef(null);

    // 監視ログをリセットする関数
    const resetMoniteringLogList = () => {
        // ログデータを更新
        setMoniteringLogList([]);
        // 次のページがあるか
        setHasNextPage(false);
    }

    // APIからログを再帰的に取得する関数
    const fetchLogList = async (requestId, filter = {}, limit = 100, nextToken = null, occurrenceAtCondition=null) => {
        // ログリスト
        const logList = [];

        // パラメータを作成
        const variables = {
            sortDirection: "DESC",
            occurrence_at: occurrenceAtCondition,
            filter: JSON.parse(JSON.stringify(filter)),
        };

        // クエリ名
        let queryName = "";

        // 復帰フラグが指定されている場合
        if (filter.recover_flg != null) {
            queryName = "moniteringLogsByRecover_flgAndOccurrence_at";
            variables.recover_flg = filter.recover_flg.eq;
            delete variables.filter.recover_flg;
        }
        // 確認フラグが指定されている場合
        else if (filter.confirmed_flg != null) {
            queryName = "moniteringLogsByConfirmed_flgAndOccurrence_at";
            variables.confirmed_flg = filter.confirmed_flg.eq;
            delete variables.filter.confirmed_flg;
        }
        else {
            queryName = "moniteringLogsByTypeAndOccurrence_at";
            variables.type = "MoniteringLog";
        }


        console.log("variables", variables);
        console.log("queryName", queryName);

        // ログリストが指定数に達するまでループ
        while (limit === null || logList.length < limit) {
            // リクエストIDが変わっている場合は処理を中断
            if (requestId !== currentRequestId) {
                // console.log("fetchLogList:", "リクエストIDが変わっているため処理を中断");
                return {
                    logList: logList,
                    nextToken: null,
                };
            }

            // ネクストトークンをセット
            variables.nextToken = nextToken;

            // リクエストを実行
            const result = await API.graphql(graphqlOperation(graphqlQuery[queryName], variables));;
            // console.log("result", result);

            // ネクストトークンをセット
            nextToken = result.data[queryName].nextToken
            // console.log("nextToken", nextToken);

            // ログリストをセット
            logList.push(...result.data[queryName].items);

            // ネクストトークンがない場合は処理を中断
            if (nextToken == null) {
                break;
            }
        }

        // 次のページがあるか
        setHasNextPage(nextToken != null);

        return {
            logList: logList,
            nextToken: nextToken,
        };
    }

    // 監視ログ一覧データを取得する関数
    const getMoniteringLogList = async (filter = {}) => {
        // リクエストIDを作成
        const requestId = currentRequestId + 1;

        console.info("requestId", requestId, "ログ取得開始");

        // ローディング開始
        setIsLoading(true);

        // ネクストトークンを初期化
        currentNextToken = null;

        // エラー情報を初期化
        setError(null);

        // ログリストを初期化
        setMoniteringLogList([]);

        // 次のページがあるか
        setHasNextPage(true);

        try {
            // リクエストIDを更新
            currentRequestId = requestId;

            // APIからコンテンツデータを取得
            let { logList, nextToken } = await fetchLogList(requestId, filter);

            // リクエストIDが変わっていない場合
            if (currentRequestId !== requestId) {
                console.log("requestId", requestId, "リクエストIDが変わっているため処理を中断");
                return;
            }

            // ネクストトークンをセット
            currentNextToken = nextToken;

            // チェック済みフラグを更新
            logList = addCheckFlag(logList);

            // ログデータを更新
            updateLogList((prev) => logList);
        } catch (error) {
            console.log("requestId", requestId, error);

            // エラー情報をセット
            setError(error);
        }

        // ローディング終了
        setIsLoading(false);

        console.log("requestId", requestId, "ログ取得終了");
    };

    // 追加取得
    const getMoreMoniteringLogList = async (filter = {}) => {
        // ネクストトークンがない場合は処理を中断
        if (!currentNextToken) {
            return;
        }

        // リクエストIDを作成
        const requestId = currentRequestId + 1;

        console.log("requestId", requestId, "追加ログ取得開始");

        // ローディング開始
        setIsLoading(true);

        // エラー情報を初期化
        setError(null);

        // パラメータを作成
        try {
            // リクエストIDを更新
            currentRequestId = requestId;

            // APIからコンテンツデータを取得
            let { logList, nextToken } = await fetchLogList(currentRequestId, filter, 100, currentNextToken);

            // リクエストIDが変わっていない場合
            if (currentRequestId !== requestId) {
                console.log("requestId", requestId, "リクエストIDが変わっているため処理を中断");
                return;
            }

            // ネクストトークンをセット
            currentNextToken = nextToken;

            // チェック済みフラグを更新
            logList = addCheckFlag(logList);

            // ログデータを更新
            updateLogList((prev) => [...prev, ...logList]);
        } catch (error) {
            console.log(error);

            // エラー情報をセット
            setError(error);
        }

        // ローディング終了
        setIsLoading(false);

        console.log("requestId", requestId, "追加ログ取得終了");
    }

    // 監視ログ一覧を更新する関数
    const updateLogList = (set = () => {}) => {
        // ログデータを更新
        setMoniteringLogList((prev)=>{
            let logList = set(prev);
            
            return logList.sort((a, b) => a.occurrence_at < b.occurrence_at ? 1 : -1);
        });
    }

    // 監視ログのGraphqlサブスクリプションを登録する関数
    const subscribeMoniteringLogList = ({
        location_name = null,
        log_type = null,
        occurrence_source = null,
        recover_flg = null,
        confirmed_flg = null,
    }) => {
        console.log("subscribeMoniteringLogList");

        // 条件に一致するかチェック
        const checkCondition = (log) => {
            console.log({
                location_name,
                log_type,
                occurrence_source,
                recover_flg,
                confirmed_flg,
            });

            // 条件に一致するかチェック
            if (location_name != null && log.location_name !== location_name.eq) {
                console.log("location");
                return false;
            }
            if (log_type != null && log.log_type !== log_type.eq) {
                console.log("log_type");
                return false;
            }
            if (occurrence_source != null && log.occurrence_source !== occurrence_source.eq) {
                console.log("occurrence_source");
                return false;
            }
            if (recover_flg != null && log.recover_flg !== recover_flg.eq) {
                console.log("recover_flg");
                return false;
            }
            if (confirmed_flg != null && log.confirmed_flg !== confirmed_flg.eq) {
                console.log("confirmed_flg");
                return false;
            }

            return true;
        }

        // サブスクリプションを登録
        createSubscription.current = API.graphql(graphqlOperation(onCreateMoniteringLog)).subscribe({
            next: (data) => {
                console.log("onCreateMoniteringLog", data);

                // ログデータを取得
                const log = data.value.data.onCreateMoniteringLog;
                
                // 条件に一致するかチェック
                if (!checkCondition(log)) {
                    return;
                }
                
                // ログデータを追加
                updateLogList((prev) => [...addCheckFlag([log]), ...prev]);
            },
            error: (error) => {
                console.log(error);
            }
        });

        // サブスクリプションを登録
        updateSubscription.current = API.graphql(graphqlOperation(onUpdateMoniteringLog)).subscribe({
            next: (data) => {
                console.log("onUpdateMoniteringLog", data);

                // ログデータを取得
                const log = data.value.data.onUpdateMoniteringLog;

                // ログデータを更新
                updateLogList((prev) => {
                    return prev.map((item) => {
                        if (item.id === log.id) {
                            // ログデータを更新
                            console.log("checkCondition(log)", checkCondition(log));
                            console.log("addCheckFlag([log])", addCheckFlag([log]));
                            return checkCondition(log) ? addCheckFlag([log]).pop() : null;
                        }
                        return item;
                    }).filter((el) => el !== null)
                });
            },
            error: (error) => {
                console.log(error);
            }
        });

        console.log(moniteringLogList);

        return;
    }

    // 監視ログのGraphqlサブスクリプションを解除する関数
    const unsubscribeMoniteringLogList = () => {
        console.log("unsubscribeMoniteringLogList");

        // サブスクリプションを解除
        if (createSubscription.current) {
            createSubscription.current.unsubscribe();
            createSubscription.current = null;
        }

        if (updateSubscription.current) {
            updateSubscription.current.unsubscribe();
            updateSubscription.current = null;
        }
    }

    // 監視ログを検索する関数
    const searchMoniteringLogList = async (occurrenceAtCondition, filter={}) => {
        console.log("searchMoniteringLogList", occurrenceAtCondition, filter);

        // リクエストIDを作成
        const requestId = currentRequestId + 1;

        console.info("requestId", requestId, "ログ検索開始");

        // ローディング開始
        setIsLoading(true);
        // 次のページがあるか
        setHasNextPage(true);

        // ネクストトークンを初期化
        currentNextToken = null;

        // エラー情報を初期化
        setError(null);

        // ログリストを初期化
        setMoniteringLogList([]);

        // サブスクリプションを解除
        unsubscribeMoniteringLogList();

        try {
            // リクエストIDを更新
            currentRequestId = requestId;

            // APIからコンテンツデータを取得
            let { logList } = await fetchLogList(requestId, filter, null, null, occurrenceAtCondition);

            // リクエストIDが変わっていない場合
            if (currentRequestId !== requestId) {
                console.log("requestId", requestId, "リクエストIDが変わっているため処理を中断");
                return;
            }

            // チェック済みフラグを更新
            logList = addCheckFlag(logList);

            // ログデータを更新
            updateLogList((prev) => logList);

            // サブスクリプションを登録
            subscribeMoniteringLogList(filter);
        } catch (error) {
            console.log("requestId", requestId, error);

            // エラー情報をセット
            setError(error);
        }

        // ローディング終了
        setIsLoading(false);

        console.log("requestId", requestId, "ログ検索終了");
    }

    return {
        moniteringLogList,
        setMoniteringLogList,
        isLoading,
        error,
        hasNextPage,
        resetMoniteringLogList,
        getMoniteringLogList,
        getMoreMoniteringLogList,
        subscribeMoniteringLogList,
        unsubscribeMoniteringLogList,
        searchMoniteringLogList,
    };
};

export { useMoniteringLogList };