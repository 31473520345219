import { SetteiLocation } from '../ui-components';

export default function KanshiSetteiLocationWrapper(props) {
    
    return (
        <SetteiLocation
            display="flex"
            gap="8px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            situation={props.situation}
            overrides={{
                "Settei/Tab35993728": { 
                    onClick: () => props.onClickLocation("YSCC"),
                    overrides: {
                        text: {
                            children: props.displaySettingJson.location_name.YSCC,
                        }
                    },
                    style: {
                        cursor: "pointer",
                    }
                },
                "Settei/Tab35993730": {
                    onClick: () => props.onClickLocation("SPE"),
                    overrides: {
                        text: {
                            children: props.displaySettingJson.location_name.SPE,
                        }
                    },
                    style: {
                        cursor: "pointer",
                    }
                },
                "Settei/Tab35993732": {
                    onClick: () => props.onClickLocation("SPW"),
                    overrides: {
                        text: {
                            children: props.displaySettingJson.location_name.SPW,
                        }
                    },
                    style: {
                        cursor: "pointer",
                    }
                },
                "Settei/Tab35993734": {
                    onClick: () => props.onClickLocation("GUNMA"),
                    overrides: {
                        text: {
                            children: props.displaySettingJson.location_name.SAYADO,
                        }
                    },
                    style: {
                        cursor: "pointer",
                    }
                },
            }}        
        ></SetteiLocation>
    );
}