import { useEffect, useState, useContext, Fragment } from "react";
import { Flex } from "@aws-amplify/ui-react";
import { UserContext } from "./UserContext";

import {
    CommonTitle,
    CommonBtnBlue,
    Myicons,
} from './ui-components'

import {
    HeaderWrapper,
    DatakanriListTitleWrapper,
    DatakanriListFileWrapper,
    DatakanriListFolderWrapper,
    DatakanriBreadcrumbsWrapper,
    DatakanriSearchWrapper,
    DatakanriBtnWrapper,
    CheckWrapper,
    DatakanriDataMakingWrapper,
} from './componentsWrapper'

import {
    downloadFile,
    getFileList,
    searchFileList,
    batchDownloadFile,
    deleteFile,
    batchDeleteFile,
} from './lib/StorageUtil'
import { CustomModal } from "./customComponents/CustomModal";


// ソート一覧
export const SORT = Object.freeze({
    COL: {
        NAME: "name",
        LAST_MODIFIED: "lastModified"
    },
    direction: {
        ASC: "asc",
        DESC: "desc"
    },
})

const DataKanriMain = () => {
    const { userInfo } = useContext(UserContext);

    const [s3Data, setS3Data] = useState([]);
    const [breadcrumbs, setBreadcrumbs] = useState([]);

    // 検索モード
    const [searchMode, setSearchMode] = useState(false);

    // 検索キーワード
    const [searchQuery, setSearchQuery] = useState("");

    // 全選択チェックボックスの状態
    const [allSelect, setAllSelect] = useState(false);
    // 全選択チェックボックスの状態が、全選択でも全解除でもない状態
    const [isIndeterminate, setIsIndeterminate] = useState(false);

    // 一括ダウンロード確認モーダルの表示状態
    const [isBatchDownloadConfirmModalOpen, setIsBatchDownloadConfirmModalOpen] = useState(false);

    // 一括削除確認モーダルの表示状態
    const [isBatchDeleteConfirmModalOpen, setIsBatchDeleteConfirmModalOpen] = useState(false);

    // トップディレクトリ
    const topDirectory = userInfo.isAdminOrg ? "public/" : "public/公開データ/"

    // ソートするカラム
    const [sortCol, setSortCol] = useState(SORT.COL.NAME);

    // ソートする方向
    const [sortDirection, setSortDirection] = useState(SORT.direction.ASC);

    // ソート関数
    const sort = (data) => {
        data.sort((a, b) => {
            if (a[sortCol] < b[sortCol]) {
                return sortDirection === SORT.direction.ASC ? -1 : 1;
            }
            if (a[sortCol] > b[sortCol]) {
                return sortDirection === SORT.direction.ASC ? 1 : -1;
            }
            return 0;
        });
        return data;
    }

    useEffect(() => {
        setS3Data((prev) => [...sort(prev)]);
    }, [sortCol, sortDirection]);

    useEffect(() => {
        setTimeout(() => {
            getFileList(topDirectory).then((fileList) => {
                // console.log("s3Data: " + fileList);
                setS3Data(sort(fileList));
            })
        }, 1000);
    }, []);


    function convertBreadcrumbs(key) {
        var pathArray = key.split("/");
        var topDirectoryArray = topDirectory.split("/");
        var breadcrumbsList = []
        var searchText = ""
        for (var i = 0; i < pathArray.length; i++) {
            if (pathArray[i] !== "") {
                searchText += pathArray[i] + "/"
                // topDirectoryに含まれる階層は、breadcrumbsに追加しない
                if (!topDirectoryArray.includes(pathArray[i])) {
                    var breadcrumb = {
                        name: pathArray[i],
                        searchKey: searchText
                    }
                    breadcrumbsList.push(breadcrumb)
                }
            }
        }

        return breadcrumbsList
    }

    function onClickData(item) {
        // フォルダの場合は、searchKeyを元に下層のファイルリストを取得
        if (!item.isFile) {
            // 検索モードを解除
            setSearchMode(false)

            getFileList(item.searchKey).then((fileList) => {
                // console.log("s3Data: " + fileList);
                setS3Data(sort(fileList));
            });
            // breadcrumbsを更新
            setBreadcrumbs(convertBreadcrumbs(item.searchKey));
        }
    }

    // パンクズリストをクリックした時の処理
    function onClickBreadcrumbs(searchKey) {
        // 検索モードを解除
        setSearchMode(false)

        // breadcrumbsのクリックされた階層の下層のファイルリストを取得
        getFileList(searchKey).then((fileList) => {
            // console.log("s3Data: " + fileList);
            setS3Data(sort(fileList));
        });
        // breadcrumbsを更新
        setBreadcrumbs(convertBreadcrumbs(searchKey));
    }

    // チェックボックスをクリックした時の処理
    function onClickCheck(index, isCheck) {
        var newS3Data = s3Data;
        newS3Data[index].check = isCheck;
        setS3Data(sort(newS3Data));

        // 全選択チェックボックスの状態を更新
        updateAllSelect()
    }

    // 全選択チェックボックスをクリックした時の処理
    function onClickAllSelect(isCheck) {
        var newS3Data = s3Data;
        newS3Data.map((item) => {
            item.check = isCheck;
        })
        setS3Data(sort(newS3Data));
        setAllSelect(isCheck);
        setIsIndeterminate(false);
    }

    // 全選択チェックボックスの状態を更新
    function updateAllSelect() {
        // 全選択チェックボックスの状態を更新
        var allSelect = true;
        var isIndeterminate = false;

        if (s3Data.length === 0) {
            allSelect = false;
        } else {
            for (var i = 0; i < s3Data.length; i++) {
                if (!s3Data[i].check) {
                    allSelect = false;
                }
                if (s3Data[i].check) {
                    isIndeterminate = true;
                }
            }
        }

        setAllSelect(allSelect);
        setIsIndeterminate(isIndeterminate && !allSelect);
    }

    useEffect(() => {
        updateAllSelect()
    }, [s3Data]);

    // 検索ボタンをエンターした時の処理
    function onSearchEnter(e) {
        // 検索キーワードが空の場合
        if (searchQuery === "") {
            return
        }

        // 検索モードにする
        setSearchMode(true)

        // 現在のディレクトリ
        const currentDirectory = breadcrumbs.length > 0 ? breadcrumbs[breadcrumbs.length - 1].searchKey : topDirectory

        // ファイルを検索
        searchFileList(currentDirectory, searchQuery, (fileList) => {
            // console.log("s3Data: " + fileList);
            setS3Data(old => [...sort(fileList)]);
        });
    }

    // 一括ダウンロードボタンをクリックした時の処理
    const onBatchDownloadClick = () => {
        // ダウンロード一覧を初期化
        const downloadList = []

        // チェックされているファイルをダウンロード一覧に追加
        s3Data.map((item) => {
            if (item.check) {
                downloadList.push(item)
            }
        })

        // ダウンロード一覧を元に、ファイルをダウンロード
        if (downloadList.length > 0) {
            if (downloadList.length === 1) {
                // １ファイルの場合
                downloadFile(downloadList[0].key, downloadList[0].name, downloadList[0].fileType)
            }
            else {
                // 複数ファイルの場合
                batchDownloadFile(downloadList)
            }
        }

        // モーダルを閉じる
        setIsBatchDownloadConfirmModalOpen(false)
    }

    // 一括削除ボタンをクリックした時の処理
    const onBatchDeleteClick = () => {
        // 削除一覧を初期化
        const deleteList = []

        // チェックされているファイルを削除一覧に追加
        s3Data.map((item) => {
            if (item.check) {
                deleteList.push(item)
            }
        })

        // 削除一覧を元に、ファイルを削除
        if (deleteList.length > 0) {
            batchDeleteFile(deleteList).then(() => {
                refreshS3Data()
            })
        }

        // モーダルを閉じる
        setIsBatchDeleteConfirmModalOpen(false)
    }

    // s3データの再取得
    const refreshS3Data = () => {
        // 現在のディレクトリ
        const currentDirectory = breadcrumbs.length > 0 ? breadcrumbs[breadcrumbs.length - 1].searchKey : topDirectory

        if (searchMode) {
            // 検索モードの場合
            searchFileList(currentDirectory, searchQuery, (fileList) => {
                // console.log("s3Data: " + fileList);
                setS3Data(old => [...sort(fileList)]);
            });
        } else {
            // 現在のディレクトリのファイルリストを取得
            getFileList(currentDirectory).then((fileList) => {
                // console.log("s3Data: " + fileList);
                setS3Data(sort(fileList));
            });

            // breadcrumbsを更新
            setBreadcrumbs(convertBreadcrumbs(currentDirectory));
        }
    }

    // 時系列データ作成モーダル
    const [isTimeSeriesDataModalOpen, setIsTimeSeriesDataModalOpen] = useState(false);

    return (
        <div >
            <HeaderWrapper/>

            <Flex
                gap="32px"
                direction="column"
                // width="1864px"
                marginLeft={"28px"}
                marginRight={"28px"}
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                position="relative"
                padding="0px 0px 0px 0px"
                display="flex"

            >
                <CommonTitle
                    size="large"
                    overrides={{
                        text: {
                            children: "データ管理",
                        }
                    }}
                // {...getOverrideProps(overrides, "Common/Title")}
                ></CommonTitle>
                <Flex
                    direction="row"
                    width="unset"
                    height="unset"
                    justifyContent="space-between"
                    alignItems="center"
                    shrink="0"
                    alignSelf="stretch"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    display="flex"
                // {...getOverrideProps(overrides, "01")}
                >
                    <DatakanriSearchWrapper
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        onClear={() => setSearchQuery("")}
                        onSubmit={onSearchEnter}
                    />
                    <CommonBtnBlue
                        display={userInfo.isAdminOrg ? "flex" : "none"}
                        overrides={{
                            text: {
                                children: "時系列データ作成",
                            }
                        }}
                        situation="default"
                        style={{ cursor: "pointer" }}
                        onClick={() => setIsTimeSeriesDataModalOpen(true)}
                    ></CommonBtnBlue>
                </Flex>
                <Flex
                    // gap="1128px"
                    direction="row"
                    width="unset"
                    height="unset"
                    justifyContent="flex-start"
                    alignItems="center"
                    shrink="0"
                    alignSelf="stretch"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    display="flex"
                // {...getOverrideProps(overrides, "02")}
                >
                    <Flex
                        gap="24px"
                        direction="row"
                        width="unset"
                        height="unset"
                        justifyContent="flex-start"
                        alignItems="center"
                        shrink="0"
                        position="relative"
                        padding="0px 0px 0px 0px"
                        display="flex"
                        grow={1}
                    // {...getOverrideProps(overrides, "Bread493018437")}
                    >
                        <DatakanriBreadcrumbsWrapper
                            folderName="/"
                            onClick={() => onClickBreadcrumbs(topDirectory)}
                            situation="default"
                        />
                        {
                            breadcrumbs.map((item, index) => {
                                return (
                                    <Fragment key={index}>
                                        <Myicons
                                            // marginRight="24px"
                                            type="angle-right"
                                        ></Myicons>
                                        <DatakanriBreadcrumbsWrapper
                                            key={index}
                                            // folderName={item.name + "/"}
                                            folderName={item.name}
                                            onClick={() => onClickBreadcrumbs(item.searchKey)}
                                            situation={(breadcrumbs.length - 1 === index) ? "select" : "default"} />
                                    </Fragment>
                                );
                            })
                        }
                    </Flex>
                    <Flex
                        gap="16px"
                        direction="row"
                        width="unset"
                        height="unset"
                        justifyContent="flex-start"
                        alignItems="center"
                        shrink="0"
                        position="relative"
                        padding="0px 0px 0px 0px"
                        display="flex"
                    >
                        {
                            (allSelect || isIndeterminate) && (
                                <>
                                    {
                                        userInfo.isAdminOrg && userInfo.isAdminUser && (
                                            <DatakanriBtnWrapper
                                                text="一括削除"
                                                onClick={() => {
                                                    setIsBatchDeleteConfirmModalOpen(true)
                                                }}
                                            />
                                        )
                                    }
                                    <DatakanriBtnWrapper
                                        text="一括ダウンロード"
                                        onClick={() => {
                                            setIsBatchDownloadConfirmModalOpen(true)
                                        }}
                                    />
                                </>
                            )

                        }
                    </Flex>
                </Flex>
                <Flex
                    gap="0"
                    direction="column"
                    width="unset"
                    height="unset"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    shrink="0"
                    alignSelf="stretch"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    display="flex"
                // {...getOverrideProps(overrides, "03")}
                >
                    <DatakanriListTitleWrapper
                        check={allSelect}
                        isIndeterminate={isIndeterminate}
                        onClickAllSelect={onClickAllSelect}
                        sortCol={sortCol}
                        sortDirection={sortDirection}
                        onSortBy={(col) => {
                            console.log("ソート: " + col);

                            if (sortCol === col) {
                                setSortDirection(sortDirection === SORT.direction.ASC ? SORT.direction.DESC : SORT.direction.ASC);
                            }
                            else {
                                setSortCol(col);
                                setSortDirection(SORT.direction.ASC);
                            }
                        }}
                        displayCheckbox={s3Data.map((item) => item.isFile).includes(true)}
                    />

                    <Flex
                        gap="0"
                        direction="column"
                        width="unset"
                        height='calc(100vh - 340px)'
                        // height="200px"
                        // height={`calc(100% - 260px)`}
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        overflow="scroll"
                        shrink="0"
                        alignSelf="stretch"
                        position="relative"
                        padding="0px 0px 0px 0px"
                    // {...getOverrideProps(overrides, "Denpakansyo")}
                    >
                        {
                            s3Data.map((item, index) => (
                                item.isFile
                                    ?
                                    <DatakanriListFileWrapper
                                        key={index}
                                        item={item}
                                        onDeleteClick={() => {
                                            deleteFile(item.key).then(() => { refreshS3Data() })
                                        }}
                                        onDownloadClick={() => downloadFile(item.key, item.name, item.fileType)}
                                        onCheckClick={(isCheck) => onClickCheck(index, isCheck)}
                                    />
                                    :
                                    <DatakanriListFolderWrapper
                                        key={index}
                                        item={item}
                                        onClick={() => onClickData(item)}
                                    />
                            ))
                        }
                    </Flex>
                </Flex>
            </Flex>

            {/* 一括ダウンロード確認モーダル */}
            <CustomModal
                open={isBatchDownloadConfirmModalOpen}
                onClose={() => setIsBatchDownloadConfirmModalOpen(false)}
            >
                <CheckWrapper
                    msg={"選択した項目を一括ダウンロードします"}
                    onClickOk={onBatchDownloadClick}
                    onClickCancel={() => setIsBatchDownloadConfirmModalOpen(false)}
                />
            </CustomModal>

            {/* 一括削除確認モーダル */}
            <CustomModal
                open={isBatchDeleteConfirmModalOpen}
                onClose={() => setIsBatchDeleteConfirmModalOpen(false)}
            >
                <CheckWrapper
                    msg={"選択した項目を一括削除します"}
                    onClickOk={onBatchDeleteClick}
                    onClickCancel={() => setIsBatchDeleteConfirmModalOpen(false)}
                />
            </CustomModal>

            {/* 時系列データ作成モーダル */}
            <CustomModal
                open={isTimeSeriesDataModalOpen}
                onClose={() => setIsTimeSeriesDataModalOpen(false)}
            >
                <DatakanriDataMakingWrapper
                    onClose={() => setIsTimeSeriesDataModalOpen(false)}
                />
            </CustomModal>
        </div>
    );

}

export default DataKanriMain;