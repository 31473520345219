import { useState, useEffect } from 'react';

import {
    KanshiSearchbox,
} from '../ui-components'

import {
    locationKeyList,
    logTypeNames,
    DeviceLogNames,
    waveMoniteringBandList,
    waveMoniteringBandNameList,
    LogRelation,
} from '../lib/ConstList';


export default function KanshiSearchWrapper(props) {
    // const [selectTab, setSelectTab] = useState("全て");

    // function onClickCommonBtnText(tabName){
    //     setSelectTab(tabName);
    //     if (tabName === "絞込み") {
    //         props.isSearchOpen(true);
    //     } else {
    //         props.isSearchOpen(false);
    //     }
    // }

    const [searchCondition, setSearchCondition] = useState({
        startDate: new Date(new Date().getTime() + 9 * 60 * 60 * 1000 - 24 * 60 * 60 * 1000).toISOString().slice(0, 16), // 1日前
        endDate: new Date(new Date().getTime() + 9 * 60 * 60 * 1000).toISOString().slice(0, 16), // 現在時刻
        locationName: "ALL",
        alarmType: "ALL",
        logType: "ALL",
        occurenceSource: "ALL",
        isRecover: "ALL",
        isConfirm: "ALL",
    });

    const [locationOptions, setLocationOptions] = useState({ "ALL": "全て" });

    const [alarmTypeOptions, setAlarmTypeOptions] = useState({
        "ALL": "全て",
        "radio": "干渉波監視",
        "health": "機器監視",
    });

    const [occurenceSourceOptions, setOccurenceSourceOptions] = useState({
        "ALL": "全て",
        ...DeviceLogNames,
    });

    const [logTypeOptions, setLogTypeOptions] = useState({
        "ALL": "全て",
    });

    const [isRecover, setIsRecover] = useState({
        "ALL": "全て",
        "Recovered": "復帰済",
        "NotRecovered": "未復帰",
    });

    const [isConfirm, setIsConfirm] = useState({
        "ALL": "全て",
        "Confirmed": "確認済",
        "NotConfirmed": "未確認",
    });

    const handleInputOnChange = (e) => {
        const { name, value } = e.target;
        setSearchCondition(prevState => ({
            ...prevState,
            [name]: value != "" ? value : null,
        }));
    }

    const handleRadioOnChange = (e) => {
        const { name, value } = e.target;
        setSearchCondition(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    const handleLocationSelectOnChange = (e) => {
        const { name, value } = e.target;
        setSearchCondition(prevState => ({
            ...prevState,
            [name]: Object.keys(locationOptions).find(key => locationOptions[key] === value)
        }));
    }

    const handleAlarmTypeSelectOnChange = (e) => {
        const { name, value } = e.target;
        setSearchCondition(prevState => ({
            ...prevState,
            [name]: Object.keys(alarmTypeOptions).find(key => alarmTypeOptions[key] === value),
        }));
    }

    const handleOccurenceSourceSelectOnChange = (e) => {
        const { name, value } = e.target;
        setSearchCondition(prevState => ({
            ...prevState,
            [name]: Object.keys(occurenceSourceOptions).find(key => occurenceSourceOptions[key] === value),
        }));
    }

    const handleLogTypeSelectOnChange = (e) => {
        const { name, value } = e.target;
        setSearchCondition(prevState => ({
            ...prevState,
            [name]: Object.keys(logTypeOptions).find(key => logTypeOptions[key] === value),
        }));
    }

    const handleIsRecoverSelectOnChange = (e) => {
        const { name, value } = e.target;
        setSearchCondition(prevState => ({
            ...prevState,
            [name]: Object.keys(isRecover).find(key => isRecover[key] === value),
        }));
    }

    const handleIsConfirmSelectOnChange = (e) => {
        const { name, value } = e.target;
        setSearchCondition(prevState => ({
            ...prevState,
            [name]: Object.keys(isConfirm).find(key => isConfirm[key] === value),
        }));
    }

    const onClickSearchBtn = () => {
        // 入力チェック
        if (validation() != null) {
            return;
        }

        props.onClickLogSearchBtn(searchCondition);
    }

    const locationOptionsUpdate = () => {
        // console.log("locationOptionsUpdate");
        // console.log("props.displaySettingJson:", props.displaySettingJson);
        const newLocationOptions = {
            ...locationOptions,
        };

        locationKeyList.forEach((locationKey) => {
            if (props.displaySettingJson.location_name[locationKey]) {
                newLocationOptions[locationKey] = props.displaySettingJson.location_name[locationKey];
            }
        });
        setLocationOptions(newLocationOptions);
        // console.log("locationOptions:", locationOptions);
    }

    const occurenceSourceOptionsUpdate = () => {
        // console.log("occurenceSourceOptionsUpdate");
        // console.log("props.displaySettingJson:", props.displaySettingJson);
        const newOccurenceSourceOptions = {
            "ALL": "全て",

            ...searchCondition.alarmType != "health" ? waveMoniteringBandNameList : {},
            ...searchCondition.alarmType != "radio" ? DeviceLogNames : {},
        };

        if (searchCondition.alarmType != "health") {
            waveMoniteringBandList.forEach((band) => {
                if (props.displaySettingJson.band_label[band]) {
                    newOccurenceSourceOptions[band] = props.displaySettingJson.band_label[band].name;
                } else if (band === "total") {
                    newOccurenceSourceOptions[band] = "合計";
                }
            });
        }

        // 現在の発生元が選択肢にない場合は全てを選択
        if (!newOccurenceSourceOptions[searchCondition.occurenceSource]) {
            setSearchCondition(prevState => ({
                ...prevState,
                occurenceSource: "ALL",
            }));
        }

        setOccurenceSourceOptions(newOccurenceSourceOptions);
    }

    const logTypeOptionsUpdate = () => {
        // console.log("logTypeOptionsUpdate");
        // console.log("props.displaySettingJson:", props.displaySettingJson);

        const newLogTypeOptions = {
            "ALL": "全て",
        };

        for (const alarmType in LogRelation) {
            if (alarmType == searchCondition.alarmType || searchCondition.alarmType === "ALL") {
                for (const occurenceSource in LogRelation[alarmType]) {
                    if (searchCondition.occurenceSource == occurenceSource || searchCondition.occurenceSource === "ALL") {
                        for (const logType of LogRelation[alarmType][occurenceSource]) {
                            newLogTypeOptions[logType] = logTypeNames[logType];
                        }
                    }
                }
            }
        }

        // 現在のログ種別が選択肢にない場合は全てを選択
        if (!newLogTypeOptions[searchCondition.logType]) {
            setSearchCondition(prevState => ({
                ...prevState,
                logType: "ALL",
            }));
        }

        setLogTypeOptions(newLogTypeOptions);
    }

    // バリデーション
    const validation = () => {
        // 期間がnullの場合、エラー
        if (searchCondition.startDate === null || searchCondition.endDate === null) {
            return "正しい期間を入力してください。";
        }

        // 期間が逆の場合、エラー
        if (searchCondition.startDate > searchCondition.endDate) {
            return "開始終了期間が逆転しています。";
        }

        return null;
    }


    useEffect(() => {
        locationOptionsUpdate();
        occurenceSourceOptionsUpdate();
        logTypeOptionsUpdate();
        // console.log("useEffect displaySettingJson:", props.displaySettingJson);
    }, [props.displaySettingJson]);

    useEffect(() => {
        // 発生元の選択肢を更新
        occurenceSourceOptionsUpdate();

        // ログ種別の選択肢を更新
        logTypeOptionsUpdate();
    }, [searchCondition]);


    return (
        <>
            <KanshiSearchbox
                alignSelf="stretch"
                type={props.searchBoxDisplayType}
                overrides={{
                    "KanshiSearchbox": {
                        gap: "15px",
                    },
                    "ContentFrame": {
                        gap: "15px",
                    },
                    "Kanshi/Btn/Search": {
                        situation: props.selectTab,
                        overrides: {
                            "Common/Btn/Text39255568": {
                                // onClick: () => onClickCommonBtnText("全て"),
                                onClick: () => props.onClickSearchTab("全て"),
                                overrides: {
                                    text: {
                                        children: "すべて",
                                    }
                                },
                                style: {
                                    cursor: "pointer",
                                }
                            },
                            "Common/Btn/Text39256946": {
                                // onClick: () => onClickCommonBtnText("発生中"),
                                onClick: () => props.onClickSearchTab("発生中"),
                                overrides: {
                                    text: {
                                        children: "発生中",
                                    }
                                },
                                style: {
                                    cursor: "pointer",
                                }
                            },
                            "Common/Btn/Text39257144": {
                                // onClick: () => onClickCommonBtnText("未確認"),
                                onClick: () => props.onClickSearchTab("未確認"),
                                overrides: {
                                    text: {
                                        children: "未確認",
                                    }
                                },
                                style: {
                                    cursor: "pointer",
                                }
                            },
                            "Common/Btn/Text443017947": {
                                // onClick: () => onClickCommonBtnText("絞込み"),
                                onClick: () => props.onClickSearchTab("絞込み"),
                                overrides: {
                                    text: {
                                        children: "絞込み",
                                    }
                                },
                                style: {
                                    cursor: "pointer",
                                }
                            },
                        }
                    },
                    "TextField575924785": {
                        name: "startDate",
                        type: "datetime-local",
                        defaultValue: searchCondition.startDate,
                        onChange: handleInputOnChange,
                        // onBlur: onblur,
                        width: "100%",
                    },
                    "TextField575924787": {
                        name: "endDate",
                        type: "datetime-local",
                        defaultValue: searchCondition.endDate,
                        onChange: handleInputOnChange,
                        // onBlur: onblur,
                        width: "100%",
                    },
                    // 期間入力エラーメッセージ
                    "ErrorMessage": {
                        display: validation() == null ? "none" : "flex",
                    },
                    // 期間入力エラーメッセージ
                    "text575924790": {
                        children: validation(),
                    },
                    // 拠点名
                    "SelectField575924791": {
                        name: "locationName",
                        placeholder: "",
                        defaultValue: locationOptions[searchCondition.locationName],
                        options: Object.values(locationOptions),
                        onChange: handleLocationSelectOnChange,
                        className: "amplify-field--small",
                    },
                    // 監視種別
                    "SelectField575924792": {
                        name: "alarmType",
                        placeholder: "",
                        defaultValue: "全て",
                        options: Object.values(alarmTypeOptions),
                        onChange: handleAlarmTypeSelectOnChange,
                        label: "監視種別",
                        className: "amplify-field--small",
                    },
                    // 発生元
                    "SelectField575924794": {
                        name: "occurenceSource",
                        placeholder: "",
                        value: occurenceSourceOptions[searchCondition.occurenceSource],
                        options: Object.values(occurenceSourceOptions),
                        onChange: handleOccurenceSourceSelectOnChange,
                        label: "発生元",
                        className: "amplify-field--small",
                    },
                    // ログ種別
                    "SelectField575924795": {
                        name: "logType",
                        placeholder: "",
                        value: logTypeOptions[searchCondition.logType],
                        options: Object.values(logTypeOptions),
                        onChange: handleLogTypeSelectOnChange,
                        label: "ログ種別",
                        className: "amplify-field--small",
                    },
                    // 復帰状態
                    "SelectField575924796": {
                        name: "isRecover",
                        placeholder: "",
                        value: isRecover[searchCondition.isRecover],
                        options: Object.values(isRecover),
                        onChange: handleIsRecoverSelectOnChange,
                        label: "復帰状態",
                        className: "amplify-field--small",
                    },
                    // 確認状態
                    "SelectField575924797": {
                        name: "isConfirm",
                        placeholder: "",
                        value: isConfirm[searchCondition.isConfirm],
                        options: Object.values(isConfirm),
                        onChange: handleIsConfirmSelectOnChange,
                        label: "確認状態",
                        className: "amplify-field--small",
                    },
                    // 検索ボタン
                    "Common/Btn/Blue": {
                        onClick: onClickSearchBtn,
                        overrides: {
                            text: {
                                children: "検索",
                            }
                        },
                        style: {
                            cursor: "pointer",
                        }
                    },
                }}

            />
        </>
    );
}