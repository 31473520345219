/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import {
  Flex,
  Radio,
  Text,
  TextAreaField,
  TextField,
} from "@aws-amplify/ui-react";
import Spacer from "./Spacer";
import CommonDivider from "./CommonDivider";
import CommonBtnWhite from "./CommonBtnWhite";
import CommonBtnBlue from "./CommonBtnBlue";
export default function KanshiFrameLogDetails(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      gap="56px"
      direction="column"
      width="unset"
      height="unset"
      justifyContent="center"
      alignItems="center"
      position="relative"
      borderRadius="20px"
      padding="40px 40px 40px 40px"
      backgroundColor="rgba(255,255,255,1)"
      {...rest}
      {...getOverrideProps(overrides, "KanshiFrameLogDetails")}
    >
      <Flex
        gap="40px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 911")}
      >
        <Flex
          gap="24px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          padding="24px 24px 24px 24px"
          backgroundColor="rgba(245,245,245,1)"
          {...getOverrideProps(overrides, "Frame 910")}
        >
          <Flex
            gap="0"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 909")}
          >
            <Flex
              gap="0"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              shrink="0"
              position="relative"
              padding="8px 8px 8px 8px"
              {...getOverrideProps(overrides, "Title507632581")}
            >
              <Text
                fontFamily="Noto Sans"
                fontSize="16px"
                fontWeight="700"
                color="rgba(100,100,100,1)"
                lineHeight="16px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0.65px"
                width="216px"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="発生日時"
                {...getOverrideProps(overrides, "text507632582")}
              ></Text>
              <Spacer
                display="flex"
                gap="10px"
                direction="column"
                width="40px"
                height="32px"
                justifyContent="center"
                alignItems="center"
                overflow="hidden"
                shrink="0"
                position="relative"
                padding="10px 10px 10px 10px"
                {...getOverrideProps(overrides, "Spacer507632583")}
              ></Spacer>
              <Text
                fontFamily="Noto Sans"
                fontSize="16px"
                fontWeight="700"
                color="rgba(100,100,100,1)"
                lineHeight="16px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0.65px"
                width="112px"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="拠点名"
                {...getOverrideProps(overrides, "text507632584")}
              ></Text>
              <Spacer
                display="flex"
                gap="10px"
                direction="column"
                width="40px"
                height="32px"
                justifyContent="center"
                alignItems="center"
                overflow="hidden"
                shrink="0"
                position="relative"
                padding="10px 10px 10px 10px"
                {...getOverrideProps(overrides, "Spacer507632585")}
              ></Spacer>
              <Text
                fontFamily="Noto Sans"
                fontSize="16px"
                fontWeight="700"
                color="rgba(100,100,100,1)"
                lineHeight="16px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0.65px"
                width="152px"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="発生源"
                {...getOverrideProps(overrides, "text507632586")}
              ></Text>
              <Spacer
                display="flex"
                gap="10px"
                direction="column"
                width="40px"
                height="32px"
                justifyContent="center"
                alignItems="center"
                overflow="hidden"
                shrink="0"
                position="relative"
                padding="10px 10px 10px 10px"
                {...getOverrideProps(overrides, "Spacer507632587")}
              ></Spacer>
              <Text
                fontFamily="Noto Sans"
                fontSize="16px"
                fontWeight="700"
                color="rgba(100,100,100,1)"
                lineHeight="16px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0.65px"
                width="152px"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="ログ種別"
                {...getOverrideProps(overrides, "text507632588")}
              ></Text>
              <Spacer
                display="flex"
                gap="10px"
                direction="column"
                width="40px"
                height="32px"
                justifyContent="center"
                alignItems="center"
                overflow="hidden"
                shrink="0"
                position="relative"
                padding="10px 10px 10px 10px"
                {...getOverrideProps(overrides, "Spacer507632589")}
              ></Spacer>
              <Text
                fontFamily="Noto Sans"
                fontSize="16px"
                fontWeight="700"
                color="rgba(100,100,100,1)"
                lineHeight="16px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0.65px"
                width="96px"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="確認状態"
                {...getOverrideProps(overrides, "text507632590")}
              ></Text>
            </Flex>
            <CommonDivider
              display="flex"
              gap="0"
              direction="row"
              width="unset"
              height="1px"
              justifyContent="center"
              alignItems="center"
              overflow="hidden"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Common/Divider")}
            ></CommonDivider>
            <Flex
              gap="0"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              shrink="0"
              position="relative"
              padding="8px 8px 8px 8px"
              {...getOverrideProps(overrides, "Title507632592")}
            >
              <Text
                fontFamily="Noto Sans"
                fontSize="16px"
                fontWeight="400"
                color="rgba(100,100,100,1)"
                lineHeight="16px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0.65px"
                width="216px"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="2022/10/04 09:40:00"
                {...getOverrideProps(overrides, "text507632593")}
              ></Text>
              <Spacer
                display="flex"
                gap="10px"
                direction="column"
                width="40px"
                height="32px"
                justifyContent="center"
                alignItems="center"
                overflow="hidden"
                shrink="0"
                position="relative"
                padding="10px 10px 10px 10px"
                {...getOverrideProps(overrides, "Spacer507632594")}
              ></Spacer>
              <Text
                fontFamily="Noto Sans"
                fontSize="16px"
                fontWeight="400"
                color="rgba(100,100,100,1)"
                lineHeight="16px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0.65px"
                width="112px"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="小夜戸"
                {...getOverrideProps(overrides, "text507632595")}
              ></Text>
              <Spacer
                display="flex"
                gap="10px"
                direction="column"
                width="40px"
                height="32px"
                justifyContent="center"
                alignItems="center"
                overflow="hidden"
                shrink="0"
                position="relative"
                padding="10px 10px 10px 10px"
                {...getOverrideProps(overrides, "Spacer507632596")}
              ></Spacer>
              <Text
                fontFamily="Noto Sans"
                fontSize="16px"
                fontWeight="400"
                color="rgba(100,100,100,1)"
                lineHeight="16px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0.65px"
                width="152px"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="キャビネット電源1"
                {...getOverrideProps(overrides, "text507632597")}
              ></Text>
              <Spacer
                display="flex"
                gap="10px"
                direction="column"
                width="40px"
                height="32px"
                justifyContent="center"
                alignItems="center"
                overflow="hidden"
                shrink="0"
                position="relative"
                padding="10px 10px 10px 10px"
                {...getOverrideProps(overrides, "Spacer507632598")}
              ></Spacer>
              <Text
                fontFamily="Noto Sans"
                fontSize="16px"
                fontWeight="400"
                color="rgba(100,100,100,1)"
                lineHeight="16px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0.65px"
                width="152px"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="電力密度平均値"
                {...getOverrideProps(overrides, "text507632599")}
              ></Text>
              <Spacer
                display="flex"
                gap="10px"
                direction="column"
                width="40px"
                height="32px"
                justifyContent="center"
                alignItems="center"
                overflow="hidden"
                shrink="0"
                position="relative"
                padding="10px 10px 10px 10px"
                {...getOverrideProps(overrides, "Spacer507632600")}
              ></Spacer>
              <Text
                fontFamily="Noto Sans"
                fontSize="16px"
                fontWeight="400"
                color="rgba(100,100,100,1)"
                lineHeight="16px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0.65px"
                width="96px"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="確認済み"
                {...getOverrideProps(overrides, "text507632601")}
              ></Text>
            </Flex>
          </Flex>
          <TextAreaField
            width="unset"
            height="unset"
            shrink="0"
            alignSelf="stretch"
            placeholder="Placeholder"
            size="small"
            isDisabled={true}
            labelHidden={true}
            variation="default"
            {...getOverrideProps(overrides, "TextAreaField507632602")}
          ></TextAreaField>
        </Flex>
        <Flex
          gap="64px"
          direction="row"
          width="920px"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Edit")}
        >
          <Flex
            gap="32px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Edit01")}
          >
            <Text
              fontFamily="Noto Sans"
              fontSize="16px"
              fontWeight="500"
              color="rgba(100,100,100,1)"
              lineHeight="16px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              letterSpacing="0.65px"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="確認状態を変更"
              {...getOverrideProps(overrides, "text507632605")}
            ></Text>
            <Flex
              gap="16px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Frame 917")}
            >
              <Radio
                width="unset"
                height="unset"
                shrink="0"
                size="default"
                defaultChecked={true}
                isDisabled={false}
                labelPosition="end"
                children="確認済に変更"
                {...getOverrideProps(overrides, "Radio513421786")}
              ></Radio>
              <Radio
                width="unset"
                height="unset"
                shrink="0"
                size="default"
                defaultChecked={true}
                isDisabled={false}
                labelPosition="end"
                children="未確認に変更"
                {...getOverrideProps(overrides, "Radio514622016")}
              ></Radio>
            </Flex>
          </Flex>
          <Flex
            gap="32px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            grow="1"
            shrink="1"
            basis="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Edit02")}
          >
            <Flex
              gap="32px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Frame 908")}
            >
              <Text
                fontFamily="Noto Sans"
                fontSize="16px"
                fontWeight="500"
                color="rgba(100,100,100,1)"
                lineHeight="16px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0.65px"
                width="240px"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="確認結果を編集"
                {...getOverrideProps(overrides, "text507632615")}
              ></Text>
              <Flex
                gap="32px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "Frame 918")}
              >
                <TextField
                  width="unset"
                  height="unset"
                  label="期間"
                  placeholder="2024/04/01  00:00"
                  grow="1"
                  shrink="1"
                  basis="0"
                  size="small"
                  isDisabled={false}
                  labelHidden={false}
                  variation="default"
                  {...getOverrideProps(overrides, "TextField")}
                ></TextField>
                <TextAreaField
                  width="464px"
                  height="148px"
                  label="確認結果"
                  shrink="0"
                  placeholder="Placeholder"
                  size="small"
                  isDisabled={false}
                  labelHidden={false}
                  variation="default"
                  {...getOverrideProps(overrides, "TextAreaField507632618")}
                ></TextAreaField>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        gap="40px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "ButtonFrame")}
      >
        <CommonBtnWhite
          display="flex"
          gap="10px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          border="1px SOLID rgba(0,153,255,1)"
          borderRadius="8px"
          padding="9px 23px 9px 23px"
          backgroundColor="rgba(255,255,255,1)"
          situation="default"
          {...getOverrideProps(overrides, "Common/Btn/White")}
        ></CommonBtnWhite>
        <CommonBtnBlue
          display="flex"
          gap="10px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          position="relative"
          borderRadius="8px"
          padding="10px 24px 10px 24px"
          backgroundColor="rgba(0,153,255,1)"
          situation="default"
          size="large"
          {...getOverrideProps(overrides, "Common/Btn/Blue")}
        ></CommonBtnBlue>
      </Flex>
    </Flex>
  );
}
