import { Children } from 'react'
import {
    KanshiAntennaFrame
} from '../ui-components'

export default function KanshiAntennaFrameWrapper(props) {
    return (
        <KanshiAntennaFrame
        overrides={{
            "Kanshi/Antenna/indicator505131263": {
                type: props.waveMoniteringStatus["3400-3440"]["Threshold A"].state,
            },
            "Kanshi/Antenna/indicator505131264": {
                type: props.waveMoniteringStatus["3440-3480"]["Threshold A"].state,
            },
            "Kanshi/Antenna/indicator505131265": {
                type: props.waveMoniteringStatus["3480-3520"]["Threshold A"].state,
            },
            "Kanshi/Antenna/indicator505131266": {
                type: props.waveMoniteringStatus["3520-3560"]["Threshold A"].state,
            },
            "Kanshi/Antenna/indicator505131267": {
                type: props.waveMoniteringStatus["3560-3600"]["Threshold A"].state,
            },
            "Kanshi/Antenna/indicator505131268": {
                type: props.waveMoniteringStatus["3600-3700"]["Threshold A"].state,
            },
            "Kanshi/Antenna/indicator505131269": {
                type: props.waveMoniteringStatus["3700-3800"]["Threshold A"].state,
            },
            "Kanshi/Antenna/indicator505131270": {
                type: props.waveMoniteringStatus["3800-3900"]["Threshold A"].state,
            },
            "Kanshi/Antenna/indicator505131271": {
                type: props.waveMoniteringStatus["3900-4000"]["Threshold A"].state,
            },
            "Kanshi/Antenna/indicator505131272": {
                type: props.waveMoniteringStatus["4000-4100"]["Threshold A"].state,
            },
            "Kanshi/Antenna/indicator505131277": {
                type: props.waveMoniteringStatus["3400-3440"]["Threshold B"].state,
            },
            "Kanshi/Antenna/indicator505131278": {
                type: props.waveMoniteringStatus["3440-3480"]["Threshold B"].state,
            },
            "Kanshi/Antenna/indicator505131279": {
                type: props.waveMoniteringStatus["3480-3520"]["Threshold B"].state,
            },
            "Kanshi/Antenna/indicator505131280": {
                type: props.waveMoniteringStatus["3520-3560"]["Threshold B"].state,
            },
            "Kanshi/Antenna/indicator505131281": {
                type: props.waveMoniteringStatus["3560-3600"]["Threshold B"].state,
            },
            "Kanshi/Antenna/indicator505131282": {
                type: props.waveMoniteringStatus["3600-3700"]["Threshold B"].state,
            },
            "Kanshi/Antenna/indicator505131283": {
                type: props.waveMoniteringStatus["3700-3800"]["Threshold B"].state,
            },
            "Kanshi/Antenna/indicator505131284": {
                type: props.waveMoniteringStatus["3800-3900"]["Threshold B"].state,
            },
            "Kanshi/Antenna/indicator505131285": {
                type: props.waveMoniteringStatus["3900-4000"]["Threshold B"].state,
            },
            "Kanshi/Antenna/indicator505131286": {
                type: props.waveMoniteringStatus["4000-4100"]["Threshold B"].state,
            },
            "Kanshi/Antenna/indicator505131291": {
                type: props.waveMoniteringStatus["3400-3440"]["Threshold C"].state,
            },
            "Kanshi/Antenna/indicator505131292": {
                type: props.waveMoniteringStatus["3440-3480"]["Threshold C"].state,
            },
            "Kanshi/Antenna/indicator505131293": {
                type: props.waveMoniteringStatus["3480-3520"]["Threshold C"].state,
            },
            "Kanshi/Antenna/indicator505131294": {
                type: props.waveMoniteringStatus["3520-3560"]["Threshold C"].state,
            },
            "Kanshi/Antenna/indicator505131295": {
                type: props.waveMoniteringStatus["3560-3600"]["Threshold C"].state,
            },
            "Kanshi/Antenna/indicator505131296": {
                type: props.waveMoniteringStatus["3600-3700"]["Threshold C"].state,
            },
            "Kanshi/Antenna/indicator505131297": {
                type: props.waveMoniteringStatus["3700-3800"]["Threshold C"].state,
            },
            "Kanshi/Antenna/indicator505131298": {
                type: props.waveMoniteringStatus["3800-3900"]["Threshold C"].state,
            },
            "Kanshi/Antenna/indicator505131299": {
                type: props.waveMoniteringStatus["3900-4000"]["Threshold C"].state,
            },
            "Kanshi/Antenna/indicator505131300": {
                type: props.waveMoniteringStatus["4000-4100"]["Threshold C"].state,
            },  
            "text505131249": {
                children: props.displaySettingJson.band_label["3400-3440"].name
            },
            "text505131250": {
                children: props.displaySettingJson.band_label["3440-3480"].name
            },
            "text505131251": {
                children: props.displaySettingJson.band_label["3480-3520"].name
            },
            "text505131252": {
                children: props.displaySettingJson.band_label["3520-3560"].name
            },
            "text505131253": {
                children: props.displaySettingJson.band_label["3560-3600"].name
            },
            "text505131254": {
                children: props.displaySettingJson.band_label["3600-3700"].name
            },
            "text505131255": {
                children: props.displaySettingJson.band_label["3700-3800"].name
            },
            "text505131256": {
                children: props.displaySettingJson.band_label["3800-3900"].name
            },
            "text505131257": {
                children: props.displaySettingJson.band_label["3900-4000"].name
            },
            "text505131258": {
                children: props.displaySettingJson.band_label["4000-4100"].name
            },
        }}
        >

        </KanshiAntennaFrame>
    )
}