/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "@aws-amplify/ui-react/internal";
import KanshiBtnSearch from "./KanshiBtnSearch";
import { Flex, SelectField, Text, TextField } from "@aws-amplify/ui-react";
import Myicons from "./Myicons";
import CommonBtnBlue from "./CommonBtnBlue";
export default function KanshiSearchbox(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: {
        "Kanshi/Btn/Search": { situation: "\u7D5E\u8FBC\u307F" },
        TextField575924785: {},
        text575924786: {},
        TextField575924787: {},
        Input: {},
        Myicons: {},
        text575924790: {},
        ErrorMessage: {},
        Period: {},
        SelectField575924791: {},
        SelectField575924792: {},
        "Frame 1": {},
        SelectField575924794: {},
        SelectField575924795: {},
        SelectField575924796: {},
        SelectField575924797: {},
        "Frame 2": {},
        ContentFrame: {},
        "Common/Btn/Blue": {},
        Detail: { display: "flex" },
        KanshiSearchbox: {},
      },
      variantValues: { type: "Select" },
    },
    {
      overrides: {
        "Kanshi/Btn/Search": {},
        TextField575924785: {},
        text575924786: {},
        TextField575924787: {},
        Input: {},
        Myicons: {},
        text575924790: {},
        ErrorMessage: {},
        Period: {},
        SelectField575924791: {},
        SelectField575924792: {},
        "Frame 1": {},
        SelectField575924794: {},
        SelectField575924795: {},
        SelectField575924796: {},
        SelectField575924797: {},
        "Frame 2": {},
        ContentFrame: {},
        "Common/Btn/Blue": {},
        Detail: {},
        KanshiSearchbox: {},
      },
      variantValues: { type: "Default" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="32px"
      direction="column"
      width="unset"
      height="unset"
      justifyContent="center"
      alignItems="center"
      position="relative"
      border="1px SOLID rgba(210,210,210,1)"
      borderRadius="8px"
      padding="23px 23px 23px 23px"
      backgroundColor="rgba(255,255,255,1)"
      display="flex"
      {...rest}
      {...getOverrideProps(overrides, "KanshiSearchbox")}
    >
      <KanshiBtnSearch
        display="flex"
        gap="16px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        situation="すべて"
        {...getOverrideProps(overrides, "Kanshi/Btn/Search")}
      ></KanshiBtnSearch>
      <Flex
        gap="0"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="flex-end"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        display="none"
        {...getOverrideProps(overrides, "Detail")}
      >
        <Flex
          gap="24px"
          direction="column"
          width="580px"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "ContentFrame")}
        >
          <Flex
            gap="20px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "Frame 1")}
          >
            <Flex
              gap="8px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Period")}
            >
              <Flex
                gap="8px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-end"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "Input")}
              >
                <TextField
                  width="149px"
                  height="unset"
                  label="絞込み期間"
                  placeholder="2024/04/01  00:00"
                  shrink="0"
                  size="small"
                  isDisabled={false}
                  labelHidden={false}
                  variation="default"
                  {...getOverrideProps(overrides, "TextField575924785")}
                ></TextField>
                <Text
                  fontFamily="Noto Sans"
                  fontSize="14px"
                  fontWeight="500"
                  color="rgba(100,100,100,1)"
                  lineHeight="14px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  letterSpacing="0.55px"
                  width="14px"
                  height="24px"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="〜"
                  {...getOverrideProps(overrides, "text575924786")}
                ></Text>
                <TextField
                  width="149px"
                  height="unset"
                  placeholder="2024/04/01  00:00"
                  shrink="0"
                  size="small"
                  isDisabled={false}
                  labelHidden={true}
                  variation="default"
                  {...getOverrideProps(overrides, "TextField575924787")}
                ></TextField>
              </Flex>
              <Flex
                gap="8px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="center"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "ErrorMessage")}
              >
                <Myicons
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  overflow="hidden"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  type="attention"
                  {...getOverrideProps(overrides, "Myicons")}
                ></Myicons>
                <Text
                  fontFamily="Noto Sans"
                  fontSize="12px"
                  fontWeight="700"
                  color="rgba(255,76,79,1)"
                  lineHeight="12px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  letterSpacing="0.45px"
                  width="unset"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="開始終了期間が逆転しています"
                  {...getOverrideProps(overrides, "text575924790")}
                ></Text>
              </Flex>
            </Flex>
            <SelectField
              width="92px"
              height="unset"
              label="拠点"
              placeholder="全て"
              shrink="0"
              size="small"
              isDisabled={false}
              labelHidden={false}
              variation="default"
              {...getOverrideProps(overrides, "SelectField575924791")}
            ></SelectField>
            <SelectField
              width="120px"
              height="unset"
              label="監視種別"
              placeholder="全て"
              shrink="0"
              size="small"
              isDisabled={false}
              labelHidden={false}
              variation="default"
              {...getOverrideProps(overrides, "SelectField575924792")}
            ></SelectField>
          </Flex>
          <Flex
            gap="20px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "Frame 2")}
          >
            <SelectField
              width="162px"
              height="unset"
              label="発生元"
              placeholder="全て"
              shrink="0"
              size="small"
              isDisabled={false}
              labelHidden={false}
              variation="default"
              {...getOverrideProps(overrides, "SelectField575924794")}
            ></SelectField>
            <SelectField
              width="106px"
              height="unset"
              label="ログ種別"
              placeholder="全て"
              shrink="0"
              size="small"
              isDisabled={false}
              labelHidden={false}
              variation="default"
              {...getOverrideProps(overrides, "SelectField575924795")}
            ></SelectField>
            <SelectField
              width="92px"
              height="unset"
              label="復帰状態"
              placeholder="復帰済"
              shrink="0"
              size="small"
              isDisabled={false}
              labelHidden={false}
              variation="default"
              {...getOverrideProps(overrides, "SelectField575924796")}
            ></SelectField>
            <SelectField
              width="92px"
              height="unset"
              label="確認状態"
              placeholder="確認済"
              shrink="0"
              size="small"
              isDisabled={false}
              labelHidden={false}
              variation="default"
              {...getOverrideProps(overrides, "SelectField575924797")}
            ></SelectField>
          </Flex>
        </Flex>
        <CommonBtnBlue
          display="flex"
          gap="10px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="flex-end"
          shrink="0"
          position="relative"
          borderRadius="8px"
          padding="10px 24px 10px 24px"
          backgroundColor="rgba(0,153,255,1)"
          situation="situation4"
          size="small"
          {...getOverrideProps(overrides, "Common/Btn/Blue")}
        ></CommonBtnBlue>
      </Flex>
    </Flex>
  );
}
