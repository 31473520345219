/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "@aws-amplify/ui-react/internal";
import { Flex, Text, View } from "@aws-amplify/ui-react";
import Myicons from "./Myicons";
export default function CommonBadgeHeaderAccount(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: {
        text478914266: {},
        Name: {},
        text478914268: {},
        Mail: {},
        "Rectangle 87": {},
        text478915643: {},
        Logout: {},
        Menu: {},
        Myicons: {},
        CommonBadgeHeaderAccount: {},
      },
      variantValues: { type: "Default" },
    },
    {
      overrides: {
        text478914266: {},
        Name: {},
        text478914268: {},
        Mail: {},
        "Rectangle 87": {},
        text478915643: {},
        Logout: {},
        Menu: {},
        Myicons: {},
        CommonBadgeHeaderAccount: { backgroundColor: "rgba(240,240,240,1)" },
      },
      variantValues: { type: "Hover" },
    },
    {
      overrides: {
        text478914266: {},
        Name: {},
        text478914268: {},
        Mail: {},
        "Rectangle 87": {},
        text478915643: {},
        Logout: {},
        Menu: { display: "flex" },
        Myicons: {},
        CommonBadgeHeaderAccount: { backgroundColor: "rgba(240,240,240,1)" },
      },
      variantValues: { type: "Select" },
    },
    {
      overrides: {
        text478914266: {},
        Name: {},
        text478914268: {},
        Mail: {},
        "Rectangle 87": {},
        text478915643: {},
        Logout: { backgroundColor: "rgba(245,245,245,1)" },
        Menu: { display: "flex" },
        Myicons: {},
        CommonBadgeHeaderAccount: { backgroundColor: "rgba(240,240,240,1)" },
      },
      variantValues: { type: "LogoutHover" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <View
      width="40px"
      height="40px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      position="relative"
      borderRadius="20px"
      padding="0px 0px 0px 0px"
      backgroundColor="rgba(255,255,255,1)"
      {...rest}
      {...getOverrideProps(overrides, "CommonBadgeHeaderAccount")}
    >
      <Flex
        gap="0"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        position="absolute"
        top="42px"
        left="-192px"
        boxShadow="0px 0px 10px rgba(0, 0, 0, 0.10000000149011612)"
        borderRadius="4px"
        padding="8px 0px 8px 0px"
        backgroundColor="rgba(255,255,255,1)"
        display="none"
        {...getOverrideProps(overrides, "Menu")}
      >
        <Flex
          gap="10px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          padding="10px 16px 10px 16px"
          backgroundColor="rgba(255,255,255,1)"
          display="flex"
          {...getOverrideProps(overrides, "Name")}
        >
          <Text
            fontFamily="Noto Sans"
            fontSize="12px"
            fontWeight="500"
            color="rgba(53,56,59,1)"
            lineHeight="12px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0.45px"
            width="200px"
            height="12px"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="都築 梨央"
            {...getOverrideProps(overrides, "text478914266")}
          ></Text>
        </Flex>
        <Flex
          gap="10px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          padding="10px 16px 10px 16px"
          backgroundColor="rgba(255,255,255,1)"
          display="flex"
          {...getOverrideProps(overrides, "Mail")}
        >
          <Text
            fontFamily="Noto Sans"
            fontSize="12px"
            fontWeight="500"
            color="rgba(53,56,59,1)"
            lineHeight="12px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0.45px"
            width="200px"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="tsuzuki@trafficsim.co.jp"
            {...getOverrideProps(overrides, "text478914268")}
          ></Text>
        </Flex>
        <View
          width="unset"
          height="1px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          backgroundColor="rgba(210,210,210,1)"
          {...getOverrideProps(overrides, "Rectangle 87")}
        ></View>
        <Flex
          gap="16px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="8px 16px 8px 16px"
          backgroundColor="rgba(255,255,255,1)"
          display="flex"
          {...getOverrideProps(overrides, "Logout")}
        >
          <Text
            fontFamily="Noto Sans"
            fontSize="12px"
            fontWeight="500"
            color="rgba(53,56,59,1)"
            lineHeight="12px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0.45px"
            width="96px"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="ログアウト"
            {...getOverrideProps(overrides, "text478915643")}
          ></Text>
        </Flex>
      </Flex>
      <Myicons
        width="24px"
        height="24px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        overflow="hidden"
        position="absolute"
        top="calc(50% - 12px - 0px)"
        left="calc(50% - 12px - 0px)"
        padding="0px 0px 0px 0px"
        type="user"
        {...getOverrideProps(overrides, "Myicons")}
      ></Myicons>
    </View>
  );
}
