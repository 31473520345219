/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "@aws-amplify/ui-react/internal";
import { Text, View } from "@aws-amplify/ui-react";
export default function DatakanriBtn(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: {
        "Rectangle 17": {},
        "\u4E00\u62EC\u30C0\u30A6\u30F3\u30ED\u30FC\u30C9": {},
        DatakanriBtn: {},
      },
      variantValues: { situation: "default" },
    },
    {
      overrides: {
        "Rectangle 17": { backgroundColor: "rgba(240,240,240,1)" },
        "\u4E00\u62EC\u30C0\u30A6\u30F3\u30ED\u30FC\u30C9": {},
        DatakanriBtn: {},
      },
      variantValues: { situation: "hover" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <View
      width="180px"
      height="40px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      position="relative"
      padding="0px 0px 0px 0px"
      {...rest}
      {...getOverrideProps(overrides, "DatakanriBtn")}
    >
      <View
        width="180px"
        height="40px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="0%"
        bottom="0%"
        left="0%"
        right="0%"
        border="1px SOLID rgba(170,170,170,1)"
        borderRadius="20px"
        padding="0px 0px 0px 0px"
        backgroundColor="rgba(255,255,255,1)"
        {...getOverrideProps(overrides, "Rectangle 17")}
      ></View>
      <Text
        fontFamily="Noto Sans"
        fontSize="14px"
        fontWeight="500"
        color="rgba(53,56,59,1)"
        lineHeight="26px"
        textAlign="center"
        display="block"
        direction="column"
        justifyContent="unset"
        letterSpacing="0.55px"
        width="160px"
        height="26px"
        gap="unset"
        alignItems="unset"
        position="absolute"
        top="17.5%"
        bottom="17.5%"
        left="5.56%"
        right="5.56%"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="一括ダウンロード"
        {...getOverrideProps(
          overrides,
          "\u4E00\u62EC\u30C0\u30A6\u30F3\u30ED\u30FC\u30C9"
        )}
      ></Text>
    </View>
  );
}
