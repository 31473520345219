import { useState } from 'react';

import {
    getOverrideProps,
    getOverridesFromVariants,
    mergeVariantsAndOverrides,
} from "@aws-amplify/ui-react/internal";

import { CommonBtnRound } from '../ui-components';

export default function CommonBtnRoundWrapper(props) {
    const { overrides: overridesProp, ...rest } = props;
    const variants = [];
    const overrides = mergeVariantsAndOverrides(
        getOverridesFromVariants(variants, props),
        overridesProp || {}
    );
    const defaultSituation = props.color
    const [color, setColor] = useState(props.color);
    
    function onMouseEventEnter(){
        setColor("Hover")
    }

    function onMouseEventLeave(){
        setColor(defaultSituation)
    }

    return (
        <CommonBtnRound
            onClick={props.onClick}
            onMouseEnter={onMouseEventEnter}
            onMouseLeave={onMouseEventLeave}
            color={color}
            overrides={{
                text: {
                    children: props.children,
                }
            }}  
            style={{
                cursor: "pointer",
            }}
            {...getOverrideProps(overrides, "CommonBtnRound")}
        >
        </CommonBtnRound>
    );
}