/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "@aws-amplify/ui-react/internal";
import { Flex, Text, View } from "@aws-amplify/ui-react";
import Myicons from "./Myicons";
export default function CommonHeaderIcon(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: {
        text41466865: { children: "\u30DF\u30E5\u30FC\u30C8" },
        Pulldown: { display: "flex" },
        Myicons41466859: {},
        text41466861: {},
        "Btn/Teishi/Default": {},
        text41466862: {},
        Myicons41466863: {},
        icons: {},
        CommonHeaderIcon: {},
      },
      variantValues: { situation: "Off", pulldown: "Show", type: "Alarm" },
    },
    {
      overrides: {
        text41466865: {},
        Pulldown: {},
        Myicons41466859: {},
        text41466861: {},
        "Btn/Teishi/Default": {},
        text41466862: {},
        Myicons41466863: {},
        icons: {},
        CommonHeaderIcon: {},
      },
      variantValues: { situation: "Off", pulldown: "Hide", type: "Alarm" },
    },
    {
      overrides: {
        text41466865: {},
        Pulldown: { left: "8px", display: "flex" },
        Myicons41466859: { type: "alarmMute" },
        text41466861: {},
        "Btn/Teishi/Default": {},
        text41466862: { display: "block" },
        Myicons41466863: {},
        icons: {},
        CommonHeaderIcon: { width: "115px" },
      },
      variantValues: { situation: "Mute", pulldown: "Show", type: "Alarm" },
    },
    {
      overrides: {
        text41466865: {},
        Pulldown: { left: "8px" },
        Myicons41466859: { type: "alarmMute" },
        text41466861: {},
        "Btn/Teishi/Default": {},
        text41466862: { display: "block" },
        Myicons41466863: {},
        icons: {},
        CommonHeaderIcon: { width: "115px" },
      },
      variantValues: { situation: "Mute", pulldown: "Hide", type: "Alarm" },
    },
    {
      overrides: {
        text41466865: {},
        Pulldown: { left: "21px" },
        Myicons41466859: { type: "alarmWarningNew" },
        text41466861: {},
        "Btn/Teishi/Default": { display: "flex" },
        text41466862: {},
        Myicons41466863: { display: "none" },
        icons: {},
        CommonHeaderIcon: { width: "128px" },
      },
      variantValues: { situation: "On", pulldown: "Hide", type: "Alarm" },
    },
    {
      overrides: {
        text41466865: { children: "\u6D88\u706F" },
        Pulldown: { display: "flex" },
        Myicons41466859: { type: "patlightOn" },
        text41466861: {},
        "Btn/Teishi/Default": {},
        text41466862: {},
        Myicons41466863: {},
        icons: {},
        CommonHeaderIcon: {},
      },
      variantValues: { situation: "Off", pulldown: "Show", type: "Patlight" },
    },
    {
      overrides: {
        text41466865: {},
        Pulldown: {},
        Myicons41466859: { type: "patlightOn" },
        text41466861: {},
        "Btn/Teishi/Default": {},
        text41466862: {},
        Myicons41466863: {},
        icons: {},
        CommonHeaderIcon: {},
      },
      variantValues: { situation: "Off", pulldown: "Hide", type: "Patlight" },
    },
    {
      overrides: {
        text41466865: { children: "\u518D\u958B" },
        Pulldown: { left: "-22px", display: "flex" },
        Myicons41466859: { type: "patlightMute" },
        text41466861: {},
        "Btn/Teishi/Default": {},
        text41466862: { display: "block", children: "\u6D88\u706F" },
        Myicons41466863: {},
        icons: {},
        CommonHeaderIcon: { width: "85px" },
      },
      variantValues: { situation: "Mute", pulldown: "Show", type: "Patlight" },
    },
    {
      overrides: {
        text41466865: {},
        Pulldown: { left: "-22px" },
        Myicons41466859: { type: "patlightMute" },
        text41466861: {},
        "Btn/Teishi/Default": {},
        text41466862: { display: "block", children: "\u6D88\u706F" },
        Myicons41466863: {},
        icons: {},
        CommonHeaderIcon: { width: "85px" },
      },
      variantValues: { situation: "Mute", pulldown: "Hide", type: "Patlight" },
    },
    {
      overrides: {
        text41466865: {},
        Pulldown: { left: "21px" },
        Myicons41466859: { type: "patlightWarning" },
        text41466861: { children: "\u6D88\u706F\u3059\u308B" },
        "Btn/Teishi/Default": { display: "flex" },
        text41466862: {},
        Myicons41466863: { display: "none" },
        icons: {},
        CommonHeaderIcon: { width: "128px" },
      },
      variantValues: { situation: "On", pulldown: "Hide", type: "Patlight" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <View
      width="48px"
      height="30px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      position="relative"
      padding="0px 0px 0px 0px"
      {...rest}
      {...getOverrideProps(overrides, "CommonHeaderIcon")}
    >
      <Flex
        gap="10px"
        direction="row"
        width="107px"
        height="32px"
        justifyContent="center"
        alignItems="center"
        position="absolute"
        top="34px"
        left="-59px"
        padding="10px 16px 10px 16px"
        backgroundColor="rgba(100,100,100,1)"
        display="none"
        {...getOverrideProps(overrides, "Pulldown")}
      >
        <Text
          fontFamily="Noto Sans"
          fontSize="12px"
          fontWeight="500"
          color="rgba(255,255,255,1)"
          lineHeight="12px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          letterSpacing="0.45px"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="ミュート解除"
          {...getOverrideProps(overrides, "text41466865")}
        ></Text>
      </Flex>
      <Flex
        gap="8px"
        direction="row"
        width="unset"
        height="30px"
        justifyContent="center"
        alignItems="center"
        position="absolute"
        top="0px"
        right="0px"
        padding="0px 0px 0px 0px"
        display="flex"
        {...getOverrideProps(overrides, "icons")}
      >
        <Myicons
          width="24px"
          height="24px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          overflow="hidden"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          type="alarmON"
          {...getOverrideProps(overrides, "Myicons41466859")}
        ></Myicons>
        <Flex
          gap="16px"
          direction="row"
          width="unset"
          height="30px"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          position="relative"
          border="1px SOLID rgba(0,153,255,1)"
          borderRadius="16px"
          padding="7px 15px 7px 15px"
          backgroundColor="rgba(255,255,255,1)"
          display="none"
          {...getOverrideProps(overrides, "Btn/Teishi/Default")}
        >
          <Text
            fontFamily="Noto Sans"
            fontSize="14px"
            fontWeight="500"
            color="rgba(0,153,255,1)"
            lineHeight="14px"
            textAlign="center"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0.55px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="停止する"
            {...getOverrideProps(overrides, "text41466861")}
          ></Text>
        </Flex>
        <Text
          fontFamily="Noto Sans"
          fontSize="14px"
          fontWeight="500"
          color="rgba(100,100,100,1)"
          lineHeight="14px"
          textAlign="left"
          display="none"
          direction="column"
          justifyContent="unset"
          letterSpacing="0.55px"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="ミュート"
          {...getOverrideProps(overrides, "text41466862")}
        ></Text>
        <Myicons
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          type="TriangleDownNew"
          {...getOverrideProps(overrides, "Myicons41466863")}
        ></Myicons>
      </Flex>
    </View>
  );
}
