import { Flex } from "@aws-amplify/ui-react";
import { useEffect } from "react";


import {
    KanshiLogListWrapper,
} from '../'

export default function LocalKanshiLogAreaWrapper(props) {

    //　Local版レイアウトに合わせて高さを調整
    const logListHeight = "760px";

    return (
        <>
        <Flex
            gap="16px"
            direction="column"
            width="100%"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            // {...getOverrideProps(overrides, "Log")}
        >
            <Flex
                gap="0"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="center"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                // {...getOverrideProps(overrides, "Frame 863")}
            >
                <KanshiLogListWrapper
                logData={props.moniteringLogData}
                displaySettingJson={props.displaySettingJson}
                logListHeight={logListHeight}
                >
                </KanshiLogListWrapper>
            </Flex>
        </Flex>
        </>
    );
}