// 暫定対応
// import { createDeviceMoniteringStatus,createWaveMoniteringStatus } from '../graphql/mutations';


import { API, graphqlOperation } from 'aws-amplify';
import { listDeviceMoniteringStatuses, listWaveMoniteringStatuses } from '../graphql/queries';
import { onUpdateDeviceMoniteringStatus, onUpdateWaveMoniteringStatus } from '../graphql/subscriptions';
import {
    locationKeyList,
    waveMoniteringBandList,
    waveMoniteringBandThresholdList,
    deviceMoniteringDeviceList,
} from './ConstList';

// import { WaveMoniteringStatus, DeviceMoniteringStatus } from "../models";



export const getDeviceMoniteringStatus = async (nextToken = null, allItems = []) => {

    let mergedItems = [];
    try {
        const variables = {
            nextToken: nextToken,
        };
        const result = await API.graphql(graphqlOperation(listDeviceMoniteringStatuses, variables));

        // パラメータで受け取ったリストと取得したリストをマージする
        mergedItems = [...allItems, ...result.data.listDeviceMoniteringStatuses.items];

        // console.log("getDeviceMoniteringStatus result nextToken", result.data.listDeviceMoniteringStatuses.nextToken);
        // console.log("getDeviceMoniteringStatus result items", result.data.listDeviceMoniteringStatuses.items);

        if (result.data.listDeviceMoniteringStatuses.nextToken) {
            // nextTokenがある場合はnextTokenと現在のリストを渡して続きを取得する
            // console.log("getDeviceMoniteringStatus nextToken", result.data.listDeviceMoniteringStatuses.nextToken);
            return getDeviceMoniteringStatus(result.data.listDeviceMoniteringStatuses.nextToken, mergedItems);
        }
    } catch (error) {
        console.log("getDeviceMoniteringStatus", error);
    }

    // console.log("getDeviceMoniteringStatus mergedItems", mergedItems);
    return mergedItems;
}

export const subscriveToUpdateDeviceMoniteringStatus = async () => {
    let observable;
    try {
        observable = API.graphql(graphqlOperation(onUpdateDeviceMoniteringStatus))

    } catch (error) {
        console.log("subscriveToDeviceMoniteringStatus", error);
    }

    return observable;
}

export const getWaveMoniteringStatus = async (nextToken = null, allItems = []) => {

    let mergedItems = [];
    try {
        const variables = {
            limit: 150, // 件数が100件以上あるのが確定しているのでlimitをdefaultの100件から150件に変更
            nextToken: nextToken,
        };
        const result = await API.graphql(graphqlOperation(listWaveMoniteringStatuses, variables));
        
        mergedItems = [...allItems, ...result.data.listWaveMoniteringStatuses.items];

        // console.log("getWaveMoniteringStatus result nextToken", result.data.listWaveMoniteringStatuses.nextToken);
        // console.log("getWaveMoniteringStatus result items", result.data.listWaveMoniteringStatuses.items);
    
        if (result.data.listWaveMoniteringStatuses.nextToken) {
            // console.log("getWaveMoniteringStatus nextToken", result.data.listWaveMoniteringStatuses.nextToken);
            return getWaveMoniteringStatus(result.data.listWaveMoniteringStatuses.nextToken, mergedItems);
        }
    } catch (error) {
        console.log("getWaveMoniteringStatus", error);
    }

    // console.log("getWaveMoniteringStatus mergedItems", mergedItems);
    return mergedItems;
}

export const subscriveToUpdateWaveMoniteringStatus = async () => {

    let observable;
    try {
        observable = await API.graphql(graphqlOperation(onUpdateWaveMoniteringStatus))
    } catch (error) {
        console.log("subscriveToUpdateWaveMoniteringStatus", error);
    }
    
    return observable;
}

// 初期データ作成用　通常運用時に使用することはない想定
// export const createInitialDataWaveMoniteringStatus = async () => {

//     try {
//         locationKeyList.map(async (locationName) => {
//             waveMoniteringBandList.map(async (band) => {
//                 const thresholdList = waveMoniteringBandThresholdList[band];
//                 thresholdList.map(async (threshold) => {
//                     const item = {
//                         location_name: locationName,
//                         band: band,
//                         threshold_type: threshold,
//                         state: "Default",
//                         occurrence_detail: "[]",
//                     };

//                     const result = await API.graphql(graphqlOperation(createWaveMoniteringStatus, {input: item}));

//                     console.log("createInitialDataWaveMoniteringStatus", result.data.createWaveMoniteringStatus);

//                 });
//             });
//         });
//     } catch (error) {
//         console.log(error);
//     }
// }

// export const createInitialDataDeviceMoniteringStatus = async () => {
    
//         try {
//             locationKeyList.map(async (locationName) => {
//                 deviceMoniteringDeviceList.map(async (device) => {
//                     const item = {
//                         location_name: locationName,
//                         device: device,
//                         state: "Default",
//                         occurrence_detail: "[]",
//                     };
    
//                     const result = await API.graphql(graphqlOperation(createDeviceMoniteringStatus, {input: item}));

//                     console.log("createInitialDataDeviceMoniteringStatus", result.data.createDeviceMoniteringStatus);
//                 });
//             });
//         } catch (error) {
//             console.log(error);
//         }

// }


