// // // // // // // // // // // 
// 要素外のクリックイベントを登録するカスタムフック
// // // // // // // // // // // 

// react
import { useEffect, useRef } from 'react';

// 要素外のクリックイベントを登録するカスタムフック
const useClickOutSide = ({

    onCiickInside,
    onClickOutside,
}) => {
    // 対象の要素を取得
    const ref = useRef(null);

    // 対象の要素
    useEffect(() => {
        console.log("useClickOutSide useEffect", ref);
        //対象の要素を取得
        const el = ref.current;

        //対象の要素がなければ何もしない
        if (!el) return;

        //クリックした時に実行する関数
        const onClick = (e) => {
            if (!el?.contains(e.target)) {
                //ここに外側をクリックしたときの処理
                onClickOutside();
            } else {
                //ここに内側をクリックしたときの処理
                onCiickInside();
            }
        };

        //クリックイベントを設定
        document.addEventListener("click", onClick);

        //クリーンアップ関数
        return () => {
            //コンポーネントがアンマウント、再レンダリングされたときにクリックイベントを削除
            document.removeEventListener("click", onClick);
        };
    }, [ref]);

    return {ref};
}

export default useClickOutSide;
