import { useState } from 'react';

import {
    getOverrideProps,
    getOverridesFromVariants,
    mergeVariantsAndOverrides,
} from "@aws-amplify/ui-react/internal";

import { DatakanriBtn } from '../ui-components';

export default function DatakanriBtnWrapper(props) {
    const { overrides: overridesProp, ...rest } = props;
    const variants = [];
    const overrides = mergeVariantsAndOverrides(
        getOverridesFromVariants(variants, props),
        overridesProp || {}
    );
    const [situation, setSituation] = useState("default");
    
    function onMouseEventEnter(){
        setSituation("hover")
    }

    function onMouseEventLeave(){
        setSituation("default")
    }

    return (
        <DatakanriBtn
            onClick={props.onClick}
            onMouseEnter={onMouseEventEnter}
            onMouseLeave={onMouseEventLeave}
            situation={situation}
            overrides={{
                DatakanriBtn: {
                    style: {
                        cursor: "pointer",
                    }
                },
                "\u4E00\u62EC\u30C0\u30A6\u30F3\u30ED\u30FC\u30C9": {
                    children: props.text,
                }
            }}
            {...getOverrideProps(overrides, "\u4E00\u62EC\u30C0\u30A6\u30F3\u30ED\u30FC\u30C9")}
        >
        </DatakanriBtn>
    );
}