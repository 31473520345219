/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "@aws-amplify/ui-react/internal";
import Myicons from "./Myicons";
import { Flex } from "@aws-amplify/ui-react";
export default function CommonBadgeIcon(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: { Myicons: {}, CommonBadgeIcon: {} },
      variantValues: { situation: "default", type: "white" },
    },
    {
      overrides: {
        Myicons: {},
        CommonBadgeIcon: { backgroundColor: "rgba(245,245,245,1)" },
      },
      variantValues: { situation: "hover", type: "white" },
    },
    {
      overrides: {
        Myicons: {},
        CommonBadgeIcon: { backgroundColor: "rgba(242,250,255,1)" },
      },
      variantValues: { situation: "default", type: "blue" },
    },
    {
      overrides: { Myicons: {}, CommonBadgeIcon: {} },
      variantValues: { situation: "hover", type: "blue" },
    },
    {
      overrides: {
        Myicons: {},
        CommonBadgeIcon: { backgroundColor: "rgba(245,245,245,1)" },
      },
      variantValues: { situation: "default", type: "gray" },
    },
    {
      overrides: { Myicons: {}, CommonBadgeIcon: {} },
      variantValues: { situation: "hover", type: "gray" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="10px"
      direction="row"
      width="unset"
      height="unset"
      justifyContent="center"
      alignItems="center"
      position="relative"
      borderRadius="16px"
      padding="8px 8px 8px 8px"
      backgroundColor="rgba(255,255,255,1)"
      display="flex"
      {...rest}
      {...getOverrideProps(overrides, "CommonBadgeIcon")}
    >
      <Myicons
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        overflow="hidden"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        type="angle-down"
        {...getOverrideProps(overrides, "Myicons")}
      ></Myicons>
    </Flex>
  );
}
