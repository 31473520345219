import { useState, useEffect } from "react";
import { Flex } from "@aws-amplify/ui-react";

import {
    CommonTitle,
} from './ui-components'

import {
    LocalHeaderWrapper,
} from './componentsWrapper/local'

import {
    LocalKanshiLogAreaWrapper,
    LocalKanshiLocationWrapper
} from './componentsWrapper/local'

import {
    getLocalMoniteringLog
} from './lib/LocalLogUtil'

import {
    deviceMoniteringDeviceList,
    waveMoniteringBandList,
    waveMoniteringBandThresholdList
} from './lib/ConstList'

import {
    getLocalHealthStatus,
    getLocalRadioStatus,
} from './lib/LocalStatusUtil'


const LocalMain = () => {

    const [displaySettingJson, setDisplaySettingJson] = useState({
        "location_name": {
            "YSCC": "YSCC",
            "SPE": "SPE",
            "SPW": "SPW",
            "SAYADO": "小夜戸"
        },
        "band_label": {
            "3400-3440": {
                "name": "S-1",
                "color": "#bbbcbe"
            },
            "3440-3480": {
                "name": "D-1",
                "color": "#cc0033"
            },
            "3480-3520": {
                "name": "D-2",
                "color": "#cc0033"
            },
            "3520-3560": {
                "name": "K-1",
                "color": "#eb5505"
            },
            "3560-3600": {
                "name": "S-2",
                "color": "#bbbcbe"
            },
            "3600-3700": {
                "name": "D-3",
                "color": "#cc0033"
            },
            "3700-3800": {
                "name": "K-2",
                "color": "#eb5505"
            },
            "3800-3900": {
                "name": "R-1",
                "color": "#ff00ff"
            },
            "3900-4000": {
                "name": "S-3",
                "color": "#bbbcbe"
            },
            "4000-4100": {
                "name": "K-3",
                "color": "#eb5505"
            }
        }
    });

    // ログデータ取得
    const fetchData = async () => {
        try {
            const data = await getLocalMoniteringLog();
            console.log("getLocalMoniteringLog", data);
            setMoniteringLogData(data);
        } catch (error) {
            console.log("KanshiLogAreaWrapper useEffect getLocalMoniteringLog error", error);
        }
    };

    // 機器監視ステータスデータの初期化
    const initialDeviceMoniteringStatus = () => {
        const initialData = {};

        deviceMoniteringDeviceList.forEach(device => {
            if (device === "Measurement_Pc_Main" || device === "Measurement_Pc_Sub") {
                initialData[device] = {
                    state: "Default",
                    device: device
                };
            } else {
                initialData[device] = {
                    state: "None",
                    device: device
                };
            }
        });

        return initialData;
    }

    // 干渉波監視ステータスデータの初期化
    const initialWaveMoniteringStatus = () => {
        const initialData = {};

        waveMoniteringBandList.forEach(band => {
            const thresholdList = waveMoniteringBandThresholdList[band]
            thresholdList.forEach(threshold => {
                if (!initialData[band]) {
                    initialData[band] = {};
                }
                initialData[band][threshold] = {
                    state: "None",
                    band: band,
                    threshold: threshold
                };
            })
        });

        return initialData;
    }

    // 機器監視ステータスデータ取得
    const getDeviceMoniteringStatusData = async () => {
        try {
            const data = await getLocalHealthStatus();
            // console.log('getLocalHealthStatus', data)
            if (data === null || Object.keys(data).length === 0) {
                console.log('getLocalHealthStatus data is null or empty')
                return;
            }
            setDeviceMoniteringStatus(data);
        } catch (error) {
            console.log("LocalKanshiLocationWrapper useEffect getLocalMoniteringLog error", error);
        }
    }

    // // 干渉波監視ステータスデータ取得
    const getWaveMoniteringStatusData = async () => {
        try {
            const data = await getLocalRadioStatus();
            // console.log('getLocalRadioStatus', data)
            // console.log(data);
            if (data === null || Object.keys(data).length === 0) {
                console.log('getLocalRadioStatus data is null or empty')
                return;
            }
            setWaveMoniteringStatus(data);
        } catch (error) {
            console.log("LocalKanshiLocationWrapper useEffect getLocalMoniteringLog error", error);
        }
    }

    // 変更通知を受け取った際は、機器監視ステータスデータと干渉波監視ステータスデータを再取得
    const onStatusChange = async (msg) => {
        // console.log("onStatusChange", msg);

        if (msg === 'health') {
            // console.log('health status update')
            const data = await getLocalHealthStatus();
            if (data === null || Object.keys(data).length === 0) {
                console.log('getLocalHealthStatus data is null or empty')
                return;
            }
            setDeviceMoniteringStatus(data);
        } else if (msg === 'radio') {
            // console.log('radio status update')
            const data = await getLocalRadioStatus();
            if (data === null || Object.keys(data).length === 0) {
                console.log('getLocalRadioStatus data is null or empty')
                return;
            }
            setWaveMoniteringStatus(data);
        }
    }

    // ログデータ管理State
    const [moniteringLogData, setMoniteringLogData] = useState([]);

    // 機器監視ステータスデータと干渉監視ステータスデータの管理State
    const [deviceMoniteringStatus, setDeviceMoniteringStatus] = useState(initialDeviceMoniteringStatus());
    const [waveMoniteringStatus, setWaveMoniteringStatus] = useState(initialWaveMoniteringStatus());

    // const [locationState, setLocationState] = useState(initialLocationState());

    useEffect(() => {
        // console.log("KanshiMain");
        // console.log("process.env.REACT_APP_TEST: ",process.env.REACT_APP_TEST)
        // console.log("process.env.REACT_APP_LOCATION_NAME: ",process.env.REACT_APP_LOCATION_NAME)
        // console.log("isLocalMode: ",isLocalMode)
        // 表示設定取得

        // ログデータ取得
        fetchData();

        // 機器監視ステータスデータと干渉波監視ステータスデータ取得をコール
        getDeviceMoniteringStatusData();
        getWaveMoniteringStatusData();

        // ログデータ変更通知の受信EventSourceを登録
        const hostname = window.location.hostname;
        const eventSource = new EventSource(`http://${hostname}:3001/addLogEvents`);

        eventSource.onmessage = (event) => {
            // console.log("KanshiLogAreaWrapper useEffect onMoniteringLogDataChange", event);

            // event.dataがhealth or radioの場合は、対応する監視ステータスデータを再取得
            if (event.data === 'health' || event.data === 'radio') {
                onStatusChange(event.data);
            } else {
                console.log('unknown event.data', event.data)
            }
            // ログデータ再取得
            fetchData();
        };

        eventSource.onerror = (error) => {
            console.error('EventSource failed:', error);
        };

        return () => {
            //　ページを離れる際は、EventSourceをクローズ
            eventSource.close();
        };
    }, []);

    return (
        <div >
            <LocalHeaderWrapper/>

            <Flex
                gap="40px"
                direction="row"
                width="100%"
                height="unset"
                justifyContent="space-between"
                alignItems="flex-start"
                position="relative"
                padding="0px 24px 0px 24px"
            // {...rest}
            // // {...getOverrideProps(overrides, "LayoutTougoukanshiAll")}
            >
                <Flex
                    gap="24px"
                    direction="column"
                    width="unset"
                    height="unset"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                // // {...getOverrideProps(overrides, "Frame 866")}
                >
                    <CommonTitle
                        width="240px"
                        height="18px"
                        display="block"
                        gap="unset"
                        alignItems="unset"
                        justifyContent="unset"
                        shrink="0"
                        position="relative"
                        padding="0px 0px 0px 0px"
                        size="large"
                        overrides={{
                            text: {
                                children: "統合監視",
                            }
                        }}
                    // // {...getOverrideProps(overrides, "Common/Title500220679")}
                    ></CommonTitle>
                    <Flex
                        gap="0"
                        direction="column"
                        width="unset"
                        height="798px"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        shrink="0"
                        position="relative"
                        padding="0px 0px 0px 0px"
                    // // {...getOverrideProps(overrides, "Kyoten")}
                    >
                        {displaySettingJson !== null && (
                            <LocalKanshiLocationWrapper
                                display="flex"
                                gap="8px"
                                direction="row"
                                width="unset"
                                height="unset"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                shrink="0"
                                position="relative"
                                padding="0px 0px 0px 0px"
                                deviceMoniteringStatus={deviceMoniteringStatus}
                                waveMoniteringStatus={waveMoniteringStatus}
                                displaySettingJson={displaySettingJson}
                            // situation="全拠点"
                            // // {...getOverrideProps(overrides, "Kanshi/Location")}
                            ></LocalKanshiLocationWrapper>
                        )}
                    </Flex>
                </Flex>
                <Flex
                    gap="12px"
                    grow={1}
                    direction="column"
                    width="unset"
                    height="unset"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                // {...getOverrideProps(overrides, "Frame 865")}
                >
                    <Flex
                        gap="371px"
                        direction="row"
                        width="100%"
                        height="unset"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        shrink="0"
                        alignSelf="stretch"
                        position="relative"
                        padding="0px 0px 0px 0px"
                    // {...getOverrideProps(overrides, "Frame 864")}
                    >
                        <CommonTitle
                            width="240px"
                            height="18px"
                            display="block"
                            gap="unset"
                            alignItems="unset"
                            justifyContent="unset"
                            shrink="0"
                            position="relative"
                            padding="0px 0px 0px 0px"
                            size="large"
                            overrides={{
                                text: {
                                    children: "監視ログ",
                                }
                            }}
                        // {...getOverrideProps(overrides, "Common/Title500220741")}
                        ></CommonTitle>
                    </Flex>
                    {displaySettingJson !== null && (
                        <LocalKanshiLogAreaWrapper
                            displaySettingJson={displaySettingJson}
                            moniteringLogData={moniteringLogData}
                        >
                        </LocalKanshiLogAreaWrapper>
                    )}
                </Flex>
            </Flex>
        </div>
    );

}

export default LocalMain;