/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "@aws-amplify/ui-react/internal";
import { Flex, Text } from "@aws-amplify/ui-react";
export default function KanshiBtnkakunin(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: {
        text: { children: "\u78BA\u8A8D\u6E08" },
        KanshiBtnkakunin: { backgroundColor: "rgba(255,255,255,1)" },
      },
      variantValues: { situation: "Default", check: "true", type: "Default" },
    },
    {
      overrides: {
        text: {},
        KanshiBtnkakunin: { backgroundColor: "rgba(245,245,245,1)" },
      },
      variantValues: { situation: "Default", check: "false", type: "Default" },
    },
    {
      overrides: {
        text: {},
        KanshiBtnkakunin: { backgroundColor: "rgba(235,235,235,1)" },
      },
      variantValues: { situation: "Hover", check: "false", type: "Default" },
    },
    {
      overrides: {
        text: {},
        KanshiBtnkakunin: { backgroundColor: "rgba(240,235,217,1)" },
      },
      variantValues: { situation: "Default", check: "false", type: "Warning" },
    },
    {
      overrides: {
        text: { children: "\u78BA\u8A8D\u6E08" },
        KanshiBtnkakunin: { backgroundColor: "rgba(255,250,231,1)" },
      },
      variantValues: { situation: "Default", check: "true", type: "Warning" },
    },
    {
      overrides: {
        text: {},
        KanshiBtnkakunin: { backgroundColor: "rgba(220,216,199,1)" },
      },
      variantValues: { situation: "Hover", check: "false", type: "Warning" },
    },
    {
      overrides: { text: {}, KanshiBtnkakunin: {} },
      variantValues: { situation: "Default", check: "false", type: "Error" },
    },
    {
      overrides: {
        text: { children: "\u78BA\u8A8D\u6E08" },
        KanshiBtnkakunin: { backgroundColor: "rgba(255,241,244,1)" },
      },
      variantValues: { situation: "Default", check: "true", type: "Error" },
    },
    {
      overrides: {
        text: {},
        KanshiBtnkakunin: { backgroundColor: "rgba(220,208,210,1)" },
      },
      variantValues: { situation: "Hover", check: "false", type: "Error" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="10px"
      direction="row"
      width="unset"
      height="unset"
      justifyContent="center"
      alignItems="center"
      position="relative"
      borderRadius="14px"
      padding="6px 10px 6px 10px"
      backgroundColor="rgba(240,227,230,1)"
      display="flex"
      {...rest}
      {...getOverrideProps(overrides, "KanshiBtnkakunin")}
    >
      <Text
        fontFamily="Noto Sans"
        fontSize="12px"
        fontWeight="400"
        color="rgba(53,56,59,1)"
        lineHeight="12px"
        textAlign="left"
        display="block"
        direction="column"
        justifyContent="unset"
        letterSpacing="0.45px"
        width="76px"
        height="unset"
        gap="unset"
        alignItems="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="確認済にする"
        {...getOverrideProps(overrides, "text")}
      ></Text>
    </Flex>
  );
}
