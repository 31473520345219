/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "@aws-amplify/ui-react/internal";
import { CheckboxField, Flex, Text } from "@aws-amplify/ui-react";
import Spacer from "./Spacer";
import Myicons from "./Myicons";
import KanshiBtnkakunin from "./KanshiBtnkakunin";
import CommonDivider from "./CommonDivider";
export default function KanshiLog(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: {
        CheckboxField491717853: {},
        Spacer491717854: {},
        text491717855: {},
        Spacer491717856: {},
        text491717857: {},
        Spacer491717858: {},
        text491717859: {},
        Spacer491717860: {},
        text491717861: {},
        Spacer491717864: {},
        text491717865: {},
        Title: {},
        Myicons: {},
        CheckboxField491717868: {},
        Spacer491717869: {},
        text491717870: {},
        Spacer491717871: {},
        text491717872: {},
        Spacer491717873: {},
        text491717874: {},
        Spacer491717875: {},
        text491717876: {},
        Spacer491717877: {},
        "Kanshi/Btn/kakunin": {},
        Contents: {},
        "Common/Divider": {},
        KanshiLog: {},
      },
      variantValues: { type: "Title", logType: "Title", iconType: "CheckOn" },
    },
    {
      overrides: {
        CheckboxField491717853: { defaultChecked: "false" },
        Spacer491717854: {},
        text491717855: {},
        Spacer491717856: {},
        text491717857: {},
        Spacer491717858: {},
        text491717859: {},
        Spacer491717860: {},
        text491717861: {},
        Spacer491717864: {},
        text491717865: {},
        Title: {},
        Myicons: {},
        CheckboxField491717868: {},
        Spacer491717869: {},
        text491717870: {},
        Spacer491717871: {},
        text491717872: {},
        Spacer491717873: {},
        text491717874: {},
        Spacer491717875: {},
        text491717876: {},
        Spacer491717877: {},
        "Kanshi/Btn/kakunin": {},
        Contents: {},
        "Common/Divider": {},
        KanshiLog: {},
      },
      variantValues: { type: "Title", logType: "Title", iconType: "CheckOff" },
    },
    {
      overrides: {
        CheckboxField491717853: {},
        Spacer491717854: {},
        text491717855: {},
        Spacer491717856: {},
        text491717857: {},
        Spacer491717858: {},
        text491717859: {},
        Spacer491717860: {},
        text491717861: {},
        Spacer491717864: {},
        text491717865: {},
        Title: { display: "none" },
        Myicons: {},
        CheckboxField491717868: {},
        Spacer491717869: {},
        text491717870: {},
        Spacer491717871: {},
        text491717872: {},
        Spacer491717873: {},
        text491717874: {},
        Spacer491717875: {},
        text491717876: {},
        Spacer491717877: {},
        "Kanshi/Btn/kakunin": {},
        Contents: { display: "flex" },
        "Common/Divider": {},
        KanshiLog: {},
      },
      variantValues: {
        type: "Default",
        logType: "Contetnts",
        iconType: "Circle",
      },
    },
    {
      overrides: {
        CheckboxField491717853: {},
        Spacer491717854: {},
        text491717855: {},
        Spacer491717856: {},
        text491717857: {},
        Spacer491717858: {},
        text491717859: {},
        Spacer491717860: {},
        text491717861: {},
        Spacer491717864: {},
        text491717865: {},
        Title: { display: "none" },
        Myicons: { type: "CircleWarning" },
        CheckboxField491717868: {},
        Spacer491717869: {},
        text491717870: {},
        Spacer491717871: {},
        text491717872: {},
        Spacer491717873: {},
        text491717874: {},
        Spacer491717875: {},
        text491717876: {},
        Spacer491717877: {},
        "Kanshi/Btn/kakunin": { type: "Warning" },
        Contents: { backgroundColor: "rgba(255,250,231,1)", display: "flex" },
        "Common/Divider": {},
        KanshiLog: {},
      },
      variantValues: {
        type: "Warning",
        logType: "Contetnts",
        iconType: "Circle",
      },
    },
    {
      overrides: {
        CheckboxField491717853: {},
        Spacer491717854: {},
        text491717855: {},
        Spacer491717856: {},
        text491717857: {},
        Spacer491717858: {},
        text491717859: {},
        Spacer491717860: {},
        text491717861: {},
        Spacer491717864: {},
        text491717865: {},
        Title: { display: "none" },
        Myicons: { type: "CircleError" },
        CheckboxField491717868: {},
        Spacer491717869: {},
        text491717870: {},
        Spacer491717871: {},
        text491717872: {},
        Spacer491717873: {},
        text491717874: {},
        Spacer491717875: {},
        text491717876: {},
        Spacer491717877: {},
        "Kanshi/Btn/kakunin": {
          type: "Error",
          backgroundColor: "rgba(240,227,230,1)",
        },
        Contents: { backgroundColor: "rgba(255,241,244,1)", display: "flex" },
        "Common/Divider": {},
        KanshiLog: {},
      },
      variantValues: {
        type: "Error",
        logType: "Contetnts",
        iconType: "Circle",
      },
    },
    {
      overrides: {
        CheckboxField491717853: {},
        Spacer491717854: {},
        text491717855: {},
        Spacer491717856: {},
        text491717857: {},
        Spacer491717858: {},
        text491717859: {},
        Spacer491717860: {},
        text491717861: {},
        Spacer491717864: {},
        text491717865: {},
        Title: { display: "none" },
        Myicons: { type: "CircleGray" },
        CheckboxField491717868: {},
        Spacer491717869: {},
        text491717870: {},
        Spacer491717871: {},
        text491717872: {},
        Spacer491717873: {},
        text491717874: {},
        Spacer491717875: {},
        text491717876: {},
        Spacer491717877: {},
        "Kanshi/Btn/kakunin": {},
        Contents: { display: "flex" },
        "Common/Divider": {},
        KanshiLog: {},
      },
      variantValues: { type: "Gray", logType: "Contetnts", iconType: "Circle" },
    },
    {
      overrides: {
        CheckboxField491717853: {},
        Spacer491717854: {},
        text491717855: {},
        Spacer491717856: {},
        text491717857: {},
        Spacer491717858: {},
        text491717859: {},
        Spacer491717860: {},
        text491717861: {},
        Spacer491717864: {},
        text491717865: {},
        Title: { display: "none" },
        Myicons: { display: "none" },
        CheckboxField491717868: { defaultChecked: "false" },
        Spacer491717869: {},
        text491717870: {},
        Spacer491717871: {},
        text491717872: {},
        Spacer491717873: {},
        text491717874: {},
        Spacer491717875: {},
        text491717876: {},
        Spacer491717877: {},
        "Kanshi/Btn/kakunin": { check: "true" },
        Contents: { display: "flex" },
        "Common/Divider": {},
        KanshiLog: {},
      },
      variantValues: {
        type: "Default",
        logType: "Contetnts",
        iconType: "CheckOff",
      },
    },
    {
      overrides: {
        CheckboxField491717853: {},
        Spacer491717854: {},
        text491717855: {},
        Spacer491717856: {},
        text491717857: {},
        Spacer491717858: {},
        text491717859: {},
        Spacer491717860: {},
        text491717861: {},
        Spacer491717864: {},
        text491717865: {},
        Title: { display: "none" },
        Myicons: { display: "none" },
        CheckboxField491717868: {},
        Spacer491717869: {},
        text491717870: {},
        Spacer491717871: {},
        text491717872: {},
        Spacer491717873: {},
        text491717874: {},
        Spacer491717875: {},
        text491717876: {},
        Spacer491717877: {},
        "Kanshi/Btn/kakunin": { check: "true" },
        Contents: { display: "flex" },
        "Common/Divider": {},
        KanshiLog: {},
      },
      variantValues: {
        type: "Default",
        logType: "Contetnts",
        iconType: "CheckOn",
      },
    },
    {
      overrides: {
        CheckboxField491717853: {},
        Spacer491717854: {},
        text491717855: {},
        Spacer491717856: {},
        text491717857: {},
        Spacer491717858: {},
        text491717859: {},
        Spacer491717860: {},
        text491717861: {},
        Spacer491717864: {},
        text491717865: {},
        Title: { display: "none" },
        Myicons: { display: "none" },
        CheckboxField491717868: {},
        Spacer491717869: {},
        text491717870: {},
        Spacer491717871: {},
        text491717872: {},
        Spacer491717873: {},
        text491717874: {},
        Spacer491717875: {},
        text491717876: {},
        Spacer491717877: {},
        "Kanshi/Btn/kakunin": { check: "true", type: "Warning" },
        Contents: { backgroundColor: "rgba(255,250,231,1)", display: "flex" },
        "Common/Divider": {},
        KanshiLog: {},
      },
      variantValues: {
        type: "Warning",
        logType: "Contetnts",
        iconType: "CheckOn",
      },
    },
    {
      overrides: {
        CheckboxField491717853: {},
        Spacer491717854: {},
        text491717855: {},
        Spacer491717856: {},
        text491717857: {},
        Spacer491717858: {},
        text491717859: {},
        Spacer491717860: {},
        text491717861: {},
        Spacer491717864: {},
        text491717865: {},
        Title: { display: "none" },
        Myicons: { display: "none" },
        CheckboxField491717868: { defaultChecked: "false" },
        Spacer491717869: {},
        text491717870: {},
        Spacer491717871: {},
        text491717872: {},
        Spacer491717873: {},
        text491717874: {},
        Spacer491717875: {},
        text491717876: {},
        Spacer491717877: {},
        "Kanshi/Btn/kakunin": { check: "true", type: "Warning" },
        Contents: { backgroundColor: "rgba(255,250,231,1)", display: "flex" },
        "Common/Divider": {},
        KanshiLog: {},
      },
      variantValues: {
        type: "Warning",
        logType: "Contetnts",
        iconType: "CheckOff",
      },
    },
    {
      overrides: {
        CheckboxField491717853: {},
        Spacer491717854: {},
        text491717855: {},
        Spacer491717856: {},
        text491717857: {},
        Spacer491717858: {},
        text491717859: {},
        Spacer491717860: {},
        text491717861: {},
        Spacer491717864: {},
        text491717865: {},
        Title: { display: "none" },
        Myicons: { display: "none" },
        CheckboxField491717868: {},
        Spacer491717869: {},
        text491717870: {},
        Spacer491717871: {},
        text491717872: {},
        Spacer491717873: {},
        text491717874: {},
        Spacer491717875: {},
        text491717876: {},
        Spacer491717877: {},
        "Kanshi/Btn/kakunin": {
          check: "true",
          type: "Warning",
          backgroundColor: "rgba(255,241,244,1)",
        },
        Contents: { backgroundColor: "rgba(255,241,244,1)", display: "flex" },
        "Common/Divider": {},
        KanshiLog: {},
      },
      variantValues: {
        type: "Error",
        logType: "Contetnts",
        iconType: "CheckOn",
      },
    },
    {
      overrides: {
        CheckboxField491717853: {},
        Spacer491717854: {},
        text491717855: {},
        Spacer491717856: {},
        text491717857: {},
        Spacer491717858: {},
        text491717859: {},
        Spacer491717860: {},
        text491717861: {},
        Spacer491717864: {},
        text491717865: {},
        Title: { display: "none" },
        Myicons: { display: "none" },
        CheckboxField491717868: { defaultChecked: "false" },
        Spacer491717869: {},
        text491717870: {},
        Spacer491717871: {},
        text491717872: {},
        Spacer491717873: {},
        text491717874: {},
        Spacer491717875: {},
        text491717876: {},
        Spacer491717877: {},
        "Kanshi/Btn/kakunin": {
          check: "true",
          type: "Warning",
          backgroundColor: "rgba(255,241,244,1)",
        },
        Contents: { backgroundColor: "rgba(255,241,244,1)", display: "flex" },
        "Common/Divider": {},
        KanshiLog: {},
      },
      variantValues: {
        type: "Error",
        logType: "Contetnts",
        iconType: "CheckOff",
      },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="0"
      direction="column"
      width="760px"
      height="unset"
      justifyContent="flex-start"
      alignItems="flex-start"
      position="relative"
      padding="0px 0px 0px 0px"
      display="flex"
      {...rest}
      {...getOverrideProps(overrides, "KanshiLog")}
    >
      <Flex
        gap="0"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="8px 8px 8px 8px"
        backgroundColor="rgba(255,255,255,1)"
        display="flex"
        {...getOverrideProps(overrides, "Title")}
      >
        <CheckboxField
          width="unset"
          height="unset"
          shrink="0"
          size="default"
          defaultChecked={true}
          isDisabled={false}
          labelPosition="end"
          {...getOverrideProps(overrides, "CheckboxField491717853")}
        ></CheckboxField>
        <Spacer
          display="flex"
          gap="10px"
          direction="column"
          width="8px"
          height="32px"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
          shrink="0"
          position="relative"
          padding="10px 10px 10px 10px"
          {...getOverrideProps(overrides, "Spacer491717854")}
        ></Spacer>
        <Text
          fontFamily="Noto Sans"
          fontSize="12px"
          fontWeight="700"
          color="rgba(100,100,100,1)"
          lineHeight="12px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          letterSpacing="0.45px"
          width="144px"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="日付"
          {...getOverrideProps(overrides, "text491717855")}
        ></Text>
        <Spacer
          display="flex"
          gap="10px"
          direction="column"
          width="unset"
          height="32px"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
          grow="1"
          shrink="1"
          basis="0"
          position="relative"
          padding="10px 10px 10px 10px"
          {...getOverrideProps(overrides, "Spacer491717856")}
        ></Spacer>
        <Text
          fontFamily="Noto Sans"
          fontSize="12px"
          fontWeight="700"
          color="rgba(100,100,100,1)"
          lineHeight="12px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          letterSpacing="0.45px"
          width="104px"
          height="12px"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="拠点名"
          {...getOverrideProps(overrides, "text491717857")}
        ></Text>
        <Spacer
          display="flex"
          gap="10px"
          direction="column"
          width="unset"
          height="32px"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
          grow="1"
          shrink="1"
          basis="0"
          position="relative"
          padding="10px 10px 10px 10px"
          {...getOverrideProps(overrides, "Spacer491717858")}
        ></Spacer>
        <Text
          fontFamily="Noto Sans"
          fontSize="12px"
          fontWeight="700"
          color="rgba(100,100,100,1)"
          lineHeight="12px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          letterSpacing="0.45px"
          width="128px"
          height="12px"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="発生源"
          {...getOverrideProps(overrides, "text491717859")}
        ></Text>
        <Spacer
          display="flex"
          gap="10px"
          direction="column"
          width="unset"
          height="32px"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
          grow="1"
          shrink="1"
          basis="0"
          position="relative"
          padding="10px 10px 10px 10px"
          {...getOverrideProps(overrides, "Spacer491717860")}
        ></Spacer>
        <Text
          fontFamily="Noto Sans"
          fontSize="12px"
          fontWeight="700"
          color="rgba(100,100,100,1)"
          lineHeight="12px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          letterSpacing="0.45px"
          width="96px"
          height="12px"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="ログ種別"
          {...getOverrideProps(overrides, "text491717861")}
        ></Text>
        <Spacer
          display="flex"
          gap="10px"
          direction="column"
          width="unset"
          height="32px"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
          grow="1"
          shrink="1"
          basis="0"
          position="relative"
          padding="10px 10px 10px 10px"
          {...getOverrideProps(overrides, "Spacer491717864")}
        ></Spacer>
        <Text
          fontFamily="Noto Sans"
          fontSize="12px"
          fontWeight="700"
          color="rgba(100,100,100,1)"
          lineHeight="12px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          letterSpacing="0.45px"
          width="96px"
          height="12px"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="確認状態"
          {...getOverrideProps(overrides, "text491717865")}
        ></Text>
      </Flex>
      <Flex
        gap="0"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="8px 8px 8px 8px"
        backgroundColor="rgba(255,255,255,1)"
        display="none"
        {...getOverrideProps(overrides, "Contents")}
      >
        <Myicons
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          type="CircleDefault"
          {...getOverrideProps(overrides, "Myicons")}
        ></Myicons>
        <CheckboxField
          width="unset"
          height="unset"
          display="none"
          shrink="0"
          size="default"
          defaultChecked={true}
          isDisabled={false}
          labelPosition="end"
          {...getOverrideProps(overrides, "CheckboxField491717868")}
        ></CheckboxField>
        <Spacer
          display="flex"
          gap="10px"
          direction="column"
          width="8px"
          height="32px"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
          shrink="0"
          position="relative"
          padding="10px 10px 10px 10px"
          {...getOverrideProps(overrides, "Spacer491717869")}
        ></Spacer>
        <Text
          fontFamily="Noto Sans"
          fontSize="12px"
          fontWeight="400"
          color="rgba(53,56,59,1)"
          lineHeight="12px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          letterSpacing="0.45px"
          width="144px"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="2022/10/04 09:30:00"
          {...getOverrideProps(overrides, "text491717870")}
        ></Text>
        <Spacer
          display="flex"
          gap="10px"
          direction="column"
          width="unset"
          height="32px"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
          grow="1"
          shrink="1"
          basis="0"
          position="relative"
          padding="10px 10px 10px 10px"
          {...getOverrideProps(overrides, "Spacer491717871")}
        ></Spacer>
        <Text
          fontFamily="Noto Sans"
          fontSize="12px"
          fontWeight="400"
          color="rgba(53,56,59,1)"
          lineHeight="12px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          letterSpacing="0.45px"
          width="104px"
          height="12px"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="小夜戸"
          {...getOverrideProps(overrides, "text491717872")}
        ></Text>
        <Spacer
          display="flex"
          gap="10px"
          direction="column"
          width="unset"
          height="32px"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
          grow="1"
          shrink="1"
          basis="0"
          position="relative"
          padding="10px 10px 10px 10px"
          {...getOverrideProps(overrides, "Spacer491717873")}
        ></Spacer>
        <Text
          fontFamily="Noto Sans"
          fontSize="12px"
          fontWeight="400"
          color="rgba(53,56,59,1)"
          lineHeight="12px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          letterSpacing="0.45px"
          width="128px"
          height="12px"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="キャビネット電源1"
          {...getOverrideProps(overrides, "text491717874")}
        ></Text>
        <Spacer
          display="flex"
          gap="10px"
          direction="column"
          width="unset"
          height="32px"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
          grow="1"
          shrink="1"
          basis="0"
          position="relative"
          padding="10px 10px 10px 10px"
          {...getOverrideProps(overrides, "Spacer491717875")}
        ></Spacer>
        <Text
          fontFamily="Noto Sans"
          fontSize="12px"
          fontWeight="400"
          color="rgba(53,56,59,1)"
          lineHeight="12px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          letterSpacing="0.45px"
          width="96px"
          height="12px"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="電力密度平均値"
          {...getOverrideProps(overrides, "text491717876")}
        ></Text>
        <Spacer
          display="flex"
          gap="10px"
          direction="column"
          width="unset"
          height="32px"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
          grow="1"
          shrink="1"
          basis="0"
          position="relative"
          padding="10px 10px 10px 10px"
          {...getOverrideProps(overrides, "Spacer491717877")}
        ></Spacer>
        <KanshiBtnkakunin
          display="flex"
          gap="10px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          position="relative"
          borderRadius="14px"
          padding="6px 10px 6px 10px"
          situation="Default"
          check={false}
          type="Default"
          {...getOverrideProps(overrides, "Kanshi/Btn/kakunin")}
        ></KanshiBtnkakunin>
      </Flex>
      <CommonDivider
        display="flex"
        gap="0"
        direction="row"
        width="unset"
        height="1px"
        justifyContent="center"
        alignItems="center"
        overflow="hidden"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Common/Divider")}
      ></CommonDivider>
    </Flex>
  );
}
