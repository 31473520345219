import { View, Flex, Text, SelectField } from "@aws-amplify/ui-react";
import { useEffect, useState } from "react";
import { CustomModal } from "../customComponents/CustomModal";

import {
    SetteiItem,
    // SetteiInputBox,
    Spacer,
    CommonDivider
} from '../ui-components';

import {
    SetteiInputBoxWrapper,
    SetteiItemWrapper,
    SetteiItemCycleWrapper,
    CheckWrapper,
} from '../componentsWrapper';

import {
    keisokuSettingJson,
    keisokuCycleSettingJson,
    keisokuKanshiSettingJson,
    SETTING_INPUTBOX_UNIT_NOTHING,
    SETTING_INPUTBOX_UNIT_DBI,
    SETTING_ITEM_TYPE_INPUT,
    SETTING_ITEM_TYPE_SELECT,
    getKeisokuSettingJson,
    convertSettingValueToInputValue,
    convertInputValueToSelctValue,
    convertInputValueToSettingValue,
    convertSelectValueToInputValue,
    convertStatusTypeValueToSelectValue,
    convertSelectValueToStatusTypeValue,
    mergeObjects,
    updateKeisokuSettingJson,
    inputNumberLimit,
    signalKanshiSettingJson,
} from '../lib/SettingUtil';

import { getFormatNowDate } from '../lib/StorageUtil';

export default function KanshiSetteiKeisokuWrapper(props) {
    const [settingValueJson, setSettingValueJson] = useState(null);

    // 変更後の設定値
    const [changeSettingValueJson, setChangeSettingValueJson] = useState(null);

    const getSettingValueJson = async (locationName) => {
        const settingJson = await getKeisokuSettingJson(locationName);
        console.log("settingJson", settingJson);
        setSettingValueJson(settingJson);
        setChangeSettingValueJson(settingJson);
    }

    // UIコンポーネントの都合上、GUNMAが来るのでSAYADOに変換する関数
    const convertLocationName = (locationName) => {
        return locationName === "GUNMA" ? "SAYADO" : locationName;
    }

    useEffect(() => {
        const searchLocationName = convertLocationName(props.location)
        getSettingValueJson(searchLocationName)
    }, [props.location]);

    useEffect(() => {
        if (props.inputSettingJson !== null && changeSettingValueJson !== null) {
            // マージする
            let mergeSettingJson = mergeObjects(changeSettingValueJson, props.inputSettingJson);

            // バリデーションチェック
            Object.entries(keisokuSettingJson).map((item, index) => {
                console.log(item[0], item[1]);
                if (item[1].type === "number") {
                    // 整数の場合
                    mergeSettingJson[item[0]] = inputNumberLimit(mergeSettingJson[item[0]], item[1].input.step, item[1].range)
                }
            });

            Object.entries(keisokuCycleSettingJson).map((item, index) => {
                console.log(item[0], item[1]);
                Object.entries(item[1]).map((itemDetail, index) => {
                    for (let key of ["1st", "2nd", "3rd"]) {
                        if (!(mergeSettingJson[item[0]][key] != null)) {
                            mergeSettingJson[item[0]][key] = null;
                        }
                        else {
                            mergeSettingJson[item[0]][key] = inputNumberLimit(mergeSettingJson[item[0]][key], 1, { min: 1, max: 3 })
                        }
                    }
                });
            });

            Object.entries(keisokuKanshiSettingJson).map((item, index) => {
                Object.entries(item[1]).map((itemDetail, index) => {
                    mergeSettingJson[item[0]][itemDetail[0]].Monitoring = mergeSettingJson[item[0]][itemDetail[0]].Monitoring == true ? true : false;

                    if (!(mergeSettingJson[item[0]][itemDetail[0]].Status_Type == "Error"
                        || mergeSettingJson[item[0]][itemDetail[0]].Status_Type == "Warning")) {
                        mergeSettingJson[item[0]][itemDetail[0]].Status_Type = "Info";
                    }

                    mergeSettingJson[item[0]][itemDetail[0]].Value = inputNumberLimit(
                        mergeSettingJson[item[0]][itemDetail[0]].Value,
                        itemDetail[1].option1.input.step, itemDetail[1].option1.range)
                });
            });

            Object.entries(signalKanshiSettingJson).map((supeana) => {
                Object.entries(supeana[1]).map((antena) => {
                    Object.entries(antena[1]).map((itemDetail, index) => {
                        mergeSettingJson[supeana[0] + "-" + antena[0]][itemDetail[0]].Monitoring = mergeSettingJson[supeana[0] + "-" + antena[0]][itemDetail[0]].Monitoring == true ? true : false;

                        if (!(mergeSettingJson[supeana[0] + "-" + antena[0]][itemDetail[0]].Status_Type == "Error"
                            || mergeSettingJson[supeana[0] + "-" + antena[0]][itemDetail[0]].Status_Type == "Warning")) {
                            mergeSettingJson[supeana[0] + "-" + antena[0]][itemDetail[0]].Status_Type = "Info";
                        }

                        mergeSettingJson[supeana[0] + "-" + antena[0]][itemDetail[0]].Value = inputNumberLimit(
                            mergeSettingJson[supeana[0] + "-" + antena[0]][itemDetail[0]].Value,
                            itemDetail[1].option1.input.step, itemDetail[1].option1.range)
                    });
                });
            });

            // マージした設定値をセットする
            setChangeSettingValueJson((prev) => {
                    return {
                        ...mergeSettingJson
                    }
                });
            }

    }, [props.inputSettingJson]);

    // ダウンロードボタンイベント
    useEffect(() => {
        console.log(props.downloadCount);

        if (props.downloadCount > 0) {
            // jsonファイルをダウンロードする
            const json = JSON.stringify(changeSettingValueJson, null, '  ');

            const blob = new Blob([json], { type: "application/json" });

            const a = document.createElement("a");

            a.href = URL.createObjectURL(blob);

            a.download = "interference-monitoring-電波干渉監視設定-" + props.displaySettingJson.location_name[convertLocationName(props.location)] + "-" + getFormatNowDate() + ".json";

            a.click();
        }
    }, [props.downloadCount]);

    // 
    const onClickSaveButton = async () => {
        console.log("onClickSaveButton", changeSettingValueJson);

        props.onCloseChangeModal();

        let saveSettingValueJson = changeSettingValueJson;

        let totalThresholdDMonitoring = false;

        // total.Threshold D.Targetを設定する
        Object.entries(changeSettingValueJson.total["Threshold D"].Target).map((item, index) => {
            saveSettingValueJson.total["Threshold D"].Target[item[0]] = changeSettingValueJson[item[0]]["Threshold C"].Monitoring;
            totalThresholdDMonitoring = totalThresholdDMonitoring || changeSettingValueJson[item[0]]["Threshold C"].Monitoring;
        })

        // total.Threshold D.Monitoringを設定する
        saveSettingValueJson.total["Threshold D"].Monitoring = totalThresholdDMonitoring && changeSettingValueJson.total["Threshold D"].Monitoring;

        console.log("saveSettingValueJson", saveSettingValueJson);

        // 設定更新
        await updateKeisokuSettingJson(convertLocationName(props.location), saveSettingValueJson);

        // 設定値を再取得
        getSettingValueJson(convertLocationName(props.location));
    }

    useEffect(() => {
        console.log("changeSettingValueJson", changeSettingValueJson);
    }, [changeSettingValueJson]);

    return (
        <>
            <Flex
                gap="16px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="center"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 40px 0px 40px"
                backgroundColor="rgba(255,255,255,1)"
            // {...getOverrideProps(overrides, "Frame 897")}
            >
                <Flex
                    gap="40px"
                    direction="column"
                    width="unset"
                    height='calc(100vh - 340px)'
                    // height="200px"
                    // height={`calc(100% - 260px)`}
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    overflow="scroll"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                // {...getOverrideProps(overrides, "Denpakansyo")}
                >
                    <Flex
                        gap="16px"
                        direction="column"
                        width="unset"
                        height="unset"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        shrink="0"
                        position="relative"
                        padding="0px 0px 0px 0px"
                    // {...getOverrideProps(overrides, "Denpa")}
                    >
                        <Text
                            fontFamily="Noto Sans"
                            fontSize="16px"
                            fontWeight="700"
                            color="rgba(53,56,59,1)"
                            lineHeight="16px"
                            textAlign="left"
                            display="block"
                            direction="column"
                            justifyContent="unset"
                            letterSpacing="0.65px"
                            width="unset"
                            height="unset"
                            gap="unset"
                            alignItems="unset"
                            shrink="0"
                            position="relative"
                            padding="0px 0px 0px 0px"
                            whiteSpace="pre-wrap"
                            children="電波計測設定"
                        // {...getOverrideProps(overrides, "text505156929")}
                        ></Text>
                        <Flex
                            gap="0"
                            direction="row"
                            width="unset"
                            height="unset"
                            justifyContent="flex-start"
                            alignItems="center"
                            shrink="0"
                            position="relative"
                            padding="0px 1px 0px 1px"
                        // {...getOverrideProps(overrides, "Frame 882")}
                        >
                            {
                                changeSettingValueJson !== null &&
                                Object.entries(keisokuSettingJson).map((item, index) => (
                                    <>
                                        {item[1].type && item[1].type === SETTING_ITEM_TYPE_INPUT ?
                                            <SetteiInputBoxWrapper
                                                key={item[0]}
                                                width={item[1].unit === SETTING_INPUTBOX_UNIT_DBI ? "170px" : "120px"}
                                                height="90px"
                                                attention={item[1].range ? "true" : "false"}
                                                attentiontext={item[1].range ? "範囲：" + item[1].range.min + "-" + item[1].range.max : ""}
                                                label={item[1].labeltext ? "true" : "false"}
                                                labelValue={item[1].labeltext}
                                                unit={item[1].unit}
                                                // inputValue={getSettingValue(item, settingValueJson[item[0]])}
                                                inputValue={convertSettingValueToInputValue(item[1].unit, changeSettingValueJson[item[0]])}
                                                inputType={item[1].input.type}
                                                {
                                                ...item[1].input.type == "number" && {
                                                    inputStep: item[1].input.step,
                                                    inputMin: item[1].input.min,
                                                    ...item[1].range !== null && {
                                                        inputMax: item[1].range.max,
                                                        inputMin: item[1].range.min,
                                                    }
                                                }
                                                }
                                                onChangeHandler={(e) => {
                                                    // let value = !isNaN(parseInt(e.target.value)) ? parseInt(e.target.value) : 0;
                                                    let value = e.target.value

                                                    console.log(value);
                                                    console.log(convertInputValueToSettingValue(item[1].unit, value));

                                                    setChangeSettingValueJson({
                                                        ...changeSettingValueJson,
                                                        [item[0]]: convertInputValueToSettingValue(item[1].unit, value)
                                                    });
                                                }}
                                                onBlurHandler={(e) => {
                                                    let value = e.target.value

                                                    // 数字の場合
                                                    if (item[1].input.type === "number") {
                                                        // 整数の場合
                                                        value = inputNumberLimit(value, item[1].input.step, item[1].range)
                                                    }

                                                    console.log(value);
                                                    setChangeSettingValueJson({
                                                        ...changeSettingValueJson,
                                                        [item[0]]: convertInputValueToSettingValue(item[1].unit, value)
                                                    });
                                                }}
                                            />
                                            :
                                            <View
                                                key={"keisokusetting" + index}
                                                width="152px"
                                                height="87px"
                                                display="block"
                                                gap="unset"
                                                alignItems="unset"
                                                justifyContent="unset"
                                                shrink="0"
                                                position="relative"
                                                padding="0px 0px 0px 0px"
                                            // {...getOverrideProps(overrides, "Frame529130765")}
                                            >
                                                <SelectField
                                                    key={"keisokusettingSelect" + index}
                                                    width="152px"
                                                    height="unset"
                                                    label={item[1].labeltext}
                                                    position="absolute"
                                                    top="0px"
                                                    left="0px"
                                                    size="small"
                                                    fontSize="0.875rem" // SelectFieldのsize=smallが効かない対策
                                                    gap={"0.25rem"} // SelectFieldのsize=smallが効かない対策
                                                    options={item[1].options}
                                                    value={convertInputValueToSelctValue(item[1].labeltext, changeSettingValueJson[item[0]])}
                                                    isDisabled={false}
                                                    labelHidden={false}
                                                    variation="default"
                                                    onChange={(e) => {
                                                        console.log(e.target.value);
                                                        console.log(convertSelectValueToInputValue(item[1].labeltext, e.target.value));
                                                        setChangeSettingValueJson({
                                                            ...changeSettingValueJson,
                                                            [item[0]]: convertSelectValueToInputValue(item[1].labeltext, e.target.value)
                                                        });
                                                    }}
                                                ></SelectField>
                                            </View>
                                        }
                                        {changeSettingValueJson !== null &&
                                            Object.entries(keisokuSettingJson).length - 1 !== index ?
                                            <Spacer
                                                key={"keisokusettingSpacer" + index}
                                                display="flex"
                                                gap="10px"
                                                direction="column"
                                                width="40px"
                                                height="unset"
                                                justifyContent="center"
                                                alignItems="center"
                                                overflow="hidden"
                                                shrink="0"
                                                alignSelf="stretch"
                                                position="relative"
                                                padding="10px 10px 10px 10px"
                                            ></Spacer>
                                            : null
                                        }
                                    </>
                                ))}
                        </Flex>
                        <Flex
                            gap="0"
                            direction="column"
                            width="unset"
                            height="unset"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            shrink="0"
                            alignSelf="stretch"
                            position="relative"
                            padding="0px 0px 0px 0px"
                        >
                            {changeSettingValueJson !== null &&
                                Object.entries(keisokuCycleSettingJson).map((item, index) => (
                                    <>
                                        <SetteiItemCycleWrapper
                                            key={"keisokucyclesetting" + index}
                                            labeltext={item[1].labeltext}
                                            cyclevalue1={changeSettingValueJson[item[0]]["1st"] !== null ? "アンテナ" + changeSettingValueJson[item[0]]["1st"] : "なし"}
                                            cyclevalue1OnChangeHandler={(e) => {
                                                console.log(e.target.value);
                                                console.log(convertSelectValueToInputValue(item[0], e.target.value));
                                                setChangeSettingValueJson({
                                                    ...changeSettingValueJson,
                                                    [item[0]]: {
                                                        ...changeSettingValueJson[item[0]],
                                                        "1st": convertSelectValueToInputValue(item[0], e.target.value)
                                                    }
                                                });
                                            }}
                                            cyclevalue2={changeSettingValueJson[item[0]]["2nd"] !== null ? "アンテナ" + changeSettingValueJson[item[0]]["2nd"] : "なし"}
                                            cyclevalue2OnChangeHandler={(e) => {
                                                console.log(e.target.value);
                                                console.log(convertSelectValueToInputValue(item[0], e.target.value));

                                                let value = convertSelectValueToInputValue(item[0], e.target.value);

                                                setChangeSettingValueJson({
                                                    ...changeSettingValueJson,
                                                    [item[0]]: {
                                                        ...changeSettingValueJson[item[0]],
                                                        "2nd": value,
                                                        ...value == null && {
                                                            "3rd": null,
                                                        }
                                                    }
                                                });
                                            }}
                                            cyclevalue3={changeSettingValueJson[item[0]]["3rd"] !== null ? "アンテナ" + changeSettingValueJson[item[0]]["3rd"] : "なし"}
                                            cyclevalue3OnChangeHandler={(e) => {
                                                console.log(e.target.value);
                                                console.log(convertSelectValueToInputValue(item[0], e.target.value));
                                                setChangeSettingValueJson({
                                                    ...changeSettingValueJson,
                                                    [item[0]]: {
                                                        ...changeSettingValueJson[item[0]],
                                                        "3rd": convertSelectValueToInputValue(item[0], e.target.value)
                                                    }
                                                });
                                            }}
                                        >
                                        </SetteiItemCycleWrapper>
                                    </>
                                ))}
                        </Flex>
                    </Flex>
                    <Flex
                        gap="16px"
                        direction="column"
                        width="unset"
                        height="unset"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        shrink="0"
                        position="relative"
                        padding="0px 0px 0px 0px"
                    // {...getOverrideProps(overrides, "Kansyoha")}
                    >
                        <Text
                            fontFamily="Noto Sans"
                            fontSize="16px"
                            fontWeight="700"
                            color="rgba(53,56,59,1)"
                            lineHeight="16px"
                            textAlign="left"
                            display="block"
                            direction="column"
                            justifyContent="unset"
                            letterSpacing="0.65px"
                            width="unset"
                            height="unset"
                            gap="unset"
                            alignItems="unset"
                            shrink="0"
                            position="relative"
                            padding="0px 0px 0px 0px"
                            whiteSpace="pre-wrap"
                            children="干渉波アラーム監視設定"
                        // {...getOverrideProps(overrides, "text505156939")}
                        ></Text>
                        <Flex
                            gap="0"
                            direction="column"
                            width="unset"
                            height="unset"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            shrink="0"
                            position="relative"
                            padding="0px 0px 0px 0px"
                        // {...getOverrideProps(overrides, "Frame 881")}
                        >
                            <Flex
                                gap="48px"
                                direction="row"
                                width="unset"
                                height="unset"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                shrink="0"
                                alignSelf="stretch"
                                position="relative"
                                padding="16px 32px 16px 32px"
                                backgroundColor="rgba(245,245,245,1)"
                            // {...getOverrideProps(overrides, "Title")}
                            >
                                <Text
                                    fontFamily="Noto Sans"
                                    fontSize="16px"
                                    fontWeight="400"
                                    color="rgba(53,56,59,1)"
                                    lineHeight="16px"
                                    textAlign="left"
                                    display="block"
                                    direction="column"
                                    justifyContent="unset"
                                    letterSpacing="0.65px"
                                    width="96px"
                                    height="unset"
                                    gap="unset"
                                    alignItems="unset"
                                    shrink="0"
                                    position="relative"
                                    padding="0px 0px 0px 0px"
                                    whiteSpace="pre-wrap"
                                    children="対象周波数"
                                // {...getOverrideProps(overrides, "text505156942")}
                                ></Text>
                                <Text
                                    fontFamily="Noto Sans"
                                    fontSize="16px"
                                    fontWeight="400"
                                    color="rgba(53,56,59,1)"
                                    lineHeight="16px"
                                    textAlign="left"
                                    display="block"
                                    direction="column"
                                    justifyContent="unset"
                                    letterSpacing="0.65px"
                                    width="160px"
                                    height="unset"
                                    gap="unset"
                                    alignItems="unset"
                                    shrink="0"
                                    position="relative"
                                    padding="0px 0px 0px 0px"
                                    whiteSpace="pre-wrap"
                                    children="監視項目"
                                // {...getOverrideProps(overrides, "text505156943")}
                                ></Text>
                                <Text
                                    fontFamily="Noto Sans"
                                    fontSize="16px"
                                    fontWeight="400"
                                    color="rgba(53,56,59,1)"
                                    lineHeight="16px"
                                    textAlign="left"
                                    display="block"
                                    direction="column"
                                    justifyContent="unset"
                                    letterSpacing="0.65px"
                                    width="120px"
                                    height="unset"
                                    gap="unset"
                                    alignItems="unset"
                                    shrink="0"
                                    position="relative"
                                    padding="0px 0px 0px 0px"
                                    whiteSpace="pre-wrap"
                                    children="有効／無効"
                                // {...getOverrideProps(overrides, "text505156944")}
                                ></Text>
                                <Text
                                    fontFamily="Noto Sans"
                                    fontSize="16px"
                                    fontWeight="400"
                                    color="rgba(53,56,59,1)"
                                    lineHeight="16px"
                                    textAlign="left"
                                    display="block"
                                    direction="column"
                                    justifyContent="unset"
                                    letterSpacing="0.65px"
                                    width="120px"
                                    height="unset"
                                    gap="unset"
                                    alignItems="unset"
                                    shrink="0"
                                    position="relative"
                                    padding="0px 0px 0px 0px"
                                    whiteSpace="pre-wrap"
                                    children="状態種別"
                                // {...getOverrideProps(overrides, "text505156945")}
                                ></Text>
                                <Text
                                    fontFamily="Noto Sans"
                                    fontSize="16px"
                                    fontWeight="400"
                                    color="rgba(53,56,59,1)"
                                    lineHeight="16px"
                                    textAlign="left"
                                    display="block"
                                    direction="column"
                                    justifyContent="unset"
                                    letterSpacing="0.65px"
                                    width="120px"
                                    height="unset"
                                    gap="unset"
                                    alignItems="unset"
                                    shrink="0"
                                    position="relative"
                                    padding="0px 0px 0px 0px"
                                    whiteSpace="pre-wrap"
                                    children="閾値"
                                // {...getOverrideProps(overrides, "text505156946")}
                                ></Text>
                            </Flex>
                            <Flex
                                gap="0"
                                direction="column"
                                width="unset"
                                // height="500px"
                                height="unset"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                overflow="hidden"
                                shrink="0"
                                position="relative"
                                padding="0px 0px 0px 0px"
                            // {...getOverrideProps(overrides, "Frame")}
                            >
                                {changeSettingValueJson !== null &&
                                    Object.entries(keisokuKanshiSettingJson).map((item, index) => (
                                        <>
                                            <Flex
                                                key={"FrameA" + index}
                                                gap="0"
                                                direction="row"
                                                width="unset"
                                                height="unset"
                                                justifyContent="flex-start"
                                                alignItems="flex-start"
                                                shrink="0"
                                                position="relative"
                                                padding="0px 0px 0px 0px"
                                            // {...getOverrideProps(overrides, "3400-3440")}
                                            >

                                                <Flex
                                                    key={"FrameB" + index}
                                                    gap="0"
                                                    direction="row"
                                                    width="unset"
                                                    height="unset"
                                                    justifyContent="center"
                                                    alignItems="flex-start"
                                                    shrink="0"
                                                    alignSelf="stretch"
                                                    position="relative"
                                                    padding="34px 32px 34px 32px"
                                                    backgroundColor="rgba(255,255,255,1)"
                                                // {...getOverrideProps(overrides, "Taisyo505156949")}
                                                >
                                                    <Text
                                                        key={"label" + index}
                                                        fontFamily="Noto Sans"
                                                        fontSize="14px"
                                                        fontWeight="700"
                                                        color="rgba(53,56,59,1)"
                                                        lineHeight="22.399999618530273px"
                                                        textAlign="left"
                                                        display="block"
                                                        direction="column"
                                                        justifyContent="unset"
                                                        letterSpacing="0.55px"
                                                        width="88px"
                                                        height="unset"
                                                        gap="unset"
                                                        alignItems="unset"
                                                        shrink="0"
                                                        position="relative"
                                                        padding="0px 0px 0px 0px"
                                                        whiteSpace="nowrap"
                                                        children={item[0] !== "total" ? item[0] : "合計"}
                                                    // {...getOverrideProps(overrides, "text505156950")}
                                                    ></Text>
                                                </Flex>
                                                <Flex
                                                    key={"FrameC" + index}
                                                    gap="0"
                                                    direction="column"
                                                    width="unset"
                                                    height="unset"
                                                    justifyContent="flex-start"
                                                    alignItems="flex-start"
                                                    shrink="0"
                                                    position="relative"
                                                    padding="0px 0px 0px 0px"
                                                // {...getOverrideProps(overrides, "Frame 880505156951")}
                                                >
                                                    {Object.entries(item[1]).map((itemDetail, index) => (
                                                        <>
                                                            <SetteiItemWrapper
                                                                key={item[0] + "setting" + index}
                                                                setteiname={itemDetail[1].labeltext}
                                                                isvalidoptions={itemDetail[1].isvalidoptions}
                                                                isvalidvalue={changeSettingValueJson[item[0]][itemDetail[0]].Monitoring ? "ON" : "OFF"}
                                                                isvalidvalueOnChange={(e) => {
                                                                    console.log(e.target.value);

                                                                    setChangeSettingValueJson({
                                                                        ...changeSettingValueJson,
                                                                        [item[0]]: {
                                                                            ...changeSettingValueJson[item[0]],
                                                                            [itemDetail[0]]: {
                                                                                ...changeSettingValueJson[item[0]][itemDetail[0]],
                                                                                Monitoring: e.target.value === "ON" ? true : false
                                                                            }
                                                                        }
                                                                    });
                                                                }}

                                                                statustypeoptions={itemDetail[1].statustypeoptions}
                                                                statustypevalue={convertStatusTypeValueToSelectValue(changeSettingValueJson[item[0]][itemDetail[0]].Status_Type)}
                                                                statustypevalueOnChange={(e) => {
                                                                    console.log(e.target.value);
                                                                    console.log(convertSelectValueToStatusTypeValue(e.target.value));

                                                                    setChangeSettingValueJson({
                                                                        ...changeSettingValueJson,
                                                                        [item[0]]: {
                                                                            ...changeSettingValueJson[item[0]],
                                                                            [itemDetail[0]]: {
                                                                                ...changeSettingValueJson[item[0]][itemDetail[0]],
                                                                                Status_Type: convertSelectValueToStatusTypeValue(e.target.value)
                                                                            }
                                                                        }
                                                                    });
                                                                }}
                                                                display1={itemDetail[1].option1 ? "flex" : "none"}
                                                                texttop1={"21px"}
                                                                label1={itemDetail[1].option1 && itemDetail[1].option1.labeltext ? "true" : "false"}
                                                                labelValue1={itemDetail[1].option1 && itemDetail[1].option1.labeltext ? itemDetail[1].option1.labeltext : ""}
                                                                attention1={itemDetail[1].option1 && itemDetail[1].option1.range ? "範囲：" + itemDetail[1].option1.range.min + "-" + itemDetail[1].option1.range.max : ""}
                                                                attentiontext1={itemDetail[1].option1 && itemDetail[1].option1.range ? "範囲：" + itemDetail[1].option1.range.min + "-" + itemDetail[1].option1.range.max : ""}
                                                                unit1={itemDetail[1].option1 && itemDetail[1].option1.unit ? itemDetail[1].option1.unit : SETTING_INPUTBOX_UNIT_NOTHING}
                                                                inputValue1={changeSettingValueJson[item[0]][itemDetail[0]].Value != null ? changeSettingValueJson[item[0]][itemDetail[0]].Value : ""}
                                                                inputType1={itemDetail[1].option1.input.type}
                                                                {
                                                                ...itemDetail[1].option1.input.type == "number" && {
                                                                    inputStep1: itemDetail[1].option1.input.step,
                                                                    ...itemDetail[1].option1.range !== null && {
                                                                        inputMax1: itemDetail[1].option1.range.max,
                                                                        inputMin1: itemDetail[1].option1.range.min,
                                                                    }
                                                                }
                                                                }
                                                                inputValue1OnChange={(e) => {
                                                                    setChangeSettingValueJson({
                                                                        ...changeSettingValueJson,
                                                                        [item[0]]: {
                                                                            ...changeSettingValueJson[item[0]],
                                                                            [itemDetail[0]]: {
                                                                                ...changeSettingValueJson[item[0]][itemDetail[0]],
                                                                                Value: e.target.value,
                                                                            }
                                                                        }
                                                                    });
                                                                }}
                                                                inputValue1OnBlur={(e) => {
                                                                    let value = e.target.value;
                                                                    console.log(value);

                                                                    // 数字の場合
                                                                    if (itemDetail[1].option1.input.type === "number") {
                                                                        // 整数の場合
                                                                        value = inputNumberLimit(value, itemDetail[1].option1.input.step, itemDetail[1].option1.range)
                                                                    }
                                                                    console.log(value);

                                                                    setChangeSettingValueJson(prev => {
                                                                        return {
                                                                            ...changeSettingValueJson,
                                                                            [item[0]]: {
                                                                                ...changeSettingValueJson[item[0]],
                                                                                [itemDetail[0]]: {
                                                                                    ...changeSettingValueJson[item[0]][itemDetail[0]],
                                                                                    Value: value,
                                                                                }
                                                                            }
                                                                        }
                                                                    });
                                                                }}
                                                                display2={itemDetail[1].option2 ? "flex" : "none"}
                                                                // texttop2={"21px"}
                                                                // label2={itemDetail[1].option2 && itemDetail[1].option2.labeltext ? "true" : "false"}
                                                                // labelValue2={itemDetail[1].option2 && itemDetail[1].option2.labeltext ? itemDetail[1].option2.labeltext : ""}
                                                                // attention2={itemDetail[1].option2 && itemDetail[1].option2.range ? "範囲：" + item[1].option2.range.min + "-" + item[1].option2.range.max : ""}
                                                                // attentiontext2={itemDetail[1].option2 && itemDetail[1].option2.range ? "範囲：" + item[1].option2.range.min + "-" + item[1].option2.range.max : ""}
                                                                // unit2={itemDetail[1].option2 && itemDetail[1].option2.unit ? itemDetail[1].option2.unit : SETTING_INPUTBOX_UNIT_NOTHING}
                                                                // inputValue2={itemDetail[1].option2 && itemDetail[1].option2.inputvalue ? itemDetail[1].option2.inputvalue : ""}
                                                                display3={itemDetail[1].option3 ? "flex" : "none"}
                                                                // texttop3={"21px"}
                                                                // label3={itemDetail[1].option3 && itemDetail[1].option3.labeltext ? "true" : "false"}
                                                                // labelValue3={itemDetail[1].option3 && itemDetail[1].option3.labeltext ? itemDetail[1].option3.labeltext : ""}
                                                                // attention3={itemDetail[1].option3 && itemDetail[1].option3.range ? "範囲：" + item[1].option3.range.min + "-" + item[1].option3.range.max : ""}
                                                                // attentiontext3={itemDetail[1].option3 && itemDetail[1].option3.range ? "範囲：" + item[1].option3.range.min + "-" + item[1].option3.range.max : ""}
                                                                // unit3={itemDetail[1].option3 && itemDetail[1].option3.unit ? itemDetail[1].option3.unit : SETTING_INPUTBOX_UNIT_NOTHING}
                                                                // inputValue3={itemDetail[1].option3 && itemDetail[1].option3.inputvalue ? itemDetail[1].option3.inputvalue : ""}
                                                                display4={itemDetail[1].option4 ? "flex" : "none"}
                                                            // texttop4={"21px"}
                                                            // label4={itemDetail[1].option4 && itemDetail[1].option4.labeltext ? "true" : "false"}
                                                            // labelValue4={itemDetail[1].option4 && itemDetail[1].option4.labeltext ? itemDetail[1].option4.labeltext : ""}
                                                            // attention4={itemDetail[1].option4 && itemDetail[1].option4.range ? "範囲：" + item[1].option4.range.min + "-" + item[1].option4.range.max : ""}
                                                            // attentiontext4={itemDetail[1].option4 && itemDetail[1].option4.range ? "範囲：" + item[1].option4.range.min + "-" + item[1].option4.range.max : ""}
                                                            // unit4={itemDetail[1].option4 && itemDetail[1].option4.unit ? itemDetail[1].option4.unit : SETTING_INPUTBOX_UNIT_NOTHING}
                                                            // inputValue4={itemDetail[1].option4 && itemDetail[1].option4.inputvalue ? itemDetail[1].option4.inputvalue : ""}
                                                            // dispdivider={Object.entries(item[1]).length - 1 !== index ? true : false}
                                                            // {...getOverrideProps(overrides, "Settei/Item505156952")}
                                                            ></SetteiItemWrapper>
                                                        </>
                                                    ))}
                                                </Flex>

                                            </Flex>
                                            <CommonDivider
                                                key={"divider" + index}
                                                display="flex"
                                                gap="0"
                                                direction="row"
                                                width="unset"
                                                height="1px"
                                                justifyContent="center"
                                                alignItems="center"
                                                overflow="hidden"
                                                shrink="0"
                                                alignSelf="stretch"
                                                position="relative"
                                                padding="0px 0px 0px 0px"
                                            // {...getOverrideProps(overrides, "Common/Divider505156955")}
                                            ></CommonDivider>
                                        </>
                                    ))}
                            </Flex>
                        </Flex>
                    </Flex>

                    <Flex
                        gap="16px"
                        direction="column"
                        width="unset"
                        height="unset"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        shrink="0"
                        position="relative"
                        padding="0px 0px 0px 0px"
                    // {...getOverrideProps(overrides, "Kansyoha")}
                    >
                        <Text
                            fontFamily="Noto Sans"
                            fontSize="16px"
                            fontWeight="700"
                            color="rgba(53,56,59,1)"
                            lineHeight="16px"
                            textAlign="left"
                            display="block"
                            direction="column"
                            justifyContent="unset"
                            letterSpacing="0.65px"
                            width="unset"
                            height="unset"
                            gap="unset"
                            alignItems="unset"
                            shrink="0"
                            position="relative"
                            padding="0px 0px 0px 0px"
                            whiteSpace="pre-wrap"
                            children="校正経路監視"
                        // {...getOverrideProps(overrides, "text505156939")}
                        ></Text>
                        <Flex
                            gap="0"
                            direction="column"
                            width="unset"
                            height="unset"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            shrink="0"
                            position="relative"
                            padding="0px 0px 0px 0px"
                        // {...getOverrideProps(overrides, "Frame 881")}
                        >
                            <Flex
                                gap="48px"
                                direction="row"
                                width="unset"
                                height="unset"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                shrink="0"
                                alignSelf="stretch"
                                position="relative"
                                padding="16px 32px 16px 32px"
                                backgroundColor="rgba(245,245,245,1)"
                            // {...getOverrideProps(overrides, "Title")}
                            >
                                <Text
                                    fontFamily="Noto Sans"
                                    fontSize="16px"
                                    fontWeight="400"
                                    color="rgba(53,56,59,1)"
                                    lineHeight="16px"
                                    textAlign="left"
                                    display="block"
                                    direction="column"
                                    justifyContent="unset"
                                    letterSpacing="0.65px"
                                    width="96px"
                                    height="unset"
                                    gap="unset"
                                    alignItems="unset"
                                    shrink="0"
                                    position="relative"
                                    padding="0px 0px 0px 0px"
                                    whiteSpace="pre-wrap"
                                    children="スペアナ"
                                // {...getOverrideProps(overrides, "text505156942")}
                                ></Text>
                                <Text
                                    fontFamily="Noto Sans"
                                    fontSize="16px"
                                    fontWeight="400"
                                    color="rgba(53,56,59,1)"
                                    lineHeight="16px"
                                    textAlign="left"
                                    display="block"
                                    direction="column"
                                    justifyContent="unset"
                                    letterSpacing="0.65px"
                                    width="160px"
                                    height="unset"
                                    gap="unset"
                                    alignItems="unset"
                                    shrink="0"
                                    position="relative"
                                    padding="0px 0px 0px 0px"
                                    whiteSpace="pre-wrap"
                                    children="アンテナ"
                                // {...getOverrideProps(overrides, "text505156943")}
                                ></Text>
                                <Text
                                    fontFamily="Noto Sans"
                                    fontSize="16px"
                                    fontWeight="400"
                                    color="rgba(53,56,59,1)"
                                    lineHeight="16px"
                                    textAlign="left"
                                    display="block"
                                    direction="column"
                                    justifyContent="unset"
                                    letterSpacing="0.65px"
                                    width="120px"
                                    height="unset"
                                    gap="unset"
                                    alignItems="unset"
                                    shrink="0"
                                    position="relative"
                                    padding="0px 0px 0px 0px"
                                    whiteSpace="pre-wrap"
                                    children="有効／無効"
                                // {...getOverrideProps(overrides, "text505156944")}
                                ></Text>
                                <Text
                                    fontFamily="Noto Sans"
                                    fontSize="16px"
                                    fontWeight="400"
                                    color="rgba(53,56,59,1)"
                                    lineHeight="16px"
                                    textAlign="left"
                                    display="block"
                                    direction="column"
                                    justifyContent="unset"
                                    letterSpacing="0.65px"
                                    width="120px"
                                    height="unset"
                                    gap="unset"
                                    alignItems="unset"
                                    shrink="0"
                                    position="relative"
                                    padding="0px 0px 0px 0px"
                                    whiteSpace="pre-wrap"
                                    children="状態種別"
                                // {...getOverrideProps(overrides, "text505156945")}
                                ></Text>
                                <Text
                                    fontFamily="Noto Sans"
                                    fontSize="16px"
                                    fontWeight="400"
                                    color="rgba(53,56,59,1)"
                                    lineHeight="16px"
                                    textAlign="left"
                                    display="block"
                                    direction="column"
                                    justifyContent="unset"
                                    letterSpacing="0.65px"
                                    width="120px"
                                    height="unset"
                                    gap="unset"
                                    alignItems="unset"
                                    shrink="0"
                                    position="relative"
                                    padding="0px 0px 0px 0px"
                                    whiteSpace="pre-wrap"
                                    children="閾値"
                                // {...getOverrideProps(overrides, "text505156946")}
                                ></Text>
                            </Flex>
                            <Flex
                                gap="0"
                                direction="column"
                                width="unset"
                                // height="500px"
                                height="unset"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                overflow="hidden"
                                shrink="0"
                                position="relative"
                                padding="0px 0px 0px 0px"
                            // {...getOverrideProps(overrides, "Frame")}
                            >
                                {changeSettingValueJson !== null &&
                                    Object.entries(signalKanshiSettingJson).map((supeana, index) => (
                                        <>
                                            <Flex
                                                key={"FrameA" + index}
                                                gap="0"
                                                direction="row"
                                                width="unset"
                                                height="unset"
                                                justifyContent="flex-start"
                                                alignItems="flex-start"
                                                shrink="0"
                                                position="relative"
                                                padding="0px 0px 0px 0px"
                                            // {...getOverrideProps(overrides, "3400-3440")}
                                            >
                                                <Flex
                                                    key={"FrameB" + index}
                                                    gap="0"
                                                    direction="row"
                                                    width="unset"
                                                    height="unset"
                                                    justifyContent="center"
                                                    alignItems="flex-start"
                                                    shrink="0"
                                                    alignSelf="stretch"
                                                    position="relative"
                                                    padding="34px 32px 34px 32px"
                                                    backgroundColor="rgba(255,255,255,1)"
                                                // {...getOverrideProps(overrides, "Taisyo505156949")}
                                                >
                                                    <Text
                                                        key={"label" + index}
                                                        fontFamily="Noto Sans"
                                                        fontSize="14px"
                                                        fontWeight="700"
                                                        color="rgba(53,56,59,1)"
                                                        lineHeight="22.399999618530273px"
                                                        textAlign="left"
                                                        display="block"
                                                        direction="column"
                                                        justifyContent="unset"
                                                        letterSpacing="0.55px"
                                                        width="88px"
                                                        height="unset"
                                                        gap="unset"
                                                        alignItems="unset"
                                                        shrink="0"
                                                        position="relative"
                                                        padding="0px 0px 0px 0px"
                                                        whiteSpace="nowrap"
                                                        children={supeana[0] == "S1" ? "スペアナ１" : "スペアナ２"}
                                                    // {...getOverrideProps(overrides, "text505156950")}
                                                    ></Text>
                                                </Flex>
                                                <Flex
                                                    key={"FrameC" + index}
                                                    gap="0"
                                                    direction="column"
                                                    width="unset"
                                                    height="unset"
                                                    justifyContent="flex-start"
                                                    alignItems="flex-start"
                                                    shrink="0"
                                                    position="relative"
                                                    padding="0px 0px 0px 0px"
                                                // {...getOverrideProps(overrides, "Frame 880505156951")}
                                                >
                                                    {Object.entries(supeana[1]).map((antena, index) => (
                                                        Object.entries(antena[1]).map((itemDetail, index) => (
                                                            <>
                                                                <SetteiItemWrapper
                                                                    key={supeana[0] + "-" + antena[0] + "setting" + index}
                                                                    setteiname={itemDetail[1].labeltext}
                                                                    isvalidoptions={itemDetail[1].isvalidoptions}
                                                                    isvalidvalue={changeSettingValueJson[supeana[0] + "-" + antena[0]][itemDetail[0]].Monitoring != null && changeSettingValueJson[supeana[0] + "-" + antena[0]][itemDetail[0]].Monitoring ? "ON" : "OFF"}
                                                                    isvalidvalueOnChange={(e) => {
                                                                        console.log(e.target.value);

                                                                        setChangeSettingValueJson({
                                                                            ...changeSettingValueJson,
                                                                            [supeana[0] + "-" + antena[0]]: {
                                                                                ...changeSettingValueJson[supeana[0] + "-" + antena[0]],
                                                                                [itemDetail[0]]: {
                                                                                    ...changeSettingValueJson[supeana[0] + "-" + antena[0]][itemDetail[0]],
                                                                                    Monitoring: e.target.value === "ON" ? true : false
                                                                                }
                                                                            }
                                                                        });
                                                                    }}

                                                                    statustypeoptions={itemDetail[1].statustypeoptions}
                                                                    statustypevalue={convertStatusTypeValueToSelectValue(changeSettingValueJson[supeana[0] + "-" + antena[0]][itemDetail[0]].Status_Type)}
                                                                    statustypevalueOnChange={(e) => {
                                                                        console.log(e.target.value);
                                                                        console.log(convertSelectValueToStatusTypeValue(e.target.value));

                                                                        setChangeSettingValueJson({
                                                                            ...changeSettingValueJson,
                                                                            [supeana[0] + "-" + antena[0]]: {
                                                                                ...changeSettingValueJson[supeana[0] + "-" + antena[0]],
                                                                                [itemDetail[0]]: {
                                                                                    ...changeSettingValueJson[supeana[0] + "-" + antena[0]][itemDetail[0]],
                                                                                    Status_Type: convertSelectValueToStatusTypeValue(e.target.value)
                                                                                }
                                                                            }
                                                                        });
                                                                    }}
                                                                    display1={itemDetail[1].option1 ? "flex" : "none"}
                                                                    texttop1={"21px"}
                                                                    label1={itemDetail[1].option1 && itemDetail[1].option1.labeltext ? "true" : "false"}
                                                                    labelValue1={itemDetail[1].option1 && itemDetail[1].option1.labeltext ? itemDetail[1].option1.labeltext : ""}
                                                                    attention1={itemDetail[1].option1 && itemDetail[1].option1.range ? "範囲：" + itemDetail[1].option1.range.min + "-" + itemDetail[1].option1.range.max : ""}
                                                                    attentiontext1={itemDetail[1].option1 && itemDetail[1].option1.range ? "範囲：" + itemDetail[1].option1.range.min + "-" + itemDetail[1].option1.range.max : ""}
                                                                    unit1={itemDetail[1].option1 && itemDetail[1].option1.unit ? itemDetail[1].option1.unit : SETTING_INPUTBOX_UNIT_NOTHING}
                                                                    inputValue1={changeSettingValueJson[supeana[0] + "-" + antena[0]][itemDetail[0]].Value != null ? changeSettingValueJson[supeana[0] + "-" + antena[0]][itemDetail[0]].Value : ""}
                                                                    inputType1={itemDetail[1].option1.input.type}
                                                                    {
                                                                    ...itemDetail[1].option1.input.type == "number" && {
                                                                        inputStep1: itemDetail[1].option1.input.step,
                                                                        ...itemDetail[1].option1.range !== null && {
                                                                            inputMax1: itemDetail[1].option1.range.max,
                                                                            inputMin1: itemDetail[1].option1.range.min,
                                                                        }
                                                                    }
                                                                    }
                                                                    inputValue1OnChange={(e) => {
                                                                        setChangeSettingValueJson({
                                                                            ...changeSettingValueJson,
                                                                            [supeana[0] + "-" + antena[0]]: {
                                                                                ...changeSettingValueJson[supeana[0] + "-" + antena[0]],
                                                                                [itemDetail[0]]: {
                                                                                    ...changeSettingValueJson[supeana[0] + "-" + antena[0]][itemDetail[0]],
                                                                                    Value: e.target.value,
                                                                                }
                                                                            }
                                                                        });
                                                                    }}
                                                                    inputValue1OnBlur={(e) => {
                                                                        let value = e.target.value;
                                                                        console.log(value);

                                                                        // 数字の場合
                                                                        if (itemDetail[1].option1.input.type === "number") {
                                                                            // 整数の場合
                                                                            value = inputNumberLimit(value, itemDetail[1].option1.input.step, itemDetail[1].option1.range)
                                                                        }
                                                                        console.log(value);

                                                                        setChangeSettingValueJson(prev => {
                                                                            return {
                                                                                ...changeSettingValueJson,
                                                                                [supeana[0] + "-" + antena[0]]: {
                                                                                    ...changeSettingValueJson[supeana[0] + "-" + antena[0]],
                                                                                    [itemDetail[0]]: {
                                                                                        ...changeSettingValueJson[supeana[0] + "-" + antena[0]][itemDetail[0]],
                                                                                        Value: value,
                                                                                    }
                                                                                }
                                                                            }
                                                                        });
                                                                    }}
                                                                    display2={itemDetail[1].option2 ? "flex" : "none"}
                                                                    display3={itemDetail[1].option3 ? "flex" : "none"}
                                                                    display4={itemDetail[1].option4 ? "flex" : "none"}
                                                                ></SetteiItemWrapper>
                                                            </>
                                                        ))))}
                                                </Flex>

                                            </Flex>
                                            <CommonDivider
                                                key={"divider" + index}
                                                display="flex"
                                                gap="0"
                                                direction="row"
                                                width="unset"
                                                height="1px"
                                                justifyContent="center"
                                                alignItems="center"
                                                overflow="hidden"
                                                shrink="0"
                                                alignSelf="stretch"
                                                position="relative"
                                                padding="0px 0px 0px 0px"
                                            // {...getOverrideProps(overrides, "Common/Divider505156955")}
                                            ></CommonDivider>
                                        </>
                                    ))}
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>


            {/* 設定変更確認モーダル */}
            <CustomModal
                open={props.openChangeModal}
                onClose={props.onCloseChangeModal}
            >
                <CheckWrapper
                    msg={props.displaySettingJson.location_name[convertLocationName(props.location)] + "の電波干渉監視設定を変更します。よろしいですか？"}
                    onClickOk={onClickSaveButton}
                    onClickCancel={props.onCloseChangeModal}
                />
            </CustomModal>
        </>
    );
}