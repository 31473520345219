// // // // // // // // // // // 
// パトライトを制御するコンテキスト
// // // // // // // // // // // 

// react
import {createContext, useContext} from 'react'

// envUtil
import { isLocalMode } from "../lib/envUtil";

// パトライトを制御するカスタムフック
import { useControlPatlite } from "../hooks/useControlPatlite";

// ユーザーコンテキスト
import { UserContext } from '../UserContext';

// コンテキストの作成
const ControlPatliteContext = createContext();

// パトライトを制御するコンテキスト
const ControlPatliteProvider = ({ children }) => {
    // ユーザーコンテキストから取得したユーザー情報
    const { userInfo } = useContext(UserContext);

    // パトライトを制御するカスタムフック
    const { patliteState, patlitePullDownState, onClickPatlite, onClickPatlitePullDown, setPatlitePullDownState } = useControlPatlite(!isLocalMode && userInfo.isAdminOrg)

    return (
        <ControlPatliteContext.Provider value={{patliteState, patlitePullDownState, onClickPatlite, onClickPatlitePullDown, setPatlitePullDownState}}>
            {children}
        </ControlPatliteContext.Provider>
    )
}

export default ControlPatliteProvider

// 作成したContextを外部から使いやすいようにする
export const useControlPatliteContext = () => useContext(ControlPatliteContext);