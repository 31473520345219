/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "@aws-amplify/ui-react/internal";
import { Flex, Text, View } from "@aws-amplify/ui-react";
import Myicons from "./Myicons";
export default function CommonBadgeHeader(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: {
        text: { width: "48px", children: "\u9589\u3058\u308B" },
        "Frame 333": { left: "-12px", display: "flex" },
        Myicons: { type: "cross" },
        CommonBadgeHeader: {},
      },
      variantValues: { situation: "hover", type: "cross" },
    },
    {
      overrides: {
        text: {},
        "Frame 333": {},
        Myicons: { type: "cross" },
        CommonBadgeHeader: { backgroundColor: "rgba(245,245,245,1)" },
      },
      variantValues: { situation: "default", type: "cross" },
    },
    {
      overrides: {
        text: { width: "48px", children: "\u3082\u3069\u308B" },
        "Frame 333": { left: "-12px", display: "flex" },
        Myicons: { type: "ArrowLeft" },
        CommonBadgeHeader: {},
      },
      variantValues: { situation: "hover", type: "Back" },
    },
    {
      overrides: {
        text: {},
        "Frame 333": {},
        Myicons: { type: "ArrowLeft" },
        CommonBadgeHeader: { backgroundColor: "rgba(245,245,245,1)" },
      },
      variantValues: { situation: "default", type: "Back" },
    },
    {
      overrides: {
        text: { width: "48px", children: "\u30DB\u30FC\u30E0" },
        "Frame 333": { left: "-12px", display: "flex" },
        Myicons: { type: "home" },
        CommonBadgeHeader: { backgroundColor: "rgba(245,245,245,1)" },
      },
      variantValues: { situation: "hover", type: "home" },
    },
    {
      overrides: {
        text: { width: "48px", children: "\u30DB\u30FC\u30E0" },
        "Frame 333": { left: "-12px" },
        Myicons: { type: "home" },
        CommonBadgeHeader: {},
      },
      variantValues: { situation: "default", type: "home" },
    },
    {
      overrides: {
        text: {
          width: "75px",
          height: "unset",
          children: "\u30C7\u30FC\u30BF\u7BA1\u7406",
        },
        "Frame 333": { left: "-25px", display: "flex" },
        Myicons: { type: "management" },
        CommonBadgeHeader: { backgroundColor: "rgba(245,245,245,1)" },
      },
      variantValues: { situation: "hover", type: "datakanri" },
    },
    {
      overrides: {
        text: {
          width: "75px",
          height: "unset",
          children: "\u30C7\u30FC\u30BF\u7BA1\u7406",
        },
        "Frame 333": { left: "-25px" },
        Myicons: { type: "management" },
        CommonBadgeHeader: {},
      },
      variantValues: { situation: "default", type: "datakanri" },
    },
    {
      overrides: {
        text: {},
        "Frame 333": { display: "flex" },
        Myicons: {},
        CommonBadgeHeader: { backgroundColor: "rgba(245,245,245,1)" },
      },
      variantValues: { situation: "hover", type: "setting" },
    },
    {
      overrides: {
        text: {},
        "Frame 333": {},
        Myicons: {},
        CommonBadgeHeader: {},
      },
      variantValues: { situation: "default", type: "setting" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <View
      width="40px"
      height="40px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      position="relative"
      borderRadius="20px"
      padding="0px 0px 0px 0px"
      backgroundColor="rgba(255,255,255,1)"
      {...rest}
      {...getOverrideProps(overrides, "CommonBadgeHeader")}
    >
      <Flex
        gap="10px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        position="absolute"
        top="42px"
        left="-47px"
        border="1px SOLID rgba(217,217,217,1)"
        padding="7px 7px 7px 7px"
        backgroundColor="rgba(255,255,255,1)"
        display="none"
        {...getOverrideProps(overrides, "Frame 333")}
      >
        <Text
          fontFamily="Noto Sans"
          fontSize="12px"
          fontWeight="500"
          color="rgba(53,56,59,1)"
          lineHeight="12px"
          textAlign="center"
          display="block"
          direction="column"
          justifyContent="unset"
          letterSpacing="0.45px"
          width="116px"
          height="12px"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="アラーム管理設定"
          {...getOverrideProps(overrides, "text")}
        ></Text>
      </Flex>
      <Myicons
        width="24px"
        height="24px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        overflow="hidden"
        position="absolute"
        top="8px"
        left="8px"
        padding="0px 0px 0px 0px"
        type="setting"
        {...getOverrideProps(overrides, "Myicons")}
      ></Myicons>
    </View>
  );
}
