import { useState } from 'react'
import {
    DatakanriListFile,
    CommonBadgeIcon,
} from '../ui-components'
import Myicons from '../ui-components/Myicons'

import { SORT } from '../DataKanriMain'
import { Flex } from '@aws-amplify/ui-react';

export default function DatakanriListTitleWrapper({
    check,
    isIndeterminate,
    onClickAllSelect,
    sortCol,
    sortDirection,
    onSortBy,
    displayCheckbox,
}) {
    // ソートボタンを表示するコンポーネント
    const SortByButton = ({ col, children }) => {
        const [situation, setSituation] = useState("default");
        function onMouseEventEnter(){
            setSituation("hover")
        }
    
        function onMouseEventLeave(){
            setSituation("default")
        }

        return (
            <Flex
                // justifyContent="flex-start"
                alignItems="center"
                style={{
                    cursor: "pointer",
                }}
            >
                <span>{children}</span>

                <CommonBadgeIcon
                    display={col == sortCol ? "flex" : "none"}
                    situation={situation}
                    type="white"
                    padding={"5px"}
                    onMouseEnter={onMouseEventEnter}
                    onMouseLeave={onMouseEventLeave}
                    overrides={{
                        Myicons: {
                            padding: "2px",
                            position: "relative",
                            type: sortDirection == SORT.direction.DESC ? "SortUp" : "SortDown",
                        },
                    }}
                ></CommonBadgeIcon>
            </Flex>
        );
    };

    return (
        <DatakanriListFile
            width={"100%"}
            minWidth={"1355px"}
            overrides={{
                FileName: {
                    onClick: (e) => {
                        e.stopPropagation();

                        onSortBy(SORT.COL.NAME);
                    }
                },
                "Common/Badge/Icon577027501": {
                    display: "none",
                },
                text480814280: {
                    children: (
                        <SortByButton col={SORT.COL.NAME}>ファイル名</SortByButton>
                    )
                },
                Date: {
                    onClick: (e) => {
                        e.stopPropagation();

                        onSortBy(SORT.COL.LAST_MODIFIED);
                    },
                    position: "unset",
                    height: "unset",
                },
                text480814283: {
                    position: "unset",
                    height: "unset",
                    children: (
                        <SortByButton col={SORT.COL.LAST_MODIFIED}>最終更新日</SortByButton>
                    )
                },
                CheckboxField: {
                    ...!displayCheckbox && {
                        display: "none",
                    },
                    isIndeterminate: isIndeterminate,
                    onClick: (e) => {
                        e.stopPropagation();
                    },
                    onChange: (e) => {
                        e.stopPropagation();
                        onClickAllSelect(e.target.checked);
                    },
                    checked: check,
                }
            }}
            type="Title"
            check={check ? "on" : "off"}
        ></DatakanriListFile>
    );
}