/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "@aws-amplify/ui-react/internal";
import { Flex, Text } from "@aws-amplify/ui-react";
import CommonBtnBlue from "./CommonBtnBlue";
import CommonBtnWhite from "./CommonBtnWhite";
export default function Check(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: {
        text: {},
        "Common/Btn/Blue": {},
        "Common/Btn/White": {},
        "Frame 744": {},
        Check: {},
      },
      variantValues: { cancel: "true" },
    },
    {
      overrides: {
        text: {},
        "Common/Btn/Blue": {},
        "Common/Btn/White": { display: "none" },
        "Frame 744": {},
        Check: {},
      },
      variantValues: { cancel: "false" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="80px"
      direction="column"
      width="unset"
      height="unset"
      justifyContent="center"
      alignItems="center"
      position="relative"
      borderRadius="20px"
      padding="80px 80px 80px 80px"
      backgroundColor="rgba(255,255,255,1)"
      display="flex"
      {...rest}
      {...getOverrideProps(overrides, "Check")}
    >
      <Text
        fontFamily="Noto Sans"
        fontSize="20px"
        fontWeight="500"
        color="rgba(53,56,59,1)"
        lineHeight="28px"
        textAlign="left"
        display="block"
        direction="column"
        justifyContent="unset"
        letterSpacing="0.85px"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="選択した項目を確認済に変更します"
        {...getOverrideProps(overrides, "text")}
      ></Text>
      <Flex
        gap="16px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        display="flex"
        {...getOverrideProps(overrides, "Frame 744")}
      >
        <CommonBtnBlue
          display="flex"
          gap="10px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          position="relative"
          borderRadius="8px"
          padding="10px 24px 10px 24px"
          backgroundColor="rgba(0,153,255,1)"
          situation="default"
          size="large"
          {...getOverrideProps(overrides, "Common/Btn/Blue")}
        ></CommonBtnBlue>
        <CommonBtnWhite
          display="flex"
          gap="10px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          border="1px SOLID rgba(0,153,255,1)"
          borderRadius="8px"
          padding="9px 23px 9px 23px"
          backgroundColor="rgba(255,255,255,1)"
          situation="default"
          {...getOverrideProps(overrides, "Common/Btn/White")}
        ></CommonBtnWhite>
      </Flex>
    </Flex>
  );
}
