import React, { useMemo } from 'react'
import { Flex, Text } from "@aws-amplify/ui-react";
import {
    KanshiAntennaFrame,
    KanshiAntennaTotal
} from '../ui-components'

import {
    KanshiAntennaFrameWrapper
} from './'

export default function KanshiKyotenAntennaStateWrapper(props) {

    // 経路監視状態を表示ステータスを計算する関数
    const clacSignalState = () => {
        const speAnaKeyList = [
            "S1-A1",
            "S1-A2",
            "S1-A3",
            "S2-A1",
            "S2-A2",
            "S2-A3"
        ]

        const signalStateList = speAnaKeyList.map((key) => {
            return props.waveMoniteringStatus[key]["signal"].state
        })

        console.log("signalStateList", signalStateList);

        if (signalStateList.some((state) => state == "Error")) {
            return "Error"
        }

        if (signalStateList.some((state) => state == "Warning")) {
            return "Warning"
        }

        if (signalStateList.every((state) => state == "None")) {
            return "None"
        }

        return 'Default'
    }

    return (
        <>
            <Flex
                gap="56px"
                direction="column"
                width="unset"
                height="648px"
                justifyContent="flex-start"
                alignItems="flex-start"
                shrink="0"
                position="relative"
                padding="0px 40px 0px 40px"
                backgroundColor="rgba(245,245,245,1)"
                style={{
                    flexGrow: 1,
                }}
            // {...getOverrideProps(overrides, "Frame 894")}
            >
                <KanshiAntennaFrameWrapper
                    width="992px"
                    height="255px"
                    display="block"
                    gap="unset"
                    alignItems="unset"
                    justifyContent="unset"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    displaySettingJson={props.displaySettingJson}
                    waveMoniteringStatus={props.waveMoniteringStatus}
                // {...getOverrideProps(overrides, "Kanshi/Antenna/Frame")}
                ></KanshiAntennaFrameWrapper>
                <Flex
                    gap="48px"
                    direction="column"
                    width="unset"
                    height="unset"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                // {...getOverrideProps(overrides, "Total505135309")}
                >
                    <Flex
                        gap="16px"
                        direction="column"
                        width="unset"
                        height="unset"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        shrink="0"
                        position="relative"
                        padding="0px 0px 0px 0px"
                    // {...getOverrideProps(overrides, "Total505135310")}
                    >
                        <Flex
                            gap="16px"
                            direction="row"
                            width="unset"
                            height="unset"
                            justifyContent="flex-start"
                            alignItems="flex-end"
                            shrink="0"
                            alignSelf="stretch"
                            position="relative"
                            padding="0px 0px 0px 0px"
                            whiteSpace="pre-wrap"
                            children="(合計値判定が有効になっている周波数帯域のみ対象)"
                        // {...getOverrideProps(overrides, "Frame 855")}
                        >
                            <Text
                                fontFamily="Noto Sans"
                                fontSize="16px"
                                fontWeight="400"
                                color="rgba(53,56,59,1)"
                                lineHeight="16px"
                                textAlign="left"
                                display="block"
                                direction="column"
                                justifyContent="unset"
                                letterSpacing="0.65px"
                                width="40px"
                                height="unset"
                                gap="unset"
                                alignItems="unset"
                                shrink="0"
                                position="relative"
                                padding="0px 0px 0px 0px"
                                whiteSpace="pre-wrap"
                                children="合計"
                            // {...getOverrideProps(overrides, "text505135312")}
                            ></Text>
                            <Text
                                fontFamily="Noto Sans"
                                fontSize="14px"
                                fontWeight="400"
                                color="rgba(100,100,100,1)"
                                lineHeight="14px"
                                textAlign="left"
                                display="block"
                                direction="column"
                                justifyContent="unset"
                                letterSpacing="0.55px"
                                width="unset"
                                height="unset"
                                gap="unset"
                                alignItems="unset"
                                shrink="0"
                                position="relative"
                                padding="0px 0px 0px 0px"
                                whiteSpace="pre-wrap"
                                children="(合計対象は上記A/B/Cの判定のうち１つでも有効になっている周波数帯が対象)"
                            // {...getOverrideProps(overrides, "text505135313")}
                            ></Text>
                        </Flex>
                        <KanshiAntennaTotal
                            display="flex"
                            gap="16px"
                            direction="row"
                            width="unset"
                            height="unset"
                            justifyContent="flex-start"
                            alignItems="center"
                            shrink="0"
                            position="relative"
                            padding="20px 16px 20px 16px"
                            backgroundColor="rgba(255,255,255,1)"
                            overrides={{
                                "Kanshi/Antenna/indicator": {
                                    type: props.waveMoniteringStatus["total"]["Threshold D"].state,
                                },
                                "text": {
                                    children: "D：合計値判定",
                                }
                            }}
                        // {...getOverrideProps(overrides, "Kanshi/Antenna/Total")}
                        ></KanshiAntennaTotal>
                    </Flex>
                    <Flex
                        gap="16px"
                        direction="column"
                        width="unset"
                        height="unset"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        shrink="0"
                        position="relative"
                        padding="0px 0px 0px 0px"
                        style={{
                            // display: "none",
                        }}
                    >
                        <Flex
                            gap="16px"
                            direction="row"
                            width="unset"
                            height="unset"
                            justifyContent="flex-start"
                            alignItems="flex-end"
                            shrink="0"
                            alignSelf="stretch"
                            position="relative"
                            padding="0px 0px 0px 0px"
                        >
                            <Text
                                fontFamily="Noto Sans"
                                fontSize="16px"
                                fontWeight="400"
                                color="rgba(53,56,59,1)"
                                lineHeight="16px"
                                textAlign="left"
                                display="block"
                                direction="column"
                                justifyContent="unset"
                                letterSpacing="0.65px"
                                width="unset"
                                height="unset"
                                gap="unset"
                                alignItems="unset"
                                shrink="0"
                                position="relative"
                                padding="0px 0px 0px 0px"
                                whiteSpace="pre-wrap"
                                children="計測異常"
                            ></Text>
                            <Text
                                fontFamily="Noto Sans"
                                fontSize="14px"
                                fontWeight="400"
                                color="rgba(100,100,100,1)"
                                lineHeight="14px"
                                textAlign="left"
                                display="block"
                                direction="column"
                                justifyContent="unset"
                                letterSpacing="0.55px"
                                width="unset"
                                height="unset"
                                gap="unset"
                                alignItems="unset"
                                shrink="0"
                                position="relative"
                                padding="0px 0px 0px 0px"
                                whiteSpace="pre-wrap"
                                children="（入力経路の異常を検知した際に発報）"
                            ></Text>
                        </Flex>
                        <KanshiAntennaTotal
                            display="flex"
                            gap="16px"
                            direction="row"
                            width="unset"
                            height="unset"
                            justifyContent="center"
                            alignItems="center"
                            shrink="0"
                            position="relative"
                            padding="20px 16px 20px 16px"
                            backgroundColor="rgba(255,255,255,1)"
                            overrides={{
                                "Kanshi/Antenna/indicator": {
                                    type: clacSignalState(),
                                },
                                "text": {
                                    children: "計測異常（校正）",
                                }
                            }}
                        ></KanshiAntennaTotal>
                    </Flex>
                </Flex>
            </Flex>
        </>
    )
}