import { useState, useEffect } from "react";
import { Flex } from "@aws-amplify/ui-react";

import {
    CommonTitle,
    CommonBtnRound,
} from './ui-components'

import {
    HeaderWrapper,
    KanshiLocationWrapper,
    KanshiLogAreaWrapper,
    KanshiFrameSummaryWrapper,
} from './componentsWrapper'

import {
    getDisplaySettingJson
} from './lib/SettingUtil'

import { TAB_ALL, TAB_SAYADO, TAB_SPE, TAB_SPW, TAB_YSCC } from "./lib/ConstList";

const KanshiMain = () => {
    // 現在選択中のタブ名
    const [selectedLocation, setSelectedLocation] = useState(TAB_ALL);

    // 表示ラベル設定
    const [displaySettingJson, setDisplaySettingJson] = useState({
        "location_name": {
            "YSCC": "YSCC",
            "SPE": "SPE",
            "SPW": "SPW",
            "SAYADO": "小夜戸"
        },
        "band_label": {
            "3400-3440": {
                "name": "S-1",
                "color": "#bbbcbe"
            },
            "3440-3480": {
                "name": "D-1",
                "color": "#cc0033"
            },
            "3480-3520": {
                "name": "D-2",
                "color": "#cc0033"
            },
            "3520-3560": {
                "name": "K-1",
                "color": "#eb5505"
            },
            "3560-3600": {
                "name": "S-2",
                "color": "#bbbcbe"
            },
            "3600-3700": {
                "name": "D-3",
                "color": "#cc0033"
            },
            "3700-3800": {
                "name": "K-2",
                "color": "#eb5505"
            },
            "3800-3900": {
                "name": "R-1",
                "color": "#ff00ff"
            },
            "3900-4000": {
                "name": "S-3",
                "color": "#bbbcbe"
            },
            "4000-4100": {
                "name": "K-3",
                "color": "#eb5505"
            }
        }
    });

    const initDisplaySettingJson = async () => {
        const displaySettingJson = await getDisplaySettingJson();
        if (displaySettingJson !== null) {
            setDisplaySettingJson(displaySettingJson);
        } else {
            console.log("displaySettingJson is null");
        }
    }

    useEffect(() => {
        // console.log("KanshiMain");
        // console.log("process.env.REACT_APP_TEST: ",process.env.REACT_APP_TEST)
        // console.log("process.env.REACT_APP_LOCATION_NAME: ",process.env.REACT_APP_LOCATION_NAME)
        // console.log("isLocalMode: ",isLocalMode)
        // 表示設定取得
        initDisplaySettingJson();
    }, []);

    return (
        <div
            style={{
                height: '100vh',
                display: "flex",
                flexFlow: "column"
            }}
        >
            <HeaderWrapper
            />
            <Flex
                gap="40px"
                direction="row"
                // width="1872px"
                marginLeft={"24px"}
                marginRight={"24px"}
                height="100%"
                justifyContent="space-between"
                alignItems="flex-start"
                position="relative"
                padding="0px 0px 0px 0px"
            // {...rest}
            // // {...getOverrideProps(overrides, "LayoutTougoukanshiAll")}
            >
                <Flex
                    gap="24px"
                    direction="column"
                    width="unset"
                    height="100%"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                // // {...getOverrideProps(overrides, "Frame 866")}
                >
                    <CommonTitle
                        width="240px"
                        height="18px"
                        display="block"
                        gap="unset"
                        alignItems="unset"
                        justifyContent="unset"
                        shrink="0"
                        position="relative"
                        padding="0px 0px 0px 0px"
                        size="large"
                        overrides={{
                            text: {
                                children: "統合監視",
                            }
                        }}
                    // // {...getOverrideProps(overrides, "Common/Title500220679")}
                    ></CommonTitle>
                    <Flex
                        gap="0"
                        direction="column"
                        width="unset"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        shrink="0"
                        position="relative"
                        paddingBottom={"24px"}
                        style={{
                            flexGrow: 1,
                        }}
                    // // {...getOverrideProps(overrides, "Kyoten")}
                    >
                        {displaySettingJson !== null && (
                            <KanshiLocationWrapper
                                display="flex"
                                gap="8px"
                                direction="row"
                                width="unset"
                                height="unset"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                shrink="0"
                                position="relative"
                                padding="0px 0px 0px 0px"
                                displaySettingJson={displaySettingJson}
                                selectedLocation={selectedLocation}
                                setSelectedLocation={setSelectedLocation}
                            // situation="全拠点"
                            // // {...getOverrideProps(overrides, "Kanshi/Location")}
                            ></KanshiLocationWrapper>
                        )}
                    </Flex>
                </Flex>
                <Flex
                    gap="12px"
                    direction="column"
                    width="unset"
                    height="100%"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    style={{
                        flexGrow: 1,
                    }}
                // {...getOverrideProps(overrides, "Frame 865")}
                >
                    <Flex
                        gap="371px"
                        direction="row"
                        width="unset"
                        height="unset"
                        justifyContent="space-between"
                        alignItems="flex-start"
                        shrink="0"
                        alignSelf="stretch"
                        position="relative"
                        padding="0px 0px 0px 0px"
                    // {...getOverrideProps(overrides, "Frame 864")}
                    >
                        <CommonTitle
                            width="240px"
                            height="18px"
                            display="block"
                            gap="unset"
                            alignItems="unset"
                            justifyContent="unset"
                            shrink="0"
                            position="relative"
                            padding="0px 0px 0px 0px"
                            size="large"
                            overrides={{
                                text: {
                                    children: "監視ログ",
                                }
                            }}
                        // {...getOverrideProps(overrides, "Common/Title500220741")}
                        ></CommonTitle>

                        {/* 定期レポート出力 */}
                        <KanshiFrameSummaryWrapper
                            displaySettingJson={displaySettingJson}
                        />
                    </Flex>

                    {displaySettingJson !== null && (
                        <KanshiLogAreaWrapper
                            displaySettingJson={displaySettingJson}
                            selectedLocation={selectedLocation}
                        >
                        </KanshiLogAreaWrapper>
                    )}
                </Flex>
            </Flex>
        </div>
    );

}

export default KanshiMain;