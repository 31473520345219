/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "@aws-amplify/ui-react/internal";
import { View } from "@aws-amplify/ui-react";
export default function KanshiAntennaindicator(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: { KanshiAntennaindicator: {} },
      variantValues: { type: "Default" },
    },
    {
      overrides: {
        KanshiAntennaindicator: { backgroundColor: "rgba(255,76,79,1)" },
      },
      variantValues: { type: "Error" },
    },
    {
      overrides: {
        KanshiAntennaindicator: { backgroundColor: "rgba(250,180,50,1)" },
      },
      variantValues: { type: "Warning" },
    },
    {
      overrides: {
        KanshiAntennaindicator: { backgroundColor: "rgba(170,170,170,1)" },
      },
      variantValues: { type: "None" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <View
      width="56px"
      height="16px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      position="relative"
      borderRadius="2px"
      padding="0px 0px 0px 0px"
      backgroundColor="rgba(38,193,147,1)"
      {...rest}
      {...getOverrideProps(overrides, "KanshiAntennaindicator")}
    ></View>
  );
}
