/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import CommonTitle from "./CommonTitle";
import CommonBtnBlue from "./CommonBtnBlue";
import { CheckboxField, Flex, Text } from "@aws-amplify/ui-react";
import DatakanriDataMakingDate from "./DatakanriDataMakingDate";
import CommonBtnWhite from "./CommonBtnWhite";
export default function KanshiFrameSummary(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      gap="40px"
      direction="column"
      width="unset"
      height="unset"
      justifyContent="center"
      alignItems="center"
      position="relative"
      borderRadius="20px"
      padding="40px 40px 40px 40px"
      backgroundColor="rgba(255,255,255,1)"
      {...rest}
      {...getOverrideProps(overrides, "KanshiFrameSummary")}
    >
      <Flex
        gap="96px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="space-between"
        alignItems="flex-start"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 891")}
      >
        <CommonTitle
          width="382px"
          height="18px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          size="large"
          {...getOverrideProps(overrides, "Common/Title")}
        ></CommonTitle>
        <Flex
          gap="16px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Button")}
        >
          <CommonBtnBlue
            display="flex"
            gap="10px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            position="relative"
            borderRadius="8px"
            padding="10px 24px 10px 24px"
            backgroundColor="rgba(0,153,255,1)"
            situation="default"
            size="large"
            {...getOverrideProps(overrides, "Common/Btn/Blue504225344")}
          ></CommonBtnBlue>
          <CommonBtnBlue
            display="flex"
            gap="10px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            position="relative"
            borderRadius="8px"
            padding="10px 24px 10px 24px"
            backgroundColor="rgba(0,153,255,1)"
            situation="default"
            size="large"
            {...getOverrideProps(overrides, "Common/Btn/Blue504225346")}
          ></CommonBtnBlue>
        </Flex>
      </Flex>
      <Flex
        gap="80px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="flex-end"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        border="1px SOLID rgba(210,210,210,1)"
        borderRadius="4px"
        padding="23px 39px 23px 39px"
        {...getOverrideProps(overrides, "Frame 892")}
      >
        <Flex
          gap="32px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-end"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Frame 919")}
        >
          <DatakanriDataMakingDate
            display="flex"
            gap="8px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            example={false}
            error={false}
            {...getOverrideProps(overrides, "Datakanri/DataMaking/Date")}
          ></DatakanriDataMakingDate>
          <Flex
            gap="40px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame515221836")}
          >
            <Flex
              gap="8px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Frame 766")}
            >
              <Text
                fontFamily="Inter"
                fontSize="14px"
                fontWeight="400"
                color="rgba(100,100,100,1)"
                lineHeight="21px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="対象拠点"
                {...getOverrideProps(overrides, "label515221838")}
              ></Text>
              <Flex
                gap="32px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "Frame515221920")}
              >
                <CheckboxField
                  width="unset"
                  height="unset"
                  label="YSCC"
                  gap="4px"
                  shrink="0"
                  size="small"
                  defaultChecked={true}
                  isDisabled={false}
                  labelPosition="end"
                  {...getOverrideProps(overrides, "CheckboxField515221828")}
                ></CheckboxField>
                <CheckboxField
                  width="unset"
                  height="unset"
                  label="SPE"
                  gap="4px"
                  shrink="0"
                  size="small"
                  defaultChecked={false}
                  isDisabled={false}
                  labelPosition="end"
                  {...getOverrideProps(overrides, "CheckboxField515221879")}
                ></CheckboxField>
                <CheckboxField
                  width="unset"
                  height="unset"
                  label="SPW"
                  gap="4px"
                  shrink="0"
                  size="small"
                  defaultChecked={false}
                  isDisabled={false}
                  labelPosition="end"
                  {...getOverrideProps(overrides, "CheckboxField515221906")}
                ></CheckboxField>
                <CheckboxField
                  width="unset"
                  height="unset"
                  label="小夜戸"
                  gap="4px"
                  shrink="0"
                  size="small"
                  defaultChecked={false}
                  isDisabled={false}
                  labelPosition="end"
                  {...getOverrideProps(overrides, "CheckboxField515221913")}
                ></CheckboxField>
              </Flex>
            </Flex>
            <Flex
              gap="8px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Frame 767")}
            >
              <Text
                fontFamily="Inter"
                fontSize="14px"
                fontWeight="400"
                color="rgba(100,100,100,1)"
                lineHeight="21px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="対象監視種別"
                {...getOverrideProps(overrides, "label515221845")}
              ></Text>
              <Flex
                gap="32px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "Frame515221921")}
              >
                <CheckboxField
                  width="unset"
                  height="unset"
                  label="干渉波監視"
                  gap="4px"
                  shrink="0"
                  size="small"
                  defaultChecked={true}
                  isDisabled={false}
                  labelPosition="end"
                  {...getOverrideProps(overrides, "CheckboxField515221922")}
                ></CheckboxField>
                <CheckboxField
                  width="unset"
                  height="unset"
                  label="機器監視"
                  gap="4px"
                  shrink="0"
                  size="small"
                  defaultChecked={false}
                  isDisabled={false}
                  labelPosition="end"
                  {...getOverrideProps(overrides, "CheckboxField515221923")}
                ></CheckboxField>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <CommonBtnWhite
          display="flex"
          gap="10px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          border="1px SOLID rgba(0,153,255,1)"
          borderRadius="8px"
          padding="9px 23px 9px 23px"
          backgroundColor="rgba(255,255,255,1)"
          situation="default"
          {...getOverrideProps(overrides, "Common/Btn/White")}
        ></CommonBtnWhite>
      </Flex>
      <Flex
        gap="16px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="flex-start"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame567727827")}
      >
        <Text
          fontFamily="Noto Sans"
          fontSize="20px"
          fontWeight="500"
          color="rgba(53,56,59,1)"
          lineHeight="20px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          letterSpacing="0.85px"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="集計結果"
          {...getOverrideProps(overrides, "text")}
        ></Text>
        <Flex
          width="unset"
          height="unset"
          {...getOverrideProps(overrides, "Kanshi/Frame/Summary/Result")}
        ></Flex>
      </Flex>
    </Flex>
  );
}
