import { useState ,useEffect } from "react";
import {
    KanshiLocation,
} from '../../ui-components'
import { 
    KanshiKyotenStateWrapper,
} from '../../componentsWrapper'

import { isLocalMode, localLocationName } from "../../lib/envUtil";

export default function LocalKanshiLocationWrapper(props) {

    //　拠点タブ(KanshiLocationTab)の状態定義
    const STATE_TAB_ERROR = "Error";
    const STATE_TAB_WARNING = "Warning";
    const STATE_TAB_USUAL = "Usual";

    const STATE_CARD_ERROR = "error";
    const STATE_CARD_WARNING = "warning";
    const STATE_CARD_DEFAULT = "default";

    const TAB_ALL = "全拠点";
    const TAB_YSCC = "YSCC";
    const TAB_SPE = "SPE";
    const TAB_SPW = "SPW";
    const TAB_SAYADO = "SAYADO";

    // 選択拠点タブ名
    const selectedLocation = localLocationName;

    // 干渉波及び機器ステータスの拠点毎サマリー
    const initialLocationState = () => {
        const initialData = {
            allStatus: STATE_TAB_USUAL,
            allStatusCount: 0,
            waveState: STATE_CARD_DEFAULT,
            waveStateCount: 0,
            deviceState: STATE_CARD_DEFAULT,
            deviceStateCount: 0,
        }

        return initialData
    }

    const [locationState, setLocationState] = useState(initialLocationState());

    // 機器監視ステータスデータか干渉波監視ステータスデータが更新された際に、拠点サマリーを更新
    useEffect(() => {
        const deviceAggregated = aggregateByLocationAndType(props.deviceMoniteringStatus, 'device');
        const waveAggregated = aggregateByLocationAndType(props.waveMoniteringStatus, 'wave');
        
        // console.log('deviceAggregated', deviceAggregated)
        // console.log('waveAggregated', waveAggregated)

        const mergedData = {};

        if (!mergedData) {
            mergedData = { device: { errorCount: 0, warningCount: 0 }, wave: { errorCount: 0, warningCount: 0 } };
        }
        mergedData.device = deviceAggregated.device;
        
        if (!mergedData) {
            mergedData = { device: { errorCount: 0, warningCount: 0 }, wave: { errorCount: 0, warningCount: 0 } };
        }
        mergedData.wave = waveAggregated.wave;
        
        setLocationState(transformLocationStateData(mergedData));
    }, [props.deviceMoniteringStatus, props.waveMoniteringStatus]);

    // 拠点サマリー結果からコンポーネントに受け渡すステータスデータを生成
    function transformLocationStateData(originalData){
        
        // console.log('transformLocationStateData', originalData)
    
        const waveErrorCount = originalData.wave.errorCount;
        const deviceErrorCount = originalData.device.errorCount;
        const waveWarningCount = originalData.wave.warningCount;
        const deviceWarningCount = originalData.device.warningCount;

        // 状態判定
        const waveState = waveErrorCount > 0 ? STATE_CARD_ERROR : (waveWarningCount > 0 ? STATE_CARD_WARNING : STATE_CARD_DEFAULT);
        const deviceState = deviceErrorCount > 0 ? STATE_CARD_ERROR : (deviceWarningCount > 0 ? STATE_CARD_WARNING : STATE_CARD_DEFAULT);

        let allState;
        let allStateCount;

        if (waveErrorCount + deviceErrorCount > 0) {
            allState = STATE_TAB_ERROR;
            allStateCount = waveErrorCount + deviceErrorCount; // 両方のエラーカウントの合計
        } else if (waveWarningCount + deviceWarningCount > 0) {
            allState = STATE_TAB_WARNING;
            allStateCount = waveWarningCount + deviceWarningCount; // 両方の警告カウントの合計
        } else {
            allState = STATE_TAB_USUAL;
            allStateCount = 0; // Usualの場合はカウント0
        }

        const newData = {
            allStatus: allState,
            allStatusCount: allStateCount,
            waveState: waveState,
            waveStateCount: waveErrorCount + waveWarningCount,
            deviceState: deviceState,
            deviceStateCount: deviceErrorCount + deviceWarningCount
        };
    
        return newData;
    }

    function aggregateByLocationAndType(data, type) {
        let dataArray = [];

        if (type === 'device') {
            // dataArray = Object.values(data).flatMap(locationData => Object.values(locationData));
            dataArray = Object.values(data)
        } else if (type === 'wave') {
            dataArray = Object.values(data).flatMap(bandData => Object.values(bandData));
        }
        
        // console.log('dataArray', dataArray, 'type' , type)
        return dataArray.reduce((acc, curr) => {
        //   console.log('acc', acc, 'curr', curr);
          if (!acc[type]) {
            // console.log('acc initialize')
            acc = { device: { errorCount: 0, warningCount: 0 }, wave: { errorCount: 0, warningCount: 0 } };
          }
    
          if (curr.state === "Error") {
            acc[type].errorCount += 1;
          } else if (curr.state === "Warning") {
            acc[type].warningCount += 1;
          }
    
          return acc;
        }, {});
      }; 

    return (
        <>
        <KanshiLocation  width={"40%"} children={""}
            overrides= {{
            "Kanshi/Location/Tab36163935": {
                display: !isLocalMode ? "flex" : "none",
            },
            "Kanshi/Location/Tab36163938": {
                display: "flex",
                type:locationState.allStatus,
                situation:"Select",
                overrides: {
                    text438115330: {
                        children: props.displaySettingJson.location_name[localLocationName],
                    },
                    text438115332: {
                        lineHeight: "16px",
                        children: locationState.allStatusCount,
                    },
                }
            },
            "Kanshi/Location/Tab36163942": {
                display: "none",
            },
            "Kanshi/Location/Tab36163944": {
                display: "none",
            },
            "Kanshi/Location/Tab36163946": {
                display: "none",
            },
            KanshiLocation: {},
            }}
        ></KanshiLocation>
            <KanshiKyotenStateWrapper
            displaySettingJson={props.displaySettingJson}
            location={selectedLocation}
            waveState={locationState.waveState}
            deviceState={locationState.deviceState}
            deviceMoniteringStatus={props.deviceMoniteringStatus}
            waveMoniteringStatus={props.waveMoniteringStatus}
            >
            </KanshiKyotenStateWrapper>
        </>
    );
}