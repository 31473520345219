/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "@aws-amplify/ui-react/internal";
import { Icon, View } from "@aws-amplify/ui-react";
export default function Loading(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: { Line: {}, Ellipse: {}, Circle: {}, Loading: {} },
      variantValues: { type: "0" },
    },
    {
      overrides: {
        Line: {},
        Ellipse: {
          viewBox: {
            minX: 0,
            minY: 0,
            width: 8.000000349691163,
            height: 8.000000349691163,
          },
        },
        Circle: {
          left: "calc(50% - 16px - -32px)",
          transformOrigin: "top left",
          transform: "rotate(90deg)",
        },
        Loading: {},
      },
      variantValues: { type: "90" },
    },
    {
      overrides: {
        Line: {},
        Ellipse: {
          viewBox: {
            minX: 0,
            minY: 0,
            width: 8.000000699382326,
            height: 8.000000699382213,
          },
        },
        Circle: {
          top: "32px",
          left: "32px",
          transformOrigin: "top left",
          transform: "rotate(-180deg)",
        },
        Loading: {},
      },
      variantValues: { type: "180" },
    },
    {
      overrides: {
        Line: {},
        Ellipse: {
          viewBox: {
            minX: 0,
            minY: 0,
            width: 8.000000349691163,
            height: 8.000000349691163,
          },
        },
        Circle: {
          top: "32px",
          left: "0px",
          transformOrigin: "top left",
          transform: "rotate(-90deg)",
        },
        Loading: {},
      },
      variantValues: { type: "270" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <View
      width="32px"
      height="32px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      position="relative"
      padding="0px 0px 0px 0px"
      {...rest}
      {...getOverrideProps(overrides, "Loading")}
    >
      <Icon
        width="32px"
        height="32px"
        viewBox={{ minX: 0, minY: 0, width: 32, height: 32 }}
        paths={[
          {
            d: "M32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16ZM3.2 16C3.2 23.0692 8.93075 28.8 16 28.8C23.0692 28.8 28.8 23.0692 28.8 16C28.8 8.93075 23.0692 3.2 16 3.2C8.93075 3.2 3.2 8.93075 3.2 16Z",
            fill: "rgba(210,210,210,1)",
            fillRule: "nonzero",
          },
        ]}
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="0px"
        left="0px"
        {...getOverrideProps(overrides, "Line")}
      ></Icon>
      <View
        width="32px"
        height="32px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="0px"
        left="calc(50% - 16px - 0px)"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Circle")}
      >
        <Icon
          width="8px"
          height="8px"
          viewBox={{ minX: 0, minY: 0, width: 8, height: 8 }}
          paths={[
            {
              d: "M8 4C8 6.20914 6.20914 8 4 8C1.79086 8 0 6.20914 0 4C0 1.79086 1.79086 0 4 0C6.20914 0 8 1.79086 8 4Z",
              fill: "rgba(0,153,255,1)",
              fillRule: "nonzero",
            },
          ]}
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="-2px"
          left="12px"
          {...getOverrideProps(overrides, "Ellipse")}
        ></Icon>
      </View>
    </View>
  );
}
