/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "@aws-amplify/ui-react/internal";
import { Flex, Text } from "@aws-amplify/ui-react";
import Loading from "./Loading";
import CommonDivider from "./CommonDivider";
import Spacer from "./Spacer";
export default function KanshiFrameSummaryResult(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: {
        "\u96C6\u8A08\u7D50\u679C\u304C\u8868\u793A\u3055\u308C\u307E\u3059569221979":
          {},
        Init: { display: "none" },
        Loading569221981: {},
        Loading569221980: {},
        "\u96C6\u8A08\u7D50\u679C\u304C\u8868\u793A\u3055\u308C\u307E\u3059569221983":
          {},
        Empty: {},
        "\u96C6\u8A08\u7D50\u679C\u304C\u8868\u793A\u3055\u308C\u307E\u3059569221985":
          {},
        Error: {},
        "Common/Divider569221987": {},
        YSCC569221990: {},
        Spacer569221991: {},
        Number569221992: {},
        "YSCC/Title": {},
        text569221996: {},
        "YSCC/Kansyoha/Frame": {},
        text569222000: {},
        Spacer569222001: {},
        text569222002: {},
        "YSCC/Kansyoha/Error/Frame": {},
        text569222005: {},
        Spacer569222006: {},
        text569222007: {},
        "YSCC/Kansyoha/Error/Result/1": {},
        "Common/Divider569222008": {},
        text569222010: {},
        Spacer569222011: {},
        text569222012: {},
        "YSCC/Kansyoha/Error/Result/2": {},
        "Common/Divider569222013": {},
        text569222015: {},
        Spacer569222016: {},
        text569222017: {},
        "YSCC/Kansyoha/Error/Result/3": {},
        "YSCC/Kansyoha/Error/Result": {},
        "YSCC/Kansyoha/Error": {},
        "Common/Divider569222018": {},
        text569222021: {},
        Spacer569222022: {},
        text569222023: {},
        "YSCC/Kansyoha/Warning/Frame": {},
        text569222025: {},
        Spacer569222026: {},
        text569222027: {},
        "YSCC/Kansyoha/Warning/Result": {},
        "YSCC/Kansyoha/Warning": {},
        "YSCC/Kansyoha/Contents": {},
        "YSCC/Kansyoha": {},
        "Common/Divider569222028": {},
        text569222031: {},
        "YSCC/Kiki/Frame": {},
        text569222035: {},
        Spacer569222036: {},
        text569222037: {},
        "YSCC/Kiki/Error/Frame": {},
        text569222040: {},
        Spacer569222041: {},
        text569222042: {},
        "YSCC/Kiki/Error/Result/1": {},
        "Common/Divider569222043": {},
        text569222045: {},
        Spacer569222046: {},
        text569222047: {},
        "YSCC/Kiki/Error/Result/2": {},
        "YSCC/Kiki/Error/Result": {},
        "YSCC/Kiki/Error": {},
        "Common/Divider569222048": {},
        text569222051: {},
        Spacer569222052: {},
        text569222053: {},
        "YSCC/Kiki/Warning/Frame": {},
        text569222055: {},
        Spacer569222056: {},
        text569222057: {},
        "YSCC/Kiki/Warning/Result": {},
        "YSCC/Kiki/Warning": {},
        "YSCC/Kiki/Contents": {},
        "YSCC/Kiki": {},
        "YSCC/Contents": {},
        YSCC569221988: {},
        "Common/Divider569222058": {},
        SPE569222061: {},
        Spacer569222062: {},
        Number569222063: {},
        "SPE/Title": {},
        text569222067: {},
        "SPE/Kansyoha/Frame": {},
        text569222071: {},
        Spacer569222072: {},
        text569222073: {},
        "SPE/Kansyoha/Error/Frame": {},
        text569222076: {},
        Spacer569222077: {},
        text569222078: {},
        "SPE/Kansyoha/Error/Result/1": {},
        "Common/Divider569222079": {},
        text569222081: {},
        Spacer569222082: {},
        text569222083: {},
        "SPE/Kansyoha/Error/Result/2": {},
        "Common/Divider569222084": {},
        text569222086: {},
        Spacer569222087: {},
        text569222088: {},
        "SPE/Kansyoha/Error/Result/3": {},
        "SPE/Kansyoha/Error/Result": {},
        "SPE/Kansyoha/Error": {},
        "Common/Divider569222089": {},
        text569222092: {},
        Spacer569222093: {},
        text569222094: {},
        "SPE/Kansyoha/Warning/Frame": {},
        text569222096: {},
        Spacer569222097: {},
        text569222098: {},
        "SPE/Kansyoha/Warning/Result": {},
        "SPE/Kansyoha/Warning": {},
        "SPE/Kansyoha/Contents": {},
        "SPE/Kansyoha": {},
        "Common/Divider569222099": {},
        text569222102: {},
        "SPE/Kiki/Frame": {},
        text569222106: {},
        Spacer569222107: {},
        text569222108: {},
        "SPE/Kiki/Error/Frame": {},
        text569222111: {},
        Spacer569222112: {},
        text569222113: {},
        "SPE/Kiki/Error/Result/1": {},
        "Common/Divider569222114": {},
        text569222116: {},
        Spacer569222117: {},
        text569222118: {},
        "SPE/Kiki/Error/Result/2": {},
        "SPE/Kiki/Error/Result": {},
        "SPE/Kiki/Error": {},
        "Common/Divider569222119": {},
        text569222122: {},
        Spacer569222123: {},
        text569222124: {},
        "SPE/Kiki/Warning/Frame": {},
        text569222126: {},
        Spacer569222127: {},
        text569222128: {},
        "SPE/Kiki/Warning/Result": {},
        "SPE/Kiki/Warning": {},
        "SPE/Kiki/Contents": {},
        "SPE/Kiki": {},
        "SPE/Contents": {},
        SPE569222059: {},
        "Common/Divider569222129": {},
        SPW569222132: {},
        Spacer569222133: {},
        Number569222134: {},
        "SPW/Title": {},
        text569222138: {},
        "SPW/Kansyoha/Frame": {},
        text569222142: {},
        Spacer569222143: {},
        text569222144: {},
        "SPW/Kansyoha/Error/Frame": {},
        text569222147: {},
        Spacer569222148: {},
        text569222149: {},
        "SPW/Kansyoha/Error/Result/1": {},
        "Common/Divider569222150": {},
        text569222152: {},
        Spacer569222153: {},
        text569222154: {},
        "SPW/Kansyoha/Error/Result/2": {},
        "Common/Divider569222155": {},
        text569222157: {},
        Spacer569222158: {},
        text569222159: {},
        "SPW/Kansyoha/Error/Result/3": {},
        "SPW/Kansyoha/Error/Result": {},
        "SPW/Kansyoha/Error": {},
        "Common/Divider569222160": {},
        text569222163: {},
        Spacer569222164: {},
        text569222165: {},
        "SPW/Kansyoha/Warining/Frame": {},
        text569222167: {},
        Spacer569222168: {},
        text569222169: {},
        "SPW/Kansyoha/Warining/Result": {},
        "SPW/Kansyoha/Warining": {},
        "SPW/Kansyoha/Contents": {},
        "SPW/Kansyoha": {},
        "Common/Divider569222170": {},
        text569222173: {},
        "SPW/Kiki/Frame": {},
        text569222177: {},
        Spacer569222178: {},
        text569222179: {},
        "SPW/Kiki/Error/Frame": {},
        text569222182: {},
        Spacer569222183: {},
        text569222184: {},
        "SPW/Kiki/Error/Result/1": {},
        "Common/Divider569222185": {},
        text569222187: {},
        Spacer569222188: {},
        text569222189: {},
        "SPW/Kiki/Error/Result/2": {},
        "SPW/Kiki/Error/Result": {},
        "SPW/Kiki/Error": {},
        "Common/Divider569222190": {},
        text569222193: {},
        Spacer569222194: {},
        text569222195: {},
        "SPW/Kiki/Warning/Frame": {},
        text569222197: {},
        Spacer569222198: {},
        text569222199: {},
        "SPW/Kiki/Warning/Result": {},
        "SPW/Kiki/Warning": {},
        "SPW/Kiki/Contents": {},
        "SPW/Kiki": {},
        "SPW/Contents": {},
        SPW569222130: {},
        "Common/Divider569222200": {},
        Success: { display: "flex" },
        KanshiFrameSummaryResult: {},
      },
      variantValues: { result: "Success" },
    },
    {
      overrides: {
        "\u96C6\u8A08\u7D50\u679C\u304C\u8868\u793A\u3055\u308C\u307E\u3059569221979":
          {},
        Init: { display: "none" },
        Loading569221981: {},
        Loading569221980: {},
        "\u96C6\u8A08\u7D50\u679C\u304C\u8868\u793A\u3055\u308C\u307E\u3059569221983":
          {},
        Empty: {},
        "\u96C6\u8A08\u7D50\u679C\u304C\u8868\u793A\u3055\u308C\u307E\u3059569221985":
          {},
        Error: { display: "flex" },
        "Common/Divider569221987": {},
        YSCC569221990: {},
        Spacer569221991: {},
        Number569221992: {},
        "YSCC/Title": {},
        text569221996: {},
        "YSCC/Kansyoha/Frame": {},
        text569222000: {},
        Spacer569222001: {},
        text569222002: {},
        "YSCC/Kansyoha/Error/Frame": {},
        text569222005: {},
        Spacer569222006: {},
        text569222007: {},
        "YSCC/Kansyoha/Error/Result/1": {},
        "Common/Divider569222008": {},
        text569222010: {},
        Spacer569222011: {},
        text569222012: {},
        "YSCC/Kansyoha/Error/Result/2": {},
        "Common/Divider569222013": {},
        text569222015: {},
        Spacer569222016: {},
        text569222017: {},
        "YSCC/Kansyoha/Error/Result/3": {},
        "YSCC/Kansyoha/Error/Result": {},
        "YSCC/Kansyoha/Error": {},
        "Common/Divider569222018": {},
        text569222021: {},
        Spacer569222022: {},
        text569222023: {},
        "YSCC/Kansyoha/Warning/Frame": {},
        text569222025: {},
        Spacer569222026: {},
        text569222027: {},
        "YSCC/Kansyoha/Warning/Result": {},
        "YSCC/Kansyoha/Warning": {},
        "YSCC/Kansyoha/Contents": {},
        "YSCC/Kansyoha": {},
        "Common/Divider569222028": {},
        text569222031: {},
        "YSCC/Kiki/Frame": {},
        text569222035: {},
        Spacer569222036: {},
        text569222037: {},
        "YSCC/Kiki/Error/Frame": {},
        text569222040: {},
        Spacer569222041: {},
        text569222042: {},
        "YSCC/Kiki/Error/Result/1": {},
        "Common/Divider569222043": {},
        text569222045: {},
        Spacer569222046: {},
        text569222047: {},
        "YSCC/Kiki/Error/Result/2": {},
        "YSCC/Kiki/Error/Result": {},
        "YSCC/Kiki/Error": {},
        "Common/Divider569222048": {},
        text569222051: {},
        Spacer569222052: {},
        text569222053: {},
        "YSCC/Kiki/Warning/Frame": {},
        text569222055: {},
        Spacer569222056: {},
        text569222057: {},
        "YSCC/Kiki/Warning/Result": {},
        "YSCC/Kiki/Warning": {},
        "YSCC/Kiki/Contents": {},
        "YSCC/Kiki": {},
        "YSCC/Contents": {},
        YSCC569221988: {},
        "Common/Divider569222058": {},
        SPE569222061: {},
        Spacer569222062: {},
        Number569222063: {},
        "SPE/Title": {},
        text569222067: {},
        "SPE/Kansyoha/Frame": {},
        text569222071: {},
        Spacer569222072: {},
        text569222073: {},
        "SPE/Kansyoha/Error/Frame": {},
        text569222076: {},
        Spacer569222077: {},
        text569222078: {},
        "SPE/Kansyoha/Error/Result/1": {},
        "Common/Divider569222079": {},
        text569222081: {},
        Spacer569222082: {},
        text569222083: {},
        "SPE/Kansyoha/Error/Result/2": {},
        "Common/Divider569222084": {},
        text569222086: {},
        Spacer569222087: {},
        text569222088: {},
        "SPE/Kansyoha/Error/Result/3": {},
        "SPE/Kansyoha/Error/Result": {},
        "SPE/Kansyoha/Error": {},
        "Common/Divider569222089": {},
        text569222092: {},
        Spacer569222093: {},
        text569222094: {},
        "SPE/Kansyoha/Warning/Frame": {},
        text569222096: {},
        Spacer569222097: {},
        text569222098: {},
        "SPE/Kansyoha/Warning/Result": {},
        "SPE/Kansyoha/Warning": {},
        "SPE/Kansyoha/Contents": {},
        "SPE/Kansyoha": {},
        "Common/Divider569222099": {},
        text569222102: {},
        "SPE/Kiki/Frame": {},
        text569222106: {},
        Spacer569222107: {},
        text569222108: {},
        "SPE/Kiki/Error/Frame": {},
        text569222111: {},
        Spacer569222112: {},
        text569222113: {},
        "SPE/Kiki/Error/Result/1": {},
        "Common/Divider569222114": {},
        text569222116: {},
        Spacer569222117: {},
        text569222118: {},
        "SPE/Kiki/Error/Result/2": {},
        "SPE/Kiki/Error/Result": {},
        "SPE/Kiki/Error": {},
        "Common/Divider569222119": {},
        text569222122: {},
        Spacer569222123: {},
        text569222124: {},
        "SPE/Kiki/Warning/Frame": {},
        text569222126: {},
        Spacer569222127: {},
        text569222128: {},
        "SPE/Kiki/Warning/Result": {},
        "SPE/Kiki/Warning": {},
        "SPE/Kiki/Contents": {},
        "SPE/Kiki": {},
        "SPE/Contents": {},
        SPE569222059: {},
        "Common/Divider569222129": {},
        SPW569222132: {},
        Spacer569222133: {},
        Number569222134: {},
        "SPW/Title": {},
        text569222138: {},
        "SPW/Kansyoha/Frame": {},
        text569222142: {},
        Spacer569222143: {},
        text569222144: {},
        "SPW/Kansyoha/Error/Frame": {},
        text569222147: {},
        Spacer569222148: {},
        text569222149: {},
        "SPW/Kansyoha/Error/Result/1": {},
        "Common/Divider569222150": {},
        text569222152: {},
        Spacer569222153: {},
        text569222154: {},
        "SPW/Kansyoha/Error/Result/2": {},
        "Common/Divider569222155": {},
        text569222157: {},
        Spacer569222158: {},
        text569222159: {},
        "SPW/Kansyoha/Error/Result/3": {},
        "SPW/Kansyoha/Error/Result": {},
        "SPW/Kansyoha/Error": {},
        "Common/Divider569222160": {},
        text569222163: {},
        Spacer569222164: {},
        text569222165: {},
        "SPW/Kansyoha/Warining/Frame": {},
        text569222167: {},
        Spacer569222168: {},
        text569222169: {},
        "SPW/Kansyoha/Warining/Result": {},
        "SPW/Kansyoha/Warining": {},
        "SPW/Kansyoha/Contents": {},
        "SPW/Kansyoha": {},
        "Common/Divider569222170": {},
        text569222173: {},
        "SPW/Kiki/Frame": {},
        text569222177: {},
        Spacer569222178: {},
        text569222179: {},
        "SPW/Kiki/Error/Frame": {},
        text569222182: {},
        Spacer569222183: {},
        text569222184: {},
        "SPW/Kiki/Error/Result/1": {},
        "Common/Divider569222185": {},
        text569222187: {},
        Spacer569222188: {},
        text569222189: {},
        "SPW/Kiki/Error/Result/2": {},
        "SPW/Kiki/Error/Result": {},
        "SPW/Kiki/Error": {},
        "Common/Divider569222190": {},
        text569222193: {},
        Spacer569222194: {},
        text569222195: {},
        "SPW/Kiki/Warning/Frame": {},
        text569222197: {},
        Spacer569222198: {},
        text569222199: {},
        "SPW/Kiki/Warning/Result": {},
        "SPW/Kiki/Warning": {},
        "SPW/Kiki/Contents": {},
        "SPW/Kiki": {},
        "SPW/Contents": {},
        SPW569222130: {},
        "Common/Divider569222200": {},
        Success: {},
        KanshiFrameSummaryResult: {},
      },
      variantValues: { result: "Error" },
    },
    {
      overrides: {
        "\u96C6\u8A08\u7D50\u679C\u304C\u8868\u793A\u3055\u308C\u307E\u3059569221979":
          {},
        Init: { display: "none" },
        Loading569221981: {},
        Loading569221980: {},
        "\u96C6\u8A08\u7D50\u679C\u304C\u8868\u793A\u3055\u308C\u307E\u3059569221983":
          {},
        Empty: { display: "flex" },
        "\u96C6\u8A08\u7D50\u679C\u304C\u8868\u793A\u3055\u308C\u307E\u3059569221985":
          {},
        Error: {},
        "Common/Divider569221987": {},
        YSCC569221990: {},
        Spacer569221991: {},
        Number569221992: {},
        "YSCC/Title": {},
        text569221996: {},
        "YSCC/Kansyoha/Frame": {},
        text569222000: {},
        Spacer569222001: {},
        text569222002: {},
        "YSCC/Kansyoha/Error/Frame": {},
        text569222005: {},
        Spacer569222006: {},
        text569222007: {},
        "YSCC/Kansyoha/Error/Result/1": {},
        "Common/Divider569222008": {},
        text569222010: {},
        Spacer569222011: {},
        text569222012: {},
        "YSCC/Kansyoha/Error/Result/2": {},
        "Common/Divider569222013": {},
        text569222015: {},
        Spacer569222016: {},
        text569222017: {},
        "YSCC/Kansyoha/Error/Result/3": {},
        "YSCC/Kansyoha/Error/Result": {},
        "YSCC/Kansyoha/Error": {},
        "Common/Divider569222018": {},
        text569222021: {},
        Spacer569222022: {},
        text569222023: {},
        "YSCC/Kansyoha/Warning/Frame": {},
        text569222025: {},
        Spacer569222026: {},
        text569222027: {},
        "YSCC/Kansyoha/Warning/Result": {},
        "YSCC/Kansyoha/Warning": {},
        "YSCC/Kansyoha/Contents": {},
        "YSCC/Kansyoha": {},
        "Common/Divider569222028": {},
        text569222031: {},
        "YSCC/Kiki/Frame": {},
        text569222035: {},
        Spacer569222036: {},
        text569222037: {},
        "YSCC/Kiki/Error/Frame": {},
        text569222040: {},
        Spacer569222041: {},
        text569222042: {},
        "YSCC/Kiki/Error/Result/1": {},
        "Common/Divider569222043": {},
        text569222045: {},
        Spacer569222046: {},
        text569222047: {},
        "YSCC/Kiki/Error/Result/2": {},
        "YSCC/Kiki/Error/Result": {},
        "YSCC/Kiki/Error": {},
        "Common/Divider569222048": {},
        text569222051: {},
        Spacer569222052: {},
        text569222053: {},
        "YSCC/Kiki/Warning/Frame": {},
        text569222055: {},
        Spacer569222056: {},
        text569222057: {},
        "YSCC/Kiki/Warning/Result": {},
        "YSCC/Kiki/Warning": {},
        "YSCC/Kiki/Contents": {},
        "YSCC/Kiki": {},
        "YSCC/Contents": {},
        YSCC569221988: {},
        "Common/Divider569222058": {},
        SPE569222061: {},
        Spacer569222062: {},
        Number569222063: {},
        "SPE/Title": {},
        text569222067: {},
        "SPE/Kansyoha/Frame": {},
        text569222071: {},
        Spacer569222072: {},
        text569222073: {},
        "SPE/Kansyoha/Error/Frame": {},
        text569222076: {},
        Spacer569222077: {},
        text569222078: {},
        "SPE/Kansyoha/Error/Result/1": {},
        "Common/Divider569222079": {},
        text569222081: {},
        Spacer569222082: {},
        text569222083: {},
        "SPE/Kansyoha/Error/Result/2": {},
        "Common/Divider569222084": {},
        text569222086: {},
        Spacer569222087: {},
        text569222088: {},
        "SPE/Kansyoha/Error/Result/3": {},
        "SPE/Kansyoha/Error/Result": {},
        "SPE/Kansyoha/Error": {},
        "Common/Divider569222089": {},
        text569222092: {},
        Spacer569222093: {},
        text569222094: {},
        "SPE/Kansyoha/Warning/Frame": {},
        text569222096: {},
        Spacer569222097: {},
        text569222098: {},
        "SPE/Kansyoha/Warning/Result": {},
        "SPE/Kansyoha/Warning": {},
        "SPE/Kansyoha/Contents": {},
        "SPE/Kansyoha": {},
        "Common/Divider569222099": {},
        text569222102: {},
        "SPE/Kiki/Frame": {},
        text569222106: {},
        Spacer569222107: {},
        text569222108: {},
        "SPE/Kiki/Error/Frame": {},
        text569222111: {},
        Spacer569222112: {},
        text569222113: {},
        "SPE/Kiki/Error/Result/1": {},
        "Common/Divider569222114": {},
        text569222116: {},
        Spacer569222117: {},
        text569222118: {},
        "SPE/Kiki/Error/Result/2": {},
        "SPE/Kiki/Error/Result": {},
        "SPE/Kiki/Error": {},
        "Common/Divider569222119": {},
        text569222122: {},
        Spacer569222123: {},
        text569222124: {},
        "SPE/Kiki/Warning/Frame": {},
        text569222126: {},
        Spacer569222127: {},
        text569222128: {},
        "SPE/Kiki/Warning/Result": {},
        "SPE/Kiki/Warning": {},
        "SPE/Kiki/Contents": {},
        "SPE/Kiki": {},
        "SPE/Contents": {},
        SPE569222059: {},
        "Common/Divider569222129": {},
        SPW569222132: {},
        Spacer569222133: {},
        Number569222134: {},
        "SPW/Title": {},
        text569222138: {},
        "SPW/Kansyoha/Frame": {},
        text569222142: {},
        Spacer569222143: {},
        text569222144: {},
        "SPW/Kansyoha/Error/Frame": {},
        text569222147: {},
        Spacer569222148: {},
        text569222149: {},
        "SPW/Kansyoha/Error/Result/1": {},
        "Common/Divider569222150": {},
        text569222152: {},
        Spacer569222153: {},
        text569222154: {},
        "SPW/Kansyoha/Error/Result/2": {},
        "Common/Divider569222155": {},
        text569222157: {},
        Spacer569222158: {},
        text569222159: {},
        "SPW/Kansyoha/Error/Result/3": {},
        "SPW/Kansyoha/Error/Result": {},
        "SPW/Kansyoha/Error": {},
        "Common/Divider569222160": {},
        text569222163: {},
        Spacer569222164: {},
        text569222165: {},
        "SPW/Kansyoha/Warining/Frame": {},
        text569222167: {},
        Spacer569222168: {},
        text569222169: {},
        "SPW/Kansyoha/Warining/Result": {},
        "SPW/Kansyoha/Warining": {},
        "SPW/Kansyoha/Contents": {},
        "SPW/Kansyoha": {},
        "Common/Divider569222170": {},
        text569222173: {},
        "SPW/Kiki/Frame": {},
        text569222177: {},
        Spacer569222178: {},
        text569222179: {},
        "SPW/Kiki/Error/Frame": {},
        text569222182: {},
        Spacer569222183: {},
        text569222184: {},
        "SPW/Kiki/Error/Result/1": {},
        "Common/Divider569222185": {},
        text569222187: {},
        Spacer569222188: {},
        text569222189: {},
        "SPW/Kiki/Error/Result/2": {},
        "SPW/Kiki/Error/Result": {},
        "SPW/Kiki/Error": {},
        "Common/Divider569222190": {},
        text569222193: {},
        Spacer569222194: {},
        text569222195: {},
        "SPW/Kiki/Warning/Frame": {},
        text569222197: {},
        Spacer569222198: {},
        text569222199: {},
        "SPW/Kiki/Warning/Result": {},
        "SPW/Kiki/Warning": {},
        "SPW/Kiki/Contents": {},
        "SPW/Kiki": {},
        "SPW/Contents": {},
        SPW569222130: {},
        "Common/Divider569222200": {},
        Success: {},
        KanshiFrameSummaryResult: {},
      },
      variantValues: { result: "Empty" },
    },
    {
      overrides: {
        "\u96C6\u8A08\u7D50\u679C\u304C\u8868\u793A\u3055\u308C\u307E\u3059569221979":
          {},
        Init: { display: "none" },
        Loading569221981: {},
        Loading569221980: { display: "flex" },
        "\u96C6\u8A08\u7D50\u679C\u304C\u8868\u793A\u3055\u308C\u307E\u3059569221983":
          {},
        Empty: {},
        "\u96C6\u8A08\u7D50\u679C\u304C\u8868\u793A\u3055\u308C\u307E\u3059569221985":
          {},
        Error: {},
        "Common/Divider569221987": {},
        YSCC569221990: {},
        Spacer569221991: {},
        Number569221992: {},
        "YSCC/Title": {},
        text569221996: {},
        "YSCC/Kansyoha/Frame": {},
        text569222000: {},
        Spacer569222001: {},
        text569222002: {},
        "YSCC/Kansyoha/Error/Frame": {},
        text569222005: {},
        Spacer569222006: {},
        text569222007: {},
        "YSCC/Kansyoha/Error/Result/1": {},
        "Common/Divider569222008": {},
        text569222010: {},
        Spacer569222011: {},
        text569222012: {},
        "YSCC/Kansyoha/Error/Result/2": {},
        "Common/Divider569222013": {},
        text569222015: {},
        Spacer569222016: {},
        text569222017: {},
        "YSCC/Kansyoha/Error/Result/3": {},
        "YSCC/Kansyoha/Error/Result": {},
        "YSCC/Kansyoha/Error": {},
        "Common/Divider569222018": {},
        text569222021: {},
        Spacer569222022: {},
        text569222023: {},
        "YSCC/Kansyoha/Warning/Frame": {},
        text569222025: {},
        Spacer569222026: {},
        text569222027: {},
        "YSCC/Kansyoha/Warning/Result": {},
        "YSCC/Kansyoha/Warning": {},
        "YSCC/Kansyoha/Contents": {},
        "YSCC/Kansyoha": {},
        "Common/Divider569222028": {},
        text569222031: {},
        "YSCC/Kiki/Frame": {},
        text569222035: {},
        Spacer569222036: {},
        text569222037: {},
        "YSCC/Kiki/Error/Frame": {},
        text569222040: {},
        Spacer569222041: {},
        text569222042: {},
        "YSCC/Kiki/Error/Result/1": {},
        "Common/Divider569222043": {},
        text569222045: {},
        Spacer569222046: {},
        text569222047: {},
        "YSCC/Kiki/Error/Result/2": {},
        "YSCC/Kiki/Error/Result": {},
        "YSCC/Kiki/Error": {},
        "Common/Divider569222048": {},
        text569222051: {},
        Spacer569222052: {},
        text569222053: {},
        "YSCC/Kiki/Warning/Frame": {},
        text569222055: {},
        Spacer569222056: {},
        text569222057: {},
        "YSCC/Kiki/Warning/Result": {},
        "YSCC/Kiki/Warning": {},
        "YSCC/Kiki/Contents": {},
        "YSCC/Kiki": {},
        "YSCC/Contents": {},
        YSCC569221988: {},
        "Common/Divider569222058": {},
        SPE569222061: {},
        Spacer569222062: {},
        Number569222063: {},
        "SPE/Title": {},
        text569222067: {},
        "SPE/Kansyoha/Frame": {},
        text569222071: {},
        Spacer569222072: {},
        text569222073: {},
        "SPE/Kansyoha/Error/Frame": {},
        text569222076: {},
        Spacer569222077: {},
        text569222078: {},
        "SPE/Kansyoha/Error/Result/1": {},
        "Common/Divider569222079": {},
        text569222081: {},
        Spacer569222082: {},
        text569222083: {},
        "SPE/Kansyoha/Error/Result/2": {},
        "Common/Divider569222084": {},
        text569222086: {},
        Spacer569222087: {},
        text569222088: {},
        "SPE/Kansyoha/Error/Result/3": {},
        "SPE/Kansyoha/Error/Result": {},
        "SPE/Kansyoha/Error": {},
        "Common/Divider569222089": {},
        text569222092: {},
        Spacer569222093: {},
        text569222094: {},
        "SPE/Kansyoha/Warning/Frame": {},
        text569222096: {},
        Spacer569222097: {},
        text569222098: {},
        "SPE/Kansyoha/Warning/Result": {},
        "SPE/Kansyoha/Warning": {},
        "SPE/Kansyoha/Contents": {},
        "SPE/Kansyoha": {},
        "Common/Divider569222099": {},
        text569222102: {},
        "SPE/Kiki/Frame": {},
        text569222106: {},
        Spacer569222107: {},
        text569222108: {},
        "SPE/Kiki/Error/Frame": {},
        text569222111: {},
        Spacer569222112: {},
        text569222113: {},
        "SPE/Kiki/Error/Result/1": {},
        "Common/Divider569222114": {},
        text569222116: {},
        Spacer569222117: {},
        text569222118: {},
        "SPE/Kiki/Error/Result/2": {},
        "SPE/Kiki/Error/Result": {},
        "SPE/Kiki/Error": {},
        "Common/Divider569222119": {},
        text569222122: {},
        Spacer569222123: {},
        text569222124: {},
        "SPE/Kiki/Warning/Frame": {},
        text569222126: {},
        Spacer569222127: {},
        text569222128: {},
        "SPE/Kiki/Warning/Result": {},
        "SPE/Kiki/Warning": {},
        "SPE/Kiki/Contents": {},
        "SPE/Kiki": {},
        "SPE/Contents": {},
        SPE569222059: {},
        "Common/Divider569222129": {},
        SPW569222132: {},
        Spacer569222133: {},
        Number569222134: {},
        "SPW/Title": {},
        text569222138: {},
        "SPW/Kansyoha/Frame": {},
        text569222142: {},
        Spacer569222143: {},
        text569222144: {},
        "SPW/Kansyoha/Error/Frame": {},
        text569222147: {},
        Spacer569222148: {},
        text569222149: {},
        "SPW/Kansyoha/Error/Result/1": {},
        "Common/Divider569222150": {},
        text569222152: {},
        Spacer569222153: {},
        text569222154: {},
        "SPW/Kansyoha/Error/Result/2": {},
        "Common/Divider569222155": {},
        text569222157: {},
        Spacer569222158: {},
        text569222159: {},
        "SPW/Kansyoha/Error/Result/3": {},
        "SPW/Kansyoha/Error/Result": {},
        "SPW/Kansyoha/Error": {},
        "Common/Divider569222160": {},
        text569222163: {},
        Spacer569222164: {},
        text569222165: {},
        "SPW/Kansyoha/Warining/Frame": {},
        text569222167: {},
        Spacer569222168: {},
        text569222169: {},
        "SPW/Kansyoha/Warining/Result": {},
        "SPW/Kansyoha/Warining": {},
        "SPW/Kansyoha/Contents": {},
        "SPW/Kansyoha": {},
        "Common/Divider569222170": {},
        text569222173: {},
        "SPW/Kiki/Frame": {},
        text569222177: {},
        Spacer569222178: {},
        text569222179: {},
        "SPW/Kiki/Error/Frame": {},
        text569222182: {},
        Spacer569222183: {},
        text569222184: {},
        "SPW/Kiki/Error/Result/1": {},
        "Common/Divider569222185": {},
        text569222187: {},
        Spacer569222188: {},
        text569222189: {},
        "SPW/Kiki/Error/Result/2": {},
        "SPW/Kiki/Error/Result": {},
        "SPW/Kiki/Error": {},
        "Common/Divider569222190": {},
        text569222193: {},
        Spacer569222194: {},
        text569222195: {},
        "SPW/Kiki/Warning/Frame": {},
        text569222197: {},
        Spacer569222198: {},
        text569222199: {},
        "SPW/Kiki/Warning/Result": {},
        "SPW/Kiki/Warning": {},
        "SPW/Kiki/Contents": {},
        "SPW/Kiki": {},
        "SPW/Contents": {},
        SPW569222130: {},
        "Common/Divider569222200": {},
        Success: {},
        KanshiFrameSummaryResult: {},
      },
      variantValues: { result: "Loading" },
    },
    {
      overrides: {
        "\u96C6\u8A08\u7D50\u679C\u304C\u8868\u793A\u3055\u308C\u307E\u3059569221979":
          {},
        Init: {},
        Loading569221981: {},
        Loading569221980: {},
        "\u96C6\u8A08\u7D50\u679C\u304C\u8868\u793A\u3055\u308C\u307E\u3059569221983":
          {},
        Empty: {},
        "\u96C6\u8A08\u7D50\u679C\u304C\u8868\u793A\u3055\u308C\u307E\u3059569221985":
          {},
        Error: {},
        "Common/Divider569221987": {},
        YSCC569221990: {},
        Spacer569221991: {},
        Number569221992: {},
        "YSCC/Title": {},
        text569221996: {},
        "YSCC/Kansyoha/Frame": {},
        text569222000: {},
        Spacer569222001: {},
        text569222002: {},
        "YSCC/Kansyoha/Error/Frame": {},
        text569222005: {},
        Spacer569222006: {},
        text569222007: {},
        "YSCC/Kansyoha/Error/Result/1": {},
        "Common/Divider569222008": {},
        text569222010: {},
        Spacer569222011: {},
        text569222012: {},
        "YSCC/Kansyoha/Error/Result/2": {},
        "Common/Divider569222013": {},
        text569222015: {},
        Spacer569222016: {},
        text569222017: {},
        "YSCC/Kansyoha/Error/Result/3": {},
        "YSCC/Kansyoha/Error/Result": {},
        "YSCC/Kansyoha/Error": {},
        "Common/Divider569222018": {},
        text569222021: {},
        Spacer569222022: {},
        text569222023: {},
        "YSCC/Kansyoha/Warning/Frame": {},
        text569222025: {},
        Spacer569222026: {},
        text569222027: {},
        "YSCC/Kansyoha/Warning/Result": {},
        "YSCC/Kansyoha/Warning": {},
        "YSCC/Kansyoha/Contents": {},
        "YSCC/Kansyoha": {},
        "Common/Divider569222028": {},
        text569222031: {},
        "YSCC/Kiki/Frame": {},
        text569222035: {},
        Spacer569222036: {},
        text569222037: {},
        "YSCC/Kiki/Error/Frame": {},
        text569222040: {},
        Spacer569222041: {},
        text569222042: {},
        "YSCC/Kiki/Error/Result/1": {},
        "Common/Divider569222043": {},
        text569222045: {},
        Spacer569222046: {},
        text569222047: {},
        "YSCC/Kiki/Error/Result/2": {},
        "YSCC/Kiki/Error/Result": {},
        "YSCC/Kiki/Error": {},
        "Common/Divider569222048": {},
        text569222051: {},
        Spacer569222052: {},
        text569222053: {},
        "YSCC/Kiki/Warning/Frame": {},
        text569222055: {},
        Spacer569222056: {},
        text569222057: {},
        "YSCC/Kiki/Warning/Result": {},
        "YSCC/Kiki/Warning": {},
        "YSCC/Kiki/Contents": {},
        "YSCC/Kiki": {},
        "YSCC/Contents": {},
        YSCC569221988: {},
        "Common/Divider569222058": {},
        SPE569222061: {},
        Spacer569222062: {},
        Number569222063: {},
        "SPE/Title": {},
        text569222067: {},
        "SPE/Kansyoha/Frame": {},
        text569222071: {},
        Spacer569222072: {},
        text569222073: {},
        "SPE/Kansyoha/Error/Frame": {},
        text569222076: {},
        Spacer569222077: {},
        text569222078: {},
        "SPE/Kansyoha/Error/Result/1": {},
        "Common/Divider569222079": {},
        text569222081: {},
        Spacer569222082: {},
        text569222083: {},
        "SPE/Kansyoha/Error/Result/2": {},
        "Common/Divider569222084": {},
        text569222086: {},
        Spacer569222087: {},
        text569222088: {},
        "SPE/Kansyoha/Error/Result/3": {},
        "SPE/Kansyoha/Error/Result": {},
        "SPE/Kansyoha/Error": {},
        "Common/Divider569222089": {},
        text569222092: {},
        Spacer569222093: {},
        text569222094: {},
        "SPE/Kansyoha/Warning/Frame": {},
        text569222096: {},
        Spacer569222097: {},
        text569222098: {},
        "SPE/Kansyoha/Warning/Result": {},
        "SPE/Kansyoha/Warning": {},
        "SPE/Kansyoha/Contents": {},
        "SPE/Kansyoha": {},
        "Common/Divider569222099": {},
        text569222102: {},
        "SPE/Kiki/Frame": {},
        text569222106: {},
        Spacer569222107: {},
        text569222108: {},
        "SPE/Kiki/Error/Frame": {},
        text569222111: {},
        Spacer569222112: {},
        text569222113: {},
        "SPE/Kiki/Error/Result/1": {},
        "Common/Divider569222114": {},
        text569222116: {},
        Spacer569222117: {},
        text569222118: {},
        "SPE/Kiki/Error/Result/2": {},
        "SPE/Kiki/Error/Result": {},
        "SPE/Kiki/Error": {},
        "Common/Divider569222119": {},
        text569222122: {},
        Spacer569222123: {},
        text569222124: {},
        "SPE/Kiki/Warning/Frame": {},
        text569222126: {},
        Spacer569222127: {},
        text569222128: {},
        "SPE/Kiki/Warning/Result": {},
        "SPE/Kiki/Warning": {},
        "SPE/Kiki/Contents": {},
        "SPE/Kiki": {},
        "SPE/Contents": {},
        SPE569222059: {},
        "Common/Divider569222129": {},
        SPW569222132: {},
        Spacer569222133: {},
        Number569222134: {},
        "SPW/Title": {},
        text569222138: {},
        "SPW/Kansyoha/Frame": {},
        text569222142: {},
        Spacer569222143: {},
        text569222144: {},
        "SPW/Kansyoha/Error/Frame": {},
        text569222147: {},
        Spacer569222148: {},
        text569222149: {},
        "SPW/Kansyoha/Error/Result/1": {},
        "Common/Divider569222150": {},
        text569222152: {},
        Spacer569222153: {},
        text569222154: {},
        "SPW/Kansyoha/Error/Result/2": {},
        "Common/Divider569222155": {},
        text569222157: {},
        Spacer569222158: {},
        text569222159: {},
        "SPW/Kansyoha/Error/Result/3": {},
        "SPW/Kansyoha/Error/Result": {},
        "SPW/Kansyoha/Error": {},
        "Common/Divider569222160": {},
        text569222163: {},
        Spacer569222164: {},
        text569222165: {},
        "SPW/Kansyoha/Warining/Frame": {},
        text569222167: {},
        Spacer569222168: {},
        text569222169: {},
        "SPW/Kansyoha/Warining/Result": {},
        "SPW/Kansyoha/Warining": {},
        "SPW/Kansyoha/Contents": {},
        "SPW/Kansyoha": {},
        "Common/Divider569222170": {},
        text569222173: {},
        "SPW/Kiki/Frame": {},
        text569222177: {},
        Spacer569222178: {},
        text569222179: {},
        "SPW/Kiki/Error/Frame": {},
        text569222182: {},
        Spacer569222183: {},
        text569222184: {},
        "SPW/Kiki/Error/Result/1": {},
        "Common/Divider569222185": {},
        text569222187: {},
        Spacer569222188: {},
        text569222189: {},
        "SPW/Kiki/Error/Result/2": {},
        "SPW/Kiki/Error/Result": {},
        "SPW/Kiki/Error": {},
        "Common/Divider569222190": {},
        text569222193: {},
        Spacer569222194: {},
        text569222195: {},
        "SPW/Kiki/Warning/Frame": {},
        text569222197: {},
        Spacer569222198: {},
        text569222199: {},
        "SPW/Kiki/Warning/Result": {},
        "SPW/Kiki/Warning": {},
        "SPW/Kiki/Contents": {},
        "SPW/Kiki": {},
        "SPW/Contents": {},
        SPW569222130: {},
        "Common/Divider569222200": {},
        Success: {},
        KanshiFrameSummaryResult: {},
      },
      variantValues: { result: "Init" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="0"
      direction="column"
      width="unset"
      height="unset"
      justifyContent="flex-start"
      alignItems="center"
      overflow="hidden"
      position="relative"
      padding="0px 0px 0px 0px"
      display="flex"
      {...rest}
      {...getOverrideProps(overrides, "KanshiFrameSummaryResult")}
    >
      <Flex
        gap="10px"
        direction="row"
        width="unset"
        height="160px"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        position="relative"
        borderRadius="4px"
        padding="40px 40px 40px 40px"
        backgroundColor="rgba(245,245,245,1)"
        display="flex"
        {...getOverrideProps(overrides, "Init")}
      >
        <Text
          fontFamily="Noto Sans"
          fontSize="14px"
          fontWeight="500"
          color="rgba(100,100,100,1)"
          lineHeight="28px"
          textAlign="center"
          display="block"
          direction="column"
          justifyContent="unset"
          letterSpacing="0.55px"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="集計結果が表示されます"
          {...getOverrideProps(
            overrides,
            "\u96C6\u8A08\u7D50\u679C\u304C\u8868\u793A\u3055\u308C\u307E\u3059569221979"
          )}
        ></Text>
      </Flex>
      <Flex
        gap="10px"
        direction="row"
        width="unset"
        height="160px"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        position="relative"
        borderRadius="4px"
        padding="40px 40px 40px 40px"
        backgroundColor="rgba(255,255,255,1)"
        display="none"
        {...getOverrideProps(overrides, "Loading569221980")}
      >
        <Loading
          width="32px"
          height="32px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          type="0"
          {...getOverrideProps(overrides, "Loading569221981")}
        ></Loading>
      </Flex>
      <Flex
        gap="10px"
        direction="row"
        width="unset"
        height="160px"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        position="relative"
        borderRadius="4px"
        padding="40px 40px 40px 40px"
        backgroundColor="rgba(245,245,245,1)"
        display="none"
        {...getOverrideProps(overrides, "Empty")}
      >
        <Text
          fontFamily="Noto Sans"
          fontSize="14px"
          fontWeight="500"
          color="rgba(255,76,79,1)"
          lineHeight="28px"
          textAlign="center"
          display="block"
          direction="column"
          justifyContent="unset"
          letterSpacing="0.55px"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="集計結果はありません"
          {...getOverrideProps(
            overrides,
            "\u96C6\u8A08\u7D50\u679C\u304C\u8868\u793A\u3055\u308C\u307E\u3059569221983"
          )}
        ></Text>
      </Flex>
      <Flex
        gap="10px"
        direction="row"
        width="unset"
        height="160px"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        position="relative"
        borderRadius="4px"
        padding="40px 40px 40px 40px"
        backgroundColor="rgba(245,245,245,1)"
        display="none"
        {...getOverrideProps(overrides, "Error")}
      >
        <Text
          fontFamily="Noto Sans"
          fontSize="14px"
          fontWeight="500"
          color="rgba(255,76,79,1)"
          lineHeight="28px"
          textAlign="center"
          display="block"
          direction="column"
          justifyContent="unset"
          letterSpacing="0.55px"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="集計に失敗しました&#xA;再度お試しください"
          {...getOverrideProps(
            overrides,
            "\u96C6\u8A08\u7D50\u679C\u304C\u8868\u793A\u3055\u308C\u307E\u3059569221985"
          )}
        ></Text>
      </Flex>
      <Flex
        gap="0"
        direction="column"
        width="unset"
        height="536px"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        display="none"
        {...getOverrideProps(overrides, "Success")}
      >
        <CommonDivider
          display="flex"
          gap="0"
          direction="row"
          width="unset"
          height="1px"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          backgroundColor="rgba(210,210,210,1)"
          {...getOverrideProps(overrides, "Common/Divider569221987")}
        ></CommonDivider>
        <Flex
          gap="0"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "YSCC569221988")}
        >
          <Flex
            gap="0"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="16px 24px 16px 24px"
            backgroundColor="rgba(245,245,245,1)"
            display="flex"
            {...getOverrideProps(overrides, "YSCC/Title")}
          >
            <Text
              fontFamily="Noto Sans"
              fontSize="14px"
              fontWeight="500"
              color="rgba(53,56,59,1)"
              lineHeight="14px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              letterSpacing="0.55px"
              width="80px"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="YSCC"
              {...getOverrideProps(overrides, "YSCC569221990")}
            ></Text>
            <Spacer
              display="flex"
              gap="10px"
              direction="column"
              width="16px"
              height="unset"
              justifyContent="center"
              alignItems="center"
              overflow="hidden"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="10px 10px 10px 10px"
              {...getOverrideProps(overrides, "Spacer569221991")}
            ></Spacer>
            <Text
              fontFamily="Noto Sans"
              fontSize="14px"
              fontWeight="500"
              color="rgba(53,56,59,1)"
              lineHeight="14px"
              textAlign="right"
              display="block"
              direction="column"
              justifyContent="unset"
              letterSpacing="0.55px"
              width="40px"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children=" 19件"
              {...getOverrideProps(overrides, "Number569221992")}
            ></Text>
          </Flex>
          <Flex
            gap="0"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "YSCC/Contents")}
          >
            <Flex
              gap="0"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "YSCC/Kansyoha")}
            >
              <Flex
                gap="0"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="flex-start"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="16px 24px 16px 24px"
                backgroundColor="rgba(255,255,255,1)"
                display="flex"
                {...getOverrideProps(overrides, "YSCC/Kansyoha/Frame")}
              >
                <Text
                  fontFamily="Noto Sans"
                  fontSize="14px"
                  fontWeight="500"
                  color="rgba(53,56,59,1)"
                  lineHeight="14px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  letterSpacing="0.55px"
                  width="88px"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="干渉波監視"
                  {...getOverrideProps(overrides, "text569221996")}
                ></Text>
              </Flex>
              <Flex
                gap="0"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "YSCC/Kansyoha/Contents")}
              >
                <Flex
                  gap="0"
                  direction="row"
                  width="unset"
                  height="unset"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  display="flex"
                  {...getOverrideProps(overrides, "YSCC/Kansyoha/Error")}
                >
                  <Flex
                    gap="0"
                    direction="row"
                    width="unset"
                    height="unset"
                    justifyContent="center"
                    alignItems="flex-start"
                    shrink="0"
                    alignSelf="stretch"
                    position="relative"
                    padding="16px 24px 16px 24px"
                    backgroundColor="rgba(245,245,245,1)"
                    display="flex"
                    {...getOverrideProps(
                      overrides,
                      "YSCC/Kansyoha/Error/Frame"
                    )}
                  >
                    <Text
                      fontFamily="Noto Sans"
                      fontSize="14px"
                      fontWeight="500"
                      color="rgba(53,56,59,1)"
                      lineHeight="14px"
                      textAlign="left"
                      display="block"
                      direction="column"
                      justifyContent="unset"
                      letterSpacing="0.55px"
                      width="40px"
                      height="unset"
                      gap="unset"
                      alignItems="unset"
                      shrink="0"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      whiteSpace="pre-wrap"
                      children="異常"
                      {...getOverrideProps(overrides, "text569222000")}
                    ></Text>
                    <Spacer
                      display="flex"
                      gap="10px"
                      direction="column"
                      width="16px"
                      height="unset"
                      justifyContent="center"
                      alignItems="center"
                      overflow="hidden"
                      shrink="0"
                      alignSelf="stretch"
                      position="relative"
                      padding="10px 10px 10px 10px"
                      {...getOverrideProps(overrides, "Spacer569222001")}
                    ></Spacer>
                    <Text
                      fontFamily="Noto Sans"
                      fontSize="14px"
                      fontWeight="500"
                      color="rgba(53,56,59,1)"
                      lineHeight="14px"
                      textAlign="right"
                      display="block"
                      direction="column"
                      justifyContent="unset"
                      letterSpacing="0.55px"
                      width="40px"
                      height="unset"
                      gap="unset"
                      alignItems="unset"
                      shrink="0"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      whiteSpace="pre-wrap"
                      children=" 10件"
                      {...getOverrideProps(overrides, "text569222002")}
                    ></Text>
                  </Flex>
                  <Flex
                    gap="0"
                    direction="column"
                    width="unset"
                    height="unset"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    grow="1"
                    shrink="1"
                    basis="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    display="flex"
                    {...getOverrideProps(
                      overrides,
                      "YSCC/Kansyoha/Error/Result"
                    )}
                  >
                    <Flex
                      gap="0"
                      direction="row"
                      width="unset"
                      height="unset"
                      justifyContent="center"
                      alignItems="center"
                      shrink="0"
                      alignSelf="stretch"
                      position="relative"
                      padding="16px 24px 16px 24px"
                      backgroundColor="rgba(255,255,255,1)"
                      display="flex"
                      {...getOverrideProps(
                        overrides,
                        "YSCC/Kansyoha/Error/Result/1"
                      )}
                    >
                      <Text
                        fontFamily="Noto Sans"
                        fontSize="14px"
                        fontWeight="500"
                        color="rgba(53,56,59,1)"
                        lineHeight="14px"
                        textAlign="left"
                        display="block"
                        direction="column"
                        justifyContent="unset"
                        letterSpacing="0.55px"
                        width="unset"
                        height="unset"
                        gap="unset"
                        alignItems="unset"
                        shrink="0"
                        position="relative"
                        padding="0px 0px 0px 0px"
                        whiteSpace="pre-wrap"
                        children="s-1"
                        {...getOverrideProps(overrides, "text569222005")}
                      ></Text>
                      <Spacer
                        display="flex"
                        gap="10px"
                        direction="column"
                        width="unset"
                        height="unset"
                        justifyContent="center"
                        alignItems="center"
                        overflow="hidden"
                        grow="1"
                        shrink="1"
                        basis="0"
                        alignSelf="stretch"
                        position="relative"
                        padding="10px 10px 10px 10px"
                        {...getOverrideProps(overrides, "Spacer569222006")}
                      ></Spacer>
                      <Text
                        fontFamily="Noto Sans"
                        fontSize="14px"
                        fontWeight="500"
                        color="rgba(53,56,59,1)"
                        lineHeight="14px"
                        textAlign="right"
                        display="block"
                        direction="column"
                        justifyContent="unset"
                        letterSpacing="0.55px"
                        width="unset"
                        height="unset"
                        gap="unset"
                        alignItems="unset"
                        shrink="0"
                        position="relative"
                        padding="0px 0px 0px 0px"
                        whiteSpace="pre-wrap"
                        children="5件"
                        {...getOverrideProps(overrides, "text569222007")}
                      ></Text>
                    </Flex>
                    <CommonDivider
                      display="flex"
                      gap="0"
                      direction="row"
                      width="unset"
                      height="1px"
                      justifyContent="center"
                      alignItems="center"
                      overflow="hidden"
                      shrink="0"
                      alignSelf="stretch"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      {...getOverrideProps(
                        overrides,
                        "Common/Divider569222008"
                      )}
                    ></CommonDivider>
                    <Flex
                      gap="0"
                      direction="row"
                      width="unset"
                      height="unset"
                      justifyContent="center"
                      alignItems="center"
                      shrink="0"
                      alignSelf="stretch"
                      position="relative"
                      padding="16px 24px 16px 24px"
                      backgroundColor="rgba(255,255,255,1)"
                      display="flex"
                      {...getOverrideProps(
                        overrides,
                        "YSCC/Kansyoha/Error/Result/2"
                      )}
                    >
                      <Text
                        fontFamily="Noto Sans"
                        fontSize="14px"
                        fontWeight="500"
                        color="rgba(53,56,59,1)"
                        lineHeight="14px"
                        textAlign="left"
                        display="block"
                        direction="column"
                        justifyContent="unset"
                        letterSpacing="0.55px"
                        width="unset"
                        height="unset"
                        gap="unset"
                        alignItems="unset"
                        shrink="0"
                        position="relative"
                        padding="0px 0px 0px 0px"
                        whiteSpace="pre-wrap"
                        children="d-2"
                        {...getOverrideProps(overrides, "text569222010")}
                      ></Text>
                      <Spacer
                        display="flex"
                        gap="10px"
                        direction="column"
                        width="unset"
                        height="unset"
                        justifyContent="center"
                        alignItems="center"
                        overflow="hidden"
                        grow="1"
                        shrink="1"
                        basis="0"
                        alignSelf="stretch"
                        position="relative"
                        padding="10px 10px 10px 10px"
                        {...getOverrideProps(overrides, "Spacer569222011")}
                      ></Spacer>
                      <Text
                        fontFamily="Noto Sans"
                        fontSize="14px"
                        fontWeight="500"
                        color="rgba(53,56,59,1)"
                        lineHeight="14px"
                        textAlign="right"
                        display="block"
                        direction="column"
                        justifyContent="unset"
                        letterSpacing="0.55px"
                        width="unset"
                        height="unset"
                        gap="unset"
                        alignItems="unset"
                        shrink="0"
                        position="relative"
                        padding="0px 0px 0px 0px"
                        whiteSpace="pre-wrap"
                        children="4件"
                        {...getOverrideProps(overrides, "text569222012")}
                      ></Text>
                    </Flex>
                    <CommonDivider
                      display="flex"
                      gap="0"
                      direction="row"
                      width="unset"
                      height="1px"
                      justifyContent="center"
                      alignItems="center"
                      overflow="hidden"
                      shrink="0"
                      alignSelf="stretch"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      {...getOverrideProps(
                        overrides,
                        "Common/Divider569222013"
                      )}
                    ></CommonDivider>
                    <Flex
                      gap="0"
                      direction="row"
                      width="unset"
                      height="unset"
                      justifyContent="center"
                      alignItems="center"
                      shrink="0"
                      alignSelf="stretch"
                      position="relative"
                      padding="16px 24px 16px 24px"
                      backgroundColor="rgba(255,255,255,1)"
                      display="flex"
                      {...getOverrideProps(
                        overrides,
                        "YSCC/Kansyoha/Error/Result/3"
                      )}
                    >
                      <Text
                        fontFamily="Noto Sans"
                        fontSize="14px"
                        fontWeight="500"
                        color="rgba(53,56,59,1)"
                        lineHeight="14px"
                        textAlign="left"
                        display="block"
                        direction="column"
                        justifyContent="unset"
                        letterSpacing="0.55px"
                        width="unset"
                        height="unset"
                        gap="unset"
                        alignItems="unset"
                        shrink="0"
                        position="relative"
                        padding="0px 0px 0px 0px"
                        whiteSpace="pre-wrap"
                        children="k-2"
                        {...getOverrideProps(overrides, "text569222015")}
                      ></Text>
                      <Spacer
                        display="flex"
                        gap="10px"
                        direction="column"
                        width="unset"
                        height="unset"
                        justifyContent="center"
                        alignItems="center"
                        overflow="hidden"
                        grow="1"
                        shrink="1"
                        basis="0"
                        alignSelf="stretch"
                        position="relative"
                        padding="10px 10px 10px 10px"
                        {...getOverrideProps(overrides, "Spacer569222016")}
                      ></Spacer>
                      <Text
                        fontFamily="Noto Sans"
                        fontSize="14px"
                        fontWeight="500"
                        color="rgba(53,56,59,1)"
                        lineHeight="14px"
                        textAlign="right"
                        display="block"
                        direction="column"
                        justifyContent="unset"
                        letterSpacing="0.55px"
                        width="unset"
                        height="unset"
                        gap="unset"
                        alignItems="unset"
                        shrink="0"
                        position="relative"
                        padding="0px 0px 0px 0px"
                        whiteSpace="pre-wrap"
                        children="4件"
                        {...getOverrideProps(overrides, "text569222017")}
                      ></Text>
                    </Flex>
                  </Flex>
                </Flex>
                <CommonDivider
                  display="flex"
                  gap="0"
                  direction="row"
                  width="unset"
                  height="1px"
                  justifyContent="center"
                  alignItems="center"
                  overflow="hidden"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  backgroundColor="rgba(210,210,210,1)"
                  {...getOverrideProps(overrides, "Common/Divider569222018")}
                ></CommonDivider>
                <Flex
                  gap="0"
                  direction="row"
                  width="336px"
                  height="unset"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  display="flex"
                  {...getOverrideProps(overrides, "YSCC/Kansyoha/Warning")}
                >
                  <Flex
                    gap="0"
                    direction="row"
                    width="unset"
                    height="unset"
                    justifyContent="center"
                    alignItems="flex-start"
                    shrink="0"
                    alignSelf="stretch"
                    position="relative"
                    padding="16px 24px 16px 24px"
                    backgroundColor="rgba(245,245,245,1)"
                    display="flex"
                    {...getOverrideProps(
                      overrides,
                      "YSCC/Kansyoha/Warning/Frame"
                    )}
                  >
                    <Text
                      fontFamily="Noto Sans"
                      fontSize="14px"
                      fontWeight="500"
                      color="rgba(53,56,59,1)"
                      lineHeight="14px"
                      textAlign="left"
                      display="block"
                      direction="column"
                      justifyContent="unset"
                      letterSpacing="0.55px"
                      width="40px"
                      height="unset"
                      gap="unset"
                      alignItems="unset"
                      shrink="0"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      whiteSpace="pre-wrap"
                      children="警告"
                      {...getOverrideProps(overrides, "text569222021")}
                    ></Text>
                    <Spacer
                      display="flex"
                      gap="10px"
                      direction="column"
                      width="16px"
                      height="unset"
                      justifyContent="center"
                      alignItems="center"
                      overflow="hidden"
                      shrink="0"
                      alignSelf="stretch"
                      position="relative"
                      padding="10px 10px 10px 10px"
                      {...getOverrideProps(overrides, "Spacer569222022")}
                    ></Spacer>
                    <Text
                      fontFamily="Noto Sans"
                      fontSize="14px"
                      fontWeight="500"
                      color="rgba(53,56,59,1)"
                      lineHeight="14px"
                      textAlign="right"
                      display="block"
                      direction="column"
                      justifyContent="unset"
                      letterSpacing="0.55px"
                      width="40px"
                      height="unset"
                      gap="unset"
                      alignItems="unset"
                      shrink="0"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      whiteSpace="pre-wrap"
                      children=" 2件"
                      {...getOverrideProps(overrides, "text569222023")}
                    ></Text>
                  </Flex>
                  <Flex
                    gap="0"
                    direction="row"
                    width="unset"
                    height="unset"
                    justifyContent="center"
                    alignItems="center"
                    grow="1"
                    shrink="1"
                    basis="0"
                    position="relative"
                    padding="16px 24px 16px 24px"
                    backgroundColor="rgba(255,255,255,1)"
                    display="flex"
                    {...getOverrideProps(
                      overrides,
                      "YSCC/Kansyoha/Warning/Result"
                    )}
                  >
                    <Text
                      fontFamily="Noto Sans"
                      fontSize="14px"
                      fontWeight="500"
                      color="rgba(53,56,59,1)"
                      lineHeight="14px"
                      textAlign="left"
                      display="block"
                      direction="column"
                      justifyContent="unset"
                      letterSpacing="0.55px"
                      width="unset"
                      height="unset"
                      gap="unset"
                      alignItems="unset"
                      shrink="0"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      whiteSpace="pre-wrap"
                      children="s-1"
                      {...getOverrideProps(overrides, "text569222025")}
                    ></Text>
                    <Spacer
                      display="flex"
                      gap="10px"
                      direction="column"
                      width="unset"
                      height="unset"
                      justifyContent="center"
                      alignItems="center"
                      overflow="hidden"
                      grow="1"
                      shrink="1"
                      basis="0"
                      alignSelf="stretch"
                      position="relative"
                      padding="10px 10px 10px 10px"
                      {...getOverrideProps(overrides, "Spacer569222026")}
                    ></Spacer>
                    <Text
                      fontFamily="Noto Sans"
                      fontSize="14px"
                      fontWeight="500"
                      color="rgba(53,56,59,1)"
                      lineHeight="14px"
                      textAlign="right"
                      display="block"
                      direction="column"
                      justifyContent="unset"
                      letterSpacing="0.55px"
                      width="unset"
                      height="unset"
                      gap="unset"
                      alignItems="unset"
                      shrink="0"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      whiteSpace="pre-wrap"
                      children="5件"
                      {...getOverrideProps(overrides, "text569222027")}
                    ></Text>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
            <CommonDivider
              display="flex"
              gap="0"
              direction="row"
              width="unset"
              height="1px"
              justifyContent="center"
              alignItems="center"
              overflow="hidden"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              backgroundColor="rgba(210,210,210,1)"
              {...getOverrideProps(overrides, "Common/Divider569222028")}
            ></CommonDivider>
            <Flex
              gap="0"
              direction="row"
              width="472px"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "YSCC/Kiki")}
            >
              <Flex
                gap="0"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="flex-start"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="16px 24px 16px 24px"
                backgroundColor="rgba(255,255,255,1)"
                display="flex"
                {...getOverrideProps(overrides, "YSCC/Kiki/Frame")}
              >
                <Text
                  fontFamily="Noto Sans"
                  fontSize="14px"
                  fontWeight="500"
                  color="rgba(53,56,59,1)"
                  lineHeight="14px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  letterSpacing="0.55px"
                  width="88px"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="機器監視"
                  {...getOverrideProps(overrides, "text569222031")}
                ></Text>
              </Flex>
              <Flex
                gap="0"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                grow="1"
                shrink="1"
                basis="0"
                position="relative"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "YSCC/Kiki/Contents")}
              >
                <Flex
                  gap="0"
                  direction="row"
                  width="unset"
                  height="unset"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  display="flex"
                  {...getOverrideProps(overrides, "YSCC/Kiki/Error")}
                >
                  <Flex
                    gap="0"
                    direction="row"
                    width="unset"
                    height="unset"
                    justifyContent="center"
                    alignItems="flex-start"
                    shrink="0"
                    alignSelf="stretch"
                    position="relative"
                    padding="16px 24px 16px 24px"
                    backgroundColor="rgba(245,245,245,1)"
                    display="flex"
                    {...getOverrideProps(overrides, "YSCC/Kiki/Error/Frame")}
                  >
                    <Text
                      fontFamily="Noto Sans"
                      fontSize="14px"
                      fontWeight="500"
                      color="rgba(53,56,59,1)"
                      lineHeight="14px"
                      textAlign="left"
                      display="block"
                      direction="column"
                      justifyContent="unset"
                      letterSpacing="0.55px"
                      width="40px"
                      height="unset"
                      gap="unset"
                      alignItems="unset"
                      shrink="0"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      whiteSpace="pre-wrap"
                      children="異常"
                      {...getOverrideProps(overrides, "text569222035")}
                    ></Text>
                    <Spacer
                      display="flex"
                      gap="10px"
                      direction="column"
                      width="16px"
                      height="unset"
                      justifyContent="center"
                      alignItems="center"
                      overflow="hidden"
                      shrink="0"
                      alignSelf="stretch"
                      position="relative"
                      padding="10px 10px 10px 10px"
                      {...getOverrideProps(overrides, "Spacer569222036")}
                    ></Spacer>
                    <Text
                      fontFamily="Noto Sans"
                      fontSize="14px"
                      fontWeight="500"
                      color="rgba(53,56,59,1)"
                      lineHeight="14px"
                      textAlign="right"
                      display="block"
                      direction="column"
                      justifyContent="unset"
                      letterSpacing="0.55px"
                      width="40px"
                      height="unset"
                      gap="unset"
                      alignItems="unset"
                      shrink="0"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      whiteSpace="pre-wrap"
                      children=" 3件"
                      {...getOverrideProps(overrides, "text569222037")}
                    ></Text>
                  </Flex>
                  <Flex
                    gap="0"
                    direction="column"
                    width="unset"
                    height="unset"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    grow="1"
                    shrink="1"
                    basis="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    display="flex"
                    {...getOverrideProps(overrides, "YSCC/Kiki/Error/Result")}
                  >
                    <Flex
                      gap="0"
                      direction="row"
                      width="unset"
                      height="unset"
                      justifyContent="center"
                      alignItems="center"
                      shrink="0"
                      alignSelf="stretch"
                      position="relative"
                      padding="16px 24px 16px 24px"
                      backgroundColor="rgba(255,255,255,1)"
                      display="flex"
                      {...getOverrideProps(
                        overrides,
                        "YSCC/Kiki/Error/Result/1"
                      )}
                    >
                      <Text
                        fontFamily="Noto Sans"
                        fontSize="14px"
                        fontWeight="500"
                        color="rgba(53,56,59,1)"
                        lineHeight="14px"
                        textAlign="left"
                        display="block"
                        direction="column"
                        justifyContent="unset"
                        letterSpacing="0.55px"
                        width="unset"
                        height="unset"
                        gap="unset"
                        alignItems="unset"
                        shrink="0"
                        position="relative"
                        padding="0px 0px 0px 0px"
                        whiteSpace="pre-wrap"
                        children="DC電源"
                        {...getOverrideProps(overrides, "text569222040")}
                      ></Text>
                      <Spacer
                        display="flex"
                        gap="10px"
                        direction="column"
                        width="unset"
                        height="unset"
                        justifyContent="center"
                        alignItems="center"
                        overflow="hidden"
                        grow="1"
                        shrink="1"
                        basis="0"
                        alignSelf="stretch"
                        position="relative"
                        padding="10px 10px 10px 10px"
                        {...getOverrideProps(overrides, "Spacer569222041")}
                      ></Spacer>
                      <Text
                        fontFamily="Noto Sans"
                        fontSize="14px"
                        fontWeight="500"
                        color="rgba(53,56,59,1)"
                        lineHeight="14px"
                        textAlign="right"
                        display="block"
                        direction="column"
                        justifyContent="unset"
                        letterSpacing="0.55px"
                        width="unset"
                        height="unset"
                        gap="unset"
                        alignItems="unset"
                        shrink="0"
                        position="relative"
                        padding="0px 0px 0px 0px"
                        whiteSpace="pre-wrap"
                        children="5件"
                        {...getOverrideProps(overrides, "text569222042")}
                      ></Text>
                    </Flex>
                    <CommonDivider
                      display="flex"
                      gap="0"
                      direction="row"
                      width="unset"
                      height="1px"
                      justifyContent="center"
                      alignItems="center"
                      overflow="hidden"
                      shrink="0"
                      alignSelf="stretch"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      {...getOverrideProps(
                        overrides,
                        "Common/Divider569222043"
                      )}
                    ></CommonDivider>
                    <Flex
                      gap="0"
                      direction="row"
                      width="unset"
                      height="unset"
                      justifyContent="center"
                      alignItems="center"
                      shrink="0"
                      alignSelf="stretch"
                      position="relative"
                      padding="16px 24px 16px 24px"
                      backgroundColor="rgba(255,255,255,1)"
                      display="flex"
                      {...getOverrideProps(
                        overrides,
                        "YSCC/Kiki/Error/Result/2"
                      )}
                    >
                      <Text
                        fontFamily="Noto Sans"
                        fontSize="14px"
                        fontWeight="500"
                        color="rgba(53,56,59,1)"
                        lineHeight="14px"
                        textAlign="left"
                        display="block"
                        direction="column"
                        justifyContent="unset"
                        letterSpacing="0.55px"
                        width="unset"
                        height="unset"
                        gap="unset"
                        alignItems="unset"
                        shrink="0"
                        position="relative"
                        padding="0px 0px 0px 0px"
                        whiteSpace="pre-wrap"
                        children="NW SW"
                        {...getOverrideProps(overrides, "text569222045")}
                      ></Text>
                      <Spacer
                        display="flex"
                        gap="10px"
                        direction="column"
                        width="unset"
                        height="unset"
                        justifyContent="center"
                        alignItems="center"
                        overflow="hidden"
                        grow="1"
                        shrink="1"
                        basis="0"
                        alignSelf="stretch"
                        position="relative"
                        padding="10px 10px 10px 10px"
                        {...getOverrideProps(overrides, "Spacer569222046")}
                      ></Spacer>
                      <Text
                        fontFamily="Noto Sans"
                        fontSize="14px"
                        fontWeight="500"
                        color="rgba(53,56,59,1)"
                        lineHeight="14px"
                        textAlign="right"
                        display="block"
                        direction="column"
                        justifyContent="unset"
                        letterSpacing="0.55px"
                        width="unset"
                        height="unset"
                        gap="unset"
                        alignItems="unset"
                        shrink="0"
                        position="relative"
                        padding="0px 0px 0px 0px"
                        whiteSpace="pre-wrap"
                        children="4件"
                        {...getOverrideProps(overrides, "text569222047")}
                      ></Text>
                    </Flex>
                  </Flex>
                </Flex>
                <CommonDivider
                  display="flex"
                  gap="0"
                  direction="row"
                  width="unset"
                  height="1px"
                  justifyContent="center"
                  alignItems="center"
                  overflow="hidden"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  backgroundColor="rgba(210,210,210,1)"
                  {...getOverrideProps(overrides, "Common/Divider569222048")}
                ></CommonDivider>
                <Flex
                  gap="0"
                  direction="row"
                  width="336px"
                  height="unset"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  display="flex"
                  {...getOverrideProps(overrides, "YSCC/Kiki/Warning")}
                >
                  <Flex
                    gap="0"
                    direction="row"
                    width="unset"
                    height="unset"
                    justifyContent="center"
                    alignItems="flex-start"
                    shrink="0"
                    alignSelf="stretch"
                    position="relative"
                    padding="16px 24px 16px 24px"
                    backgroundColor="rgba(245,245,245,1)"
                    display="flex"
                    {...getOverrideProps(overrides, "YSCC/Kiki/Warning/Frame")}
                  >
                    <Text
                      fontFamily="Noto Sans"
                      fontSize="14px"
                      fontWeight="500"
                      color="rgba(53,56,59,1)"
                      lineHeight="14px"
                      textAlign="left"
                      display="block"
                      direction="column"
                      justifyContent="unset"
                      letterSpacing="0.55px"
                      width="40px"
                      height="unset"
                      gap="unset"
                      alignItems="unset"
                      shrink="0"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      whiteSpace="pre-wrap"
                      children="警告"
                      {...getOverrideProps(overrides, "text569222051")}
                    ></Text>
                    <Spacer
                      display="flex"
                      gap="10px"
                      direction="column"
                      width="16px"
                      height="unset"
                      justifyContent="center"
                      alignItems="center"
                      overflow="hidden"
                      shrink="0"
                      alignSelf="stretch"
                      position="relative"
                      padding="10px 10px 10px 10px"
                      {...getOverrideProps(overrides, "Spacer569222052")}
                    ></Spacer>
                    <Text
                      fontFamily="Noto Sans"
                      fontSize="14px"
                      fontWeight="500"
                      color="rgba(53,56,59,1)"
                      lineHeight="14px"
                      textAlign="right"
                      display="block"
                      direction="column"
                      justifyContent="unset"
                      letterSpacing="0.55px"
                      width="40px"
                      height="unset"
                      gap="unset"
                      alignItems="unset"
                      shrink="0"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      whiteSpace="pre-wrap"
                      children=" 4件"
                      {...getOverrideProps(overrides, "text569222053")}
                    ></Text>
                  </Flex>
                  <Flex
                    gap="0"
                    direction="row"
                    width="unset"
                    height="unset"
                    justifyContent="center"
                    alignItems="center"
                    grow="1"
                    shrink="1"
                    basis="0"
                    position="relative"
                    padding="16px 24px 16px 24px"
                    backgroundColor="rgba(255,255,255,1)"
                    display="flex"
                    {...getOverrideProps(overrides, "YSCC/Kiki/Warning/Result")}
                  >
                    <Text
                      fontFamily="Noto Sans"
                      fontSize="14px"
                      fontWeight="500"
                      color="rgba(53,56,59,1)"
                      lineHeight="14px"
                      textAlign="left"
                      display="block"
                      direction="column"
                      justifyContent="unset"
                      letterSpacing="0.55px"
                      width="unset"
                      height="unset"
                      gap="unset"
                      alignItems="unset"
                      shrink="0"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      whiteSpace="pre-wrap"
                      children="DC電源"
                      {...getOverrideProps(overrides, "text569222055")}
                    ></Text>
                    <Spacer
                      display="flex"
                      gap="10px"
                      direction="column"
                      width="unset"
                      height="unset"
                      justifyContent="center"
                      alignItems="center"
                      overflow="hidden"
                      grow="1"
                      shrink="1"
                      basis="0"
                      alignSelf="stretch"
                      position="relative"
                      padding="10px 10px 10px 10px"
                      {...getOverrideProps(overrides, "Spacer569222056")}
                    ></Spacer>
                    <Text
                      fontFamily="Noto Sans"
                      fontSize="14px"
                      fontWeight="500"
                      color="rgba(53,56,59,1)"
                      lineHeight="14px"
                      textAlign="right"
                      display="block"
                      direction="column"
                      justifyContent="unset"
                      letterSpacing="0.55px"
                      width="unset"
                      height="unset"
                      gap="unset"
                      alignItems="unset"
                      shrink="0"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      whiteSpace="pre-wrap"
                      children=" 4件"
                      {...getOverrideProps(overrides, "text569222057")}
                    ></Text>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <CommonDivider
          display="flex"
          gap="0"
          direction="row"
          width="unset"
          height="1px"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          backgroundColor="rgba(210,210,210,1)"
          {...getOverrideProps(overrides, "Common/Divider569222058")}
        ></CommonDivider>
        <Flex
          gap="0"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "SPE569222059")}
        >
          <Flex
            gap="0"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="16px 24px 16px 24px"
            backgroundColor="rgba(245,245,245,1)"
            display="flex"
            {...getOverrideProps(overrides, "SPE/Title")}
          >
            <Text
              fontFamily="Noto Sans"
              fontSize="14px"
              fontWeight="500"
              color="rgba(53,56,59,1)"
              lineHeight="14px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              letterSpacing="0.55px"
              width="80px"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="SPE"
              {...getOverrideProps(overrides, "SPE569222061")}
            ></Text>
            <Spacer
              display="flex"
              gap="10px"
              direction="column"
              width="16px"
              height="unset"
              justifyContent="center"
              alignItems="center"
              overflow="hidden"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="10px 10px 10px 10px"
              {...getOverrideProps(overrides, "Spacer569222062")}
            ></Spacer>
            <Text
              fontFamily="Noto Sans"
              fontSize="14px"
              fontWeight="500"
              color="rgba(53,56,59,1)"
              lineHeight="14px"
              textAlign="right"
              display="block"
              direction="column"
              justifyContent="unset"
              letterSpacing="0.55px"
              width="40px"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children=" 7件"
              {...getOverrideProps(overrides, "Number569222063")}
            ></Text>
          </Flex>
          <Flex
            gap="0"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "SPE/Contents")}
          >
            <Flex
              gap="0"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "SPE/Kansyoha")}
            >
              <Flex
                gap="0"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="flex-start"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="16px 24px 16px 24px"
                backgroundColor="rgba(255,255,255,1)"
                display="flex"
                {...getOverrideProps(overrides, "SPE/Kansyoha/Frame")}
              >
                <Text
                  fontFamily="Noto Sans"
                  fontSize="14px"
                  fontWeight="500"
                  color="rgba(53,56,59,1)"
                  lineHeight="14px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  letterSpacing="0.55px"
                  width="88px"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="干渉波監視"
                  {...getOverrideProps(overrides, "text569222067")}
                ></Text>
              </Flex>
              <Flex
                gap="0"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "SPE/Kansyoha/Contents")}
              >
                <Flex
                  gap="0"
                  direction="row"
                  width="unset"
                  height="unset"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  display="flex"
                  {...getOverrideProps(overrides, "SPE/Kansyoha/Error")}
                >
                  <Flex
                    gap="0"
                    direction="row"
                    width="unset"
                    height="unset"
                    justifyContent="center"
                    alignItems="flex-start"
                    shrink="0"
                    alignSelf="stretch"
                    position="relative"
                    padding="16px 24px 16px 24px"
                    backgroundColor="rgba(245,245,245,1)"
                    display="flex"
                    {...getOverrideProps(overrides, "SPE/Kansyoha/Error/Frame")}
                  >
                    <Text
                      fontFamily="Noto Sans"
                      fontSize="14px"
                      fontWeight="500"
                      color="rgba(53,56,59,1)"
                      lineHeight="14px"
                      textAlign="left"
                      display="block"
                      direction="column"
                      justifyContent="unset"
                      letterSpacing="0.55px"
                      width="40px"
                      height="unset"
                      gap="unset"
                      alignItems="unset"
                      shrink="0"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      whiteSpace="pre-wrap"
                      children="異常"
                      {...getOverrideProps(overrides, "text569222071")}
                    ></Text>
                    <Spacer
                      display="flex"
                      gap="10px"
                      direction="column"
                      width="16px"
                      height="unset"
                      justifyContent="center"
                      alignItems="center"
                      overflow="hidden"
                      shrink="0"
                      alignSelf="stretch"
                      position="relative"
                      padding="10px 10px 10px 10px"
                      {...getOverrideProps(overrides, "Spacer569222072")}
                    ></Spacer>
                    <Text
                      fontFamily="Noto Sans"
                      fontSize="14px"
                      fontWeight="500"
                      color="rgba(53,56,59,1)"
                      lineHeight="14px"
                      textAlign="right"
                      display="block"
                      direction="column"
                      justifyContent="unset"
                      letterSpacing="0.55px"
                      width="40px"
                      height="unset"
                      gap="unset"
                      alignItems="unset"
                      shrink="0"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      whiteSpace="pre-wrap"
                      children="5件"
                      {...getOverrideProps(overrides, "text569222073")}
                    ></Text>
                  </Flex>
                  <Flex
                    gap="0"
                    direction="column"
                    width="192px"
                    height="unset"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    display="flex"
                    {...getOverrideProps(
                      overrides,
                      "SPE/Kansyoha/Error/Result"
                    )}
                  >
                    <Flex
                      gap="0"
                      direction="row"
                      width="unset"
                      height="unset"
                      justifyContent="center"
                      alignItems="center"
                      shrink="0"
                      alignSelf="stretch"
                      position="relative"
                      padding="16px 24px 16px 24px"
                      backgroundColor="rgba(255,255,255,1)"
                      display="flex"
                      {...getOverrideProps(
                        overrides,
                        "SPE/Kansyoha/Error/Result/1"
                      )}
                    >
                      <Text
                        fontFamily="Noto Sans"
                        fontSize="14px"
                        fontWeight="500"
                        color="rgba(53,56,59,1)"
                        lineHeight="14px"
                        textAlign="left"
                        display="block"
                        direction="column"
                        justifyContent="unset"
                        letterSpacing="0.55px"
                        width="unset"
                        height="unset"
                        gap="unset"
                        alignItems="unset"
                        shrink="0"
                        position="relative"
                        padding="0px 0px 0px 0px"
                        whiteSpace="pre-wrap"
                        children="s-1"
                        {...getOverrideProps(overrides, "text569222076")}
                      ></Text>
                      <Spacer
                        display="flex"
                        gap="10px"
                        direction="column"
                        width="unset"
                        height="unset"
                        justifyContent="center"
                        alignItems="center"
                        overflow="hidden"
                        grow="1"
                        shrink="1"
                        basis="0"
                        alignSelf="stretch"
                        position="relative"
                        padding="10px 10px 10px 10px"
                        {...getOverrideProps(overrides, "Spacer569222077")}
                      ></Spacer>
                      <Text
                        fontFamily="Noto Sans"
                        fontSize="14px"
                        fontWeight="500"
                        color="rgba(53,56,59,1)"
                        lineHeight="14px"
                        textAlign="right"
                        display="block"
                        direction="column"
                        justifyContent="unset"
                        letterSpacing="0.55px"
                        width="unset"
                        height="unset"
                        gap="unset"
                        alignItems="unset"
                        shrink="0"
                        position="relative"
                        padding="0px 0px 0px 0px"
                        whiteSpace="pre-wrap"
                        children="2件"
                        {...getOverrideProps(overrides, "text569222078")}
                      ></Text>
                    </Flex>
                    <CommonDivider
                      display="flex"
                      gap="0"
                      direction="row"
                      width="unset"
                      height="1px"
                      justifyContent="center"
                      alignItems="center"
                      overflow="hidden"
                      shrink="0"
                      alignSelf="stretch"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      {...getOverrideProps(
                        overrides,
                        "Common/Divider569222079"
                      )}
                    ></CommonDivider>
                    <Flex
                      gap="0"
                      direction="row"
                      width="unset"
                      height="unset"
                      justifyContent="center"
                      alignItems="center"
                      shrink="0"
                      alignSelf="stretch"
                      position="relative"
                      padding="16px 24px 16px 24px"
                      backgroundColor="rgba(255,255,255,1)"
                      display="flex"
                      {...getOverrideProps(
                        overrides,
                        "SPE/Kansyoha/Error/Result/2"
                      )}
                    >
                      <Text
                        fontFamily="Noto Sans"
                        fontSize="14px"
                        fontWeight="500"
                        color="rgba(53,56,59,1)"
                        lineHeight="14px"
                        textAlign="left"
                        display="block"
                        direction="column"
                        justifyContent="unset"
                        letterSpacing="0.55px"
                        width="unset"
                        height="unset"
                        gap="unset"
                        alignItems="unset"
                        shrink="0"
                        position="relative"
                        padding="0px 0px 0px 0px"
                        whiteSpace="pre-wrap"
                        children="d-2"
                        {...getOverrideProps(overrides, "text569222081")}
                      ></Text>
                      <Spacer
                        display="flex"
                        gap="10px"
                        direction="column"
                        width="unset"
                        height="unset"
                        justifyContent="center"
                        alignItems="center"
                        overflow="hidden"
                        grow="1"
                        shrink="1"
                        basis="0"
                        alignSelf="stretch"
                        position="relative"
                        padding="10px 10px 10px 10px"
                        {...getOverrideProps(overrides, "Spacer569222082")}
                      ></Spacer>
                      <Text
                        fontFamily="Noto Sans"
                        fontSize="14px"
                        fontWeight="500"
                        color="rgba(53,56,59,1)"
                        lineHeight="14px"
                        textAlign="right"
                        display="block"
                        direction="column"
                        justifyContent="unset"
                        letterSpacing="0.55px"
                        width="unset"
                        height="unset"
                        gap="unset"
                        alignItems="unset"
                        shrink="0"
                        position="relative"
                        padding="0px 0px 0px 0px"
                        whiteSpace="pre-wrap"
                        children="1件"
                        {...getOverrideProps(overrides, "text569222083")}
                      ></Text>
                    </Flex>
                    <CommonDivider
                      display="flex"
                      gap="0"
                      direction="row"
                      width="unset"
                      height="1px"
                      justifyContent="center"
                      alignItems="center"
                      overflow="hidden"
                      shrink="0"
                      alignSelf="stretch"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      {...getOverrideProps(
                        overrides,
                        "Common/Divider569222084"
                      )}
                    ></CommonDivider>
                    <Flex
                      gap="0"
                      direction="row"
                      width="unset"
                      height="unset"
                      justifyContent="center"
                      alignItems="center"
                      shrink="0"
                      alignSelf="stretch"
                      position="relative"
                      padding="16px 24px 16px 24px"
                      backgroundColor="rgba(255,255,255,1)"
                      display="flex"
                      {...getOverrideProps(
                        overrides,
                        "SPE/Kansyoha/Error/Result/3"
                      )}
                    >
                      <Text
                        fontFamily="Noto Sans"
                        fontSize="14px"
                        fontWeight="500"
                        color="rgba(53,56,59,1)"
                        lineHeight="14px"
                        textAlign="left"
                        display="block"
                        direction="column"
                        justifyContent="unset"
                        letterSpacing="0.55px"
                        width="unset"
                        height="unset"
                        gap="unset"
                        alignItems="unset"
                        shrink="0"
                        position="relative"
                        padding="0px 0px 0px 0px"
                        whiteSpace="pre-wrap"
                        children="k-2"
                        {...getOverrideProps(overrides, "text569222086")}
                      ></Text>
                      <Spacer
                        display="flex"
                        gap="10px"
                        direction="column"
                        width="unset"
                        height="unset"
                        justifyContent="center"
                        alignItems="center"
                        overflow="hidden"
                        grow="1"
                        shrink="1"
                        basis="0"
                        alignSelf="stretch"
                        position="relative"
                        padding="10px 10px 10px 10px"
                        {...getOverrideProps(overrides, "Spacer569222087")}
                      ></Spacer>
                      <Text
                        fontFamily="Noto Sans"
                        fontSize="14px"
                        fontWeight="500"
                        color="rgba(53,56,59,1)"
                        lineHeight="14px"
                        textAlign="right"
                        display="block"
                        direction="column"
                        justifyContent="unset"
                        letterSpacing="0.55px"
                        width="unset"
                        height="unset"
                        gap="unset"
                        alignItems="unset"
                        shrink="0"
                        position="relative"
                        padding="0px 0px 0px 0px"
                        whiteSpace="pre-wrap"
                        children="2件"
                        {...getOverrideProps(overrides, "text569222088")}
                      ></Text>
                    </Flex>
                  </Flex>
                </Flex>
                <CommonDivider
                  display="flex"
                  gap="0"
                  direction="row"
                  width="unset"
                  height="1px"
                  justifyContent="center"
                  alignItems="center"
                  overflow="hidden"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  backgroundColor="rgba(210,210,210,1)"
                  {...getOverrideProps(overrides, "Common/Divider569222089")}
                ></CommonDivider>
                <Flex
                  gap="0"
                  direction="row"
                  width="336px"
                  height="unset"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  display="flex"
                  {...getOverrideProps(overrides, "SPE/Kansyoha/Warning")}
                >
                  <Flex
                    gap="0"
                    direction="row"
                    width="unset"
                    height="unset"
                    justifyContent="center"
                    alignItems="flex-start"
                    shrink="0"
                    alignSelf="stretch"
                    position="relative"
                    padding="16px 24px 16px 24px"
                    backgroundColor="rgba(245,245,245,1)"
                    display="flex"
                    {...getOverrideProps(
                      overrides,
                      "SPE/Kansyoha/Warning/Frame"
                    )}
                  >
                    <Text
                      fontFamily="Noto Sans"
                      fontSize="14px"
                      fontWeight="500"
                      color="rgba(53,56,59,1)"
                      lineHeight="14px"
                      textAlign="left"
                      display="block"
                      direction="column"
                      justifyContent="unset"
                      letterSpacing="0.55px"
                      width="40px"
                      height="unset"
                      gap="unset"
                      alignItems="unset"
                      shrink="0"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      whiteSpace="pre-wrap"
                      children="警告"
                      {...getOverrideProps(overrides, "text569222092")}
                    ></Text>
                    <Spacer
                      display="flex"
                      gap="10px"
                      direction="column"
                      width="16px"
                      height="unset"
                      justifyContent="center"
                      alignItems="center"
                      overflow="hidden"
                      shrink="0"
                      alignSelf="stretch"
                      position="relative"
                      padding="10px 10px 10px 10px"
                      {...getOverrideProps(overrides, "Spacer569222093")}
                    ></Spacer>
                    <Text
                      fontFamily="Noto Sans"
                      fontSize="14px"
                      fontWeight="500"
                      color="rgba(53,56,59,1)"
                      lineHeight="14px"
                      textAlign="right"
                      display="block"
                      direction="column"
                      justifyContent="unset"
                      letterSpacing="0.55px"
                      width="40px"
                      height="unset"
                      gap="unset"
                      alignItems="unset"
                      shrink="0"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      whiteSpace="pre-wrap"
                      children="1件"
                      {...getOverrideProps(overrides, "text569222094")}
                    ></Text>
                  </Flex>
                  <Flex
                    gap="0"
                    direction="row"
                    width="unset"
                    height="unset"
                    justifyContent="center"
                    alignItems="center"
                    grow="1"
                    shrink="1"
                    basis="0"
                    position="relative"
                    padding="16px 24px 16px 24px"
                    backgroundColor="rgba(255,255,255,1)"
                    display="flex"
                    {...getOverrideProps(
                      overrides,
                      "SPE/Kansyoha/Warning/Result"
                    )}
                  >
                    <Text
                      fontFamily="Noto Sans"
                      fontSize="14px"
                      fontWeight="500"
                      color="rgba(53,56,59,1)"
                      lineHeight="14px"
                      textAlign="left"
                      display="block"
                      direction="column"
                      justifyContent="unset"
                      letterSpacing="0.55px"
                      width="unset"
                      height="unset"
                      gap="unset"
                      alignItems="unset"
                      shrink="0"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      whiteSpace="pre-wrap"
                      children="s-1"
                      {...getOverrideProps(overrides, "text569222096")}
                    ></Text>
                    <Spacer
                      display="flex"
                      gap="10px"
                      direction="column"
                      width="unset"
                      height="unset"
                      justifyContent="center"
                      alignItems="center"
                      overflow="hidden"
                      grow="1"
                      shrink="1"
                      basis="0"
                      alignSelf="stretch"
                      position="relative"
                      padding="10px 10px 10px 10px"
                      {...getOverrideProps(overrides, "Spacer569222097")}
                    ></Spacer>
                    <Text
                      fontFamily="Noto Sans"
                      fontSize="14px"
                      fontWeight="500"
                      color="rgba(53,56,59,1)"
                      lineHeight="14px"
                      textAlign="right"
                      display="block"
                      direction="column"
                      justifyContent="unset"
                      letterSpacing="0.55px"
                      width="unset"
                      height="unset"
                      gap="unset"
                      alignItems="unset"
                      shrink="0"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      whiteSpace="pre-wrap"
                      children="1件"
                      {...getOverrideProps(overrides, "text569222098")}
                    ></Text>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
            <CommonDivider
              display="flex"
              gap="0"
              direction="row"
              width="unset"
              height="1px"
              justifyContent="center"
              alignItems="center"
              overflow="hidden"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              backgroundColor="rgba(210,210,210,1)"
              {...getOverrideProps(overrides, "Common/Divider569222099")}
            ></CommonDivider>
            <Flex
              gap="0"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "SPE/Kiki")}
            >
              <Flex
                gap="0"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="flex-start"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="16px 24px 16px 24px"
                backgroundColor="rgba(255,255,255,1)"
                display="flex"
                {...getOverrideProps(overrides, "SPE/Kiki/Frame")}
              >
                <Text
                  fontFamily="Noto Sans"
                  fontSize="14px"
                  fontWeight="500"
                  color="rgba(53,56,59,1)"
                  lineHeight="14px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  letterSpacing="0.55px"
                  width="88px"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="機器監視"
                  {...getOverrideProps(overrides, "text569222102")}
                ></Text>
              </Flex>
              <Flex
                gap="0"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "SPE/Kiki/Contents")}
              >
                <Flex
                  gap="0"
                  direction="row"
                  width="unset"
                  height="unset"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  display="flex"
                  {...getOverrideProps(overrides, "SPE/Kiki/Error")}
                >
                  <Flex
                    gap="0"
                    direction="row"
                    width="unset"
                    height="unset"
                    justifyContent="center"
                    alignItems="flex-start"
                    shrink="0"
                    alignSelf="stretch"
                    position="relative"
                    padding="16px 24px 16px 24px"
                    backgroundColor="rgba(245,245,245,1)"
                    display="flex"
                    {...getOverrideProps(overrides, "SPE/Kiki/Error/Frame")}
                  >
                    <Text
                      fontFamily="Noto Sans"
                      fontSize="14px"
                      fontWeight="500"
                      color="rgba(53,56,59,1)"
                      lineHeight="14px"
                      textAlign="left"
                      display="block"
                      direction="column"
                      justifyContent="unset"
                      letterSpacing="0.55px"
                      width="40px"
                      height="unset"
                      gap="unset"
                      alignItems="unset"
                      shrink="0"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      whiteSpace="pre-wrap"
                      children="異常"
                      {...getOverrideProps(overrides, "text569222106")}
                    ></Text>
                    <Spacer
                      display="flex"
                      gap="10px"
                      direction="column"
                      width="16px"
                      height="unset"
                      justifyContent="center"
                      alignItems="center"
                      overflow="hidden"
                      shrink="0"
                      alignSelf="stretch"
                      position="relative"
                      padding="10px 10px 10px 10px"
                      {...getOverrideProps(overrides, "Spacer569222107")}
                    ></Spacer>
                    <Text
                      fontFamily="Noto Sans"
                      fontSize="14px"
                      fontWeight="500"
                      color="rgba(53,56,59,1)"
                      lineHeight="14px"
                      textAlign="right"
                      display="block"
                      direction="column"
                      justifyContent="unset"
                      letterSpacing="0.55px"
                      width="40px"
                      height="unset"
                      gap="unset"
                      alignItems="unset"
                      shrink="0"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      whiteSpace="pre-wrap"
                      children="1件"
                      {...getOverrideProps(overrides, "text569222108")}
                    ></Text>
                  </Flex>
                  <Flex
                    gap="0"
                    direction="column"
                    width="unset"
                    height="unset"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    display="flex"
                    {...getOverrideProps(overrides, "SPE/Kiki/Error/Result")}
                  >
                    <Flex
                      gap="0"
                      direction="row"
                      width="unset"
                      height="unset"
                      justifyContent="center"
                      alignItems="center"
                      shrink="0"
                      alignSelf="stretch"
                      position="relative"
                      padding="16px 24px 16px 24px"
                      backgroundColor="rgba(255,255,255,1)"
                      display="flex"
                      {...getOverrideProps(
                        overrides,
                        "SPE/Kiki/Error/Result/1"
                      )}
                    >
                      <Text
                        fontFamily="Noto Sans"
                        fontSize="14px"
                        fontWeight="500"
                        color="rgba(53,56,59,1)"
                        lineHeight="14px"
                        textAlign="left"
                        display="block"
                        direction="column"
                        justifyContent="unset"
                        letterSpacing="0.55px"
                        width="unset"
                        height="unset"
                        gap="unset"
                        alignItems="unset"
                        shrink="0"
                        position="relative"
                        padding="0px 0px 0px 0px"
                        whiteSpace="pre-wrap"
                        children="DC電源"
                        {...getOverrideProps(overrides, "text569222111")}
                      ></Text>
                      <Spacer
                        display="flex"
                        gap="10px"
                        direction="column"
                        width="unset"
                        height="unset"
                        justifyContent="center"
                        alignItems="center"
                        overflow="hidden"
                        grow="1"
                        shrink="1"
                        basis="0"
                        alignSelf="stretch"
                        position="relative"
                        padding="10px 10px 10px 10px"
                        {...getOverrideProps(overrides, "Spacer569222112")}
                      ></Spacer>
                      <Text
                        fontFamily="Noto Sans"
                        fontSize="14px"
                        fontWeight="500"
                        color="rgba(53,56,59,1)"
                        lineHeight="14px"
                        textAlign="right"
                        display="block"
                        direction="column"
                        justifyContent="unset"
                        letterSpacing="0.55px"
                        width="unset"
                        height="unset"
                        gap="unset"
                        alignItems="unset"
                        shrink="0"
                        position="relative"
                        padding="0px 0px 0px 0px"
                        whiteSpace="pre-wrap"
                        children="1件"
                        {...getOverrideProps(overrides, "text569222113")}
                      ></Text>
                    </Flex>
                    <CommonDivider
                      display="none"
                      gap="0"
                      direction="row"
                      width="unset"
                      height="1px"
                      justifyContent="center"
                      alignItems="center"
                      overflow="hidden"
                      shrink="0"
                      alignSelf="stretch"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      {...getOverrideProps(
                        overrides,
                        "Common/Divider569222114"
                      )}
                    ></CommonDivider>
                    <Flex
                      gap="0"
                      direction="row"
                      width="unset"
                      height="unset"
                      justifyContent="center"
                      alignItems="center"
                      shrink="0"
                      alignSelf="stretch"
                      position="relative"
                      padding="16px 24px 16px 24px"
                      backgroundColor="rgba(255,255,255,1)"
                      display="none"
                      {...getOverrideProps(
                        overrides,
                        "SPE/Kiki/Error/Result/2"
                      )}
                    >
                      <Text
                        fontFamily="Noto Sans"
                        fontSize="14px"
                        fontWeight="500"
                        color="rgba(53,56,59,1)"
                        lineHeight="14px"
                        textAlign="left"
                        display="block"
                        direction="column"
                        justifyContent="unset"
                        letterSpacing="0.55px"
                        width="80px"
                        height="unset"
                        gap="unset"
                        alignItems="unset"
                        shrink="0"
                        position="relative"
                        padding="0px 0px 0px 0px"
                        whiteSpace="pre-wrap"
                        children="NW SW"
                        {...getOverrideProps(overrides, "text569222116")}
                      ></Text>
                      <Spacer
                        display="flex"
                        gap="10px"
                        direction="column"
                        width="unset"
                        height="unset"
                        justifyContent="center"
                        alignItems="center"
                        overflow="hidden"
                        grow="1"
                        shrink="1"
                        basis="0"
                        alignSelf="stretch"
                        position="relative"
                        padding="10px 10px 10px 10px"
                        {...getOverrideProps(overrides, "Spacer569222117")}
                      ></Spacer>
                      <Text
                        fontFamily="Noto Sans"
                        fontSize="14px"
                        fontWeight="500"
                        color="rgba(53,56,59,1)"
                        lineHeight="14px"
                        textAlign="right"
                        display="block"
                        direction="column"
                        justifyContent="unset"
                        letterSpacing="0.55px"
                        width="48px"
                        height="unset"
                        gap="unset"
                        alignItems="unset"
                        shrink="0"
                        position="relative"
                        padding="0px 0px 0px 0px"
                        whiteSpace="pre-wrap"
                        children="4件"
                        {...getOverrideProps(overrides, "text569222118")}
                      ></Text>
                    </Flex>
                  </Flex>
                </Flex>
                <CommonDivider
                  display="flex"
                  gap="0"
                  direction="row"
                  width="unset"
                  height="1px"
                  justifyContent="center"
                  alignItems="center"
                  overflow="hidden"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  backgroundColor="rgba(210,210,210,1)"
                  {...getOverrideProps(overrides, "Common/Divider569222119")}
                ></CommonDivider>
                <Flex
                  gap="0"
                  direction="row"
                  width="336px"
                  height="unset"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  display="flex"
                  {...getOverrideProps(overrides, "SPE/Kiki/Warning")}
                >
                  <Flex
                    gap="0"
                    direction="row"
                    width="unset"
                    height="unset"
                    justifyContent="center"
                    alignItems="flex-start"
                    shrink="0"
                    alignSelf="stretch"
                    position="relative"
                    padding="16px 24px 16px 24px"
                    backgroundColor="rgba(245,245,245,1)"
                    display="flex"
                    {...getOverrideProps(overrides, "SPE/Kiki/Warning/Frame")}
                  >
                    <Text
                      fontFamily="Noto Sans"
                      fontSize="14px"
                      fontWeight="500"
                      color="rgba(53,56,59,1)"
                      lineHeight="14px"
                      textAlign="left"
                      display="block"
                      direction="column"
                      justifyContent="unset"
                      letterSpacing="0.55px"
                      width="40px"
                      height="unset"
                      gap="unset"
                      alignItems="unset"
                      shrink="0"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      whiteSpace="pre-wrap"
                      children="警告"
                      {...getOverrideProps(overrides, "text569222122")}
                    ></Text>
                    <Spacer
                      display="flex"
                      gap="10px"
                      direction="column"
                      width="16px"
                      height="unset"
                      justifyContent="center"
                      alignItems="center"
                      overflow="hidden"
                      shrink="0"
                      alignSelf="stretch"
                      position="relative"
                      padding="10px 10px 10px 10px"
                      {...getOverrideProps(overrides, "Spacer569222123")}
                    ></Spacer>
                    <Text
                      fontFamily="Noto Sans"
                      fontSize="14px"
                      fontWeight="500"
                      color="rgba(53,56,59,1)"
                      lineHeight="14px"
                      textAlign="right"
                      display="block"
                      direction="column"
                      justifyContent="unset"
                      letterSpacing="0.55px"
                      width="40px"
                      height="unset"
                      gap="unset"
                      alignItems="unset"
                      shrink="0"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      whiteSpace="pre-wrap"
                      children=" 0件"
                      {...getOverrideProps(overrides, "text569222124")}
                    ></Text>
                  </Flex>
                  <Flex
                    gap="0"
                    direction="row"
                    width="unset"
                    height="unset"
                    justifyContent="center"
                    alignItems="center"
                    grow="1"
                    shrink="1"
                    basis="0"
                    position="relative"
                    padding="16px 24px 16px 24px"
                    backgroundColor="rgba(255,255,255,1)"
                    display="flex"
                    {...getOverrideProps(overrides, "SPE/Kiki/Warning/Result")}
                  >
                    <Text
                      fontFamily="Noto Sans"
                      fontSize="14px"
                      fontWeight="500"
                      color="rgba(53,56,59,1)"
                      lineHeight="14px"
                      textAlign="left"
                      display="none"
                      direction="column"
                      justifyContent="unset"
                      letterSpacing="0.55px"
                      width="unset"
                      height="unset"
                      gap="unset"
                      alignItems="unset"
                      shrink="0"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      whiteSpace="pre-wrap"
                      children="DC電源"
                      {...getOverrideProps(overrides, "text569222126")}
                    ></Text>
                    <Spacer
                      display="flex"
                      gap="10px"
                      direction="column"
                      width="unset"
                      height="unset"
                      justifyContent="center"
                      alignItems="center"
                      overflow="hidden"
                      grow="1"
                      shrink="1"
                      basis="0"
                      alignSelf="stretch"
                      position="relative"
                      padding="10px 10px 10px 10px"
                      {...getOverrideProps(overrides, "Spacer569222127")}
                    ></Spacer>
                    <Text
                      fontFamily="Noto Sans"
                      fontSize="14px"
                      fontWeight="500"
                      color="rgba(53,56,59,1)"
                      lineHeight="14px"
                      textAlign="right"
                      display="none"
                      direction="column"
                      justifyContent="unset"
                      letterSpacing="0.55px"
                      width="unset"
                      height="unset"
                      gap="unset"
                      alignItems="unset"
                      shrink="0"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      whiteSpace="pre-wrap"
                      children=" 4件"
                      {...getOverrideProps(overrides, "text569222128")}
                    ></Text>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <CommonDivider
          display="flex"
          gap="0"
          direction="row"
          width="unset"
          height="1px"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          backgroundColor="rgba(210,210,210,1)"
          {...getOverrideProps(overrides, "Common/Divider569222129")}
        ></CommonDivider>
        <Flex
          gap="0"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "SPW569222130")}
        >
          <Flex
            gap="0"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="16px 24px 16px 24px"
            backgroundColor="rgba(245,245,245,1)"
            display="flex"
            {...getOverrideProps(overrides, "SPW/Title")}
          >
            <Text
              fontFamily="Noto Sans"
              fontSize="14px"
              fontWeight="500"
              color="rgba(53,56,59,1)"
              lineHeight="14px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              letterSpacing="0.55px"
              width="80px"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="SPW"
              {...getOverrideProps(overrides, "SPW569222132")}
            ></Text>
            <Spacer
              display="flex"
              gap="10px"
              direction="column"
              width="16px"
              height="unset"
              justifyContent="center"
              alignItems="center"
              overflow="hidden"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="10px 10px 10px 10px"
              {...getOverrideProps(overrides, "Spacer569222133")}
            ></Spacer>
            <Text
              fontFamily="Noto Sans"
              fontSize="14px"
              fontWeight="500"
              color="rgba(53,56,59,1)"
              lineHeight="14px"
              textAlign="right"
              display="block"
              direction="column"
              justifyContent="unset"
              letterSpacing="0.55px"
              width="40px"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="10件"
              {...getOverrideProps(overrides, "Number569222134")}
            ></Text>
          </Flex>
          <Flex
            gap="0"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "SPW/Contents")}
          >
            <Flex
              gap="0"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "SPW/Kansyoha")}
            >
              <Flex
                gap="0"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="flex-start"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="16px 24px 16px 24px"
                backgroundColor="rgba(255,255,255,1)"
                display="flex"
                {...getOverrideProps(overrides, "SPW/Kansyoha/Frame")}
              >
                <Text
                  fontFamily="Noto Sans"
                  fontSize="14px"
                  fontWeight="500"
                  color="rgba(53,56,59,1)"
                  lineHeight="14px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  letterSpacing="0.55px"
                  width="88px"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="干渉波監視"
                  {...getOverrideProps(overrides, "text569222138")}
                ></Text>
              </Flex>
              <Flex
                gap="0"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "SPW/Kansyoha/Contents")}
              >
                <Flex
                  gap="0"
                  direction="row"
                  width="unset"
                  height="unset"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  display="flex"
                  {...getOverrideProps(overrides, "SPW/Kansyoha/Error")}
                >
                  <Flex
                    gap="0"
                    direction="row"
                    width="unset"
                    height="unset"
                    justifyContent="center"
                    alignItems="flex-start"
                    shrink="0"
                    alignSelf="stretch"
                    position="relative"
                    padding="16px 24px 16px 24px"
                    backgroundColor="rgba(245,245,245,1)"
                    display="flex"
                    {...getOverrideProps(overrides, "SPW/Kansyoha/Error/Frame")}
                  >
                    <Text
                      fontFamily="Noto Sans"
                      fontSize="14px"
                      fontWeight="500"
                      color="rgba(53,56,59,1)"
                      lineHeight="14px"
                      textAlign="left"
                      display="block"
                      direction="column"
                      justifyContent="unset"
                      letterSpacing="0.55px"
                      width="40px"
                      height="unset"
                      gap="unset"
                      alignItems="unset"
                      shrink="0"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      whiteSpace="pre-wrap"
                      children="異常"
                      {...getOverrideProps(overrides, "text569222142")}
                    ></Text>
                    <Spacer
                      display="flex"
                      gap="10px"
                      direction="column"
                      width="16px"
                      height="unset"
                      justifyContent="center"
                      alignItems="center"
                      overflow="hidden"
                      shrink="0"
                      alignSelf="stretch"
                      position="relative"
                      padding="10px 10px 10px 10px"
                      {...getOverrideProps(overrides, "Spacer569222143")}
                    ></Spacer>
                    <Text
                      fontFamily="Noto Sans"
                      fontSize="14px"
                      fontWeight="500"
                      color="rgba(53,56,59,1)"
                      lineHeight="14px"
                      textAlign="right"
                      display="block"
                      direction="column"
                      justifyContent="unset"
                      letterSpacing="0.55px"
                      width="40px"
                      height="unset"
                      gap="unset"
                      alignItems="unset"
                      shrink="0"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      whiteSpace="pre-wrap"
                      children=" 10件"
                      {...getOverrideProps(overrides, "text569222144")}
                    ></Text>
                  </Flex>
                  <Flex
                    gap="0"
                    direction="column"
                    width="unset"
                    height="unset"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    grow="1"
                    shrink="1"
                    basis="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    display="flex"
                    {...getOverrideProps(
                      overrides,
                      "SPW/Kansyoha/Error/Result"
                    )}
                  >
                    <Flex
                      gap="0"
                      direction="row"
                      width="unset"
                      height="unset"
                      justifyContent="center"
                      alignItems="center"
                      shrink="0"
                      alignSelf="stretch"
                      position="relative"
                      padding="16px 24px 16px 24px"
                      backgroundColor="rgba(255,255,255,1)"
                      display="flex"
                      {...getOverrideProps(
                        overrides,
                        "SPW/Kansyoha/Error/Result/1"
                      )}
                    >
                      <Text
                        fontFamily="Noto Sans"
                        fontSize="14px"
                        fontWeight="500"
                        color="rgba(53,56,59,1)"
                        lineHeight="14px"
                        textAlign="left"
                        display="block"
                        direction="column"
                        justifyContent="unset"
                        letterSpacing="0.55px"
                        width="unset"
                        height="unset"
                        gap="unset"
                        alignItems="unset"
                        shrink="0"
                        position="relative"
                        padding="0px 0px 0px 0px"
                        whiteSpace="pre-wrap"
                        children="s-1"
                        {...getOverrideProps(overrides, "text569222147")}
                      ></Text>
                      <Spacer
                        display="flex"
                        gap="10px"
                        direction="column"
                        width="unset"
                        height="unset"
                        justifyContent="center"
                        alignItems="center"
                        overflow="hidden"
                        grow="1"
                        shrink="1"
                        basis="0"
                        alignSelf="stretch"
                        position="relative"
                        padding="10px 10px 10px 10px"
                        {...getOverrideProps(overrides, "Spacer569222148")}
                      ></Spacer>
                      <Text
                        fontFamily="Noto Sans"
                        fontSize="14px"
                        fontWeight="500"
                        color="rgba(53,56,59,1)"
                        lineHeight="14px"
                        textAlign="right"
                        display="block"
                        direction="column"
                        justifyContent="unset"
                        letterSpacing="0.55px"
                        width="unset"
                        height="unset"
                        gap="unset"
                        alignItems="unset"
                        shrink="0"
                        position="relative"
                        padding="0px 0px 0px 0px"
                        whiteSpace="pre-wrap"
                        children="5件"
                        {...getOverrideProps(overrides, "text569222149")}
                      ></Text>
                    </Flex>
                    <CommonDivider
                      display="flex"
                      gap="0"
                      direction="row"
                      width="unset"
                      height="1px"
                      justifyContent="center"
                      alignItems="center"
                      overflow="hidden"
                      shrink="0"
                      alignSelf="stretch"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      {...getOverrideProps(
                        overrides,
                        "Common/Divider569222150"
                      )}
                    ></CommonDivider>
                    <Flex
                      gap="0"
                      direction="row"
                      width="unset"
                      height="unset"
                      justifyContent="center"
                      alignItems="center"
                      shrink="0"
                      alignSelf="stretch"
                      position="relative"
                      padding="16px 24px 16px 24px"
                      backgroundColor="rgba(255,255,255,1)"
                      display="flex"
                      {...getOverrideProps(
                        overrides,
                        "SPW/Kansyoha/Error/Result/2"
                      )}
                    >
                      <Text
                        fontFamily="Noto Sans"
                        fontSize="14px"
                        fontWeight="500"
                        color="rgba(53,56,59,1)"
                        lineHeight="14px"
                        textAlign="left"
                        display="block"
                        direction="column"
                        justifyContent="unset"
                        letterSpacing="0.55px"
                        width="unset"
                        height="unset"
                        gap="unset"
                        alignItems="unset"
                        shrink="0"
                        position="relative"
                        padding="0px 0px 0px 0px"
                        whiteSpace="pre-wrap"
                        children="d-2"
                        {...getOverrideProps(overrides, "text569222152")}
                      ></Text>
                      <Spacer
                        display="flex"
                        gap="10px"
                        direction="column"
                        width="unset"
                        height="unset"
                        justifyContent="center"
                        alignItems="center"
                        overflow="hidden"
                        grow="1"
                        shrink="1"
                        basis="0"
                        alignSelf="stretch"
                        position="relative"
                        padding="10px 10px 10px 10px"
                        {...getOverrideProps(overrides, "Spacer569222153")}
                      ></Spacer>
                      <Text
                        fontFamily="Noto Sans"
                        fontSize="14px"
                        fontWeight="500"
                        color="rgba(53,56,59,1)"
                        lineHeight="14px"
                        textAlign="right"
                        display="block"
                        direction="column"
                        justifyContent="unset"
                        letterSpacing="0.55px"
                        width="unset"
                        height="unset"
                        gap="unset"
                        alignItems="unset"
                        shrink="0"
                        position="relative"
                        padding="0px 0px 0px 0px"
                        whiteSpace="pre-wrap"
                        children="4件"
                        {...getOverrideProps(overrides, "text569222154")}
                      ></Text>
                    </Flex>
                    <CommonDivider
                      display="flex"
                      gap="0"
                      direction="row"
                      width="unset"
                      height="1px"
                      justifyContent="center"
                      alignItems="center"
                      overflow="hidden"
                      shrink="0"
                      alignSelf="stretch"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      {...getOverrideProps(
                        overrides,
                        "Common/Divider569222155"
                      )}
                    ></CommonDivider>
                    <Flex
                      gap="0"
                      direction="row"
                      width="unset"
                      height="unset"
                      justifyContent="center"
                      alignItems="center"
                      shrink="0"
                      alignSelf="stretch"
                      position="relative"
                      padding="16px 24px 16px 24px"
                      backgroundColor="rgba(255,255,255,1)"
                      display="flex"
                      {...getOverrideProps(
                        overrides,
                        "SPW/Kansyoha/Error/Result/3"
                      )}
                    >
                      <Text
                        fontFamily="Noto Sans"
                        fontSize="14px"
                        fontWeight="500"
                        color="rgba(53,56,59,1)"
                        lineHeight="14px"
                        textAlign="left"
                        display="block"
                        direction="column"
                        justifyContent="unset"
                        letterSpacing="0.55px"
                        width="unset"
                        height="unset"
                        gap="unset"
                        alignItems="unset"
                        shrink="0"
                        position="relative"
                        padding="0px 0px 0px 0px"
                        whiteSpace="pre-wrap"
                        children="k-2"
                        {...getOverrideProps(overrides, "text569222157")}
                      ></Text>
                      <Spacer
                        display="flex"
                        gap="10px"
                        direction="column"
                        width="unset"
                        height="unset"
                        justifyContent="center"
                        alignItems="center"
                        overflow="hidden"
                        grow="1"
                        shrink="1"
                        basis="0"
                        alignSelf="stretch"
                        position="relative"
                        padding="10px 10px 10px 10px"
                        {...getOverrideProps(overrides, "Spacer569222158")}
                      ></Spacer>
                      <Text
                        fontFamily="Noto Sans"
                        fontSize="14px"
                        fontWeight="500"
                        color="rgba(53,56,59,1)"
                        lineHeight="14px"
                        textAlign="right"
                        display="block"
                        direction="column"
                        justifyContent="unset"
                        letterSpacing="0.55px"
                        width="unset"
                        height="unset"
                        gap="unset"
                        alignItems="unset"
                        shrink="0"
                        position="relative"
                        padding="0px 0px 0px 0px"
                        whiteSpace="pre-wrap"
                        children="4件"
                        {...getOverrideProps(overrides, "text569222159")}
                      ></Text>
                    </Flex>
                  </Flex>
                </Flex>
                <CommonDivider
                  display="flex"
                  gap="0"
                  direction="row"
                  width="unset"
                  height="1px"
                  justifyContent="center"
                  alignItems="center"
                  overflow="hidden"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  backgroundColor="rgba(210,210,210,1)"
                  {...getOverrideProps(overrides, "Common/Divider569222160")}
                ></CommonDivider>
                <Flex
                  gap="0"
                  direction="row"
                  width="336px"
                  height="unset"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  display="flex"
                  {...getOverrideProps(overrides, "SPW/Kansyoha/Warining")}
                >
                  <Flex
                    gap="0"
                    direction="row"
                    width="unset"
                    height="unset"
                    justifyContent="center"
                    alignItems="flex-start"
                    shrink="0"
                    alignSelf="stretch"
                    position="relative"
                    padding="16px 24px 16px 24px"
                    backgroundColor="rgba(245,245,245,1)"
                    display="flex"
                    {...getOverrideProps(
                      overrides,
                      "SPW/Kansyoha/Warining/Frame"
                    )}
                  >
                    <Text
                      fontFamily="Noto Sans"
                      fontSize="14px"
                      fontWeight="500"
                      color="rgba(53,56,59,1)"
                      lineHeight="14px"
                      textAlign="left"
                      display="block"
                      direction="column"
                      justifyContent="unset"
                      letterSpacing="0.55px"
                      width="40px"
                      height="unset"
                      gap="unset"
                      alignItems="unset"
                      shrink="0"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      whiteSpace="pre-wrap"
                      children="警告"
                      {...getOverrideProps(overrides, "text569222163")}
                    ></Text>
                    <Spacer
                      display="flex"
                      gap="10px"
                      direction="column"
                      width="16px"
                      height="unset"
                      justifyContent="center"
                      alignItems="center"
                      overflow="hidden"
                      shrink="0"
                      alignSelf="stretch"
                      position="relative"
                      padding="10px 10px 10px 10px"
                      {...getOverrideProps(overrides, "Spacer569222164")}
                    ></Spacer>
                    <Text
                      fontFamily="Noto Sans"
                      fontSize="14px"
                      fontWeight="500"
                      color="rgba(53,56,59,1)"
                      lineHeight="14px"
                      textAlign="right"
                      display="block"
                      direction="column"
                      justifyContent="unset"
                      letterSpacing="0.55px"
                      width="40px"
                      height="unset"
                      gap="unset"
                      alignItems="unset"
                      shrink="0"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      whiteSpace="pre-wrap"
                      children=" 2件"
                      {...getOverrideProps(overrides, "text569222165")}
                    ></Text>
                  </Flex>
                  <Flex
                    gap="0"
                    direction="row"
                    width="unset"
                    height="unset"
                    justifyContent="center"
                    alignItems="center"
                    grow="1"
                    shrink="1"
                    basis="0"
                    position="relative"
                    padding="16px 24px 16px 24px"
                    backgroundColor="rgba(255,255,255,1)"
                    display="flex"
                    {...getOverrideProps(
                      overrides,
                      "SPW/Kansyoha/Warining/Result"
                    )}
                  >
                    <Text
                      fontFamily="Noto Sans"
                      fontSize="14px"
                      fontWeight="500"
                      color="rgba(53,56,59,1)"
                      lineHeight="14px"
                      textAlign="left"
                      display="block"
                      direction="column"
                      justifyContent="unset"
                      letterSpacing="0.55px"
                      width="unset"
                      height="unset"
                      gap="unset"
                      alignItems="unset"
                      shrink="0"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      whiteSpace="pre-wrap"
                      children="s-1"
                      {...getOverrideProps(overrides, "text569222167")}
                    ></Text>
                    <Spacer
                      display="flex"
                      gap="10px"
                      direction="column"
                      width="unset"
                      height="unset"
                      justifyContent="center"
                      alignItems="center"
                      overflow="hidden"
                      grow="1"
                      shrink="1"
                      basis="0"
                      alignSelf="stretch"
                      position="relative"
                      padding="10px 10px 10px 10px"
                      {...getOverrideProps(overrides, "Spacer569222168")}
                    ></Spacer>
                    <Text
                      fontFamily="Noto Sans"
                      fontSize="14px"
                      fontWeight="500"
                      color="rgba(53,56,59,1)"
                      lineHeight="14px"
                      textAlign="right"
                      display="block"
                      direction="column"
                      justifyContent="unset"
                      letterSpacing="0.55px"
                      width="unset"
                      height="unset"
                      gap="unset"
                      alignItems="unset"
                      shrink="0"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      whiteSpace="pre-wrap"
                      children="5件"
                      {...getOverrideProps(overrides, "text569222169")}
                    ></Text>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
            <CommonDivider
              display="flex"
              gap="0"
              direction="row"
              width="unset"
              height="1px"
              justifyContent="center"
              alignItems="center"
              overflow="hidden"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              backgroundColor="rgba(210,210,210,1)"
              {...getOverrideProps(overrides, "Common/Divider569222170")}
            ></CommonDivider>
            <Flex
              gap="0"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "SPW/Kiki")}
            >
              <Flex
                gap="0"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="flex-start"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="16px 24px 16px 24px"
                backgroundColor="rgba(255,255,255,1)"
                display="flex"
                {...getOverrideProps(overrides, "SPW/Kiki/Frame")}
              >
                <Text
                  fontFamily="Noto Sans"
                  fontSize="14px"
                  fontWeight="500"
                  color="rgba(53,56,59,1)"
                  lineHeight="14px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  letterSpacing="0.55px"
                  width="88px"
                  height="unset"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="機器監視"
                  {...getOverrideProps(overrides, "text569222173")}
                ></Text>
              </Flex>
              <Flex
                gap="0"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                display="flex"
                {...getOverrideProps(overrides, "SPW/Kiki/Contents")}
              >
                <Flex
                  gap="0"
                  direction="row"
                  width="unset"
                  height="unset"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  display="flex"
                  {...getOverrideProps(overrides, "SPW/Kiki/Error")}
                >
                  <Flex
                    gap="0"
                    direction="row"
                    width="unset"
                    height="unset"
                    justifyContent="center"
                    alignItems="flex-start"
                    shrink="0"
                    alignSelf="stretch"
                    position="relative"
                    padding="16px 24px 16px 24px"
                    backgroundColor="rgba(245,245,245,1)"
                    display="flex"
                    {...getOverrideProps(overrides, "SPW/Kiki/Error/Frame")}
                  >
                    <Text
                      fontFamily="Noto Sans"
                      fontSize="14px"
                      fontWeight="500"
                      color="rgba(53,56,59,1)"
                      lineHeight="14px"
                      textAlign="left"
                      display="block"
                      direction="column"
                      justifyContent="unset"
                      letterSpacing="0.55px"
                      width="40px"
                      height="unset"
                      gap="unset"
                      alignItems="unset"
                      shrink="0"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      whiteSpace="pre-wrap"
                      children="異常"
                      {...getOverrideProps(overrides, "text569222177")}
                    ></Text>
                    <Spacer
                      display="flex"
                      gap="10px"
                      direction="column"
                      width="16px"
                      height="unset"
                      justifyContent="center"
                      alignItems="center"
                      overflow="hidden"
                      shrink="0"
                      alignSelf="stretch"
                      position="relative"
                      padding="10px 10px 10px 10px"
                      {...getOverrideProps(overrides, "Spacer569222178")}
                    ></Spacer>
                    <Text
                      fontFamily="Noto Sans"
                      fontSize="14px"
                      fontWeight="500"
                      color="rgba(53,56,59,1)"
                      lineHeight="14px"
                      textAlign="right"
                      display="block"
                      direction="column"
                      justifyContent="unset"
                      letterSpacing="0.55px"
                      width="40px"
                      height="unset"
                      gap="unset"
                      alignItems="unset"
                      shrink="0"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      whiteSpace="pre-wrap"
                      children=" 3件"
                      {...getOverrideProps(overrides, "text569222179")}
                    ></Text>
                  </Flex>
                  <Flex
                    gap="0"
                    direction="column"
                    width="unset"
                    height="unset"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    grow="1"
                    shrink="1"
                    basis="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    display="flex"
                    {...getOverrideProps(overrides, "SPW/Kiki/Error/Result")}
                  >
                    <Flex
                      gap="0"
                      direction="row"
                      width="unset"
                      height="unset"
                      justifyContent="center"
                      alignItems="center"
                      shrink="0"
                      alignSelf="stretch"
                      position="relative"
                      padding="16px 24px 16px 24px"
                      backgroundColor="rgba(255,255,255,1)"
                      display="flex"
                      {...getOverrideProps(
                        overrides,
                        "SPW/Kiki/Error/Result/1"
                      )}
                    >
                      <Text
                        fontFamily="Noto Sans"
                        fontSize="14px"
                        fontWeight="500"
                        color="rgba(53,56,59,1)"
                        lineHeight="14px"
                        textAlign="left"
                        display="block"
                        direction="column"
                        justifyContent="unset"
                        letterSpacing="0.55px"
                        width="unset"
                        height="unset"
                        gap="unset"
                        alignItems="unset"
                        shrink="0"
                        position="relative"
                        padding="0px 0px 0px 0px"
                        whiteSpace="pre-wrap"
                        children="DC電源"
                        {...getOverrideProps(overrides, "text569222182")}
                      ></Text>
                      <Spacer
                        display="flex"
                        gap="10px"
                        direction="column"
                        width="unset"
                        height="unset"
                        justifyContent="center"
                        alignItems="center"
                        overflow="hidden"
                        grow="1"
                        shrink="1"
                        basis="0"
                        alignSelf="stretch"
                        position="relative"
                        padding="10px 10px 10px 10px"
                        {...getOverrideProps(overrides, "Spacer569222183")}
                      ></Spacer>
                      <Text
                        fontFamily="Noto Sans"
                        fontSize="14px"
                        fontWeight="500"
                        color="rgba(53,56,59,1)"
                        lineHeight="14px"
                        textAlign="right"
                        display="block"
                        direction="column"
                        justifyContent="unset"
                        letterSpacing="0.55px"
                        width="unset"
                        height="unset"
                        gap="unset"
                        alignItems="unset"
                        shrink="0"
                        position="relative"
                        padding="0px 0px 0px 0px"
                        whiteSpace="pre-wrap"
                        children="5件"
                        {...getOverrideProps(overrides, "text569222184")}
                      ></Text>
                    </Flex>
                    <CommonDivider
                      display="flex"
                      gap="0"
                      direction="row"
                      width="unset"
                      height="1px"
                      justifyContent="center"
                      alignItems="center"
                      overflow="hidden"
                      shrink="0"
                      alignSelf="stretch"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      {...getOverrideProps(
                        overrides,
                        "Common/Divider569222185"
                      )}
                    ></CommonDivider>
                    <Flex
                      gap="0"
                      direction="row"
                      width="unset"
                      height="unset"
                      justifyContent="center"
                      alignItems="center"
                      shrink="0"
                      alignSelf="stretch"
                      position="relative"
                      padding="16px 24px 16px 24px"
                      backgroundColor="rgba(255,255,255,1)"
                      display="flex"
                      {...getOverrideProps(
                        overrides,
                        "SPW/Kiki/Error/Result/2"
                      )}
                    >
                      <Text
                        fontFamily="Noto Sans"
                        fontSize="14px"
                        fontWeight="500"
                        color="rgba(53,56,59,1)"
                        lineHeight="14px"
                        textAlign="left"
                        display="block"
                        direction="column"
                        justifyContent="unset"
                        letterSpacing="0.55px"
                        width="unset"
                        height="unset"
                        gap="unset"
                        alignItems="unset"
                        shrink="0"
                        position="relative"
                        padding="0px 0px 0px 0px"
                        whiteSpace="pre-wrap"
                        children="NW SW"
                        {...getOverrideProps(overrides, "text569222187")}
                      ></Text>
                      <Spacer
                        display="flex"
                        gap="10px"
                        direction="column"
                        width="unset"
                        height="unset"
                        justifyContent="center"
                        alignItems="center"
                        overflow="hidden"
                        grow="1"
                        shrink="1"
                        basis="0"
                        alignSelf="stretch"
                        position="relative"
                        padding="10px 10px 10px 10px"
                        {...getOverrideProps(overrides, "Spacer569222188")}
                      ></Spacer>
                      <Text
                        fontFamily="Noto Sans"
                        fontSize="14px"
                        fontWeight="500"
                        color="rgba(53,56,59,1)"
                        lineHeight="14px"
                        textAlign="right"
                        display="block"
                        direction="column"
                        justifyContent="unset"
                        letterSpacing="0.55px"
                        width="unset"
                        height="unset"
                        gap="unset"
                        alignItems="unset"
                        shrink="0"
                        position="relative"
                        padding="0px 0px 0px 0px"
                        whiteSpace="pre-wrap"
                        children="4件"
                        {...getOverrideProps(overrides, "text569222189")}
                      ></Text>
                    </Flex>
                  </Flex>
                </Flex>
                <CommonDivider
                  display="flex"
                  gap="0"
                  direction="row"
                  width="unset"
                  height="1px"
                  justifyContent="center"
                  alignItems="center"
                  overflow="hidden"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  backgroundColor="rgba(210,210,210,1)"
                  {...getOverrideProps(overrides, "Common/Divider569222190")}
                ></CommonDivider>
                <Flex
                  gap="0"
                  direction="row"
                  width="336px"
                  height="unset"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  display="flex"
                  {...getOverrideProps(overrides, "SPW/Kiki/Warning")}
                >
                  <Flex
                    gap="0"
                    direction="row"
                    width="unset"
                    height="unset"
                    justifyContent="center"
                    alignItems="flex-start"
                    shrink="0"
                    alignSelf="stretch"
                    position="relative"
                    padding="16px 24px 16px 24px"
                    backgroundColor="rgba(245,245,245,1)"
                    display="flex"
                    {...getOverrideProps(overrides, "SPW/Kiki/Warning/Frame")}
                  >
                    <Text
                      fontFamily="Noto Sans"
                      fontSize="14px"
                      fontWeight="500"
                      color="rgba(53,56,59,1)"
                      lineHeight="14px"
                      textAlign="left"
                      display="block"
                      direction="column"
                      justifyContent="unset"
                      letterSpacing="0.55px"
                      width="40px"
                      height="unset"
                      gap="unset"
                      alignItems="unset"
                      shrink="0"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      whiteSpace="pre-wrap"
                      children="警告"
                      {...getOverrideProps(overrides, "text569222193")}
                    ></Text>
                    <Spacer
                      display="flex"
                      gap="10px"
                      direction="column"
                      width="16px"
                      height="unset"
                      justifyContent="center"
                      alignItems="center"
                      overflow="hidden"
                      shrink="0"
                      alignSelf="stretch"
                      position="relative"
                      padding="10px 10px 10px 10px"
                      {...getOverrideProps(overrides, "Spacer569222194")}
                    ></Spacer>
                    <Text
                      fontFamily="Noto Sans"
                      fontSize="14px"
                      fontWeight="500"
                      color="rgba(53,56,59,1)"
                      lineHeight="14px"
                      textAlign="right"
                      display="block"
                      direction="column"
                      justifyContent="unset"
                      letterSpacing="0.55px"
                      width="40px"
                      height="unset"
                      gap="unset"
                      alignItems="unset"
                      shrink="0"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      whiteSpace="pre-wrap"
                      children=" 4件"
                      {...getOverrideProps(overrides, "text569222195")}
                    ></Text>
                  </Flex>
                  <Flex
                    gap="0"
                    direction="row"
                    width="unset"
                    height="unset"
                    justifyContent="center"
                    alignItems="center"
                    grow="1"
                    shrink="1"
                    basis="0"
                    position="relative"
                    padding="16px 24px 16px 24px"
                    backgroundColor="rgba(255,255,255,1)"
                    display="flex"
                    {...getOverrideProps(overrides, "SPW/Kiki/Warning/Result")}
                  >
                    <Text
                      fontFamily="Noto Sans"
                      fontSize="14px"
                      fontWeight="500"
                      color="rgba(53,56,59,1)"
                      lineHeight="14px"
                      textAlign="left"
                      display="block"
                      direction="column"
                      justifyContent="unset"
                      letterSpacing="0.55px"
                      width="unset"
                      height="unset"
                      gap="unset"
                      alignItems="unset"
                      shrink="0"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      whiteSpace="pre-wrap"
                      children="DC電源"
                      {...getOverrideProps(overrides, "text569222197")}
                    ></Text>
                    <Spacer
                      display="flex"
                      gap="10px"
                      direction="column"
                      width="unset"
                      height="unset"
                      justifyContent="center"
                      alignItems="center"
                      overflow="hidden"
                      grow="1"
                      shrink="1"
                      basis="0"
                      alignSelf="stretch"
                      position="relative"
                      padding="10px 10px 10px 10px"
                      {...getOverrideProps(overrides, "Spacer569222198")}
                    ></Spacer>
                    <Text
                      fontFamily="Noto Sans"
                      fontSize="14px"
                      fontWeight="500"
                      color="rgba(53,56,59,1)"
                      lineHeight="14px"
                      textAlign="right"
                      display="block"
                      direction="column"
                      justifyContent="unset"
                      letterSpacing="0.55px"
                      width="unset"
                      height="unset"
                      gap="unset"
                      alignItems="unset"
                      shrink="0"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      whiteSpace="pre-wrap"
                      children=" 4件"
                      {...getOverrideProps(overrides, "text569222199")}
                    ></Text>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <CommonDivider
          display="flex"
          gap="0"
          direction="row"
          width="unset"
          height="1px"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          backgroundColor="rgba(210,210,210,1)"
          {...getOverrideProps(overrides, "Common/Divider569222200")}
        ></CommonDivider>
      </Flex>
    </Flex>
  );
}
