// import Amplify, { Storage } from 'aws-amplify';
import { Storage, Auth } from 'aws-amplify';
import { fromBase64 } from '@aws-sdk/util-base64-browser'
import API, { graphqlOperation } from '@aws-amplify/api';
import { s3ObjectsByTypeAndKey } from '../graphql/queries.js';
import { deleteS3Objects } from '../graphql/mutations.js';

import {
    KmsKeyringBrowser,
    KMS,
    getClient,
    buildClient,
    CommitmentPolicy,
} from '@aws-crypto/client-browser'

import { S3Client, ListObjectsV2Command } from "@aws-sdk/client-s3";
import awsconfig from '../aws-exports';

import JSZip from 'jszip';


function convertDisplayDateText(lastModified) {
    const date = lastModified;         // 現在日時を生成
    const year = date.getFullYear(); // 西暦を取得
    const month = ('0' + (date.getMonth() + 1)).slice(-2);  // 月を取得（返り値は実際の月-1なので、+1する）
    const day = ('0' + date.getDate()).slice(-2); // 日を取得
    const hour = ('0' + date.getHours()).slice(-2);
    const minite = ('0' + date.getMinutes()).slice(-2);

    var displayDateText = year + "/" + month + "/" + day + " " + hour + ":" + minite;

    return displayDateText;
}

function convertDisplaySizeText(size) {
    const kb = 1024
    const mb = Math.pow(kb, 2)
    const gb = Math.pow(kb, 3)
    const tb = Math.pow(kb, 4)

    let target = null
    let unit = 'byte'

    if (size >= tb) {
        target = tb
        unit = 'TB'
    } else if (size >= gb) {
        target = gb
        unit = 'GB'
    } else if (size >= mb) {
        target = mb
        unit = 'MB'
    } else if (size >= kb) {
        target = kb
        unit = 'KB'
    }

    const displaySizeText = target !== null ? Math.floor(size / target) : size

    return displaySizeText + unit
}


export async function getFileList(key) {
    try {

        const fileList = await getCurrentDirectoryList(key)

        console.log("fileList", fileList);

        // 現在のディレクトリを取得
        const keyList = key.split("/");

        // ディレクトリの最後が空の場合は削除する
        if (keyList[keyList.length - 1] === "") {
            keyList.pop();
        }

        return fileList;
    } catch (error) {
        throw error
    }
}

function convertS3Data(isFile, name, searchKey, result) {
    var data = {
        isFile: isFile,
        name: name,
        lastModified: "",
        fileType: "",
        filesize: "",
        searchKey: searchKey,
        key: result.Key,
        check: false,
    }

    if (data.isFile) {
        data.lastModified = convertDisplayDateText(result.LastModified)
        var dataNameArray = name.split(".");
        data.fileType = dataNameArray[dataNameArray.length - 1]
        data.filesize = convertDisplaySizeText(result.Size)
    }
    return data;
}

async function getCurrentDirectoryList(key) {

    const client = new S3Client({
        region: "ap-northeast-1",
        credentials: await Auth.currentCredentials()
    });

    const bucket = awsconfig.aws_user_files_s3_bucket

    const command = new ListObjectsV2Command({
        Bucket: bucket,
        Prefix: key,
        Delimiter: "/"
    });

    const response = await client.send(command);

    var dataList = [];

    // カレントディレクトリ内のサブフォルダをリストに追加
    if (response.CommonPrefixes) {
        response.CommonPrefixes.forEach((item) => {
            // console.log("isFolder item", item)
            var resultPassArr = item['Prefix'].split("/");
            // console.log("resultPassArr", resultPassArr)
            dataList.push(convertS3Data(false, resultPassArr[resultPassArr.length - 2], resultPassArr.join("/"), item));
        });
    }

    // カレントディレクトリ内のファイルをリストに追加
    if (response.Contents) {
        response.Contents.forEach((item) => {
            // keyと同じサイズ０のオブジェクトが返ってくる場合があるので、それは除外する
            if (item['Key'] !== key) {
                // console.log("isFolder item", item)
                var resultPassArr = item['Key'].split("/");
                // download時にpublic/は不要なので削除する
                item.Key = item.Key.replace(/^public\//, '');
                dataList.push(convertS3Data(true, resultPassArr[resultPassArr.length - 1], "", item));
            }
        });
    }

    // console.log ("S3 client dataList", dataList);

    return dataList;
}





async function kmsDecrypt(encryptText, fileType) {
    const credentials = await Auth.currentCredentials()

    const { decrypt } = buildClient(
        CommitmentPolicy.REQUIRE_ENCRYPT_REQUIRE_DECRYPT
    )

    const clientProvider = getClient(KMS, {
        credentials: Auth.essentialCredentials(credentials)
    })


    const keyIds = [process.env.REACT_APP_KMS_KEY_ID]

    const keyring = new KmsKeyringBrowser({
        clientProvider: clientProvider,
        keyIds: keyIds
    })

    try {
        const { plaintext } = await decrypt(keyring, encryptText)

        if (fileType === 'csv') {
            const decoder = new TextDecoder();
            const text = decoder.decode(plaintext)
            // BOMを付与（Excelで開いた際のの文字化け対策）
            const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
            const blob = new Blob([bom, text], { type: 'text/csv' })
            return blob
        } else if (fileType === 'png') {
            // pngファイルはBase64エンコードされているのでデコードする
            const decoder = new TextDecoder()
            const base64String = decoder.decode(plaintext)
            const utf8Array = fromBase64(base64String)
            // console.log('base64decode')
            // console.log(utf8Array)

            const blob = new Blob([utf8Array.buffer], { type: 'image/png' })
            // console.log(blob)
            return blob
        }
    } catch (error) {
        // console.log("decrypterror")
        // console.log(error)
        return null
    }
}


function downloadBlob(blob, filename) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename || 'download';
    const clickHandler = () => {
        setTimeout(() => {
            URL.revokeObjectURL(url);
            a.removeEventListener('click', clickHandler);
        }, 150);
    };
    a.addEventListener('click', clickHandler, false);
    a.click();
    return a;
}

export const downloadFile = async (key, fileName, fileType) => {
    // console.log("downloadFile key: "+ key)
    // console.log("downloadFile fileName: "+ fileName)
    // console.log("downloadFile fileType: "+ fileType)

    const result = await Storage.get(key, { level: "public", download: true });
    // console.log(result)
    const arrayBuffer = await result.Body.arrayBuffer()
    const uint8Array = new Uint8Array(arrayBuffer);

    // console.log('originaldata')
    // console.log(uint8Array)

    const decryptBlob = kmsDecrypt(uint8Array, fileType)

    decryptBlob.then(blobData => {
        if (blobData) {
            downloadBlob(blobData, fileName);
        } else {
            // console.log("no download")
        }
    })
};

// プレビュー用のURLを取得
export const getPreviewUrl = async (key, fileName, fileType) => {
    // S3からファイルを取得する
    const result = await Storage.get(key, { level: "public", download: true });

    // バッファーをUint8Arrayに変換する
    const arrayBuffer = await result.Body.arrayBuffer()
    const uint8Array = new Uint8Array(arrayBuffer);

    // kmsで復号化してBlobに変換する
    const blobData = await kmsDecrypt(uint8Array, fileType)

    // blobをURLに変換
    const url = URL.createObjectURL(blobData);

    return url;
};

// ファイルを検索
export const searchFileList = async (prefix, searchKey, callback) => {
    // 表示上限
    const limit = 100;

    // データリスト
    let dataList = [];

    // 次の検索開始位置
    let nextToken = null;

    // レスポンス
    let response = null;

    // 表示上限まで検索する
    while (dataList.length < limit) {
        // appsyncからファイルを検索する
        try {
            response = await API.graphql(graphqlOperation(s3ObjectsByTypeAndKey, {
                limit: 1000,
                type: "S3Object",
                key: {
                    beginsWith: prefix,
                },
                filter: {
                    fileName: {
                        contains: searchKey
                    },
                },
                ... nextToken != null && {
                    nextToken: nextToken
                }
            }));

            // console.log(response);

            // 検索結果がなければ終了
            if (response.data.s3ObjectsByTypeAndKey.items.length > 0) {

                // s３データを整形
                for (let i = 0; i < response.data.s3ObjectsByTypeAndKey.items.length && dataList.length < limit; i++) {
                    const item = response.data.s3ObjectsByTypeAndKey.items[i];

                    var resultPassArr = item['key'].split("/");
                    item.key = item.key.replace(/^public\//, '');

                    dataList.push(convertS3Data(true, resultPassArr[resultPassArr.length - 1], "", {
                        Key: item.key,
                        LastModified: new Date(item.lastModified),
                        Size: item.size
                    }));
                }

                // console.log(dataList);
            }
        } catch (error) {
            console.log(error)
        }
        // コールバックを実行
        await callback(dataList);

        if (response.data.s3ObjectsByTypeAndKey.nextToken) {
            nextToken = response.data.s3ObjectsByTypeAndKey.nextToken;
        }
        else {
            break;
        }
    }

    return;
}


// 現在時刻をフォーマットする関数
export function getFormatNowDate() {
    const date = new Date();
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);  // 月を取得（返り値は実際の月-1なので、+1する）
    const day = ('0' + date.getDate()).slice(-2); // 日を取得
    const hour = ('0' + date.getHours()).slice(-2);
    const minite = ('0' + date.getMinutes()).slice(-2);
    const second = ('0' + date.getSeconds()).slice(-2);
    const nowDate = year + month + day + hour + minite + second;
    return nowDate;
}


// 一括ダウンロード
export const batchDownloadFile = async (fileList) => {

    // フォルダ名を取得
    const folderName = `interference_monitoring_${getFormatNowDate()}`

    // JSZipインスタンス化
    const zip = new JSZip();

    // フォルダーを作成
    const folder = zip.folder(folderName);

    // 非同期でダウンロードを実行
    await Promise.all(fileList.map(async (file, index) => {
        const result = await Storage.get(file.key, { level: "public", download: true });
        const arrayBuffer = await result.Body.arrayBuffer()
        const uint8Array = new Uint8Array(arrayBuffer);
        const blobData = await kmsDecrypt(uint8Array, file.fileType)

        // ファイルを設定
        folder.file(file.name, blobData);
    }))

    // zipファイルを生成
    zip.generateAsync({ type: "blob" }).then(blob => {

        // ダウンロードリンクを 生成
        let dlLink = document.createElement("a");

        // blob から URL を生成
        const dataUrl = URL.createObjectURL(blob);
        dlLink.href = dataUrl;
        dlLink.download = `${folderName}.zip`;

        // 設置/クリック/削除
        document.body.insertAdjacentElement("beforeEnd", dlLink);
        dlLink.click();
        dlLink.remove();

        // オブジェクト URL の開放
        setTimeout(function () {
            window.URL.revokeObjectURL(dataUrl);
        }, 1000);
    });
}

// ファイルを削除
export const deleteFile = async (key) => {
    try {
        // dynamodbからデータを取得
        const responseS3ObjectsByTypeAndKey = await API.graphql(graphqlOperation(s3ObjectsByTypeAndKey, {
            type: "S3Object",
            key: {
                eq: 'public/' + key,
            },
        }));

        console.log(responseS3ObjectsByTypeAndKey.data.s3ObjectsByTypeAndKey.items);

        if (responseS3ObjectsByTypeAndKey.data.s3ObjectsByTypeAndKey.items.length > 0) {
            // dynamodbからも削除する
            const responseDeleteS3Objects = await API.graphql(graphqlOperation(deleteS3Objects, {
                input: {
                    id: responseS3ObjectsByTypeAndKey.data.s3ObjectsByTypeAndKey.items[0].id,
                }
            }));
            console.log(responseDeleteS3Objects.data.deleteS3Objects);
        }

        // s3削除
        const result = await Storage.remove(key, { level: "public" });

        console.log(result);
        return true;
    } catch (error) {
        console.log(error);
        return false;
    }
}

// 一括ファイル削除
export const batchDeleteFile = async (fileList) => {
    try {
        await Promise.all(fileList.map(async (file, index) => {
            const result = await deleteFile(file.key)
            console.log(result);
        }))
        return true;
    } catch (error) {
        console.log(error);
        return false;
    }
}