import {
    SetteiItem2
} from "../ui-components";


export default function SetteiItemCycleWrapper({
    labeltext,
    cyclevalue1, cyclevalue2, cyclevalue3,
    cyclevalue1OnChangeHandler, cyclevalue2OnChangeHandler, cyclevalue3OnChangeHandler,
}) {

    return (
        <SetteiItem2
            overrides={{
                text: {
                    children: labeltext,
                },
                "SelectField528926961" : {
                    options : ["アンテナ1", "アンテナ2", "アンテナ3"],
                    placeholder : "",
                    fontSize: "0.875rem", // SelectFieldのsize=smallが効かない対策
                    gap: "0.25rem",　// SelectFieldのsize=smallが効かない対策
                    value : cyclevalue1,
                    onChange : cyclevalue1OnChangeHandler,
                },
                "SelectField528926952" : {
                    options : ["アンテナ1", "アンテナ2", "アンテナ3", "なし"],
                    placeholder : "",
                    label: "2nd", // UIコンポーネンツ側不備対応
                    fontSize: "0.875rem", // SelectFieldのsize=smallが効かない対策
                    gap: "0.25rem",　// SelectFieldのsize=smallが効かない対策
                    value : cyclevalue2,
                    onChange : cyclevalue2OnChangeHandler,
                },
                "SelectField528926970" : {
                    options : ["アンテナ1", "アンテナ2", "アンテナ3", "なし"],
                    placeholder : "",
                    fontSize: "0.875rem", // SelectFieldのsize=smallが効かない対策
                    gap: "0.25rem",　// SelectFieldのsize=smallが効かない対策
                    value : cyclevalue3,
                    onChange : cyclevalue3OnChangeHandler,
                    isDisabled : cyclevalue2 === "なし",
                },
            }}
        >

        </SetteiItem2>
    );
}