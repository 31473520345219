/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import CommonTitle from "./CommonTitle";
import DatakanriDataMakingLocation from "./DatakanriDataMakingLocation";
import DatakanriDataMakingDate from "./DatakanriDataMakingDate";
import { Flex } from "@aws-amplify/ui-react";
import CommonBtnBlue from "./CommonBtnBlue";
export default function DatakanriDataMaking(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      gap="56px"
      direction="column"
      width="unset"
      height="unset"
      justifyContent="flex-start"
      alignItems="center"
      position="relative"
      borderRadius="20px"
      padding="40px 40px 40px 40px"
      backgroundColor="rgba(255,255,255,1)"
      {...rest}
      {...getOverrideProps(overrides, "DatakanriDataMaking")}
    >
      <Flex
        gap="40px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 754")}
      >
        <CommonTitle
          width="unset"
          height="18px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          size="large"
          {...getOverrideProps(overrides, "Common/Title")}
        ></CommonTitle>
        <Flex
          gap="32px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Frame 753")}
        >
          <DatakanriDataMakingLocation
            display="flex"
            gap="8px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            error={false}
            example={true}
            {...getOverrideProps(overrides, "Datakanri/DataMaking/Location")}
          ></DatakanriDataMakingLocation>
          <DatakanriDataMakingDate
            display="flex"
            gap="8px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            example={false}
            error={true}
            {...getOverrideProps(overrides, "Datakanri/DataMaking/Date")}
          ></DatakanriDataMakingDate>
        </Flex>
      </Flex>
      <CommonBtnBlue
        display="flex"
        gap="10px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        position="relative"
        borderRadius="8px"
        padding="10px 24px 10px 24px"
        backgroundColor="rgba(0,153,255,1)"
        situation="default"
        size="large"
        {...getOverrideProps(overrides, "Common/Btn/Blue")}
      ></CommonBtnBlue>
    </Flex>
  );
}
