/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "@aws-amplify/ui-react/internal";
import { Flex, Text, TextField } from "@aws-amplify/ui-react";
import Myicons from "./Myicons";
export default function DatakanriDataMakingDate(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: {
        TextField465312790: {},
        text461812780: {},
        "Frame 752": {},
        TextField465312803: {},
        "Frame 751": {},
        text461812741: {},
        Myicons: {},
        text461812744: {},
        "Frame 749": {},
        DatakanriDataMakingDate: {},
      },
      variantValues: { example: "true", error: "true" },
    },
    {
      overrides: {
        TextField465312790: {},
        text461812780: {},
        "Frame 752": {},
        TextField465312803: {},
        "Frame 751": {},
        text461812741: { display: "none" },
        Myicons: {},
        text461812744: {},
        "Frame 749": {},
        DatakanriDataMakingDate: {},
      },
      variantValues: { example: "false", error: "true" },
    },
    {
      overrides: {
        TextField465312790: {},
        text461812780: {},
        "Frame 752": {},
        TextField465312803: {},
        "Frame 751": {},
        text461812741: {},
        Myicons: {},
        text461812744: {},
        "Frame 749": { display: "none" },
        DatakanriDataMakingDate: {},
      },
      variantValues: { example: "true", error: "false" },
    },
    {
      overrides: {
        TextField465312790: {},
        text461812780: {},
        "Frame 752": {},
        TextField465312803: {},
        "Frame 751": {},
        text461812741: { display: "none" },
        Myicons: {},
        text461812744: {},
        "Frame 749": { display: "none" },
        DatakanriDataMakingDate: {},
      },
      variantValues: { example: "false", error: "false" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="8px"
      direction="column"
      width="unset"
      height="unset"
      justifyContent="flex-start"
      alignItems="flex-start"
      position="relative"
      padding="0px 0px 0px 0px"
      display="flex"
      {...rest}
      {...getOverrideProps(overrides, "DatakanriDataMakingDate")}
    >
      <Flex
        gap="9px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-end"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        display="flex"
        {...getOverrideProps(overrides, "Frame 751")}
      >
        <TextField
          width="176px"
          height="unset"
          placeholder="2024/04/01  00:00"
          shrink="0"
          label="Label"
          size="default"
          isDisabled={false}
          labelHidden={false}
          variation="default"
          {...getOverrideProps(overrides, "TextField465312790")}
        ></TextField>
        <Flex
          gap="10px"
          direction="column"
          width="unset"
          height="40px"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 752")}
        >
          <Text
            fontFamily="Noto Sans"
            fontSize="12px"
            fontWeight="500"
            color="rgba(170,170,170,1)"
            lineHeight="12px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0.45px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="〜"
            {...getOverrideProps(overrides, "text461812780")}
          ></Text>
        </Flex>
        <TextField
          width="176px"
          height="unset"
          placeholder="2024/04/01  00:00"
          shrink="0"
          size="default"
          isDisabled={false}
          labelHidden={true}
          variation="default"
          {...getOverrideProps(overrides, "TextField465312803")}
        ></TextField>
      </Flex>
      <Text
        fontFamily="Noto Sans"
        fontSize="12px"
        fontWeight="500"
        color="rgba(170,170,170,1)"
        lineHeight="12px"
        textAlign="left"
        display="block"
        direction="column"
        justifyContent="unset"
        letterSpacing="0.45px"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="ex:YYCC"
        {...getOverrideProps(overrides, "text461812741")}
      ></Text>
      <Flex
        gap="8px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        display="flex"
        {...getOverrideProps(overrides, "Frame 749")}
      >
        <Myicons
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          overflow="hidden"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          type="attention"
          {...getOverrideProps(overrides, "Myicons")}
        ></Myicons>
        <Text
          fontFamily="Noto Sans"
          fontSize="12px"
          fontWeight="700"
          color="rgba(255,76,108,1)"
          lineHeight="12px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          letterSpacing="0.45px"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          grow="1"
          shrink="1"
          basis="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="開始終了期間が逆転しています"
          {...getOverrideProps(overrides, "text461812744")}
        ></Text>
      </Flex>
    </Flex>
  );
}
