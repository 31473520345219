import { useEffect, useState } from "react";

import {
    KanshiFrameLogDetails,
} from "../ui-components";

import {
    updateMoniteringLogKakuninStatus,
    resetMoniteringLogKakuninStatus,
} from "../lib/LogUtil";

import { isLocalMode } from "../lib/envUtil";

// Date型を文字をフォーマットする関数
export const formatDatetime = (date, format="YYYY/MM/DD hh:mm") => {
    // 年
    format = format.replace(/YYYY/g, date.getFullYear());
    // 月
    format = format.replace(/MM/g, ('0' + (date.getMonth() + 1)).slice(-2));
    // 日
    format = format.replace(/DD/g, ('0' + date.getDate()).slice(-2));
    // 時
    format = format.replace(/hh/g, ('0' + date.getHours()).slice(-2));
    // 分
    format = format.replace(/mm/g, ('0' + date.getMinutes()).slice(-2));
    // 秒
    format = format.replace(/ss/g, ('0' + date.getSeconds()).slice(-2));
    
    return format;
}


export default function KanshiFrameLogDetailsWrapper(props) {
    // ログ確認フラグ
    const [confirmFlag, setConfirmFlag] = useState(props.log.confirmed_flg === 1 ? true : false);

    // 確認時間
    const [kakuninTime, setKakuninTime] = useState(props.log.confirmed_flg === 1 ? new Date(props.log.confirmation_at) : new Date());

    // メモテキストエリア
    const [memoText, setMemoText] = useState(props.log.confirmation_note != null ? props.log.confirmation_note : "");


    // バリデーション
    const validation = () => {
        // 未確認の場合、正常
        if (!confirmFlag) {
            return true;
        }

        // 確認日時がnullの場合、エラー
        if (kakuninTime === null) {
            return false;
        }

        return true;
    }

    useEffect(() => {
        console.log(kakuninTime);
    }, [kakuninTime]);
    return (
        <KanshiFrameLogDetails
            overrides={{
                // 発生日時
                text507632593: {
                    children: props.date
                },
                // 拠点名
                text507632595: {
                    children: props.kyotenName
                },
                // 発生源
                text507632597: {
                    children: props.hasseiMoto
                },
                // ログ種別
                text507632599: {
                    children: props.logType,
                },
                // 編集エリア
                "Edit": {
                    display: !isLocalMode ? "flex" : "none",
                },
                // 確認済み/未確認
                Spacer507632589: {
                    display: !isLocalMode ? "flex" : "none",
                },
                text507632590: {
                    display: !isLocalMode ? "flex" : "none",
                },
                Spacer507632600: {
                    display: !isLocalMode ? "flex" : "none",
                },
                text507632601: {
                    display: !isLocalMode ? "flex" : "none",
                    children: props.log.confirmed_flg ? "確認済み" : "未確認",
                },
                // ログ詳細
                TextAreaField507632602: {
                    isReadOnly: true,
                    isDisabled: false,
                    defaultValue: props.log.log_detail != null ? props.log.log_detail : "",
                    placeholder: "",
                },
                // 確認済みラジオボタン
                Radio513421786: {
                    children: "確認済み",
                    defaultChecked: confirmFlag,
                    onClick: (e) => {
                        setConfirmFlag(true);
                    }
                },
                // 未確認ラジオ
                Radio514622016: {
                    children: "未確認",
                    defaultChecked: !confirmFlag,
                    onClick: (e) => {
                        setConfirmFlag(false);
                    }
                },
                // 確認済みに変更する場合、編集エリア
                "Frame 908":{
                    display: confirmFlag ? "flex" : "none",
                },
                // 確認日時
                TextField: {
                    label: "確認日時",
                    type: "datetime-local",
                    placeholder: "2020/01/01 00:00",
                    isRequired: true,
                    defaultValue: kakuninTime != null ? formatDatetime(kakuninTime, "YYYY-MM-DDThh:mm") : "",
                    onBlur: (e) => {
                        console.log(e.target.value);

                        if (e.target.value === "") {
                            // 空の場合、nullを設定
                            setKakuninTime(null);
                            return;
                        }

                        // 確認日時を更新
                        setKakuninTime(new Date(e.target.value + ":00+09:00"));
                    }
                },
                // メモ入力欄
                TextAreaField507632618: {
                    placeholder: "メモを入力してください。",
                    value: memoText,
                    onChange: (e) => {
                        setMemoText(e.target.value);
                    },
                },
                // 変更ボタン
                "Common/Btn/Blue": {
                    display: !isLocalMode ? "flex" : "none",
                    overrides: {
                        text: {
                            children: "変更",
                        },
                    },
                    style: {
                        opacity: !validation() ? 0.5 : 1,
                        cursor: !validation() ? "not-allowed" : "pointer",
                    },
                    onClick: (e) => {
                        // バリデーションチェック
                        if (!validation()) {
                            return;
                        }

                        // 確認済みの場合
                        if (confirmFlag) {
                            // ログ確認状態を更新
                            updateMoniteringLogKakuninStatus(props.log.id, confirmFlag? 1: 0, kakuninTime, memoText);
                        }
                        else {
                            // 未確認に戻す
                            resetMoniteringLogKakuninStatus(props.log.id);
                        }

                        // モーダルを閉じる
                        props.closeModal();
                    }
                },
                // 閉じるボタン
                "Common/Btn/White": {
                    overrides: {
                        text: {
                            children: "閉じる",
                        },
                    },
                    onClick: (e) => {
                        // モーダルを閉じる
                        props.closeModal();
                    },
                    style: {
                        cursor: "pointer",
                    },
                },
            }}
        />
    )
}