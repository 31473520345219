import { useEffect, useState, useContext, useRef, useCallback } from "react";
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../UserContext';
import { isLocalMode } from "../lib/envUtil";
import { useControlAlarmContext } from "../contexts/ControlAlarmContext";
import { useControlPatliteContext } from "../contexts/ControlPatliteContext";
import useClickOutSide from "../hooks/useClickOutSide";
import { PULLDOWN } from "../hooks/useControlAlarm";

import {
    Header
} from '../ui-components'

import { logout } from '../Auth';

const useHoverState = () => {
    const [hover, setHover] = useState("default");

    const onMouseEnter = () => setHover("hover");
    const onMouseLeave = () => setHover("default");

    return {
        hover,
        onMouseEnter,
        onMouseLeave,
    };
};

export default function HeaderWrapper(props) {
    const contextValues = useContext(UserContext);
    let userInfo, logoutUserContext;
    if (!isLocalMode) {
        userInfo = contextValues.userInfo;
        logoutUserContext = contextValues.logoutUserContext;
    }

    const [dateText, setDateText] = useState("");
    const [accountType, setAccountType] = useState("Default");

    const navigate = useNavigate();

    // アラーム音を制御するコンテキスト
    const { alarmState, alarmPullDownState, onClickAlarm, onClickPullDown, setAlarmPullDownState } = useControlAlarmContext();

    // パトライトを制御するコンテキスト
    const { patliteState, patlitePullDownState, onClickPatlite, onClickPatlitePullDown, setPatlitePullDownState } = useControlPatliteContext();

    useEffect(() => {
        const dateTextTimer = setInterval(() => {
            setNowDateText();
        }, 1000);

        return () => clearInterval(dateTextTimer);
    }, []);

    function setNowDateText() {
        var week = ["日", "月", "火", "水", "木", "金", "土"];
        var date = new Date();         // 現在日時を生成
        var year = date.getFullYear(); // 西暦を取得
        var month = ('0' + (date.getMonth() + 1)).slice(-2);  // 月を取得（返り値は実際の月-1なので、+1する）
        var day = ('0' + date.getDate()).slice(-2); // 日を取得
        var hour = ('0' + date.getHours()).slice(-2);
        var minite = ('0' + date.getMinutes()).slice(-2);
        var second = ('0' + date.getSeconds()).slice(-2);
        var weekday = date.getDay();   // 曜日を取得（数値）

        var nowDateText = year + "/" + month + "/" + day + " (" + week[weekday] + ")  " + hour + ":" + minite + ":" + second;

        setDateText(nowDateText);
    }

    function onClickAccount() {
        if (accountType === "Default" || accountType === "Hover") {
            setAccountType("Select");
        } else {
            setAccountType("Default");
        }
    }

    function accountOnMouseEnter() {
        if (accountType === "Default") {
            setAccountType("Hover");   
        }
    }

    function accountOnMouseLeave() {
        if (accountType === "Hover") {
            setAccountType("Default");   
        }
    }

    function onClickLogout() {
        logoutUserContext();
        logout();
    }

    function onClickTop() {
        navigate('/');
    }

    function onclickDataKanri() {
        navigate('/datakanri');
    }

    function onClickSetting() {
        navigate('/settings');
    }

    const {ref: accountRef} = useClickOutSide({
        onCiickInside: () => {},
        onClickOutside: () => {
            setAccountType("Default");
        }
    });

    const {ref: alarmRef} = useClickOutSide({
        onCiickInside: () => {},
        onClickOutside: () => {
            setAlarmPullDownState(PULLDOWN.HIDE);
        }
    });

    const {ref: patliteRef} = useClickOutSide({
        onCiickInside: () => {},
        onClickOutside: () => {
            setPatlitePullDownState(PULLDOWN.HIDE);
        }
    });
    // データ管理画面へ遷移ボタン
    const {
        hover: datakanriHover,
        onMouseEnter: datakanriOnMouseEnter,
        onMouseLeave: datakanriOnMouseLeave,
    } = useHoverState();

    // 設定画面へ遷移ボタン
    const {
        hover: settingHover,
        onMouseEnter: settingOnMouseEnter,
        onMouseLeave: settingOnMouseLeave,
    } = useHoverState();

    // ホーム画面へ遷移ボタン
    const {
        hover: homeHover,
        onMouseEnter: homeOnMouseEnter,
        onMouseLeave: homeOnMouseLeave,
    } = useHoverState();    

    return (
        <>
        <Header
            marginBottom={"32px"}
            width={"100%"}
            minWidth={"1355px"}
            // onClick={props.onClick}
            // onClick={()=>clickTest("test")}
            overrides={{
                //   Myicons36793624: {},
                text: {
                    onClick: () => onClickTop(),
                    // children:"test"
                    style: {
                        cursor: "pointer",
                    },
                    children: "干渉モニターシステム",
                },
                time: {
                    children: dateText
                },
                "logo": {
                    src: "/images/logo.png"
                },
                // アラーム音
                "Common/Header/Icon482214385": {
                    display: !isLocalMode && userInfo.isAdminOrg ? "block" : "none",
                    situation: alarmState,
                    pulldown: alarmPullDownState,
                    style: {
                        cursor: "pointer",
                    },
                    overrides: {
                        CommonHeaderIcon: {
                            ref: alarmRef,
                        },
                        icons: {
                            onClick: onClickAlarm,
                        },
                        Pulldown: {
                            onClick: onClickPullDown,
                        },
                    }
                },
                // パトライト
                "Common/Header/Icon482214386": {
                    display: !isLocalMode && userInfo.isAdminOrg ? "block" : "none",
                    situation: patliteState,
                    pulldown: patlitePullDownState,
                    style: {
                        cursor: "pointer",
                    },
                    overrides: {
                        CommonHeaderIcon: {
                            ref: patliteRef,
                        },
                        icons: {
                            onClick: onClickPatlite,
                        },
                        Pulldown: {
                            onClick: onClickPatlitePullDown,
                        },
                    }
                },
                "Rectangle482214387": {
                    display: !isLocalMode && userInfo.isAdminOrg ? "block" : "none",
                },
                // データ管理画面へ遷移ボタン
                "Common/Badge/Header482214388": {
                    display: !isLocalMode && userInfo.isAdminOrg ? "block" : "none",
                    onClick: () => onclickDataKanri(),
                    style: {
                        cursor: "pointer",
                    },
                    overrides: {
                    },
                    situation: datakanriHover,
                    onMouseEnter: datakanriOnMouseEnter,
                    onMouseLeave: datakanriOnMouseLeave,
                },
                "Common/Badge/Header482214389": {
                    display: !isLocalMode && userInfo.isAdminOrg && userInfo.isAdminUser ? "block" : "none",
                    onClick: () => onClickSetting(),
                    style: {
                        cursor: "pointer",
                    },
                    overrides: {
                    },
                    situation: settingHover,
                    onMouseEnter: settingOnMouseEnter,
                    onMouseLeave: settingOnMouseLeave,
                },
                "Common/Badge/Header482214391": {
                    display: !isLocalMode && userInfo.isAdminOrg ? "block" : "none",
                    onClick: () => onClickTop(),
                    style: {
                        cursor: "pointer",
                    },
                    overrides: {
                    },
                    situation: homeHover,
                    onMouseEnter: homeOnMouseEnter,
                    onMouseLeave: homeOnMouseLeave,
                },
                "Rectangle482214390": {
                    display: !isLocalMode && userInfo.isAdminOrg ? "block" : "none",
                },
                "Common/Badge/Header/Account": {
                    type: accountType,
                    onMouseEnter: accountOnMouseEnter,
                    onMouseLeave: accountOnMouseLeave,
                    display: !isLocalMode ? "block" : "none",
                    overrides: {
                        CommonBadgeHeaderAccount: {
                            ref: accountRef,
                        },
                        Myicons: {
                            onClick: () => onClickAccount(),
                            style: {
                                cursor: "pointer",
                            },
                        },
                        Menu: {
                            // display: accountDisplay,
                            style: {
                                zIndex: 10, // 任意のz-indexの値を指定します
                                // 他のスタイルプロパティも追加できます
                            },
                            left: "",
                            right: "0px",
                            alignItems: "flex-start",
                        },
                        text478914266: {
                            children: !isLocalMode ? userInfo.name : "",
                            minWidth: "200px",
                            width: "fit-content",
                            whiteSpace: "nowrap",
                        },
                        text478914268: {
                            children: !isLocalMode ? userInfo.email : "",
                            minWidth: "200px",
                            width: "fit-content",
                            whiteSpace: "nowrap",
                        },
                        Logout: {
                            onClick: () => onClickLogout(),
                            onMouseEnter: () => setAccountType("LogoutHover"),
                            onMouseLeave: () => setAccountType("Select"),
                            style: {
                                cursor: "pointer",
                            },
                        },
                    }
                },
                Header: {
                    gap: "0px"
                },
            }}
        ></Header>
        </>
    );
}