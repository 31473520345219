// UserContext.js
import React, { createContext, useState, useEffect } from 'react';
import { userManager } from './Auth';

import axios from 'axios';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [userInfo, setUserInfo] = useState(null)

  // ログアウト時に状態を初期化とlocalstorageのクリア
  const logoutUserContext = () => {
    setUserInfo(null)
  }

  // casthiveから取得した情報を元にロールチェックを行う
  function casthiveRoleCheck(resData) {
    const jsonResData = JSON.parse(JSON.stringify(resData));

    // このアプリのタイプIDの一覧を取得
    let aryThisAppTypeID = [];
    let aryCanAccessOrgID = [];
    if (jsonResData.hasOwnProperty("userRoles")) {
      jsonResData.userRoles.forEach((userRoles) => {
        if (userRoles.hasOwnProperty("applications")) {
          userRoles.applications.forEach((applications) => {
            if (applications.id === process.env.REACT_APP_OIDC_CLIENT_ID) {
              aryThisAppTypeID.push(userRoles.userTypeId);
              aryCanAccessOrgID.push(userRoles.organizationId);
            }
          });
        }
      });
    }

    // 同じOrganizationsが複数返って来るので重複削除
    let tmp = [];
    let uniqueOrganizations = [];
    if (jsonResData.hasOwnProperty('organizations')) {
      jsonResData.organizations.forEach((organization) => {
        if (!tmp.includes(organization.id)) {
          tmp.push(organization.id);
          uniqueOrganizations.push(organization);
        }
      });
    }
    jsonResData.organizations = uniqueOrganizations;

    // 削除して空いた所を詰める
    jsonResData.organizations = jsonResData.organizations.filter(Boolean);

    // organizationsからアクセス可能な物だけにフィルタリングして削除していく
    let iOrgNum = jsonResData.organizations.length;
    for (let iCheckIdx = 0; iCheckIdx < iOrgNum; iCheckIdx++) {
      let isDelete = true;
      for (let j = 0; j < aryCanAccessOrgID.length; j++) {
        if (aryCanAccessOrgID[j] === jsonResData.organizations[iCheckIdx].id) {
          isDelete = false;
          break;
        }
      }
      if (isDelete) {
        delete jsonResData.organizations[iCheckIdx];
      }
    }

    // 削除して空いた所を詰める
    jsonResData.organizations = jsonResData.organizations.filter(Boolean);
    // console.log("jsonResData.organizations",jsonResData.organizations)

    // 組織を特定する
    let SelOrgIndex = -1;

    // アクセス可能な組織が1つだけ
    if (aryCanAccessOrgID.length === 1) {
      for (let iOrg = 0; iOrg < jsonResData.organizations.length; iOrg++) {
        if (jsonResData.organizations[iOrg].id === aryCanAccessOrgID[0]) {
          SelOrgIndex = iOrg;
          break;
        }
      }
    } 
    
    // 組織IDがちゃんと取れている場合、管理組織のユーザー＆管理者権限ユーザーかどうかを確認する
    if (SelOrgIndex >= 0) {
      let isAdminOrg = false;
      const strAdminOrgIds = process.env.REACT_APP_ADMIN_ORG_IDS
      const adminOrgIds = strAdminOrgIds.split(','); // カンマ区切りで配列に変換
      // adminOrgIdsに含まれる組織IDかどうかを確認する
      if (adminOrgIds.includes(String(jsonResData.organizations[SelOrgIndex].id))) {
        isAdminOrg = true;
      }

      let isAdminUser = false;
      
      for (let i = 0; i < jsonResData.userTypes.length; i++) {
        for (let j = 0; j < aryThisAppTypeID.length; j++) {
          if (
            jsonResData.userTypes[i].organizationId === jsonResData.organizations[SelOrgIndex].id &&
            jsonResData.userTypes[i].name === "OrganizationAppManager" &&
            jsonResData.userTypes[i].id === aryThisAppTypeID[j]
          ) {
            isAdminUser = true;
            break;
          }
        }
        if (isAdminUser) {
          break;
        }
      }
      const jsonUserInfo = {
        "isAdminUser": isAdminUser,
        "isAdminOrg": isAdminOrg,
        "name": jsonResData.lastName + " " + jsonResData.firstName,
        "email": jsonResData.email,
      }
      setUserInfo(jsonUserInfo);
    } 
  }

  const getUserInfo = async () => {
    const user = await userManager.getUser();

    if (user) {
      if (process.env.REACT_APP_OIDC_API_URL) {
        const accessToken = user.access_token;
        const apiUrl = process.env.REACT_APP_OIDC_API_URL + "/api/users/" + user.profile.sub;

        const apiConfig = {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            '$eager' : '[userRoles.[applications], organizations, userTypes, ninja]',
          },
        }
        // console.log("userAPI config", apiConfig)
        axios.get(apiUrl, apiConfig).then((res) => {
          casthiveRoleCheck(res.data);
        }).catch((err) => {
          // console.log('userAPI err', err)
        });
      } else {
        // dev環境はCastHiveのAPIをコールしないのでここで設定する
        const jsonUserInfo = {
          "isAdminUser": true,
          "isAdminOrg": true,
          name: user.profile.username,
          email: user.profile.email,
        }
        setUserInfo(jsonUserInfo);
      }
    }
  }



  useEffect(() => {
    if (userInfo === null) {
      getUserInfo();
    }

    
  }, []);


  return (userInfo  === null ? 
    <></>
    : 
    <UserContext.Provider
      value={{
        userInfo,
        setUserInfo,
        logoutUserContext
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
