/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "@aws-amplify/ui-react/internal";
import { Flex, Text } from "@aws-amplify/ui-react";
export default function KeitouzuItemBadgeLowNoiseAmplifier(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: { text: {}, KeitouzuItemBadgeLowNoiseAmplifier: {} },
      variantValues: { type: "Default" },
    },
    {
      overrides: {
        text: {},
        KeitouzuItemBadgeLowNoiseAmplifier: {
          backgroundColor: "rgba(250,180,50,1)",
        },
      },
      variantValues: { type: "Warning" },
    },
    {
      overrides: {
        text: {},
        KeitouzuItemBadgeLowNoiseAmplifier: {
          backgroundColor: "rgba(255,76,79,1)",
        },
      },
      variantValues: { type: "Error" },
    },
    {
      overrides: {
        text: {},
        KeitouzuItemBadgeLowNoiseAmplifier: {
          backgroundColor: "rgba(210,210,210,1)",
        },
      },
      variantValues: { type: "Gray" },
    },
    {
      overrides: {
        text: {},
        KeitouzuItemBadgeLowNoiseAmplifier: {
          padding: "6px 10px 6px 10px",
          backgroundColor: "rgba(255,255,255,1)",
          border: "2px SOLID rgba(100,100,100,1)",
        },
      },
      variantValues: { type: "White" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="10px"
      direction="column"
      width="unset"
      height="unset"
      justifyContent="center"
      alignItems="center"
      position="relative"
      borderRadius="4px"
      padding="8px 12px 8px 12px"
      backgroundColor="rgba(38,193,147,1)"
      display="flex"
      {...rest}
      {...getOverrideProps(overrides, "KeitouzuItemBadgeLowNoiseAmplifier")}
    >
      <Text
        fontFamily="Noto Sans"
        fontSize="12px"
        fontWeight="700"
        color="rgba(53,56,59,1)"
        lineHeight="14.40000057220459px"
        textAlign="center"
        display="block"
        direction="column"
        justifyContent="unset"
        letterSpacing="0.45px"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="LNA"
        {...getOverrideProps(overrides, "text")}
      ></Text>
    </Flex>
  );
}
