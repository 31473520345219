import {useState, useEffect} from 'react'

// amplify
import { API } from 'aws-amplify';

import {
    DatakanriDataMaking,
} from '../ui-components'

import {
    getDisplaySettingJson
} from '../lib/SettingUtil'

// Date型を文字をフォーマットする関数
const formatDatetime = (date, format="YYYY/MM/DD hh:mm") => {

    // 年
    format = format.replace(/YYYY/g, date.getFullYear());
    // 月
    format = format.replace(/MM/g, ('0' + (date.getMonth() + 1)).slice(-2));
    // 日
    format = format.replace(/DD/g, ('0' + date.getDate()).slice(-2));
    // 時
    format = format.replace(/hh/g, ('0' + date.getHours()).slice(-2));
    // 分
    format = format.replace(/mm/g, ('0' + date.getMinutes()).slice(-2));
    // 秒
    format = format.replace(/ss/g, ('0' + date.getSeconds()).slice(-2));
    
    return format;
}

export default function DatakanriDataMakingWrapper({onClose}) {
    // 表示設定
    const [displaySettingJson, setDisplaySettingJson] = useState({
        "location_name": {
            "YSCC": "YSCC",
            "SPE": "SPE",
            "SPW": "SPW",
            "SAYADO": "小夜戸"
        },
        "band_label": {
            "3400-3440": {
                "name": "S-1",
                "color": "#bbbcbe"
            },
            "3440-3480": {
                "name": "D-1",
                "color": "#cc0033"
            },
            "3480-3520": {
                "name": "D-2",
                "color": "#cc0033"
            },
            "3520-3560": {
                "name": "K-1",
                "color": "#eb5505"
            },
            "3560-3600": {
                "name": "S-2",
                "color": "#bbbcbe"
            },
            "3600-3700": {
                "name": "D-3",
                "color": "#cc0033"
            },
            "3700-3800": {
                "name": "K-2",
                "color": "#eb5505"
            },
            "3800-3900": {
                "name": "R-1",
                "color": "#ff00ff"
            },
            "3900-4000": {
                "name": "S-3",
                "color": "#bbbcbe"
            },
            "4000-4100": {
                "name": "K-3",
                "color": "#eb5505"
            }
        }
    });

    // 表示設定取得
    const initDisplaySettingJson = async () => {
        const displaySettingJson = await getDisplaySettingJson();
        if (displaySettingJson !== null) {
            setDisplaySettingJson(displaySettingJson);
        } else {
            console.log("displaySettingJson is null");
        }
    }

    // 選択された拠点
    const [selectedLocation, setSelectedLocation] = useState("");

    useEffect(() => {
        console.log(selectedLocation);
    }, [selectedLocation]);

    // 今日の日付
    const today = new Date();
    // 昨日の日付
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);

    // 選択された期間
    const [selectedDate, setSelectedDate] = useState({
        start: yesterday,
        end: today,
    });

    // 日付のバリデーション
    const dateValidation = () => {
        // 開始日時がnullの場合、エラー
        if (selectedDate.start === null) {
            return "開始日時を入力してください。";
        }

        // 終了日時がnullの場合、エラー
        if (selectedDate.end === null) {
            return "終了日時を入力してください。";
        }

        // 開始日時が終了日時よりも大きい場合、エラー
        if (selectedDate.start > selectedDate.end) {
            return "開始終了期間が逆転しています。";
        }

        return "";
    }

    // 時系列データ作成
    const createTimeSeriesData =() => {
        console.log("作成ボタンが押されました。");

        // 拠点バリデーション
        if (selectedLocation === "") {
            console.log("拠点バリデーションエラー");
            return;
        }

        // 日付バリデーション
        if (dateValidation() !== "") {
            console.log("日付バリデーションエラー");
            return;
        }

        // 時系列データ作成
        const params = {
            headers: {},
            response: true,
            queryStringParameters: {
                location: selectedLocation,
                start: formatDatetime(selectedDate.start, "YYYY-MM-DDThh:mm"),
                end: formatDatetime(selectedDate.end, "YYYY-MM-DDThh:mm"),
            }
        };

        // API呼び出し
        API.get("CreateTimeSeriesApi", "/", params)
            .then((response) => {
                // Add your code here
                console.log(response);
            })
            .catch((error) => {
                console.log(error.response);
            });

        // モーダルを閉じる
        onClose();

        console.log(selectedLocation);
        console.log(selectedDate);
    }
    
    useEffect(() => {
        // 表示設定取得
        initDisplaySettingJson();
    }, []);

    useEffect(() => {
        console.log(selectedDate);
        console.log(selectedDate.start);
        console.log(selectedDate.end);
    }, [selectedDate]);

    return (
        <>
            <DatakanriDataMaking
                overrides={{
                    // タイトル
                    "Common/Title": {
                        overrides: {
                            text: {
                                children: "時系列データ作成"
                            }
                        }
                    },
                    // 対象拠点
                    "Datakanri/DataMaking/Location": {
                        example: "true",
                        error: "false",

                        overrides: {
                            // 例
                            Example: {
                                children: "ex:YSCC",
                            },
                            SelectField: {
                                width: "405px",
                                children: (
                                    <>
                                        {
                                            Object.keys(displaySettingJson.location_name).map((key, index) => {
                                                return (
                                                    <option key={index} value={key}>{displaySettingJson.location_name[key]}</option>
                                                )
                                            })
                                        }
                                    </>
                                ),
                                onChange: (e) => {
                                    console.log(e.target.value);
                                    setSelectedLocation(e.target.value);
                                }
                            },
                        },
                    },

                    // 期間選択
                    "Datakanri/DataMaking/Date": {
                        example: "false",
                        error: dateValidation() !== "" ? "true" : "false",

                        overrides: {
                            // 開始
                            TextField465312790: {
                                width: "189px",
                                label: "対象期間",
                                type: "datetime-local",
                                placeholder: "2020/01/01 00:00",
                                isRequired: true,
                                defaultValue: selectedDate.start != null ? formatDatetime(selectedDate.start, "YYYY-MM-DDThh:mm") : "",
                                onBlur: (e) => {
                                    console.log(e.target.value);

                                    if (e.target.value === "") {
                                        // 空の場合、nullを設定
                                        setSelectedDate({
                                            start: null,
                                            end: selectedDate.end,
                                        });
                                        return;
                                    }

                                    // 開始日時を更新
                                    setSelectedDate({
                                        start: new Date(e.target.value + ":00+09:00"),
                                        end: selectedDate.end,
                                    });
                                }
                            },
                            // 終了
                            TextField465312803: {
                                width: "189px",
                                label: "～",
                                type: "datetime-local",
                                placeholder: "2020/01/01 00:00",
                                isRequired: true,
                                defaultValue: selectedDate.end != null ? formatDatetime(selectedDate.end, "YYYY-MM-DDThh:mm") : "",
                                onBlur: (e) => {
                                    console.log(e.target.value);

                                    if (e.target.value === "") {
                                        // 空の場合、nullを設定
                                        setSelectedDate({
                                            start: selectedDate.start,
                                            end: null,
                                        });
                                        return;
                                    }

                                    // 終了日時を更新
                                    setSelectedDate({
                                        start: selectedDate.start,
                                        end: new Date(e.target.value + ":00+09:00"),
                                    });
                                },
                                style:{
                                    "::-webkit-calendar-picker-indicator": {
                                        display: "none",
                                    }
                                }
                            },
                            // エラーメッセージ
                            text461812744: {
                                children: dateValidation(),
                            }
                        },
                    },

                    // 作成ボタン
                    "Common/Btn/Blue": {
                        overrides: {
                            text: {
                                children: "作成",
                            },
                        },
                        style: {
                            ... selectedLocation === "" || dateValidation() !== "" ? {
                                opacity: "0.5"
                            } : {
                                cursor: "pointer",
                            },
                        },
                        onClick: () => {
                            createTimeSeriesData();

                            // モーダルを閉じる
                            // onClose();
                        }
                    }
                }}
            />
        </>
    )
}
