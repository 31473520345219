// // // // // // // // // // // 
// パトライトを制御するカスタムフック
// // // // // // // // // // // 

// react
import { useState, useEffect, useRef } from 'react';

// amplify
import { API, graphqlOperation } from 'aws-amplify';

// graphql subscriptions
import { onUpdatePatliteStatus } from '../graphql/subscriptions';

// graphql mutations
import { updatePatliteStatus } from '../graphql/mutations';

// graphql queries
import { listPatliteStatuses } from '../graphql/queries';

// アラーム状態
const ALARM = {
    ON: 'On',
    OFF: 'Off',
    MUTE: 'Mute',
};

// プルダウン状態
const PULLDOWN = {
    SHOW: 'Show',
    HIDE: 'Hide',
};

// パトライトステータステーブルを管理をするカスタムフック
const usePatliteStatus = () => {
    // パトライトステータスの更新サブスクリプション
    const patliteStatusOnUpdateSubscription = useRef(null);

    // パトライトの状態
    const [patliteStatus, setPatliteStatus] = useState(null);

    // パトライトの状態を取得する関数
    const getPatliteStatus = async (callback) => {
        try {
            const result = await API.graphql(graphqlOperation(listPatliteStatuses));

            if (result.data.listPatliteStatuses.items.length > 0) {
                callback(result.data.listPatliteStatuses.items[0]);
                setPatliteStatus(result.data.listPatliteStatuses.items[0]);
            }
        } catch (error) {
            console.log("getPatliteStatus error", error);
        }
    }
     
    // パトライトのミュート設定を更新する関数
    const updatePatliteMute = async (isMute) => {
        if (!patliteStatus) {
            return;
        }

        try {
            const result = await API.graphql(graphqlOperation(updatePatliteStatus, {
                input: {
                    id: patliteStatus.id,
                    isMute: isMute,
                    alarmLogs: JSON.stringify([]),
                }
            }));

            console.log("updatePatliteMute result", result);

            if (result.data.updatePatliteStatus) {
                console.log("updatePatliteMute success");
            }
        } catch (error) {
            console.log("updatePatliteMute error", error);
        }
    }

    // パトライトのアラームログを全削除する関数
    const deletePatliteAlarmLogs = async () => {
        if (!patliteStatus) {
            return;
        }

        try {
            const result = await API.graphql(graphqlOperation(updatePatliteStatus, {
                input: {
                    id: patliteStatus.id,
                    alarmLogs: JSON.stringify([]),
                }
            }));

            console.log("deletePatliteAlarmLogs result", result);

            if (result.data.updatePatliteStatus) {
                console.log("deletePatliteAlarmLogs success");
            }
        } catch (error) {
            console.log("deletePatliteAlarmLogs error", error);
        }
    }

    // パトライトステータスの更新をサブスクライブする関数
    const subscribePatliteStatus = async (callback) => {
        if (!patliteStatusOnUpdateSubscription.current) {
            // パトライトステータス追加情報購読
            const onCreateObservable = API.graphql(graphqlOperation(onUpdatePatliteStatus));
            
            if (onCreateObservable) {
                patliteStatusOnUpdateSubscription.current = onCreateObservable.subscribe({
                    next: (msg) => {

                        const data = msg.value.data.onUpdatePatliteStatus;
                        if (data) {
                            callback(data);
                        } else {
                            console.log("onUpdatePatliteStatus data is null errors:" + msg.value.errors);
                        }
                    },
                    error: (error) => {
                        console.log("onUpdatePatliteStatus error", error);
                    },
                    close: () => {
                        console.log("onUpdatePatliteStatus close");
                    }
                });

                console.log("onUpdatePatliteStatus subscribe end")
            } else {
                console.log("onUpdatePatliteStatus subscribe error");
            }
        } else {
            console.log("patliteStatusOnUpdateSubscription is not null");
        }
    }

    // パトライトステータスの更新をアンサブスクライブする関数
    const unsubscribePatliteStatus = () => {
        if (patliteStatusOnUpdateSubscription.current) {
            // console.log("patliteStatusOnUpdateSubscription unsubscribe");
            patliteStatusOnUpdateSubscription.current.unsubscribe();
            patliteStatusOnUpdateSubscription.current = null;
        }
    }

    return { getPatliteStatus, subscribePatliteStatus, unsubscribePatliteStatus, updatePatliteMute, deletePatliteAlarmLogs };
}

// パトライト制御するカスタムフック
export const useControlPatlite = (isAuth) => {
    // パトライトの状態 On | Off | Mute
    const [patliteState, setPatliteState] = useState(ALARM.MUTE);

    // プルダウンの状態 Show | Hide
    const [patlitePullDownState, setPatlitePullDownState] = useState(PULLDOWN.HIDE);

    // パトライトステータステーブルを管理をするカスタムフック
    const { subscribePatliteStatus, getPatliteStatus, updatePatliteMute, deletePatliteAlarmLogs } = usePatliteStatus();

    // パトライトの状態を更新する関数
    const updatePatliteStatus = async (patliteState) => {
        // ミュートの場合
        if (patliteState.isMute) {
            setPatliteState(ALARM.MUTE);
            return
        }

        // アラームログを取得
        const alarmLogs = JSON.parse(patliteState.alarmLogs);

        // アラームログが存在する場合
        if (alarmLogs.length > 0) {
            setPatliteState(ALARM.ON);

            // プルダウンを非表示にする
            setPatlitePullDownState(PULLDOWN.HIDE);
        }
        else {
            setPatliteState(ALARM.OFF);
        }
    }

    // 初回レンダリング時の処理
    useEffect(() => {
        if (!isAuth) {
            return;
        }

        // パトライトの初期状態を取得
        getPatliteStatus(updatePatliteStatus);

        // パトライトステータスの更新をサブスクライブする
        subscribePatliteStatus(updatePatliteStatus);
    }, []);

    // パトライトアイコンボタンを押した時の処理
    const onClickPatlite = (e) => {
        if (!isAuth) {
            return;
        }

        console.log("パトライトクリック");

        // パトライトが鳴っている時
        if (patliteState === ALARM.ON) {
            // パトライトを停止する
            deletePatliteAlarmLogs();
        }
        else {
            // プルダウンを表示を切り替える
            setPatlitePullDownState(patlitePullDownState === PULLDOWN.SHOW ? PULLDOWN.HIDE : PULLDOWN.SHOW);
        }
    }

    // プルダウンをクリックした時の処理
    const onClickPatlitePullDown = (e) => {
        if (!isAuth) {
            return;
        }

        console.log("プルダウンクリック");

        // オフの時
        if (patliteState === ALARM.OFF) {
            // ミュートにする
            // setPatliteState(ALARM.MUTE);
            updatePatliteMute(true);
        }
        // ミュートの時
        else if (patliteState === ALARM.MUTE) {
            // ミュートをオフにする
            // setPatliteState(ALARM.OFF);
            updatePatliteMute(false);
        }

        // プルダウンの状態を変更
        setPatlitePullDownState('Hide');
    }

    return { patliteState, patlitePullDownState, onClickPatlite, onClickPatlitePullDown, setPatlitePullDownState};
};