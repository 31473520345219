/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "@aws-amplify/ui-react/internal";
import { Text, View } from "@aws-amplify/ui-react";
export default function CommonTitle(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: { text: {}, CommonTitle: {} },
      variantValues: { size: "large" },
    },
    {
      overrides: {
        text: {
          fontSize: "14px",
          fontWeight: "400",
          lineHeight: "14px",
          letterSpacing: "0.55px",
          bottom: "0%",
          children: "small",
        },
        CommonTitle: { height: "14px" },
      },
      variantValues: { size: "small" },
    },
    {
      overrides: {
        text: {
          fontSize: "16px",
          lineHeight: "16px",
          letterSpacing: "0.65px",
          bottom: "0%",
          children: "medium",
        },
        CommonTitle: { height: "16px" },
      },
      variantValues: { size: "medium" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <View
      width="240px"
      height="18px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      position="relative"
      padding="0px 0px 0px 0px"
      {...rest}
      {...getOverrideProps(overrides, "CommonTitle")}
    >
      <Text
        fontFamily="Noto Sans"
        fontSize="20px"
        fontWeight="500"
        color="rgba(53,56,59,1)"
        lineHeight="20px"
        textAlign="left"
        display="block"
        direction="column"
        justifyContent="unset"
        letterSpacing="0.85px"
        width="240px"
        height="unset"
        gap="unset"
        alignItems="unset"
        position="absolute"
        top="0%"
        bottom="-11.11%"
        left="0%"
        right="0%"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="large"
        {...getOverrideProps(overrides, "text")}
      ></Text>
    </View>
  );
}
