// // // // // // // // // // // //
// カスタムスピナーコンポーネント
// // // // // // // // // // // //

import { Loading } from './../ui-components';

export const CustomLoading = () => {

    return (
        <>
            <style>
                {
                    `@keyframes spin {
                        0% {transform: rotate(0deg);}
                        100% {transform: rotate(360deg);}
                    }`
                }
            </style>

            <Loading
                type="0"
                overrides={{
                    Ellipse: {
                        left: "10px",
                    },
                    Circle: {
                        style: {
                            animation: "0.9s linear infinite spin",
                        }
                    },
                }}
            />
        </>
    )
}