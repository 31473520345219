/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "@aws-amplify/ui-react/internal";
import { Flex, Text, View } from "@aws-amplify/ui-react";
export default function KanshiBtnMenu(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: {
        "Rectangle 83": {},
        text: {},
        "Kanshi/Tab": {},
        KanshiBtnMenu: {},
      },
      variantValues: { situation: "Select", type: "None" },
    },
    {
      overrides: {
        "Rectangle 83": { backgroundColor: "rgba(255,76,79,1)" },
        text: { color: "rgba(255,76,79,1)" },
        "Kanshi/Tab": {},
        KanshiBtnMenu: {},
      },
      variantValues: { situation: "Select", type: "Error" },
    },
    {
      overrides: {
        "Rectangle 83": { backgroundColor: "rgba(250,180,50,1)" },
        text: { color: "rgba(250,180,50,1)" },
        "Kanshi/Tab": {},
        KanshiBtnMenu: {},
      },
      variantValues: { situation: "Select", type: "Warning" },
    },
    {
      overrides: {
        "Rectangle 83": { backgroundColor: "rgba(245,245,245,1)" },
        text: { color: "rgba(100,100,100,1)" },
        "Kanshi/Tab": {},
        KanshiBtnMenu: {},
      },
      variantValues: { situation: "Default", type: "None" },
    },
    {
      overrides: {
        "Rectangle 83": { backgroundColor: "rgba(245,245,245,1)" },
        text: { color: "rgba(255,76,79,1)" },
        "Kanshi/Tab": {},
        KanshiBtnMenu: {},
      },
      variantValues: { situation: "Default", type: "Error" },
    },
    {
      overrides: {
        "Rectangle 83": { backgroundColor: "rgba(245,245,245,1)" },
        text: { color: "rgba(250,180,50,1)" },
        "Kanshi/Tab": {},
        KanshiBtnMenu: {},
      },
      variantValues: { situation: "Default", type: "Warning" },
    },
    {
      overrides: {
        "Rectangle 83": { backgroundColor: "rgba(230,230,230,1)" },
        text: { color: "rgba(100,100,100,1)" },
        "Kanshi/Tab": { backgroundColor: "rgba(230,230,230,1)" },
        KanshiBtnMenu: {},
      },
      variantValues: { situation: "Hover", type: "None" },
    },
    {
      overrides: {
        "Rectangle 83": { backgroundColor: "rgba(230,230,230,1)" },
        text: { color: "rgba(255,76,79,1)" },
        "Kanshi/Tab": { backgroundColor: "rgba(230,230,230,1)" },
        KanshiBtnMenu: {},
      },
      variantValues: { situation: "Hover", type: "Error" },
    },
    {
      overrides: {
        "Rectangle 83": { backgroundColor: "rgba(230,230,230,1)" },
        text: { color: "rgba(250,180,50,1)" },
        "Kanshi/Tab": { backgroundColor: "rgba(230,230,230,1)" },
        KanshiBtnMenu: {},
      },
      variantValues: { situation: "Hover", type: "Warning" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="0"
      direction="column"
      width="unset"
      height="unset"
      justifyContent="flex-start"
      alignItems="center"
      position="relative"
      padding="0px 0px 0px 0px"
      display="flex"
      {...rest}
      {...getOverrideProps(overrides, "KanshiBtnMenu")}
    >
      <View
        width="unset"
        height="2px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        backgroundColor="rgba(38,193,147,1)"
        {...getOverrideProps(overrides, "Rectangle 83")}
      ></View>
      <Flex
        gap="10px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="8px 8px 12px 8px"
        backgroundColor="rgba(245,245,245,1)"
        display="flex"
        {...getOverrideProps(overrides, "Kanshi/Tab")}
      >
        <Text
          fontFamily="Noto Sans"
          fontSize="16px"
          fontWeight="400"
          color="rgba(53,56,59,1)"
          lineHeight="16px"
          textAlign="center"
          display="block"
          direction="column"
          justifyContent="unset"
          letterSpacing="0.65px"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="干渉波監視"
          {...getOverrideProps(overrides, "text")}
        ></Text>
      </Flex>
    </Flex>
  );
}
