import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { 
  userManager,
  redirectToLogin,
  checkTokenExpiration,
  checkAndUpdateCredentials,
  checkLoginTime,
} from './Auth';
import Callback from './Callback';

const AuthProvider = ({ children }) => {
  const location = useLocation();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const checkUser = async () => {
      
    const user = await userManager.getUser();
    if (!user) {
      // console.log('checkUser: NG')
      redirectToLogin();
    }
    else {
      // console.log('checkUser: OK')
      // console.log("process.env.REACT_APP_TEST: ",process.env.REACT_APP_TEST)
      await checkTokenExpiration();
      await checkAndUpdateCredentials(user);
      await checkLoginTime(user);
      setIsLoggedIn(true);
    }
  };

  useEffect(() => {
    checkUser();

    // 定期的に認証情報をチェックする
    const intervalUserCheck = setInterval(() => {
      checkUser();
    }, 5 * 60 * 1000); // 5分ごと

    // タブが再アクティブになったときも認証情報を実行する
    function handleVisibilityChange() {
      if (document.visibilityState === 'visible') {
        checkUser();
      }
    }

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      clearInterval(intervalUserCheck);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    }

  }, []);

  if(location.pathname === '/callback') {
    return <Callback />;
  } else {
    return isLoggedIn ? children : <></>;
  }
};

export default AuthProvider;
