import {
    DatakanriListFile
  } from '../ui-components'

import {
    useState
} from 'react';

function clickTest(message) {
    alert(message)
}

export default function DatakanriListFolderWrapper(props) {
    const [check, setCheck] = useState("on");    

    function onMouseEventEnter(){
        setCheck("off")
    }

    function onMouseEventLeave(){
        setCheck("on")
    }

    return (
        <DatakanriListFile
            width={"100%"}
            minWidth={"1355px"}
            overrides={{
                DatakanriListFile: {
                    style: {
                      cursor: "pointer",
                    }
                },
                CheckboxFieldContainer: {
                    style:{
                        visibility: 'hidden'
                    }
                },  
                CheckboxField: { 
                },
                text480814280: {
                    children: props.item.name
                },
            }}
            type="Folder"
            onMouseEnter={onMouseEventEnter}
            onMouseLeave={onMouseEventLeave}
            check={check}
            onClick={props.onClick}
        ></DatakanriListFile>
    );
}