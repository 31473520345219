/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Flex, SelectField, Text } from "@aws-amplify/ui-react";
import Spacer from "./Spacer";
import CommonDivider from "./CommonDivider";
export default function SetteiItem2(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      gap="0"
      direction="column"
      width="unset"
      height="unset"
      justifyContent="flex-start"
      alignItems="flex-start"
      position="relative"
      padding="0px 0px 0px 0px"
      {...rest}
      {...getOverrideProps(overrides, "SetteiItem2")}
    >
      <Flex
        gap="0"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="16px 24px 16px 24px"
        backgroundColor="rgba(255,255,255,1)"
        {...getOverrideProps(overrides, "Frame")}
      >
        <Text
          fontFamily="Noto Sans"
          fontSize="14px"
          fontWeight="700"
          color="rgba(53,56,59,1)"
          lineHeight="22.399999618530273px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          letterSpacing="0.55px"
          width="160px"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="スペアナ１計測サイクル"
          {...getOverrideProps(overrides, "text")}
        ></Text>
        <Spacer
          display="flex"
          gap="10px"
          direction="column"
          width="48px"
          height="unset"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="10px 10px 10px 10px"
          {...getOverrideProps(overrides, "Spacer528926827")}
        ></Spacer>
        <SelectField
          width="152px"
          height="unset"
          label="1st"
          placeholder="アンテナ1"
          shrink="0"
          size="small"
          isDisabled={false}
          labelHidden={false}
          variation="default"
          {...getOverrideProps(overrides, "SelectField528926961")}
        ></SelectField>
        <Spacer
          display="flex"
          gap="10px"
          direction="column"
          width="48px"
          height="unset"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="10px 10px 10px 10px"
          {...getOverrideProps(overrides, "Spacer528926829")}
        ></Spacer>
        <SelectField
          width="152px"
          height="unset"
          label=" 2st"
          placeholder="アンテナ2"
          shrink="0"
          size="small"
          isDisabled={false}
          labelHidden={false}
          variation="default"
          {...getOverrideProps(overrides, "SelectField528926952")}
        ></SelectField>
        <Spacer
          display="flex"
          gap="10px"
          direction="column"
          width="48px"
          height="unset"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="10px 10px 10px 10px"
          {...getOverrideProps(overrides, "Spacer528926831")}
        ></Spacer>
        <SelectField
          width="152px"
          height="unset"
          label="3rd"
          placeholder="アンテナ3"
          shrink="0"
          size="small"
          isDisabled={false}
          labelHidden={false}
          variation="default"
          {...getOverrideProps(overrides, "SelectField528926970")}
        ></SelectField>
      </Flex>
      <CommonDivider
        display="flex"
        gap="0"
        direction="row"
        width="unset"
        height="1px"
        justifyContent="center"
        alignItems="center"
        overflow="hidden"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Common/Divider")}
      ></CommonDivider>
    </Flex>
  );
}
