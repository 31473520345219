/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import Myicons from "./Myicons";
import { Flex, Text } from "@aws-amplify/ui-react";
import Spacer from "./Spacer";
import CommonBadgeIcon from "./CommonBadgeIcon";
import CommonDivider from "./CommonDivider";
export default function DatakanriFileFileName(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      gap="0"
      direction="column"
      width="800px"
      height="unset"
      justifyContent="flex-start"
      alignItems="flex-start"
      position="relative"
      padding="0px 0px 0px 0px"
      {...rest}
      {...getOverrideProps(overrides, "DatakanriFileFileName")}
    >
      <Flex
        gap="0"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="8px 0px 8px 0px"
        backgroundColor="rgba(255,255,255,1)"
        {...getOverrideProps(overrides, "FileName495219781")}
      >
        <Flex
          gap="16px"
          direction="row"
          width="728px"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "FileName495219771")}
        >
          <Myicons
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            overflow="hidden"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            type="file"
            {...getOverrideProps(overrides, "Myicons")}
          ></Myicons>
          <Text
            fontFamily="Noto Sans"
            fontSize="16px"
            fontWeight="500"
            color="rgba(53,56,59,1)"
            lineHeight="16px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0.65px"
            width="696px"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="yscc_trace_20240401_0000.csv"
            {...getOverrideProps(overrides, "text")}
          ></Text>
        </Flex>
        <Spacer
          display="flex"
          gap="10px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
          grow="1"
          shrink="1"
          basis="0"
          position="relative"
          padding="10px 10px 10px 10px"
          {...getOverrideProps(overrides, "Spacer")}
        ></Spacer>
        <CommonBadgeIcon
          display="flex"
          gap="10px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          position="relative"
          borderRadius="16px"
          padding="8px 8px 8px 8px"
          backgroundColor="rgba(255,255,255,1)"
          situation="default"
          type="white"
          {...getOverrideProps(overrides, "Common/Badge/Icon")}
        ></CommonBadgeIcon>
      </Flex>
      <CommonDivider
        display="flex"
        gap="0"
        direction="row"
        width="unset"
        height="1px"
        justifyContent="center"
        alignItems="center"
        overflow="hidden"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Common/Divider")}
      ></CommonDivider>
    </Flex>
  );
}
