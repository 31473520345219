
import { SetteiInputBox } from '../ui-components';

import {
    SETTING_INPUTBOX_UNIT_DBM,
    SETTING_INPUTBOX_UNIT_DBI,
    SETTING_INPUTBOX_UNIT_DBM_MHZ
} from '../lib/SettingUtil'

export default function SetteiInputBoxWrapper(props) {

    return (
        <SetteiInputBox
            overrides={{
                // "SetteiInputBox": {
                //     attention:props.attention,
                //     label:props.label,
                //     unit:props.unit,
                // },
                PositioningFrame: {
                    width : props.width,
                },
                TextField: {
                    width: props.width,
                    placeholder: "",
                    label:props.labelValue,
                    // defaultValue:props.inputValue,
                    value:props.inputValue,
                    type: props.inputType,
                    ... props.inputType === "number" && {
                        step: props.inputStep,
                        ... props.inputMin && {
                            min: props.inputMin,
                        },
                        ... props.inputMax && {
                            max: props.inputMax,
                        },
                    },
                    onChange:props.onChangeHandler,
                    onBlur:props.onBlurHandler,
                },
                Attention: {
                    children: props.attentiontext,
                },
                "dBm/MHz": {
                    children: props.unit,
                    width: props.unit === SETTING_INPUTBOX_UNIT_DBM || props.unit === SETTING_INPUTBOX_UNIT_DBI ? "36px" : "64px", // SETTING_INPUTBOX_UNIT_DBM(dBm)に対応していないので、SETTING_INPUTBOX_UNIT_DBM_MHZにして個別対応
                }
            }}
            attention={props.attention}
            label={props.label}
            unit={props.unit === SETTING_INPUTBOX_UNIT_DBM || props.unit === SETTING_INPUTBOX_UNIT_DBI ? SETTING_INPUTBOX_UNIT_DBM_MHZ : props.unit } // SETTING_INPUTBOX_UNIT_DBM(dBm)に対応していないので、SETTING_INPUTBOX_UNIT_DBM_MHZにして個別対応}
        >
        </SetteiInputBox>
    );

}