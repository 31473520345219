import { Flex } from "@aws-amplify/ui-react";
import { useEffect, useState, useRef } from "react";


import {
    KanshiSearchWrapper,
    CommonBtnWhiteWrapper,
    KanshiLogListWrapper,
    CommonBtnBlueWrapper,
    CheckWrapper,
} from '.'

import {
    getMoniteringLog,
    subscribeToCreateMoniteringLog,
    subscribeToUpdateMoniteringLog,
    getUnconfirmedMoniteringLog,
    getUnrecoveredMoniteringLog,
    getMoniteringLogSearch,
    updateMoniteringLogKakuninStatus,
} from '../lib/LogUtil'

import { getLogTypeName } from "../lib/ConstList";

import {
    formatOccurrenceSource,
} from "./../customComponents/LogSummaryTable"

import {
    getFormatNowDate,
} from '../lib/StorageUtil'

import { isLocalMode } from "../lib/envUtil";

import { CustomModal } from "../customComponents/CustomModal";

import { TAB_ALL } from "../lib/ConstList";

import { useMoniteringLogList } from "../hooks/useMoniteringLog";

export default function KanshiLogAreaWrapper(props) {
    // 検索タブ
    const [selectSearchTab, setSelectSearchTab] = useState("全て");
    const [searchBoxDisplayType, setSearchBoxDisplayType] = useState("Default");

    //　検索エリア表示時にログ表示エリアの高さを調整するための値
    const [logListHeight, setLogListHeight] = useState(!isLocalMode ? "568px" : "760px");

    // 一括ログ確認モード
    const [bulkLogConfirm, setBulkLogConfirm] = useState(false);
    // 一括ログ確認モーダル表示
    const [bulkLogConfirmModal, setBulkLogConfirmModal] = useState(false);

    // 一括csv出力モード
    const [bulkCsvDownload, setBulkCsvDownload] = useState(false);
    // 一括csv出力モーダル表示
    const [bulkCsvDownloadModal, setBulkCsvDownloadModal] = useState(false);

    // ローカルモードの場合はログ確認、CSV出力ボタンを表示しない
    const adjustLogListHeight = (searchOpen) => (
        setLogListHeight(isLocalMode ? "760px" : !searchOpen ? "568px" : "192px")
    );

    // タブクリック時の処理
    const onSearchTabClick = (tabName) => {
        setSelectSearchTab(tabName);
        if (tabName === "絞込み") {
            setSearchBoxDisplayType("Select");
        } else {
            setSearchBoxDisplayType("Default");
        }
    };

    // 監視ログ一覧用カスタムフック
    const {
        moniteringLogList: logList,
        setMoniteringLogList: setLogData,
        getMoniteringLogList: getLogList,
        getMoreMoniteringLogList: getMoreLogList,
        resetMoniteringLogList: resetLogList,
        isLoading,
        error,
        hasNextPage,
        subscribeMoniteringLogList,
        unsubscribeMoniteringLogList,
        searchMoniteringLogList: searchLogList,
    } = useMoniteringLogList();

    // 全選択チェックボックスの状態
    const [allCheck, setAllCheck] = useState(false);

    // チェックボックスをクリックした時の処理
    function onClickCheck(index, isCheck = null) {
        var newLogList = logList;
        newLogList[index].check = isCheck === null ? !newLogList[index].check : isCheck;
        setLogData((prev) => ([...newLogList]));

        // 全選択チェックボックスの状態を更新
        updateAllCheck()
    }

    // 全選択チェックボックスをクリックした時の処理
    function onClickAllCheck(isCheck) {
        var newMoniteringLogData = logList;

        newMoniteringLogData.map((item, index) => {
            // 一括ログ確認モードの場合は、確認済みのログはチェックを外す
            if (bulkLogConfirm && item.confirmed_flg == 1) {
                newMoniteringLogData[index].check = false;
            }
            else {
                newMoniteringLogData[index].check = isCheck;
            }
        })

        setLogData((prev) => ([...newMoniteringLogData]));
        setAllCheck(isCheck);
    }

    // チェックボックスの状態をリセット
    const resetCheck = () => {
        var newLogList = logList;

        newLogList.map((item, index) => {
            newLogList[index].check = false;
        })

        setLogData((prev) => ([...newLogList]));
        setAllCheck(false);
    }

    // 全選択チェックボックスの状態を更新
    function updateAllCheck() {
        var newAllCheck = true;
        if (logList.length === 0) {
            newAllCheck = false;
        } else {
            logList.map((item, index) => {
                if (!item.check) {
                    // 一括ログ確認モードの場合は、確認ずみのログは確認しない
                    if (bulkLogConfirm && item.confirmed_flg == 1) {
                        return;
                    }

                    newAllCheck = false;
                }
            })
        }

        setAllCheck(newAllCheck);
    }

    // チェックされているログのリストを取得
    const getCheckedLogList = () => {
        var checkedLogList = [];

        logList.map((item, index) => {
            if (item.check) {
                checkedLogList.push(item);
            }
        })

        return checkedLogList;
    }

    // 一括確認処理
    const onClickBulkLogConfirm = () => {
        // モーダルを非表示
        setBulkLogConfirmModal(false);

        // チェックされているログのリストを取得
        var checkedLogList = getCheckedLogList();

        // 確認処理
        checkedLogList.map((item, index) => {
            updateMoniteringLogKakuninStatus(item.id);
        })

        // 一括ログ確認モードを解除
        setBulkLogConfirm(false);
    }

    // 一括csv出力処理
    const onClickBulkCsvDownload = () => {
        // モーダルを非表示
        setBulkCsvDownloadModal(false);

        // チェックされているログのリストを取得
        const checkedLogList = getCheckedLogList();

        // csv出力処理
        // CSV格納用 ※ヘッダーをあらかじめ設定しておく
        const header = "日付,拠点名,発生元,ログ種別,確認状態,詳細\n";

        // データ格納用
        let data = header;

        // オブジェクトの中身を取り出してカンマ区切りにする
        for (let checkedLog of checkedLogList) {
            data += new Date(checkedLog.occurrence_at).toLocaleString("ja-JP") + ",";
            data += props.displaySettingJson.location_name[checkedLog.location_name] + ",";

            data += formatOccurrenceSource(checkedLog.alarm_type, checkedLog.occurrence_source, props.displaySettingJson) + ",";

            data += getLogTypeName(checkedLog) + ",";
            data += (checkedLog.confirmed_flg ? "確認済み" : "未確認") + ",";
            data += `"${checkedLog.log_detail}"`;

            // データ末尾に改行コードを追記
            data += "\n";
        }

        ////////////////////CSV形式へ変換////////////////////
        // BOMを付与（Excelで開いた際のの文字化け対策）
        const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
        // CSV用バイナリデータを作成
        const blob = new Blob([bom, data], { type: "text/csv" });
        // blobからオブジェクトURLを作成
        const objectUrl = URL.createObjectURL(blob);

        ////////////////////ダウンロードリンクの作成とクリック////////////////////
        // ダウンロードリンクを作成 ※HTMLのaタグを作成
        const downloadLink = document.createElement("a");
        // ファイル名の設定
        const fileName = `interference_monitoring_logs_${getFormatNowDate()}.csv`;
        downloadLink.download = fileName;

        // ダウンロードファイルを設定 ※aタグのhref属性
        downloadLink.href = objectUrl;

        // ダウンロードリンクを擬似的にクリック
        downloadLink.click();

        // ダウンロードリンクを削除
        downloadLink.remove();

        // 一括csv出力モードを解除
        setBulkCsvDownload(false);
    }

    useEffect(() => {
        // console.log("KanshiLogAreaWrapper useEffect", props);
        // 検索ボックスの表示状態に合わせて、ログリストの高さを調整
        adjustLogListHeight(searchBoxDisplayType === "Select");
    }, [searchBoxDisplayType]);

    // 検索条件filterを作成
    const createFilter = ({
        location = null,
        alarmType = null,
        logType = null,
        occurenceSource = null,
        isRecover = null,
        isConfirm = null,
    }) => {
        // フィルター
        const filter = {};

        // 選択された拠点を検索条件に追加
        if (location !== null) {
            filter.location_name = {
                eq: location,
            };
        }
        // 監視種別の検索条件追加
        if (alarmType !== null) {
            filter.alarm_type = {
                eq: alarmType,
            };
        }
        // ログ種別の検索条件追加
        if (logType !== null) {
            filter.log_type = {
                eq: logType,
            };
        }
        // 発生元の検索条件追加
        if (occurenceSource !== null) {
            filter.occurrence_source = {
                eq: occurenceSource,
            };
        }

        // 確認状態の検索条件追加
        if (isConfirm !== null) {
            filter.confirmed_flg = {
                eq: isConfirm ? 1 : 0,
            };
        }

        // filterに復帰条件を追加して、未確認ログを取得
        if (isRecover != null) {
            filter.recover_flg = {
                eq: isRecover ? 1 : 0,
            };
        }

        console.log("createFilter filter:", filter);

        return filter;
    }

    // スクロールイベント
    const loadMoreRows = () => {
        // 検索以外のタブの場合
        if (selectSearchTab !== "絞込み") {
            // 拠点
            const location = props.selectedLocation !== TAB_ALL ? props.selectedLocation : null;

            // filter作成用パラメータ
            let param = {
                location: location,
            };

            // 発生中タブの場合
            if (selectSearchTab === "発生中") {
                param.isRecover = false
            }

            // 未確認タブの場合
            if (selectSearchTab === "未確認") {
                param.isConfirm = false
            }

            // filterを作成
            const filter = createFilter(param);

            // 追加データ取得
            getMoreLogList(filter)
        }
    };

    // 検索ボタンクリック時の処理
    const onClickLogSearchBtn = async (searchCondition) => {
        console.log("onClickLogSearchBtn", searchCondition);
        // 検索ボックスを閉じる
        // setSearchBoxDisplayType("Default");

        // searchConditionからoccrennce_atの検索条件を作成
        const startDate = new Date(searchCondition.startDate);
        // toISOStringを使うとUTC時間表記になるので、9時間足しておく
        startDate.setHours(startDate.getHours() + 9);
        // ミリ秒とZを削除して、+09:00を付与
        const startDateStr = startDate.toISOString().slice(0, -1) + "+09:00";

        const endDate = new Date(searchCondition.endDate);
        // toISOStringを使うとUTC時間表記になるので、9時間足しておく
        endDate.setHours(endDate.getHours() + 9);
        // ミリ秒とZを削除して、+09:00を付与
        const endDateStr = endDate.toISOString().slice(0, -1) + "+09:00";

        // 期間を検索条件に追加
        const occurenceAtCondition = {
            between: [startDateStr, endDateStr]
        };

        // filter作成用パラメータ
        let param = {
            location: searchCondition.locationName !== "ALL" ? searchCondition.locationName : null,
            alarmType: searchCondition.alarmType !== "ALL" ? searchCondition.alarmType : null,
            logType: searchCondition.logType !== "ALL" ? searchCondition.logType : null,
            occurenceSource: searchCondition.occurenceSource !== "ALL" ? searchCondition.occurenceSource : null,
        };

        // 確認状態条件
        if (searchCondition.isConfirm !== "ALL") {
            param.isConfirm = searchCondition.isConfirm === "Confirmed" ? true : false;
        }

        // 復帰状態条件
        if (searchCondition.isRecover !== "ALL") {
            param.isRecover = searchCondition.isRecover === "Recovered" ? true : false;
        }

        // filterを作成
        const filter = createFilter(param);

        // ログを検索
        searchLogList(occurenceAtCondition, filter);
    };

    useEffect(() => {
        // 検索タブ以外の場合
        if (selectSearchTab !== "絞込み") {
            // 拠点
            const location = props.selectedLocation !== TAB_ALL ? props.selectedLocation : null;

            // filter作成用パラメータ
            let param = {
                location: location,
            };

            // 発生中タブの場合
            if (selectSearchTab === "発生中") {
                param.isRecover = false
            }

            // 未確認タブの場合
            if (selectSearchTab === "未確認") {
                param.isConfirm = false
            }

            // filterを作成
            const filter = createFilter(param);

            // データ取得
            getLogList(filter);

            // サブスクライブを登録
            subscribeMoniteringLogList(filter);
        }

        return () => {
            // サブスクライブを解除
            unsubscribeMoniteringLogList()
        };
    }, [selectSearchTab, props.selectedLocation]);

    useEffect(() => {
        console.log(logList);

        // 全選択チェックボックスの状態を更新
        updateAllCheck()
    }, [logList]);

    return (
        <>
            <Flex
                gap="16px"
                direction="column"
                width="100%"
                height="unset"
                justifyContent="flex-start"
                alignItems="center"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                grow={1}
            >
                {!isLocalMode &&
                    <>
                        {/* タブ */}
                        <KanshiSearchWrapper
                            displaySettingJson={props.displaySettingJson}
                            onClickSearchTab={onSearchTabClick}
                            selectTab={selectSearchTab}
                            searchBoxDisplayType={searchBoxDisplayType}
                            onClickLogSearchBtn={(searchCondition) => onClickLogSearchBtn(searchCondition)}
                        ></KanshiSearchWrapper>

                        {/* ログ確認、CSV出力ボタン */}
                        <Flex
                            gap="8px"
                            direction="row"
                            width="unset"
                            height="unset"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            shrink="0"
                            alignSelf="stretch"
                            position="relative"
                            padding="0px 0px 0px 0px"
                        // {...getOverrideProps(overrides, "Btn")}
                        >
                            {
                                bulkCsvDownload ? (
                                    <CommonBtnWhiteWrapper
                                        overrides={{
                                            "CommonBtnWhite": {
                                                justifyContent: "center",
                                                alignItems: "center",
                                                grow: "1",
                                                style: {
                                                    cursor: "pointer",
                                                },
                                            },
                                        }}
                                        children="キャンセル"
                                        onClick={() => { console.log("CSV出力 閉じる"); setBulkCsvDownload(false); }}
                                    ></CommonBtnWhiteWrapper>
                                ) : (
                                    <>
                                        {
                                            bulkLogConfirm ? (
                                                <CommonBtnBlueWrapper
                                                    overrides={{
                                                        "CommonBtnBlue": {
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            grow: "1",
                                                            style: {
                                                                cursor: "pointer",
                                                            },
                                                        }
                                                    }}
                                                    children={`${getCheckedLogList().length}件のログを確認`}
                                                    onClick={() => {
                                                        if (getCheckedLogList().length > 0) {
                                                            setBulkLogConfirmModal(true)
                                                        }
                                                    }}
                                                // {...getOverrideProps(overrides, "Common/Btn/Blue")}
                                                ></CommonBtnBlueWrapper>
                                            ) : (
                                                <CommonBtnWhiteWrapper
                                                    overrides={{
                                                        "CommonBtnWhite": {
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            grow: "1",
                                                            style: {
                                                                cursor: "pointer",
                                                            },
                                                        }
                                                    }}
                                                    children="ログ確認"
                                                    onClick={() => {
                                                        console.log("ログ確認")
                                                        resetCheck();
                                                        setBulkLogConfirm(true);
                                                    }}
                                                ></CommonBtnWhiteWrapper>
                                            )
                                        }
                                    </>
                                )
                            }


                            {
                                bulkLogConfirm ? (
                                    <CommonBtnWhiteWrapper
                                        overrides={{
                                            "CommonBtnWhite": {
                                                justifyContent: "center",
                                                alignItems: "center",
                                                grow: "1",
                                                style: {
                                                    cursor: "pointer",
                                                },
                                            },
                                        }}
                                        children="キャンセル"
                                        onClick={() => { console.log("ログ確認 閉じる"); setBulkLogConfirm(false); }}
                                    ></CommonBtnWhiteWrapper>
                                ) : (
                                    <>
                                        {
                                            bulkCsvDownload ? (
                                                <>
                                                    <CommonBtnBlueWrapper
                                                        overrides={{
                                                            "CommonBtnBlue": {
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                                grow: "1",
                                                                style: {
                                                                    cursor: "pointer",
                                                                },
                                                            }
                                                        }}
                                                        children={`${getCheckedLogList().length}件のCSVを出力`}
                                                        onClick={() => {
                                                            if (getCheckedLogList().length > 0) {
                                                                setBulkCsvDownloadModal(true)
                                                            }
                                                        }}
                                                    // {...getOverrideProps(overrides, "Common/Btn/Blue")}
                                                    ></CommonBtnBlueWrapper>
                                                </>
                                            ) : (
                                                <CommonBtnWhiteWrapper
                                                    overrides={{
                                                        "CommonBtnWhite": {
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            grow: "1",
                                                            style: {
                                                                cursor: "pointer",
                                                            },
                                                        },
                                                    }}
                                                    children="CSV出力"
                                                    onClick={() => {
                                                        console.log("CSV出力");
                                                        resetCheck();
                                                        setBulkCsvDownload(true);
                                                    }}
                                                ></CommonBtnWhiteWrapper>
                                            )
                                        }
                                    </>
                                )
                            }

                        </Flex>
                    </>
                }
                <Flex
                    gap="0"
                    direction="column"
                    width="unset"
                    height="unset"
                    justifyContent="flex-start"
                    alignItems="center"
                    shrink="0"
                    alignSelf="stretch"
                    position="relative"
                    padding="0px 0px 24px 0px"
                    grow={1}
                // {...getOverrideProps(overrides, "Frame 863")}
                >
                    {/* ログ一覧 */}
                    <KanshiLogListWrapper
                        loadMoreRows={loadMoreRows}
                        logData={logList}
                        displaySettingJson={props.displaySettingJson}
                        logListHeight={logListHeight}
                        bulkLogConfirm={bulkLogConfirm}
                        bulkCsvDownload={bulkCsvDownload}
                        onClickCheck={onClickCheck}
                        allCheck={allCheck}
                        onClickAllCheck={onClickAllCheck}
                        isLoading={isLoading}
                        error={error}
                        hasNextPage={hasNextPage}
                    >
                    </KanshiLogListWrapper>
                </Flex>
            </Flex>

            {/* 一括確認モーダル */}
            <CustomModal
                open={bulkLogConfirmModal}
                onClose={() => setBulkLogConfirmModal(false)}
            >
                <CheckWrapper
                    msg={"選択した項目を確認済みにします"}
                    onClickOk={onClickBulkLogConfirm}
                    onClickCancel={() => setBulkLogConfirmModal(false)}
                />
            </CustomModal>

            {/* 一括CSV出力モーダル */}
            <CustomModal
                open={bulkCsvDownloadModal}
                onClose={() => setBulkCsvDownloadModal(false)}
            >
                <CheckWrapper
                    msg={"選択した項目をCSV出力します"}
                    onClickOk={onClickBulkCsvDownload}
                    onClickCancel={() => setBulkCsvDownloadModal(false)}
                />
            </CustomModal>
        </>
    );
}