/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "@aws-amplify/ui-react/internal";
import CommonBtnText from "./CommonBtnText";
import { Flex } from "@aws-amplify/ui-react";
export default function KanshiBtnSearch(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: {
        "Common/Btn/Text39255568": {},
        "Common/Btn/Text39256946": {},
        "Common/Btn/Text39257144": {},
        "Common/Btn/Text443017947": {},
        KanshiBtnSearch: {},
      },
      variantValues: { situation: "\u3059\u3079\u3066" },
    },
    {
      overrides: {
        "Common/Btn/Text39255568": { height: "26px", type: "Default" },
        "Common/Btn/Text39256946": {},
        "Common/Btn/Text39257144": {},
        "Common/Btn/Text443017947": { height: "27px", type: "Select" },
        KanshiBtnSearch: {},
      },
      variantValues: { situation: "\u7D5E\u8FBC\u307F" },
    },
    {
      overrides: {
        "Common/Btn/Text39255568": { height: "unset", type: "Default" },
        "Common/Btn/Text39256946": { height: "unset", type: "Select" },
        "Common/Btn/Text39257144": { height: "unset" },
        "Common/Btn/Text443017947": { height: "unset" },
        KanshiBtnSearch: {},
      },
      variantValues: { situation: "\u767A\u751F\u4E2D" },
    },
    {
      overrides: {
        "Common/Btn/Text39255568": { height: "26px", type: "Default" },
        "Common/Btn/Text39256946": {},
        "Common/Btn/Text39257144": { height: "27px", type: "Select" },
        "Common/Btn/Text443017947": {},
        KanshiBtnSearch: {},
      },
      variantValues: { situation: "\u672A\u78BA\u8A8D" },
    },
    {
      overrides: {
        "Common/Btn/Text39255568": {},
        "Common/Btn/Text39256946": {},
        "Common/Btn/Text39257144": {},
        "Common/Btn/Text443017947": {},
        KanshiBtnSearch: {},
      },
      variantValues: { situation: "situation5" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="16px"
      direction="row"
      width="unset"
      height="unset"
      justifyContent="flex-start"
      alignItems="flex-start"
      position="relative"
      padding="0px 0px 0px 0px"
      display="flex"
      {...rest}
      {...getOverrideProps(overrides, "KanshiBtnSearch")}
    >
      <CommonBtnText
        display="flex"
        gap="0"
        direction="column"
        width="unset"
        height="27px"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        type="Select"
        size="Small"
        {...getOverrideProps(overrides, "Common/Btn/Text39255568")}
      ></CommonBtnText>
      <CommonBtnText
        display="flex"
        gap="0"
        direction="column"
        width="unset"
        height="26px"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        type="Default"
        size="Small"
        {...getOverrideProps(overrides, "Common/Btn/Text39256946")}
      ></CommonBtnText>
      <CommonBtnText
        display="flex"
        gap="0"
        direction="column"
        width="unset"
        height="26px"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        type="Default"
        size="Small"
        {...getOverrideProps(overrides, "Common/Btn/Text39257144")}
      ></CommonBtnText>
      <CommonBtnText
        display="flex"
        gap="0"
        direction="column"
        width="unset"
        height="26px"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        type="Default"
        size="Small"
        {...getOverrideProps(overrides, "Common/Btn/Text443017947")}
      ></CommonBtnText>
    </Flex>
  );
}
