/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Flex, Image, Text, View } from "@aws-amplify/ui-react";
import Spacer from "./Spacer";
import CommonHeaderIcon from "./CommonHeaderIcon";
import CommonBadgeHeader from "./CommonBadgeHeader";
import CommonBadgeHeaderAccount from "./CommonBadgeHeaderAccount";
export default function Header(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      gap="0"
      direction="row"
      width="1355px"
      height="unset"
      justifyContent="center"
      alignItems="center"
      position="relative"
      boxShadow="0px 0px 8px rgba(0, 0, 0, 0.10000000149011612)"
      padding="16px 40px 16px 40px"
      backgroundColor="rgba(255,255,255,1)"
      {...rest}
      {...getOverrideProps(overrides, "Header")}
    >
      <Flex
        gap="8px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-end"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Title")}
      >
        <Text
          fontFamily="Noto Sans"
          fontSize="16px"
          fontWeight="700"
          color="rgba(53,56,59,1)"
          lineHeight="16px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          letterSpacing="0.65px"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="5G共同テレポート総合監視システム"
          {...getOverrideProps(overrides, "text")}
        ></Text>
        <Image
          width="108px"
          height="14px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          objectFit="cover"
          {...getOverrideProps(overrides, "logo")}
        ></Image>
      </Flex>
      <Spacer
        display="flex"
        gap="10px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="center"
        overflow="hidden"
        grow="1"
        shrink="1"
        basis="0"
        position="relative"
        padding="10px 10px 10px 10px"
        {...getOverrideProps(overrides, "Spacer482214381")}
      ></Spacer>
      <Text
        fontFamily="Noto Sans"
        fontSize="16px"
        fontWeight="500"
        color="rgba(53,56,59,1)"
        lineHeight="16px"
        textAlign="center"
        display="block"
        direction="column"
        justifyContent="unset"
        letterSpacing="0.65px"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="2022/10/03（月） 00:00:00"
        {...getOverrideProps(overrides, "time")}
      ></Text>
      <Spacer
        display="flex"
        gap="10px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="center"
        overflow="hidden"
        grow="1"
        shrink="1"
        basis="0"
        position="relative"
        padding="10px 10px 10px 10px"
        {...getOverrideProps(overrides, "Spacer482214383")}
      ></Spacer>
      <Flex
        gap="24px"
        direction="row"
        width="586px"
        height="unset"
        justifyContent="flex-end"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Menu")}
      >
        <CommonHeaderIcon
          height="30px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          situation="On"
          pulldown="Hide"
          type="Alarm"
          {...getOverrideProps(overrides, "Common/Header/Icon482214385")}
        ></CommonHeaderIcon>
        <CommonHeaderIcon
          height="30px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          situation="On"
          pulldown="Hide"
          type="Patlight"
          {...getOverrideProps(overrides, "Common/Header/Icon482214386")}
        ></CommonHeaderIcon>
        <View
          width="1px"
          height="24px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          backgroundColor="rgba(217,217,217,1)"
          {...getOverrideProps(overrides, "Rectangle482214387")}
        ></View>
        <CommonBadgeHeader
          width="40px"
          height="40px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          borderRadius="20px"
          padding="0px 0px 0px 0px"
          backgroundColor="rgba(255,255,255,1)"
          situation="default"
          type="datakanri"
          {...getOverrideProps(overrides, "Common/Badge/Header482214388")}
        ></CommonBadgeHeader>
        <CommonBadgeHeader
          width="40px"
          height="40px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          borderRadius="20px"
          padding="0px 0px 0px 0px"
          backgroundColor="rgba(255,255,255,1)"
          situation="default"
          type="setting"
          {...getOverrideProps(overrides, "Common/Badge/Header482214389")}
        ></CommonBadgeHeader>
        <View
          width="1px"
          height="24px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          backgroundColor="rgba(217,217,217,1)"
          {...getOverrideProps(overrides, "Rectangle482214390")}
        ></View>
        <CommonBadgeHeader
          width="40px"
          height="40px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          borderRadius="20px"
          padding="0px 0px 0px 0px"
          backgroundColor="rgba(255,255,255,1)"
          situation="default"
          type="home"
          {...getOverrideProps(overrides, "Common/Badge/Header482214391")}
        ></CommonBadgeHeader>
        <CommonBadgeHeaderAccount
          width="40px"
          height="40px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          borderRadius="20px"
          padding="0px 0px 0px 0px"
          backgroundColor="rgba(255,255,255,1)"
          type="Default"
          {...getOverrideProps(overrides, "Common/Badge/Header/Account")}
        ></CommonBadgeHeaderAccount>
      </Flex>
    </Flex>
  );
}
