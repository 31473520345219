/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Flex, SelectField, Text } from "@aws-amplify/ui-react";
import Spacer from "./Spacer";
import SetteiInputBox from "./SetteiInputBox";
import CommonDivider from "./CommonDivider";
export default function SetteiItem(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      gap="0"
      direction="column"
      width="unset"
      height="unset"
      justifyContent="flex-start"
      alignItems="flex-start"
      position="relative"
      padding="0px 0px 0px 0px"
      {...rest}
      {...getOverrideProps(overrides, "SetteiItem")}
    >
      <Flex
        gap="0"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="8px 24px 8px 24px"
        backgroundColor="rgba(255,255,255,1)"
        {...getOverrideProps(overrides, "Frame")}
      >
        <Text
          fontFamily="Noto Sans"
          fontSize="14px"
          fontWeight="700"
          color="rgba(53,56,59,1)"
          lineHeight="22.399999618530273px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          letterSpacing="0.55px"
          width="160px"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="消費電力監視"
          {...getOverrideProps(overrides, "text")}
        ></Text>
        <Spacer
          display="flex"
          gap="10px"
          direction="column"
          width="48px"
          height="unset"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="10px 10px 10px 10px"
          {...getOverrideProps(overrides, "Spacer502826175")}
        ></Spacer>
        <SelectField
          width="120px"
          height="unset"
          placeholder="ON"
          justifyContent="flex-end"
          shrink="0"
          size="small"
          isDisabled={false}
          labelHidden={true}
          variation="default"
          {...getOverrideProps(overrides, "SelectField502826176")}
        ></SelectField>
        <Spacer
          display="flex"
          gap="10px"
          direction="column"
          width="48px"
          height="unset"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="10px 10px 10px 10px"
          {...getOverrideProps(overrides, "Spacer502826177")}
        ></Spacer>
        <SelectField
          width="120px"
          height="unset"
          placeholder="警告"
          justifyContent="flex-end"
          shrink="0"
          size="small"
          isDisabled={false}
          labelHidden={true}
          variation="default"
          {...getOverrideProps(overrides, "SelectField502826178")}
        ></SelectField>
        <Spacer
          display="flex"
          gap="10px"
          direction="column"
          width="48px"
          height="unset"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="10px 10px 10px 10px"
          {...getOverrideProps(overrides, "Spacer502826179")}
        ></Spacer>
        <SetteiInputBox
          display="flex"
          gap="8px"
          direction="column"
          width="unset"
          height="86px"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          attention={true}
          label={true}
          unit="Byo"
          {...getOverrideProps(overrides, "Settei/InputBox502826180")}
        ></SetteiInputBox>
        <Spacer
          display="flex"
          gap="10px"
          direction="column"
          width="48px"
          height="unset"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="10px 10px 10px 10px"
          {...getOverrideProps(overrides, "Spacer502826181")}
        ></Spacer>
        <SetteiInputBox
          display="flex"
          gap="8px"
          direction="column"
          width="unset"
          height="86px"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          attention={true}
          label={true}
          unit="A"
          {...getOverrideProps(overrides, "Settei/InputBox502826182")}
        ></SetteiInputBox>
        <Spacer
          display="flex"
          gap="10px"
          direction="column"
          width="48px"
          height="unset"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="10px 10px 10px 10px"
          {...getOverrideProps(overrides, "Spacer502826183")}
        ></Spacer>
        <SetteiInputBox
          display="flex"
          gap="8px"
          direction="column"
          width="unset"
          height="86px"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          attention={true}
          label={true}
          unit="A"
          {...getOverrideProps(overrides, "Settei/InputBox502826184")}
        ></SetteiInputBox>
        <Spacer
          display="flex"
          gap="10px"
          direction="column"
          width="48px"
          height="unset"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="10px 10px 10px 10px"
          {...getOverrideProps(overrides, "Spacer502826185")}
        ></Spacer>
        <SetteiInputBox
          display="flex"
          gap="8px"
          direction="column"
          width="unset"
          height="86px"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          attention={true}
          label={true}
          unit="A"
          {...getOverrideProps(overrides, "Settei/InputBox502826186")}
        ></SetteiInputBox>
      </Flex>
      <CommonDivider
        display="flex"
        gap="0"
        direction="row"
        width="unset"
        height="1px"
        justifyContent="center"
        alignItems="center"
        overflow="hidden"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Common/Divider")}
      ></CommonDivider>
    </Flex>
  );
}
