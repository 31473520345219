// // // // // // // // // // // 
// アラーム音を制御するカスタムフック
// // // // // // // // // // // 

// react
import { useState, useEffect, useRef } from 'react';

// LogUtil
import { subscribeToCreateMoniteringLog } from '../lib/LogUtil'

// アラーム状態
export const ALARM = {
    ON: 'On',
    OFF: 'Off',
    MUTE: 'Mute',
};

// プルダウン状態
export const PULLDOWN = {
    SHOW: 'Show',
    HIDE: 'Hide',
};

// ログデータをサブスクライブするカスタムフック
const useMoniteringLogData = () => {
    // ログデータのサブスクリプション
    const moniteringLogOnCreateSubscription = useRef(null);

    // ログデータをサブスクライブする関数
    const subscribeMoniteringLogData = async (callback) => {
        if (!moniteringLogOnCreateSubscription.current) {
            // ログデータ追加情報購読
            const onCreateObservable = await subscribeToCreateMoniteringLog();
            if (onCreateObservable) {
                // console.log("CreateMoniteringLog subscribe");

                moniteringLogOnCreateSubscription.current = onCreateObservable.subscribe({
                    next: (msg) => {
                        console.log("onCreateMoniteringLog msg:", msg);
                        const data = msg.value.data.onCreateMoniteringLog;
                        if (data) {
                            callback(data);
                        } else {
                            console.log("onCreateMoniteringLog data is null errors:" + msg.value.errors);
                        }
                    },
                    error: (error) => {
                        console.log("onCreateMoniteringLog error", error);
                    },
                    close: () => {
                        console.log("onCreateMoniteringLog close");
                    }
                });

                // console.log("onCreateMoniteringLog subscribe end")
            } else {
                console.log("CreateMoniteringLog subscribe error");
            }
        } else {
            console.log("moniteringLogOnCreateSubscription is not null");
        }
    }

    // ログデータをアンサブスクライブする関数
    const unsubscribeMoniteringLogData = () => {
        if (moniteringLogOnCreateSubscription.current) {
            // console.log("moniteringLogOnCreateSubscription unsubscribe");
            moniteringLogOnCreateSubscription.current.unsubscribe();
            moniteringLogOnCreateSubscription.current = null;
        }
    }

    return { subscribeMoniteringLogData, unsubscribeMoniteringLogData };
}

// アラーム音制御するカスタムフック
export const useControlAlarm = (isAuth) => {
    // アラーム音 異常・警告
    const audioErrorRef = useRef(isAuth ? new Audio('sound/alarm/62.mp3') : null);
    const audioWarningRef = useRef(isAuth ? new Audio('sound/alarm/61.mp3') : null);
    
    if (audioErrorRef.current) {
        // ループ再生
        audioErrorRef.current.loop = true;   
    }
    if (audioWarningRef.current) {
        // ループ再生
        audioWarningRef.current.loop = true;   
    }

    // アラームの状態 On | Off (default) | Mute 
    const [alarmState, setAlarmState] = useState(ALARM.OFF);

    // プルダウンの状態 Show | Hide
    const [alarmPullDownState, setAlarmPullDownState] = useState(PULLDOWN.HIDE);

    // アラームを鳴らしているエラーの管理
    const [alarmErrorQueue, setAlarmErrorQueue] = useState([]);

    // アラームを鳴らしているエラーを登録・削除する関数
    const updateAlarmErrorQueue = (logData) => {
        console.log(logData);
        // ミュートの場合
        if (alarmState === ALARM.MUTE) {
            clearAlarmErrorQueue();
            return;
        }

        // エラーログの場合
        if (logData.recover_state === false && logData.recover_flg === 0 && logData.state_type !== "Info") {
            setAlarmErrorQueue(value => [...value, logData]);
        }
    }

    // アラームを鳴らしているエラーを全削除する関数
    const clearAlarmErrorQueue = () => {
        setAlarmErrorQueue([]);
    }

    // ログデータを制御するカスタムフック
    const { subscribeMoniteringLogData, unsubscribeMoniteringLogData } = useMoniteringLogData();

    // アラーム状態の変更を監視
    useEffect(() => {
        if (!isAuth) {
            return;
        }

        console.log(alarmState);
        // ミュートの時
        if (alarmState === ALARM.MUTE) {
            // ログデータをアンサブスクライブする
            unsubscribeMoniteringLogData();
        }
        // ミュート以外の時
        else {
            // ログデータをサブスクライブする
            subscribeMoniteringLogData(updateAlarmErrorQueue);
        }

    }, [alarmState]);

    // アラームを鳴らしているエラーの変更を監視
    useEffect(() => {
        if (!isAuth) {
            return;
        }

        console.log(alarmErrorQueue);
        // ミュートの以外の時
        if (alarmState !== ALARM.MUTE) {
            // アラームを鳴らしているエラーがある時
            if (alarmErrorQueue.length > 0) {
                // アラームを鳴らす
                playAlarm();

                // プルダウンを非表示にする
                setAlarmPullDownState(PULLDOWN.HIDE);
            }
            // アラームを鳴らしているエラーがない時
            else {
                // アラームを止める
                stopAlarm();
            }
        }
    }, [alarmErrorQueue]);

    // アラームを鳴らす処理
    const playAlarm = () => {
        // ミュートの時
        if (alarmState === ALARM.MUTE) {
            // アラームを鳴らさない
            return;
        }

        // state_typeがErrorのログが存在するかを確認するフラグ
        const isError = alarmErrorQueue.some((log) => {
            return log.state_type === 'Error';
        });
        console.log('isError:', isError);

        // state_typeがWarningのログが存在するかを確認するフラグ
        const isWarning = alarmErrorQueue.some((log) => {
            return log.state_type === 'Warning';
        });
        console.log('isWarning:', isWarning);

        // アラームを止める
        audioErrorRef.current.pause();
        audioWarningRef.current.pause();
        
        if (isError) {
            // アラーム音を鳴らす
            audioErrorRef.current.play();
        }
        else if (isWarning) {
            // アラーム音を鳴らす
            audioWarningRef.current.play();
        }

        // アラーム状態をオンにする
        setAlarmState(ALARM.ON);
    }

    // アラームを止める処理
    const stopAlarm = () => {
        // アラームを止める
        audioErrorRef.current.pause();
        audioWarningRef.current.pause();

        // アラーム状態をオフにする
        setAlarmState(ALARM.OFF);
    }

    // アラームアイコンボタンを押した時の処理
    const onClickAlarm = (e) => {
        if (!isAuth) {
            return;
        }

        console.log("アラームクリック");

        // アラームが鳴っている時
        if (alarmState === ALARM.ON) {
            // アラームを鳴らしているエラーを全削除する
            clearAlarmErrorQueue();
        }
        else {
            // プルダウンを表示を切り替える
            setAlarmPullDownState(alarmPullDownState === PULLDOWN.SHOW ? PULLDOWN.HIDE : PULLDOWN.SHOW);
        }
    }

    // プルダウンをクリックした時の処理
    const onClickPullDown = (e) => {
        if (!isAuth) {
            return;
        }

        console.log("プルダウンクリック");

        // オフの時
        if (alarmState === ALARM.OFF) {
            // ミュートにする
            setAlarmState(ALARM.MUTE);
        }
        // ミュートの時
        else if (alarmState === ALARM.MUTE) {
            // ミュートをオフにする
            setAlarmState(ALARM.OFF);
        }

        // プルダウンの状態を変更
        setAlarmPullDownState('Hide');
    }

    return { alarmState, alarmPullDownState, onClickAlarm, onClickPullDown, setAlarmPullDownState };
};