/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getS3Objects = /* GraphQL */ `
  query GetS3Objects($id: ID!) {
    getS3Objects(id: $id) {
      type
      key
      fileName
      size
      lastModified
      id
      createdAt
      updatedAt
    }
  }
`;
export const listS3Objects = /* GraphQL */ `
  query ListS3Objects(
    $filter: ModelS3ObjectsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listS3Objects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        type
        key
        fileName
        size
        lastModified
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPatliteStatus = /* GraphQL */ `
  query GetPatliteStatus($id: ID!) {
    getPatliteStatus(id: $id) {
      id
      isMute
      alarmLogs
      createdAt
      updatedAt
    }
  }
`;
export const listPatliteStatuses = /* GraphQL */ `
  query ListPatliteStatuses(
    $filter: ModelPatliteStatusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPatliteStatuses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        isMute
        alarmLogs
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const s3ObjectsByTypeAndKey = /* GraphQL */ `
  query S3ObjectsByTypeAndKey(
    $type: String!
    $key: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelS3ObjectsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    s3ObjectsByTypeAndKey(
      type: $type
      key: $key
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        type
        key
        fileName
        size
        lastModified
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMoniteringLog = /* GraphQL */ `
  query GetMoniteringLog($id: ID!) {
    getMoniteringLog(id: $id) {
      id
      type
      location_name
      occurrence_at
      recover_state
      state_type
      alarm_type
      occurrence_source
      log_type
      log_detail
      recover_flg
      recover_at
      confirmed_flg
      confirmation_at
      confirmation_note
      all_status
      sequence_number
      log_id
      createdAt
      updatedAt
    }
  }
`;
export const listMoniteringLogs = /* GraphQL */ `
  query ListMoniteringLogs(
    $filter: ModelMoniteringLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMoniteringLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        location_name
        occurrence_at
        recover_state
        state_type
        alarm_type
        occurrence_source
        log_type
        log_detail
        recover_flg
        recover_at
        confirmed_flg
        confirmation_at
        confirmation_note
        all_status
        sequence_number
        log_id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const moniteringLogsByTypeAndOccurrence_at = /* GraphQL */ `
  query MoniteringLogsByTypeAndOccurrence_at(
    $type: String!
    $occurrence_at: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMoniteringLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    moniteringLogsByTypeAndOccurrence_at(
      type: $type
      occurrence_at: $occurrence_at
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        location_name
        occurrence_at
        recover_state
        state_type
        alarm_type
        occurrence_source
        log_type
        log_detail
        recover_flg
        recover_at
        confirmed_flg
        confirmation_at
        confirmation_note
        all_status
        sequence_number
        log_id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const moniteringLogsByRecover_flgAndOccurrence_at = /* GraphQL */ `
  query MoniteringLogsByRecover_flgAndOccurrence_at(
    $recover_flg: Int!
    $occurrence_at: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMoniteringLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    moniteringLogsByRecover_flgAndOccurrence_at(
      recover_flg: $recover_flg
      occurrence_at: $occurrence_at
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        location_name
        occurrence_at
        recover_state
        state_type
        alarm_type
        occurrence_source
        log_type
        log_detail
        recover_flg
        recover_at
        confirmed_flg
        confirmation_at
        confirmation_note
        all_status
        sequence_number
        log_id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const moniteringLogsByConfirmed_flgAndOccurrence_at = /* GraphQL */ `
  query MoniteringLogsByConfirmed_flgAndOccurrence_at(
    $confirmed_flg: Int!
    $occurrence_at: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMoniteringLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    moniteringLogsByConfirmed_flgAndOccurrence_at(
      confirmed_flg: $confirmed_flg
      occurrence_at: $occurrence_at
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        location_name
        occurrence_at
        recover_state
        state_type
        alarm_type
        occurrence_source
        log_type
        log_detail
        recover_flg
        recover_at
        confirmed_flg
        confirmation_at
        confirmation_note
        all_status
        sequence_number
        log_id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const moniteringLogsByLog_idAndOccurrence_at = /* GraphQL */ `
  query MoniteringLogsByLog_idAndOccurrence_at(
    $log_id: String!
    $occurrence_at: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMoniteringLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    moniteringLogsByLog_idAndOccurrence_at(
      log_id: $log_id
      occurrence_at: $occurrence_at
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        location_name
        occurrence_at
        recover_state
        state_type
        alarm_type
        occurrence_source
        log_type
        log_detail
        recover_flg
        recover_at
        confirmed_flg
        confirmation_at
        confirmation_note
        all_status
        sequence_number
        log_id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getWaveMoniteringStatus = /* GraphQL */ `
  query GetWaveMoniteringStatus($id: ID!) {
    getWaveMoniteringStatus(id: $id) {
      id
      location_name
      band
      threshold_type
      state
      occurrence_detail
      sequence_number
      createdAt
      updatedAt
    }
  }
`;
export const listWaveMoniteringStatuses = /* GraphQL */ `
  query ListWaveMoniteringStatuses(
    $filter: ModelWaveMoniteringStatusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWaveMoniteringStatuses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        location_name
        band
        threshold_type
        state
        occurrence_detail
        sequence_number
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDeviceMoniteringStatus = /* GraphQL */ `
  query GetDeviceMoniteringStatus($id: ID!) {
    getDeviceMoniteringStatus(id: $id) {
      id
      location_name
      device
      state
      occurrence_detail
      sequence_number
      createdAt
      updatedAt
    }
  }
`;
export const listDeviceMoniteringStatuses = /* GraphQL */ `
  query ListDeviceMoniteringStatuses(
    $filter: ModelDeviceMoniteringStatusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDeviceMoniteringStatuses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        location_name
        device
        state
        occurrence_detail
        sequence_number
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getWaveMoniteringSetting = /* GraphQL */ `
  query GetWaveMoniteringSetting($id: ID!) {
    getWaveMoniteringSetting(id: $id) {
      id
      location_name
      setting_json
      createdAt
      updatedAt
    }
  }
`;
export const listWaveMoniteringSettings = /* GraphQL */ `
  query ListWaveMoniteringSettings(
    $filter: ModelWaveMoniteringSettingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWaveMoniteringSettings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        location_name
        setting_json
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDeviceMoniteringSetting = /* GraphQL */ `
  query GetDeviceMoniteringSetting($id: ID!) {
    getDeviceMoniteringSetting(id: $id) {
      id
      location_name
      setting_json
      createdAt
      updatedAt
    }
  }
`;
export const listDeviceMoniteringSettings = /* GraphQL */ `
  query ListDeviceMoniteringSettings(
    $filter: ModelDeviceMoniteringSettingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDeviceMoniteringSettings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        location_name
        setting_json
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDisplaySetting = /* GraphQL */ `
  query GetDisplaySetting($id: ID!) {
    getDisplaySetting(id: $id) {
      id
      setting_json
      createdAt
      updatedAt
    }
  }
`;
export const listDisplaySettings = /* GraphQL */ `
  query ListDisplaySettings(
    $filter: ModelDisplaySettingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDisplaySettings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        setting_json
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMeasurementPcStatus = /* GraphQL */ `
  query GetMeasurementPcStatus($id: ID!) {
    getMeasurementPcStatus(id: $id) {
      id
      pc_id
      location_name
      isMain
      status
      createdAt
      updatedAt
    }
  }
`;
export const listMeasurementPcStatuses = /* GraphQL */ `
  query ListMeasurementPcStatuses(
    $filter: ModelMeasurementPcStatusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMeasurementPcStatuses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        pc_id
        location_name
        isMain
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const measurementPcStatusesByPc_idAndLocation_name = /* GraphQL */ `
  query MeasurementPcStatusesByPc_idAndLocation_name(
    $pc_id: String!
    $location_name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMeasurementPcStatusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    measurementPcStatusesByPc_idAndLocation_name(
      pc_id: $pc_id
      location_name: $location_name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        pc_id
        location_name
        isMain
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUsers = /* GraphQL */ `
  query GetUsers($id: ID!) {
    getUsers(id: $id) {
      casthive_id
      last_login_at
      id
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        casthive_id
        last_login_at
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const usersByCasthive_idAndLast_login_at = /* GraphQL */ `
  query UsersByCasthive_idAndLast_login_at(
    $casthive_id: String!
    $last_login_at: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByCasthive_idAndLast_login_at(
      casthive_id: $casthive_id
      last_login_at: $last_login_at
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        casthive_id
        last_login_at
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
