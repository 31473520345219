// auth.js
import { UserManager, WebStorageStateStore } from 'oidc-client';
import { Auth, API, graphqlOperation } from 'aws-amplify';
import { usersByCasthive_idAndLast_login_at } from './graphql/queries';
import { createUsers, deleteUsers } from './graphql/mutations';

const oidcConfig = {
    authority: 'HTTPS://' + process.env.REACT_APP_OIDC_AUTHORITY_DOMAIN,
    client_id: process.env.REACT_APP_OIDC_CLIENT_ID,
    client_secret: process.env.REACT_APP_OIDC_CLIENT_SECRET,
    redirect_uri: process.env.REACT_APP_OIDC_REDIRECT_URL,
    response_type: 'code',
    scope: "openid profile email",
    post_logout_redirect_uri: process.env.REACT_APP_OIDC_POST_REDIRECT_URL,
    userStore: new WebStorageStateStore({ store: window.localStorage }),
    stateStore: new WebStorageStateStore({ store: window.localStorage }),
    // userStore: new CookieStateStore({ expirationInMinutes: 60 * 24 * 7 }),
    // stateStore: new CookieStateStore({ expirationInMinutes: 60 * 24 * 7 }),
  };

const userManager = new UserManager(oidcConfig);

// ログインチェック
async function isLoggedIn() {
  try {
    const user = await userManager.getUser();
    return user != null && !user.expired;
  } catch (error) {
    console.error('Error checking logged in status:', error);
    return false;
  }
}

// OIDCログインページへリダイレクト
function redirectToLogin() {
  // console.log('redirectToLogin',process.env);
  userManager.signinRedirect();
}

// ログアウト
async function logout() {
  //   await userManager.signoutRedirect();
  const user = await userManager.getUser();
  if (user) {
    await userManager.removeUser();
  }
  await Auth.signOut();
  // Cognitoのログアウトエンドポイントにリダイレクト
  const cognitoLogoutUrl = `https://${process.env.REACT_APP_OIDC_LOGOUT_DOMAIN}/logout?client_id=${oidcConfig.client_id}&logout_uri=${encodeURIComponent(oidcConfig.post_logout_redirect_uri)}`;
  window.location.href = cognitoLogoutUrl;
}

// トークンの期限切れチェック
async function checkTokenExpiration() {
  try {
    const user = await userManager.getUser();

    if (user) {
      // console.log('checkTokenExpiration user.expires_at', user.expires_at, new Date(user.expires_at * 1000).toLocaleString("ja-JP"));
      // expires_atが5分後以内の場合は更新
      const now = new Date();
      const fiveMinutesLater = new Date(now.getTime() + 5 * 60 * 1000);
      const isExpired = fiveMinutesLater >= new Date(user.expires_at * 1000);
      if (isExpired) {
        await refreshToken();
      }
    }
  } catch (error) {
    console.error('Error checking token expiration:', error);
  }
}

// リフレッシュトークン
async function refreshToken() {
  console.log('refreshToken');
  try {
    const user = await userManager.signinSilent();
    if (user) {
      await updateUserAwsCredentials(user);
    }
  } catch (error) {
    console.error('Error refreshing token:', error);
    redirectToLogin();
  }
}

async function checkAndUpdateCredentials(user) {
  try {
    // 現在認証されているユーザーを取得
    const currentUser = await Auth.currentAuthenticatedUser();
    // console.log("User is already authenticated", currentUser);

    // 認証情報の有効性を確認
    const currentCreds = await Auth.currentCredentials();

    // 期限チェック 期限まで5分以内の場合は更新
    const now = new Date();
    const fiveMinutesLater = new Date(now.getTime() + 5 * 60 * 1000);
    const isExpired = fiveMinutesLater >= new Date(currentCreds.expiration);
    // console.log("Current credentials", currentCreds.expiration, new Date(currentCreds.expiration).toLocaleString("ja-JP"));

    // 期限まで5分以内の場合は更新
    if (isExpired) {
      await updateUserAwsCredentials(user);
    }
  } catch (err) {
    // console.error("User is not authenticated or there was an error fetching credentials:", err);
    await updateUserAwsCredentials(user);
  }
}

// AWSのCredentialsを更新
async function updateUserAwsCredentials(user) {
  console.log('updateUserAwsCredentials');
  const { id_token } = user;
  // const base64Url = id_token.split('.')[1];
  // const base64 = base64Url.replace('-', '+').replace('_', '/');
  // const jsonParse = JSON.parse(window.atob(base64));
  // console.log("ID Token:", jsonParse);
  const credentials = {
    IdentityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
    Logins: {
      ['https://'+ process.env.REACT_APP_OIDC_AUTHORITY_DOMAIN ]: id_token,
    },
  };

  // Amplify.configure({
  //   Auth: {
  //     identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
  //     region: process.env.REACT_APP_COGNITO_REGION,
  //   },
  // });

  await Auth.federatedSignIn(process.env.REACT_APP_COGNITO_IDENTITY_POOL_AUTH_DOMAIN, { token: id_token, expires_at: user.expires_at }, credentials);
  // await Auth.currentCredentials().then((credentials) => {
  //       // console.log("currentCredentials", credentials);
  //   });
//   console.log('updateUserAwsCredentials Credentials', Auth.currentCredentials);
}

// ログイン時間をチェックする関数
const checkLoginTime = async (user) => {
  // ログイン時間を取得
  const variables = {
    casthive_id: user.profile.sub,
  };
  const result = await API.graphql(graphqlOperation(usersByCasthive_idAndLast_login_at, variables));

  // ログイン時間が存在しない場合
  if (result.data.usersByCasthive_idAndLast_login_at.items.length === 0) {
    // ログイン時間を登録
    const createVariables = {
      input: {
        casthive_id: user.profile.sub,
        last_login_at: new Date().toISOString()
      }
    };
    await API.graphql(graphqlOperation(createUsers, createVariables));

    return;
  } 

  // ログイン時間を取得
  const lastLoginAt = new Date(result.data.usersByCasthive_idAndLast_login_at.items[0].last_login_at);

  // 現在時間を取得
  const now = new Date();

  // ログイン時間から25時間以上経過していたらログアウト
  if (now.getTime() - lastLoginAt.getTime() > 25 * 60 * 60 * 1000) {
    // レコードを削除
    const deleteVariables = {
      input: {
        id: result.data.usersByCasthive_idAndLast_login_at.items[0].id,
      }
    };
    await API.graphql(graphqlOperation(deleteUsers, deleteVariables));

    // ログアウト
    logout();
    return;
  }
}

export {
  userManager,
  isLoggedIn,
  redirectToLogin,
  logout,
  checkTokenExpiration,
  refreshToken,
  updateUserAwsCredentials,
  checkAndUpdateCredentials,
  checkLoginTime,
};
