import React, { useEffect, useState } from 'react';
import {
    DatakanriBreadcrumbs
} from '../ui-components'

export default function DatakanriBreadcrumbsWrapper(props) {
    const [situation, setSituation] = useState(props.situation);

    useEffect(() => {
        // console.log("useEffect: " + situation + props.folderName);
        setSituation(props.situation);
    },[props.situation]);

    function onMouseEventEnter(){
        // console.log("onMouseEventEnter prestate: " + situation + props.folderName );
        if (situation !== "select") {
            // console.log("onMouseEventEnter poststate: Hover " + props.folderName);
            setSituation("hover");
        }
    }

    function onMouseEventLeave(){
        // console.log("onMouseEventLeave prestate: " + situation + props.folderName);
        if (situation !== "select") {
            // console.log("onMouseEventLeave poststate: Default " + props.folderName );
            setSituation("default");
        }
    }

    return (
        <DatakanriBreadcrumbs 
            style={props.situation === 'select' ? {} : {cursor:'pointer'}}
            // marginRight={"24px"}
            onClick={props.situation === 'select' ? null : props.onClick}
            onMouseEnter={onMouseEventEnter}
            onMouseLeave={onMouseEventLeave}
            
            overrides={{
                DatakanriBreadcrumbs:{
                    width:"fit-content",
                },
                text:{children:props.folderName}
            }}
            situation={situation}
        ></DatakanriBreadcrumbs>
    );
}