export const locationKeyList = ["YSCC", "SPE", "SPW", "SAYADO"];

export const AlarmType = {
    health: "health",
    radio: "radio",
};

export const deviceMoniteringDeviceList = [
    "Spectrum_Analyzer_1",
    "Spectrum_Analyzer_2",
    "Gps_Sensor_1",
    "Gps_Sensor_2",
    "Temperature_Sensor",
    "Direct_Current_Power_1",
    "Direct_Current_Power_2",
    "Direct_Current_Power_3",
    "Direct_Current_Power_4",
    "Direct_Current_Power_5",
    "Noise_Source",
    "Outdoor_Cabinet",
    "Energy_Gazer",
    "Lan_Switch_1",
    "Lan_Switch_2",
    "Lan_Switch_3",
    "Lan_Switch_4",
    "Measurement_Pc_Main",
    "Measurement_Pc_Sub",
    "health_setting",
];

export const DeviceKeys = {
    SPEANA_1: "Spectrum_Analyzer_1",
    SPEANA_2: "Spectrum_Analyzer_2",
    GPS_1: "Gps_Sensor_1",
    GPS_2: "Gps_Sensor_2",
    TEMP_SENSOR: "Temperature_Sensor",
    DC_POWER_1: "Direct_Current_Power_1",
    DC_POWER_2: "Direct_Current_Power_2",
    DC_POWER_3: "Direct_Current_Power_3",
    DC_POWER_4: "Direct_Current_Power_4",
    DC_POWER_5: "Direct_Current_Power_5",
    NOISE_SOURCE: "Noise_Source",
    OUTDOOR_CABINET: "Outdoor_Cabinet",
    ENERGY_GAZER: "Energy_Gazer",
    LAN_SWITCH_1: "Lan_Switch_1",
    LAN_SWITCH_2: "Lan_Switch_2",
    LAN_SWITCH_3: "Lan_Switch_3",
    LAN_SWITCH_4: "Lan_Switch_4",
    MEASUREMENT_PC_MAIN: "Measurement_Pc_Main",
    MEASUREMENT_PC_SUB: "Measurement_Pc_Sub",
    HEALTH_SETTING: "health_setting",
    ALL: "All",
};

export const DeviceKeitouzuNames = {
    [DeviceKeys.SPEANA_1]: "スペアナ #1",
    [DeviceKeys.SPEANA_2]: "スペアナ #2",
    [DeviceKeys.GPS_1]: "GPS #1",
    [DeviceKeys.GPS_2]: "GPS #2",
    [DeviceKeys.TEMP_SENSOR]: "温度センサー",
    [DeviceKeys.DC_POWER_1]: "DC電源 #1",
    [DeviceKeys.DC_POWER_2]: "DC電源 #2",
    [DeviceKeys.DC_POWER_3]: "DC電源 #3",
    [DeviceKeys.DC_POWER_4]: "DC電源 #4",
    [DeviceKeys.DC_POWER_5]: "DC電源 #5",
    [DeviceKeys.NOISE_SOURCE]: "ノイズソース温度",
    [DeviceKeys.OUTDOOR_CABINET]: "ラック内温度",
    [DeviceKeys.ENERGY_GAZER]: "電流モニター",
    [DeviceKeys.LAN_SWITCH_1]: "ネットワーク\nSW #1",
    [DeviceKeys.LAN_SWITCH_2]: "ネットワーク\nSW #2",
    [DeviceKeys.LAN_SWITCH_3]: "ネットワーク\nSW #3",
    [DeviceKeys.LAN_SWITCH_4]: "ネットワーク\nSW #4",
    [DeviceKeys.MEASUREMENT_PC_MAIN]: "計測PC\n本番機",
    [DeviceKeys.MEASUREMENT_PC_SUB]: "計測PC\n予備機",
};

export const DeviceLogNames = {
    [DeviceKeys.SPEANA_1]: "スペアナ #1",
    [DeviceKeys.SPEANA_2]: "スペアナ #2",
    [DeviceKeys.GPS_1]: "GPS #1",
    [DeviceKeys.GPS_2]: "GPS #2",
    [DeviceKeys.TEMP_SENSOR]: "温度センサー",
    [DeviceKeys.DC_POWER_1]: "DC電源 #1",
    [DeviceKeys.DC_POWER_2]: "DC電源 #2",
    [DeviceKeys.DC_POWER_3]: "DC電源 #3",
    [DeviceKeys.DC_POWER_4]: "DC電源 #4",
    [DeviceKeys.DC_POWER_5]: "DC電源 #5",
    [DeviceKeys.NOISE_SOURCE]: "ノイズソース温度",
    [DeviceKeys.OUTDOOR_CABINET]: "ラック内温度",
    [DeviceKeys.ENERGY_GAZER]: "電流モニター",
    [DeviceKeys.LAN_SWITCH_1]: "NWSW #1",
    [DeviceKeys.LAN_SWITCH_2]: "NWSW #2",
    [DeviceKeys.LAN_SWITCH_3]: "NWSW #3",
    [DeviceKeys.LAN_SWITCH_4]: "NWSW #4",
    [DeviceKeys.MEASUREMENT_PC_MAIN]: "計測PC 本番",
    [DeviceKeys.MEASUREMENT_PC_SUB]: "計測PC 予備",
    [DeviceKeys.HEALTH_SETTING]: "機器監視設定",
};

export const logTypeNames = {
    "Threshold A": "A:時間率判定",
    "Threshold B": "B:最大値判定",
    "Threshold C": "C:合計値判定",
    "Threshold D": "D:合計値判定",
    "signal": "校正経路監視",
    "Alive": "死活監視",
    "Reboot": "プロセス再起動",
    "Ping": "PING",
    "SCPI": "内部校正",
    "Max_Electricity": "電流上限",
    "Min_Electricity": "電流下限",
    "Max_Temperature": "温度上限",
    "Min_Temperature": "温度下限",
    "Disconnect_Sensor": "センサー異常",
    "LinkDown": "LinkDown",
    "LinkUp": "LinkUp",
    "ColdStart": "ColdStart",
    "WarmStart": "WarmStart",
    "Unable_Monitoring": "監視不能",
    "Change": "設定変更",
    "snmp": "SNMPTrap",
    "Consumption_Current": "消費電力監視",
    "Temperature": "温度監視",
    "Process": "プロセス監視",
    "ApiProcess": "APIプロセス",
    "WebProcess": "WEBサーバプロセス",
    "MainProcess": "メインプロセス",
    "MeasurementProcess": "計測プロセス",
    "HealthProcess": "機器監視プロセス",
    "UploadProcess": "アップロードプロセス",
}
    
// ログ種別の名前を取得
export const getLogTypeName = (log) => {
    // Valueの場合
    return logTypeNames[log.log_type] || "";
}

export const waveMoniteringBandList = [
    "3400-3440",
    "3440-3480",
    "3480-3520",
    "3520-3560",
    "3560-3600",
    "3600-3700",
    "3700-3800",
    "3800-3900",
    "3900-4000",
    "4000-4100",
    "total",
    "S1-A1",
    "S1-A2",
    "S1-A3",
    "S2-A1",
    "S2-A2",
    "S2-A3"
];

export const waveMoniteringBandNameList = {
    "3400-3440": "S-1",
    "3440-3480": "D-1",
    "3480-3520": "D-2",
    "3520-3560": "K-1",
    "3560-3600": "S-2",
    "3600-3700": "D-3",
    "3700-3800": "K-2",
    "3800-3900": "R-1",
    "3900-4000": "S-3",
    "4000-4100": "K-3",
    "total": "合計",
    "S1-A1": "スペアナ1-1",
    "S1-A2": "スペアナ1-2",
    "S1-A3": "スペアナ1-3",
    "S2-A1": "スペアナ2-1",
    "S2-A2": "スペアナ2-2",
    "S2-A3": "スペアナ2-3",
    "radio_setting": "電波干渉監視設定",
    "display_setting": "表示ラベル設定",
}

export const waveMoniteringBandThresholdList = {
    "3400-3440": ["Threshold A", "Threshold B", "Threshold C"],
    "3440-3480": ["Threshold A", "Threshold B", "Threshold C"],
    "3480-3520": ["Threshold A", "Threshold B", "Threshold C"],
    "3520-3560": ["Threshold A", "Threshold B", "Threshold C"],
    "3560-3600": ["Threshold A", "Threshold B", "Threshold C"],
    "3600-3700": ["Threshold A", "Threshold B", "Threshold C"],
    "3700-3800": ["Threshold A", "Threshold B", "Threshold C"],
    "3800-3900": ["Threshold A", "Threshold B", "Threshold C"],
    "3900-4000": ["Threshold A", "Threshold B", "Threshold C"],
    "4000-4100": ["Threshold A", "Threshold B", "Threshold C"],
    "total": ["Threshold D"],
    "S1-A1": ["signal"],
    "S1-A2": ["signal"],
    "S1-A3": ["signal"],
    "S2-A1": ["signal"],
    "S2-A2": ["signal"],
    "S2-A3": ["signal"],
}

// タブ名
export const TAB_ALL = "全拠点";
export const TAB_YSCC = "YSCC";
export const TAB_SPE = "SPE";
export const TAB_SPW = "SPW";
export const TAB_SAYADO = "SAYADO";


export const LogRelation = {
    [AlarmType.health]: {
        [DeviceKeys.SPEANA_1]: ["Ping", "SCPI", "Unable_Monitoring"],
        [DeviceKeys.SPEANA_2]: ["Ping", "SCPI", "Unable_Monitoring"],
        [DeviceKeys.GPS_1]: ["Alive", "Unable_Monitoring"],
        [DeviceKeys.GPS_2]: ["Alive", "Unable_Monitoring"],
        [DeviceKeys.TEMP_SENSOR]: ["Ping"],
        [DeviceKeys.DC_POWER_1]: ["Ping", "Max_Electricity", "Min_Electricity", "Unable_Monitoring", "Consumption_Current"],
        [DeviceKeys.DC_POWER_2]: ["Ping", "Max_Electricity", "Min_Electricity", "Unable_Monitoring", "Consumption_Current"],
        [DeviceKeys.DC_POWER_3]: ["Ping", "Max_Electricity", "Min_Electricity", "Unable_Monitoring", "Consumption_Current"],
        [DeviceKeys.DC_POWER_4]: ["Ping", "Max_Electricity", "Min_Electricity", "Unable_Monitoring", "Consumption_Current"],
        [DeviceKeys.DC_POWER_5]: ["Ping", "Max_Electricity", "Min_Electricity", "Unable_Monitoring", "Consumption_Current"],
        [DeviceKeys.NOISE_SOURCE]: ["Max_Temperature", "Min_Temperature", "Disconnect_Sensor", "Unable_Monitoring", "Temperature"],
        [DeviceKeys.OUTDOOR_CABINET]: ["Max_Temperature", "Min_Temperature", "Disconnect_Sensor", "Unable_Monitoring", "Temperature"],
        [DeviceKeys.ENERGY_GAZER]: ["Ping", "Max_Electricity", "Min_Electricity", "Unable_Monitoring", "Consumption_Current"],
        [DeviceKeys.LAN_SWITCH_1]: ["Ping", "snmp", "LinkDown", "LinkUp", "ColdStart", "WarmStart"],
        [DeviceKeys.LAN_SWITCH_2]: ["Ping", "snmp", "LinkDown", "LinkUp", "ColdStart", "WarmStart"],
        [DeviceKeys.LAN_SWITCH_3]: ["Ping", "snmp", "LinkDown", "LinkUp", "ColdStart", "WarmStart"],
        [DeviceKeys.LAN_SWITCH_4]: ["Ping", "snmp", "LinkDown", "LinkUp", "ColdStart", "WarmStart"],
        [DeviceKeys.MEASUREMENT_PC_MAIN]: ["Alive", "Reboot", "ApiProcess", "WebProcess", "MainProcess", "MeasurementProcess", "HealthProcess", "UploadProcess"],
        [DeviceKeys.MEASUREMENT_PC_SUB]: ["Alive", "Reboot"],
        [DeviceKeys.HEALTH_SETTING]: ["Change"],
    },
    [AlarmType.radio]: {
        "3400-3440": ["Threshold A", "Threshold B", "Threshold C"],
        "3440-3480": ["Threshold A", "Threshold B", "Threshold C"],
        "3480-3520": ["Threshold A", "Threshold B", "Threshold C"],
        "3520-3560": ["Threshold A", "Threshold B", "Threshold C"],
        "3560-3600": ["Threshold A", "Threshold B", "Threshold C"],
        "3600-3700": ["Threshold A", "Threshold B", "Threshold C"],
        "3700-3800": ["Threshold A", "Threshold B", "Threshold C"],
        "3800-3900": ["Threshold A", "Threshold B", "Threshold C"],
        "3900-4000": ["Threshold A", "Threshold B", "Threshold C"],
        "4000-4100": ["Threshold A", "Threshold B", "Threshold C"],
        "total": ["Threshold D"],
        "radio_setting": ["Change"],
        "display_setting": ["Change"],
        "S1-A1": ["signal"],
        "S1-A2": ["signal"],
        "S1-A3": ["signal"],
        "S2-A1": ["signal"],
        "S2-A2": ["signal"],
        "S2-A3": ["signal"],
    },
}