
import { Flex } from "@aws-amplify/ui-react";

import {
    KanshiLogWrapper
} from "../componentsWrapper";

import { CustomLoading } from "../customComponents/CustomLoading";

import { DeviceLogNames, waveMoniteringBandNameList, getLogTypeName } from "../lib/ConstList";
import { isLocalMode } from "../lib/envUtil";

import { InfiniteLoader, AutoSizer, List } from 'react-virtualized';

// 無限スクロール用の行コンポーネント
const KanshiLogRow = (props) => {
    return ((props.item.alarm_type === "radio" ?
        <KanshiLogWrapper
            log={props.item}
            key={!isLocalMode ? props.item.id : props.item.local_id}
            itemId={!isLocalMode ? props.item.id : props.item.remote_id}
            date={new Date(props.item.occurrence_at).toLocaleString("ja-JP")}　// 発生日時を表示形式に変更
            kyotenName={props.displaySettingJson.location_name[props.item.location_name]} // 拠点名を表示ラベル設定の拠点名に変更
            hasseiMoto={
                props.item.occurrence_source in props.displaySettingJson.band_label ? // 発生元を表示ラベル設定の表示に変更、totalは設定ないので合計
                    props.displaySettingJson.band_label[props.item.occurrence_source].name :
                    waveMoniteringBandNameList[props.item.occurrence_source]}
            logTypeText={getLogTypeName(props.item)} // ログ種別を表示用の名称に変更
            type={props.item.recover_state ? "Default" : // 復帰時はデフォルト、それ以外はログの状態によって変更
                props.item.state_type === "Info" ? (props.bulkLogConfirm || props.bulkCsvDownload ? "Default" : "Gray") :
                    props.item.state_type === "error" ? "Error" : props.item.state_type}
            logtype="Contetnts"
            icontype={props.bulkLogConfirm || props.bulkCsvDownload ? "CheckOn" : "Circle"}
            kakuninStatus={props.item.confirmed_flg ? "true" : "false"}
            check={props.item.check}
            checkDisabled={props.bulkLogConfirm && (props.item.confirmed_flg === 1) ? true : false}
            onClickCheck={(isCheck) => { props.onClickCheck(props.index, isCheck) }}
        // {...getOverrideProps(overrides, "Kanshi/Log500220751")}
        ></KanshiLogWrapper>
        :
        <KanshiLogWrapper
            log={props.item}
            key={!isLocalMode ? props.item.id : props.item.local_id}
            itemId={!isLocalMode ? props.item.id : props.item.remote_id}
            date={new Date(props.item.occurrence_at).toLocaleString("ja-JP")} // 発生日時を表示形式に変更
            kyotenName={props.displaySettingJson.location_name[props.item.location_name]} // 拠点名を表示ラベル設定の拠点名に変更
            hasseiMoto={DeviceLogNames[props.item.occurrence_source]} // 発生元をログ表示用の名称に変更
            logTypeText={getLogTypeName(props.item)} // ログ種別を表示用の名称に変更
            type={props.item.recover_state ? "Default" : // 復帰時はデフォルト、それ以外はログの状態によって変更
                props.item.state_type === "Info" ? (props.bulkLogConfirm || props.bulkCsvDownload ? "Default" : "Gray") :
                    props.item.state_type === "error" ? "Error" : props.item.state_type}
            logtype="Contetnts"
            icontype={props.bulkLogConfirm || props.bulkCsvDownload ? "CheckOn" : "Circle"}
            kakuninStatus={props.item.confirmed_flg ? "true" : "false"}
            check={props.item.check}
            checkDisabled={props.bulkLogConfirm && (props.item.confirmed_flg === 1) ? true : false}
            onClickCheck={(isCheck = null) => props.onClickCheck(props.index, isCheck = null)}
        >
        </KanshiLogWrapper>
    ))
};

// 無限スクロール用のコンポーネント
const KanshiLogInfiniteLoader = (props) => {
    // 行の数を取得
    const rowCount = props.hasNextPage ? props.logData.length + 1 : props.logData.length;

    // リストの更新関数
    const loadMoreRows = props.isLoading ? () => { } : props.loadMoreRows;

    // リストの更新状態
    const isRowLoaded = ({ index }) => !(props.logData.length === index && props.hasNextPage);

    // リストの描画関数
    const rowRenderer = ({ index, key, style }) => {
        // 
        let content;

        // リストの最後の行の場合は、ローディングを表示
        if (!isRowLoaded({ index })) {
            content = (
                <Flex
                    width="100%"
                    justifyContent="center"
                    alignItems="center"
                    padding="1%"
                >
                    <CustomLoading />
                </Flex>
            );
        }
        else {
            content = (
                <KanshiLogRow
                    item={props.logData[index]}
                    index={index}
                    {...props}
                />
            );
        }

        return (
            <div key={key} style={style}>
                {content}
            </div>
        );
    };

    return (
        <>
            <AutoSizer>
                {({ height, width }) => (
                    <InfiniteLoader
                        isRowLoaded={isRowLoaded}
                        loadMoreRows={loadMoreRows}
                        rowCount={rowCount}
                        threshold={0}
                    >
                        {({ onRowsRendered, registerChild }) => (
                            <List
                                ref={registerChild}
                                onRowsRendered={onRowsRendered}
                                rowRenderer={rowRenderer}
                                className="list"
                                height={height}
                                width={width}
                                rowCount={rowCount}
                                rowHeight={50}
                            />
                        )}
                    </InfiniteLoader>
                )}
            </AutoSizer>
        </>
    );
}

export default function KanshiLogListWrapper(props) {
    // useEffect(() => {
    //     // console.log("KanshiLogListWrapper useEffect", props);
    // }, []);

    return (
        <>
            <KanshiLogWrapper
                display="flex"
                gap="0"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                type="Title"
                logtype="Title"
                icontype="CheckOff"
                allCheckDisabled={!(props.bulkLogConfirm || props.bulkCsvDownload)}
                allCheck={props.allCheck}
                onClickAllCheck={props.onClickAllCheck}
            // {...getOverrideProps(overrides, "Kanshi/Log500220749")}
            ></KanshiLogWrapper>
            <Flex
                gap="0"
                grow={1}
                direction="column"
                width="100%"
                height={props.logListHeight}
                justifyContent="flex-start"
                alignItems="flex-start"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
            // onScroll={props.onHandleScroll}
            // {...getOverrideProps(overrides, "Frame 862")}
            >
                {
                    props.error != null ? (
                        <>エラー</>
                    ) : (
                        <>
                            {
                                !(props.logData.length == 0 && !props.hasNextPage && !props.isLoading) ? (
                                    <KanshiLogInfiniteLoader
                                        {...props}
                                    />
                                ) : (
                                    <Flex
                                        width="100%"
                                        justifyContent="center"
                                        alignItems="center"
                                        padding="2%"
                                    >
                                        監視ログはありません。
                                    </Flex>
                                )
                            }
                        </>
                    )
                }

            </Flex>
        </>
    );
}