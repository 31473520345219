import { useState } from 'react';

import {
    getOverrideProps,
    getOverridesFromVariants,
    mergeVariantsAndOverrides,
} from "@aws-amplify/ui-react/internal";

import { CommonBtnText } from '../ui-components';

export default function CommonBtnTextWrapper(props) {
    const { overrides: overridesProp, ...rest } = props;
    const variants = [];
    const overrides = mergeVariantsAndOverrides(
        getOverridesFromVariants(variants, props),
        overridesProp || {}
    );
    
    return (
        <CommonBtnText
            onClick={props.onClick}
            onMouseEnter={props.onMouseEnter}
            onMouseLeave={props.onMouseLeave}
            type={props.type}
            size={props.size}
            overrides={{
                text: {
                    children: props.children,
                }
            }}
            style={{
                cursor: "pointer",
            }}
            {...getOverrideProps(overrides, "CommonBtnText")}
        >
        </CommonBtnText>
    );
}