import { useState, useEffect } from 'react'
import { Flex, Text } from "@aws-amplify/ui-react";
import Spacer from "./../ui-components/Spacer";
import CommonDivider from "./../ui-components/CommonDivider";
import { DeviceLogNames, waveMoniteringBandNameList } from "../lib/ConstList";
import {
    KanshiFrameSummaryResult,
} from "../ui-components";

export const formatOccurrenceSource = (alarm_type, occurrence_source, displaySettingJson) => {
    if (alarm_type === "radio") {
        if (occurrence_source === "total") {
            return "合計値判定"
        }

        if (displaySettingJson.band_label[occurrence_source] === undefined) {
            return waveMoniteringBandNameList[occurrence_source]
        }

        return displaySettingJson.band_label[occurrence_source].name
    } else if (alarm_type === "health") {
        return DeviceLogNames[occurrence_source]
    } else {
        return ""
    }
}

export const formatStateType = (state_type) => {
    if (state_type === "Info") {
        return "情報"
    } else if (state_type === "Warning") {
        return "警告"
    } else if (state_type === "Error") {
        return "異常"
    } else if (state_type === "Recover") {
        return "復帰"
    } else {
        return ""
    }
}

export const formatAlarmType = (alarm_type) => {
    if (alarm_type === "radio") {
        return "干渉波監視"
    } else if (alarm_type === "health") {
        return "機器監視"
    } else {
        return ""
    }
}


const LogSummaryOccurrenceSourceView = ({ alarm_type, occurrence_source, count, displaySettingJson }) => {

    return (
        <>
            <Flex
                gap={0}
                direction="column"
                width={"100%"}
            >
                <Flex
                    gap="0"
                    grow={1}
                    direction="row"
                    alignItems="flex-start"
                    padding="16px"
                    backgroundColor="rgba(255,255,255,1)"
                >
                    {/* 監視項目名 */}
                    <Text
                        fontFamily="Noto Sans"
                        fontSize="14px"
                        fontWeight="500"
                        color="rgba(53,56,59,1)"
                        lineHeight="14px"
                        textAlign="left"
                        display="block"
                        direction="column"
                        justifyContent="unset"
                        letterSpacing="0.55px"
                        width="150px"
                        height="unset"
                        gap="unset"
                        alignItems="unset"
                        shrink="0"
                        position="relative"
                        padding="0px 0px 0px 0px"
                        whiteSpace="pre-wrap"
                        children={formatOccurrenceSource(alarm_type, occurrence_source, displaySettingJson)}
                    ></Text>
                    <Spacer
                        display="flex"
                        gap="10px"
                        direction="column"
                        width="unset"
                        height="unset"
                        justifyContent="center"
                        alignItems="center"
                        overflow="hidden"
                        grow="1"
                        shrink="1"
                        basis="0"
                        alignSelf="stretch"
                        position="relative"
                        padding="10px 10px 10px 10px"
                    ></Spacer>

                    {/* 件数 */}
                    <Text
                        fontFamily="Noto Sans"
                        fontSize="14px"
                        fontWeight="500"
                        color="rgba(53,56,59,1)"
                        lineHeight="14px"
                        textAlign="right"
                        display="block"
                        direction="column"
                        justifyContent="unset"
                        letterSpacing="0.55px"
                        height="unset"
                        gap="unset"
                        alignItems="unset"
                        shrink="0"
                        position="relative"
                        padding="0px 0px 0px 0px"
                        whiteSpace="pre-wrap"
                        children={` ${count}件`}
                    ></Text>
                </Flex>

                <CommonDivider
                    height="1px"
                    direction="row"
                    width="unset"
                    backgroundColor="rgba(210,210,210,1)"
                ></CommonDivider>
            </Flex>
        </>
    )
}

const LogSummaryStateTypeView = ({ alarm_type, state_type, summaryData, displaySettingJson }) => {
    return (
        <>
            <Flex
                gap="0"
                direction="row"
                width={"100%"}
            >
                <Flex
                    gap={0}
                    direction="column"
                    width={"40%"}
                >
                    <Flex
                        gap="0"
                        grow={1}
                        direction="row"
                        alignItems="flex-start"
                        padding="16px"
                        backgroundColor="rgba(245,245,245,1)"
                    >
                        {/* 異常・警告 */}
                        <Text
                            fontFamily="Noto Sans"
                            fontSize="14px"
                            fontWeight="500"
                            color="rgba(53,56,59,1)"
                            lineHeight="14px"
                            textAlign="left"
                            display="block"
                            direction="column"
                            justifyContent="unset"
                            letterSpacing="0.55px"
                            width="40px"
                            height="unset"
                            gap="unset"
                            alignItems="unset"
                            shrink="0"
                            position="sticky"
                            top="16px"
                            padding="0px 0px 0px 0px"
                            whiteSpace="pre-wrap"
                            children={formatStateType(state_type)}
                        ></Text>
                        <Spacer
                            display="flex"
                            gap="10px"
                            direction="column"
                            height="unset"
                            justifyContent="center"
                            alignItems="center"
                            overflow="hidden"
                            shrink="0"
                            alignSelf="stretch"
                            position="relative"
                            padding="10px 10px 10px 10px"
                            grow={1}
                        ></Spacer>

                        {/* 件数 */}
                        <Text
                            fontFamily="Noto Sans"
                            fontSize="14px"
                            fontWeight="500"
                            color="rgba(53,56,59,1)"
                            lineHeight="14px"
                            textAlign="right"
                            display="block"
                            direction="column"
                            justifyContent="unset"
                            letterSpacing="0.55px"
                            height="unset"
                            gap="unset"
                            alignItems="unset"
                            shrink="0"
                            position="sticky"
                            top="16px"
                            padding="0px 0px 0px 0px"
                            whiteSpace="pre-wrap"
                            children={` ${summaryData.count}件`}
                        ></Text>
                    </Flex>

                    <CommonDivider
                        height="1px"
                        direction="row"
                        width="unset"
                        backgroundColor="rgba(210,210,210,1)"
                    ></CommonDivider>
                </Flex>
                {/* 機器の集計 */}
                <Flex
                    gap="0"
                    grow={1}
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    {
                        Object.keys(summaryData.occurrence_sources).map((key, index) => {
                            return (
                                <LogSummaryOccurrenceSourceView
                                    key={index}
                                    alarm_type={alarm_type}
                                    occurrence_source={key}
                                    count={summaryData.occurrence_sources[key]}
                                    displaySettingJson={displaySettingJson}
                                />
                            )
                        })
                    }
                </Flex >
            </Flex >
        </>
    )
}

const LogSummaryAlermView = ({ alarm_type, summaryData, displaySettingJson }) => {

    return (
        <>
            {/* アラーム監視タイプ */}
            <Flex
                gap="0"
                direction="row"
                width={"100%"}
            >
                <Flex
                    gap={0}
                    direction="column"
                    width={"20%"}
                >
                    {/* 監視名 */}
                    <Flex
                        gap="0"
                        grow={1}
                        direction="row"
                        alignItems="flex-start"
                        padding="16px"
                        backgroundColor="rgba(255,255,255,1)"
                    >
                        <Text
                            fontFamily="Noto Sans"
                            fontSize="14px"
                            fontWeight="500"
                            color="rgba(53,56,59,1)"
                            lineHeight="14px"
                            textAlign="left"
                            display="block"
                            direction="column"
                            justifyContent="unset"
                            letterSpacing="0.55px"
                            height="unset"
                            gap="unset"
                            alignItems="unset"
                            shrink="0"
                            position="sticky"
                            top="16px"
                            padding="0px 0px 0px 0px"
                            whiteSpace="pre-wrap"
                            children={formatAlarmType(alarm_type)}
                        ></Text>
                    </Flex>

                    <CommonDivider
                        height="1px"
                        direction="row"
                        width="unset"
                        backgroundColor="rgba(210,210,210,1)"
                    ></CommonDivider>
                </Flex>
                <Flex
                    gap="0"
                    grow={1}
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    {
                        Object.keys(summaryData).map((key, index) => {
                            return (
                                <LogSummaryStateTypeView
                                    key={index}
                                    alarm_type={alarm_type}
                                    state_type={key}
                                    summaryData={summaryData[key]}
                                    displaySettingJson={displaySettingJson}
                                />
                            )
                        })
                    }
                </Flex >
            </Flex >

        </>
    )
}

const LogSummaryTableRowView = ({ location, summaryData, displaySettingJson }) => {
    return (
        <>
            <Flex
                gap="0"
                direction="row"
            >
                <Flex
                    gap={0}
                    direction="column"
                    width={"30%"}
                >
                    {/* 拠点名 */}
                    <Flex
                        gap="0"
                        grow={1}
                        direction="row"
                        alignItems="flex-start"
                        padding="16px"
                        backgroundColor="rgba(245,245,245,1)"
                    >
                        <Text
                            fontFamily="Noto Sans"
                            fontSize="14px"
                            fontWeight="500"
                            color="rgba(53,56,59,1)"
                            lineHeight="14px"
                            textAlign="left"
                            display="block"
                            direction="column"
                            justifyContent="unset"
                            letterSpacing="0.55px"
                            width="80px"
                            height="unset"
                            gap="unset"
                            alignItems="unset"
                            shrink="0"
                            position="sticky"
                            top="16px"
                            padding="0px 0px 0px 0px"
                            whiteSpace="pre-wrap"
                            children={displaySettingJson.location_name[location]}
                        ></Text>
                        <Spacer
                            display="flex"
                            gap="10px"
                            direction="column"
                            height="unset"
                            justifyContent="center"
                            alignItems="center"
                            overflow="hidden"
                            shrink="0"
                            alignSelf="stretch"
                            position="relative"
                            padding="10px 10px 10px 10px"
                            grow={1}
                        ></Spacer>
                        <Text
                            fontFamily="Noto Sans"
                            fontSize="14px"
                            fontWeight="500"
                            color="rgba(53,56,59,1)"
                            lineHeight="14px"
                            textAlign="right"
                            display="block"
                            direction="column"
                            justifyContent="unset"
                            letterSpacing="0.55px"
                            height="unset"
                            gap="unset"
                            alignItems="unset"
                            shrink="0"
                            position="sticky"
                            top="16px"
                            padding="0px 0px 0px 0px"
                            whiteSpace="pre-wrap"
                            children={` ${summaryData.count}件`}
                        ></Text>
                    </Flex>

                    <CommonDivider
                        height="1px"
                        direction="row"
                        width="unset"
                        backgroundColor="rgba(210,210,210,1)"
                    ></CommonDivider>
                </Flex>

                <Flex
                    gap="0"
                    grow={1}
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    padding="0px 0px 0px 0px"
                >
                    {/* アラームタイプごとの集計 */}
                    {
                        Object.keys(summaryData.alarm_type).map((key, index) => {
                            return (
                                <LogSummaryAlermView
                                    key={index}
                                    alarm_type={key}
                                    summaryData={summaryData.alarm_type[key]}
                                    displaySettingJson={displaySettingJson}
                                />
                            )
                        })
                    }
                </Flex>
            </Flex >
        </>
    )
}

export const LogSummaryTable = ({
    summaryDataList,
    displaySettingJson,
    resultType,
}) => {
    return (
        <>
            <style>
                {
                    `@keyframes spin {
                    0% {transform: rotate(0deg);}
                    100% {transform: rotate(360deg);}
                }`
                }
            </style>
            <Flex
                gap="32px"
                direction="column"
                width="100%"
                borderRadius="20px"
                backgroundColor="rgba(255,255,255,1)"
            >
                <Flex
                    gap="8px"
                    direction="row"
                    width="100%"
                    height="unset"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    overflow="auto"
                >
                    <Flex
                        direction="column"
                        width="100%"
                        maxHeight="33vh"
                        gap={0}
                    >
                        {
                            resultType == "Success" ? "" : (
                                <KanshiFrameSummaryResult
                                    width={"100%"}
                                    height={"100%"}
                                    result={resultType}
                                    overrides={{
                                        Init: {
                                            width: "100%",
                                        },
                                        Empty: {
                                            width: "100%",
                                        },
                                        Error: {
                                            width: "100%",
                                        },
                                        Loading569221981: {
                                            type: "0",
                                            overrides: {
                                                Ellipse: {
                                                    left: "10px",
                                                },
                                                Circle: {
                                                    style: {
                                                        animation: "0.9s linear infinite spin",
                                                    }
                                                },
                                            },
                                        },
                                    }}
                                />
                            )

                        }

                        {/* ログ集計　拠点ごと */}
                        {
                            Object.keys(summaryDataList).map((key, index) => {
                                return (
                                    <LogSummaryTableRowView
                                        key={index}
                                        location={key}
                                        summaryData={summaryDataList[key]}
                                        displaySettingJson={displaySettingJson}
                                    />
                                )
                            })
                        }
                    </Flex>
                </Flex>
            </Flex>
        </>
    )
}
