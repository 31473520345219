import { useEffect, useState } from "react";


import {
    HeaderLocal
} from '../../ui-components'

export default function LocalHeaderWrapper(props) {

    const [dateText, setDateText] = useState("");

    useEffect(() => {
        const dateTextTimer = setInterval(() => {
            setNowDateText();
        }, 1000);

        return () => clearInterval(dateTextTimer);
    }, []);

    function setNowDateText() {
        var week = ["日", "月", "火", "水", "木", "金", "土"];
        var date = new Date();         // 現在日時を生成
        var year = date.getFullYear(); // 西暦を取得
        var month = ('0' + (date.getMonth() + 1)).slice(-2);  // 月を取得（返り値は実際の月-1なので、+1する）
        var day = ('0' + date.getDate()).slice(-2); // 日を取得
        var hour = ('0' + date.getHours()).slice(-2);
        var minite = ('0' + date.getMinutes()).slice(-2);
        var second = ('0' + date.getSeconds()).slice(-2);
        var weekday = date.getDay();   // 曜日を取得（数値）

        var nowDateText = year + "/" + month + "/" + day + " (" + week[weekday] + ")  " + hour + ":" + minite + ":" + second;

        setDateText(nowDateText);
    }

    // インジケーター状態
    const [indicatorState, setIndicatorState] = useState(false);

    useEffect(() => {
        const indicatorStateTimer = setInterval(async () => {
            let state = false;

            try {
                const hostname = window.location.hostname;
                const response = await fetch(`http://${hostname}:3001/api/awsStatus`);
                const data = await response.json();

                state = data.state;

                console.log("fetch result", data);
            } catch (error) {
                console.log("fetch error",error);
            }

            setIndicatorState(state);
        }, 5000);

        return () => clearInterval(indicatorStateTimer);
    }, []);

    return (
        <HeaderLocal
            marginBottom={"32px"}
            width={"100%"}
            minWidth={"1355px"}
            // onClick={props.onClick}
            // onClick={()=>clickTest("test")}
            overrides={{
                time: {
                    children: dateText
                },
                "logo": {
                    src: "/images/logo.png"
                },
                // Header: {
                //     gap: "0px"
                // },
                "Indicator" : {

                },
                "Kanshi/Antenna/indicator": {
                    type: indicatorState ? "Default" : "Error"
                },
                text: {
                    children: "干渉モニターシステム",
                },
            }}
        ></HeaderLocal>
    );
}