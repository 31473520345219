import { useState } from 'react';

// import {
//     getOverrideProps,
//     getOverridesFromVariants,
//     mergeVariantsAndOverrides,
// } from "@aws-amplify/ui-react/internal";

import { Check } from '../ui-components';

export default function CheckWrapper({ msg, okBtnMsg="OK", onClickOk, cancelBtnMsg="キャンセル", onClickCancel }) {
    // OKボタンのホバー状態を管理する
    const [situationOk, setSituationOk] = useState("default");

    // キャンセルボタンのホバー状態を管理する
    const [situationCancel, setSituationCancel] = useState("default");

    return (
        <Check
            cancel='true'
            overrides={{
                text: {
                    children: msg,
                },
                "Common/Btn/Blue": {
                    overrides: {
                        text: {
                            children: okBtnMsg,
                        },
                        CommonBtnBlue: {
                            style: {
                                cursor: "pointer",
                            },
                        },
                    },
                    // backgroundColor: "",
                    situation: situationOk,
                    onMouseEnter: () => {
                        setSituationOk("hover")
                    },
                    onMouseLeave: () => {
                        setSituationOk("default")
                    },
                    onClick: onClickOk,
                },
                "Common/Btn/White": {
                    overrides: {
                        text: {
                            children: cancelBtnMsg,
                        },
                        CommonBtnWhite: {
                            style: {
                                cursor: "pointer",
                            },
                        },
                    },
                    situation: situationCancel,
                    onMouseEnter: () => {
                        setSituationCancel("hover")
                    },
                    onMouseLeave: () => {
                        setSituationCancel("default")
                    },
                    onClick: onClickCancel,
                }
            }}
        >
        </Check>
    );
}