/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "@aws-amplify/ui-react/internal";
import { Flex, SelectField, Text } from "@aws-amplify/ui-react";
import Myicons from "./Myicons";
export default function DatakanriDataMakingLocation(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: {
        SelectField: {},
        Example: {},
        Myicons: {},
        text: {},
        ErrorMessage: {},
        DatakanriDataMakingLocation: {},
      },
      variantValues: { error: "true", example: "true" },
    },
    {
      overrides: {
        SelectField: {},
        Example: { display: "none" },
        Myicons: {},
        text: {},
        ErrorMessage: {},
        DatakanriDataMakingLocation: {},
      },
      variantValues: { error: "true", example: "false" },
    },
    {
      overrides: {
        SelectField: {},
        Example: {},
        Myicons: {},
        text: {},
        ErrorMessage: { display: "none" },
        DatakanriDataMakingLocation: {},
      },
      variantValues: { error: "false", example: "true" },
    },
    {
      overrides: {
        SelectField: {},
        Example: { display: "none" },
        Myicons: {},
        text: {},
        ErrorMessage: { display: "none" },
        DatakanriDataMakingLocation: {},
      },
      variantValues: { error: "false", example: "false" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="8px"
      direction="column"
      width="unset"
      height="unset"
      justifyContent="flex-start"
      alignItems="flex-start"
      position="relative"
      padding="0px 0px 0px 0px"
      display="flex"
      {...rest}
      {...getOverrideProps(overrides, "DatakanriDataMakingLocation")}
    >
      <SelectField
        width="382px"
        height="unset"
        label="対象拠点"
        placeholder="対象拠点を選択してください"
        shrink="0"
        size="default"
        isDisabled={false}
        labelHidden={false}
        variation="default"
        {...getOverrideProps(overrides, "SelectField")}
      ></SelectField>
      <Text
        fontFamily="Noto Sans"
        fontSize="12px"
        fontWeight="500"
        color="rgba(170,170,170,1)"
        lineHeight="12px"
        textAlign="left"
        display="block"
        direction="column"
        justifyContent="unset"
        letterSpacing="0.45px"
        width="376px"
        height="unset"
        gap="unset"
        alignItems="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="ex:YYCC"
        {...getOverrideProps(overrides, "Example")}
      ></Text>
      <Flex
        gap="8px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        display="flex"
        {...getOverrideProps(overrides, "ErrorMessage")}
      >
        <Myicons
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          overflow="hidden"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          type="attention"
          {...getOverrideProps(overrides, "Myicons")}
        ></Myicons>
        <Text
          fontFamily="Noto Sans"
          fontSize="12px"
          fontWeight="700"
          color="rgba(255,76,108,1)"
          lineHeight="12px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          letterSpacing="0.45px"
          width="352px"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="開始終了期間が逆転しています"
          {...getOverrideProps(overrides, "text")}
        ></Text>
      </Flex>
    </Flex>
  );
}
