/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "@aws-amplify/ui-react/internal";
import SetteiTab from "./SetteiTab";
import { Flex } from "@aws-amplify/ui-react";
export default function SetteiLocation(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: {
        "Settei/Tab35993728": {},
        "Settei/Tab35993730": {},
        "Settei/Tab35993732": {},
        "Settei/Tab35993734": {},
        SetteiLocation: {},
      },
      variantValues: { situation: "YSCC" },
    },
    {
      overrides: {
        "Settei/Tab35993728": { situation: "default" },
        "Settei/Tab35993730": {
          situation: "select",
          backgroundColor: "rgba(255,255,255,1)",
        },
        "Settei/Tab35993732": {},
        "Settei/Tab35993734": {},
        SetteiLocation: {},
      },
      variantValues: { situation: "SPE" },
    },
    {
      overrides: {
        "Settei/Tab35993728": { situation: "default" },
        "Settei/Tab35993730": {},
        "Settei/Tab35993732": {
          situation: "select",
          backgroundColor: "rgba(255,255,255,1)",
        },
        "Settei/Tab35993734": {},
        SetteiLocation: {},
      },
      variantValues: { situation: "SPW" },
    },
    {
      overrides: {
        "Settei/Tab35993728": { situation: "default" },
        "Settei/Tab35993730": {},
        "Settei/Tab35993732": {},
        "Settei/Tab35993734": {
          situation: "select",
          backgroundColor: "rgba(255,255,255,1)",
        },
        SetteiLocation: {},
      },
      variantValues: { situation: "GUNMA" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="8px"
      direction="row"
      width="unset"
      height="unset"
      justifyContent="flex-start"
      alignItems="flex-start"
      position="relative"
      padding="0px 0px 0px 0px"
      display="flex"
      {...rest}
      {...getOverrideProps(overrides, "SetteiLocation")}
    >
      <SetteiTab
        display="flex"
        gap="10px"
        direction="row"
        width="176px"
        height="unset"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        position="relative"
        borderRadius="8px 8px 0px 0px"
        padding="16px 24px 16px 24px"
        backgroundColor="rgba(255,255,255,1)"
        situation="select"
        {...getOverrideProps(overrides, "Settei/Tab35993728")}
      ></SetteiTab>
      <SetteiTab
        display="flex"
        gap="10px"
        direction="row"
        width="176px"
        height="unset"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        position="relative"
        borderRadius="8px 8px 0px 0px"
        padding="16px 24px 16px 24px"
        situation="default"
        {...getOverrideProps(overrides, "Settei/Tab35993730")}
      ></SetteiTab>
      <SetteiTab
        display="flex"
        gap="10px"
        direction="row"
        width="176px"
        height="unset"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        position="relative"
        borderRadius="8px 8px 0px 0px"
        padding="16px 24px 16px 24px"
        situation="default"
        {...getOverrideProps(overrides, "Settei/Tab35993732")}
      ></SetteiTab>
      <SetteiTab
        display="flex"
        gap="10px"
        direction="row"
        width="176px"
        height="unset"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        position="relative"
        borderRadius="8px 8px 0px 0px"
        padding="16px 24px 16px 24px"
        situation="default"
        {...getOverrideProps(overrides, "Settei/Tab35993734")}
      ></SetteiTab>
    </Flex>
  );
}
