// import './App.css';
import { BrowserRouter as Router,Routes, Route } from 'react-router-dom';
import './buffer';
import React, { useContext } from "react";

import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';
import DataKanriMain from './DataKanriMain';
import KanshiMain from './KanshiMain';
import KanshiSettings from './KanshiSettings';
import AuthProvider from './AuthProvider';
import Callback from './Callback';
import { UserProvider, UserContext } from './UserContext';
import { isLocalMode } from './lib/envUtil';
import LocalMain from './LocalMain';

// アラーム音を制御するコンテキスト
import ControlAlarmProvider from "./contexts/ControlAlarmContext";

// パトライトを制御するコンテキスト
import ControlPatliteProvider from "./contexts/ControlPatliteContext";

// ローカルモード時はAPIキー認証でデータ取得
if (isLocalMode) {
  awsconfig.aws_appsync_authenticationType = 'API_KEY';
}
Amplify.configure(awsconfig);

//　ローカルモード以外では、認証設定を追加
if (!isLocalMode) {
  Amplify.configure({
    Auth: {
      identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
      region: process.env.REACT_APP_COGNITO_REGION,
    },
  });
}


const App = () => {
  return (
    isLocalMode ?
    <Router>
      <div className="App">
        <RouteComponentsLocal />
      </div>
    </Router>
    :
    <Router>
      <AuthProvider>
        <UserProvider>
          <ControlAlarmProvider>
            <ControlPatliteProvider>
              <div className="App">
                <RouteComponents />
              </div>
            </ControlPatliteProvider>
          </ControlAlarmProvider>
        </UserProvider>
      </AuthProvider>
    </Router>
  );
}

const RouteComponents = () => {
  const { userInfo } = useContext(UserContext);
  return (
    <Routes>
      {/* // ログイン時のOIDCコールバック用コンポーネント */}
      <Route path="/callback" Component={Callback} />
      {/* // 管理組織(スカパー)の場合は監視画面がTOP、携帯事業者はデータ管理画面のみ) */}
      <Route path="/*" Component={userInfo.isAdminOrg ? KanshiMain : DataKanriMain} />
      {userInfo.isAdminOrg && (
        <>
          <Route path="/datakanri" Component={DataKanriMain} />
          {userInfo.isAdminUser && (
          // 管理者ユーザーの場合は設定画面も表示可能
          <Route path="/settings" Component={KanshiSettings} />
          )}
        </>
      )}
    </Routes> 
  );
};

// ローカルモード時は制限付きの監視メイン画面のみ表示可能
const RouteComponentsLocal = () => {

  return (
    <Routes>
      <Route path="/*" Component={LocalMain} />
    </Routes> 
  );
};


export default App;

