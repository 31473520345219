import {
    KeitouzuFrameOther,
    KeitouzuFrameSayado
} from '../ui-components'

import { DeviceKeys } from '../lib/ConstList'
import { isLocalMode } from "../lib/envUtil";


export default function KanshiKyotenMachineStateWrapper(props) {
    // タイトル
    const KEITOUZU_TITLE_KYOKUSYA = "各局社内";
    const KEITOUZU_TITLE_OKUGAI = "屋外防水キャビネット";
    const KEITOUZU_TITLE_SHELTER = "シェルター内ラック";

    function getItemBadgeType(deviceKey) {
        if (deviceKey !== DeviceKeys.MEASUREMENT_PC_SUB) {
            const mainPcStatus = props.deviceMoniteringStatus[DeviceKeys.ALL]?.state

            if (mainPcStatus === "Error" || mainPcStatus === "Warning") {
                return mainPcStatus;
            }
            return props.deviceMoniteringStatus[deviceKey]?.state === "None" ?
                "Gray" : props.deviceMoniteringStatus[deviceKey]?.state
        } else {
            return props.deviceMoniteringStatus[deviceKey]?.state === "None" ?
                "Gray" : props.deviceMoniteringStatus[deviceKey]?.state
        }
    }

    // パラメータ
    const overrides = {
        // 回線装置2
        "Keitouzu/Item/Badge/InternetConnectionUnit2": {
            left: "159px",
        },
        // NWSW1
        "Keitouzu/Item/Badge/LanSwitch1": {
            type: getItemBadgeType(DeviceKeys.LAN_SWITCH_1),
        },
        // NWSW2
        "Keitouzu/Item/Badge/LanSwitch2": {
            type: getItemBadgeType(DeviceKeys.LAN_SWITCH_2),
        },
        // NWSW3
        "Keitouzu/Item/Badge/LanSwitch3": {
            type: getItemBadgeType(DeviceKeys.LAN_SWITCH_3),
        },
        // NWSW4
        "Keitouzu/Item/Badge/LanSwitch4": {
            type: getItemBadgeType(DeviceKeys.LAN_SWITCH_4),
        },
        // 計測PCメイン機
        "Keitouzu/Item/Badge/MeasurementPcMain": {
            type: getItemBadgeType(DeviceKeys.MEASUREMENT_PC_MAIN),
        },
        // 計測PCサブ機
        "Keitouzu/Item/Badge/MeasurementPcSub": {
            type: isLocalMode ? "White" : getItemBadgeType(DeviceKeys.MEASUREMENT_PC_SUB),
            left: "188px",
        },
        // スペアナ1
        "Keitouzu/Item/Badge/SpectrumAnalyzer1": {
            type: getItemBadgeType(DeviceKeys.SPEANA_1),
        },
        // スペアナ2
        "Keitouzu/Item/Badge/SpectrumAnalyzer2": {
            type: getItemBadgeType(DeviceKeys.SPEANA_2),
        },
        // GPS1
        "Keitouzu/Item/Badge/GpsSensor1": {
            type: getItemBadgeType(DeviceKeys.GPS_1),
        },
        // GPS2
        "Keitouzu/Item/Badge/GpsSensor2": {
            type: getItemBadgeType(DeviceKeys.GPS_2),
        },
        // DC電源1
        "Keitouzu/Item/Badge/DirectCurrentPower1": {
            type: getItemBadgeType(DeviceKeys.DC_POWER_1),
        },
        // DC電源2
        "Keitouzu/Item/Badge/DirectCurrentPower2": {
            type: getItemBadgeType(DeviceKeys.DC_POWER_2),
        },
        // DC電源3
        "Keitouzu/Item/Badge/DirectCurrentPower3": {
            type: getItemBadgeType(DeviceKeys.DC_POWER_3),
        },
        // DC電源4
        "Keitouzu/Item/Badge/DirectCurrentPower4": {
            type: getItemBadgeType(DeviceKeys.DC_POWER_4),
        },
        // DC電源5
        "Keitouzu/Item/Badge/DirectCurrentPower5": {
            type: getItemBadgeType(DeviceKeys.DC_POWER_5),
        },
        // 温度センサー
        "Keitouzu/Item/Badge/TemperatureSensor": {
            type: getItemBadgeType(DeviceKeys.TEMP_SENSOR),
        },
        // 電流モニター
        "Keitouzu/Item/Badge/EnergyGazer": {
            type: getItemBadgeType(DeviceKeys.ENERGY_GAZER),
        },
        // ラック内温度
        "Keitouzu/Item/Badge/OutdoorCabinetTemperature": {
            type: getItemBadgeType(DeviceKeys.OUTDOOR_CABINET),
        },
        // ノイズソース温度
        "Keitouzu/Item/Badge/NoiseSourceTemperature": {
            type: getItemBadgeType(DeviceKeys.NOISE_SOURCE),
        },

    };

    return (
        <>
            {
                // sayadoの場合
                props.location == "SAYADO" ? (
                    <KeitouzuFrameSayado
                        style={{
                            flexGrow: 1,
                        }}
                        overrides={{
                            ...overrides,

                            // タイトル:シェルター内ラック
                            "Keitouzu/Item/Title": {
                                overrides: {
                                    text: {
                                        children: KEITOUZU_TITLE_SHELTER
                                    }
                                }
                            },
                        }}
                    />
                ) : (
                    <KeitouzuFrameOther
                        style={{
                            flexGrow: 1,
                        }}
                        overrides={{
                            ...overrides,

                            // タイトル:屋外防水キャビネット
                            "Keitouzu/Item/Title571118095": {
                                overrides: {
                                    text: {
                                        children: KEITOUZU_TITLE_OKUGAI
                                    }
                                }
                            },

                            // タイトル:各局舎内
                            "Keitouzu/Item/Title571118060": {
                                overrides: {
                                    text: {
                                        children: KEITOUZU_TITLE_KYOKUSYA
                                    }
                                }
                            },
                            
                        }}
                    />
                )
            }

        </>
    );
}